

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "../../../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "../../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Toolkit__Intl from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/intl/Toolkit__Intl.bs.js";
import * as Toolkit__Hooks from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/hooks/Toolkit__Hooks.bs.js";
import * as Toolkit__Ui_Alert from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Alert.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ColiswebApi__V6_Geo from "../../../v6/ColiswebApi__V6_Geo.bs.js";
import * as Toolkit__Ui_Autocomplete from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Autocomplete.bs.js";
import * as Toolkit__Primitives_Array from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";
import * as AutocompleteAddress__AddressFormModal from "./AutocompleteAddress__AddressFormModal.bs.js";
import * as AutocompleteAddress__HouseNumberMissingModal from "./AutocompleteAddress__HouseNumberMissingModal.bs.js";

var Msg_geocodeError = {
  id: "_e7f1b608",
  defaultMessage: "Une erreur est survenue lors du décodage de l'adresse"
};

var Msg_placeSearchError = {
  id: "_dd9a9f8b",
  defaultMessage: "Une erreur est survenue lors de la recherche d'adresse"
};

var Msg = {
  geocodeError: Msg_geocodeError,
  placeSearchError: Msg_placeSearchError
};

function ApiComponents__AutocompleteAddress(props) {
  var __hideLine2 = props.hideLine2;
  var __shouldGeocodeLocation = props.shouldGeocodeLocation;
  var __isPublic = props.isPublic;
  var __displayEditCustomAddressAlert = props.displayEditCustomAddressAlert;
  var __placeholder = props.placeholder;
  var __optionClassName = props.optionClassName;
  var __optionsContainerClassName = props.optionsContainerClassName;
  var __inputClassName = props.inputClassName;
  var __errorSearchClassName = props.errorSearchClassName;
  var __emptySearchClassName = props.emptySearchClassName;
  var __containerClassName = props.containerClassName;
  var value = props.value;
  var onCustomAddressValidation = props.onCustomAddressValidation;
  var onSelect = props.onSelect;
  var locationBias = props.locationBias;
  var containerClassName = __containerClassName !== undefined ? __containerClassName : "";
  var emptySearchClassName = __emptySearchClassName !== undefined ? __emptySearchClassName : "";
  var errorSearchClassName = __errorSearchClassName !== undefined ? __errorSearchClassName : "";
  var inputClassName = __inputClassName !== undefined ? __inputClassName : "";
  var optionsContainerClassName = __optionsContainerClassName !== undefined ? __optionsContainerClassName : "";
  var optionClassName = __optionClassName !== undefined ? __optionClassName : "";
  var placeholder = __placeholder !== undefined ? __placeholder : "";
  var displayEditCustomAddressAlert = __displayEditCustomAddressAlert !== undefined ? __displayEditCustomAddressAlert : true;
  var isPublic = __isPublic !== undefined ? __isPublic : false;
  var shouldGeocodeLocation = __shouldGeocodeLocation !== undefined ? __shouldGeocodeLocation : true;
  var hideLine2 = __hideLine2 !== undefined ? __hideLine2 : false;
  var addressFormModal = Toolkit__Hooks.useDisclosure(undefined, undefined);
  var houseNumberFormModal = Toolkit__Hooks.useDisclosure(false, undefined);
  var geocodeError = Toolkit__Hooks.useDisclosure(undefined, undefined);
  var geocodeLocation = function (value) {
    Curry._1(geocodeError.hide, undefined);
    $$Promise.tap($$Request.fetchAPI({
              response_decode: ColiswebApi__V6_Geo.GeocodeLocation.response_decode,
              config: ColiswebApi__V6_Geo.GeocodeLocation.config
            }, {
              location: value,
              level: undefined,
              center: locationBias
            }), (function (result) {
            if (result.TAG !== "Ok") {
              return Curry._1(geocodeError.show, undefined);
            }
            var data = result._0;
            if (data !== undefined) {
              return Curry._1(onSelect, {
                          TAG: "GeocodeLocation",
                          _0: data
                        });
            } else {
              return Curry._1(geocodeError.show, undefined);
            }
          }));
  };
  var lastSuggestionSelected = React.useRef(undefined);
  var onValueSelect = function (suggestion) {
    Belt_Option.forEach(suggestion, (function (suggestion) {
            var geoSuggestion = suggestion.value;
            if (geoSuggestion.TAG !== "Geo") {
              return ;
            }
            var geoSuggestion$1 = geoSuggestion._0;
            var houseNumber = geoSuggestion$1.houseNumber;
            if (houseNumber !== undefined) {
              if (shouldGeocodeLocation) {
                return geocodeLocation(suggestion.label);
              } else {
                return Curry._1(onSelect, {
                            TAG: "GeocodeLocation",
                            _0: {
                              coordinates: {
                                latitude: 0,
                                longitude: 0
                              },
                              location: {
                                line1: houseNumber + " " + geoSuggestion$1.street,
                                line2: undefined,
                                postalCode: geoSuggestion$1.postalCode,
                                city: geoSuggestion$1.city,
                                country: geoSuggestion$1.country
                              },
                              administrativeArea: undefined,
                              level: "streetAddress"
                            }
                          });
              }
            } else {
              Curry._1(houseNumberFormModal.show, undefined);
              lastSuggestionSelected.current = suggestion;
              return ;
            }
          }));
  };
  var onSearch = function (search) {
    var tmp;
    if (isPublic) {
      var $$let = ColiswebApi__V6_Geo.Public.Autocomplete;
      tmp = {
        response_decode: $$let.response_decode,
        config: $$let.config
      };
    } else {
      tmp = {
        response_decode: ColiswebApi__V6_Geo.Autocomplete.response_decode,
        config: ColiswebApi__V6_Geo.Autocomplete.config
      };
    }
    return $$Promise.flatMap($$Request.fetchAPI(tmp, {
                    q: search,
                    center: Belt_Option.map(locationBias, (function (param) {
                            return param.latitude.toString() + "," + param.longitude.toString();
                          })),
                    limit: 5,
                    lang: Toolkit__Intl.availableLanguagesToString("fr")
                  }), (function (res) {
                  if (res.TAG === "Ok") {
                    return $$Promise.resolved({
                                TAG: "Ok",
                                _0: Curry._2(Toolkit__Primitives_Array.$$Array.map, res._0, (function (prediction) {
                                        var houseNumber = Belt_Option.mapWithDefault(prediction.houseNumber, "", (function (n) {
                                                return n + " ";
                                              }));
                                        var text = houseNumber + prediction.street + " " + prediction.postalCode + " " + prediction.city + " " + prediction.country;
                                        return {
                                                label: text,
                                                value: {
                                                  TAG: "Geo",
                                                  _0: prediction
                                                }
                                              };
                                      }))
                              });
                  } else {
                    return $$Promise.resolved({
                                TAG: "Error",
                                _0: undefined
                              });
                  }
                }));
  };
  var defaultSelectedValue = Belt_Option.map(value, (function (point) {
          if (point.TAG === "GeocodeLocation") {
            var match = point._0.location;
            return {
                    label: match.line1 + " " + match.postalCode + " " + match.city,
                    value: {
                      TAG: "SelectedValue",
                      _0: point
                    }
                  };
          }
          var match$1 = point._0;
          return {
                  label: match$1.line1 + " " + match$1.postalCode + " " + match$1.city,
                  value: {
                    TAG: "SelectedValue",
                    _0: point
                  }
                };
        }));
  var tmp;
  tmp = value !== undefined && !(value.TAG === "GeocodeLocation" || !displayEditCustomAddressAlert) ? JsxRuntime.jsx(Toolkit__Ui_Alert.make, {
          title: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                id: "_592583c3",
                defaultMessage: "Vous avez renseigné une adresse personnalisée, <a>cliquez ici</a> pour l'éditer",
                values: {
                  a: (function (children) {
                      return JsxRuntime.jsx("a", {
                                  children: children,
                                  className: "text-info-600 font-semibold underline cursor-pointer",
                                  onClick: (function (param) {
                                      Curry._1(addressFormModal.show, undefined);
                                    })
                                });
                    })
                }
              }),
          status: "info",
          className: "mt-2"
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Toolkit__Ui_Autocomplete.make, {
                      onSelect: onValueSelect,
                      value: defaultSelectedValue,
                      onSearch: onSearch,
                      emptySearchMessage: props.emptySearchMessage,
                      containerClassName: containerClassName,
                      emptySearchClassName: emptySearchClassName,
                      errorSearchClassName: errorSearchClassName,
                      inputClassName: inputClassName,
                      optionsContainerClassName: optionsContainerClassName,
                      optionClassName: optionClassName,
                      placeholder: placeholder,
                      minSearchLength: 3,
                      additionnalOption: Caml_option.some(JsxRuntime.jsx("p", {
                                children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                      id: "_eabd3b2c",
                                      defaultMessage: "Adresse introuvable ? <a>Cliquez ici</a>",
                                      values: {
                                        a: (function (children) {
                                            return JsxRuntime.jsx("a", {
                                                        children: children,
                                                        className: "text-info-500 cursor-pointer hover:underline",
                                                        onClick: (function (param) {
                                                            Curry._1(addressFormModal.show, undefined);
                                                          })
                                                      });
                                          })
                                      }
                                    }),
                                className: "p-2 text-sm"
                              })),
                      children: (function (param) {
                          var setSuggestions = param.setSuggestions;
                          var setSearch = param.setSearch;
                          return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                      children: [
                                        JsxRuntime.jsx(AutocompleteAddress__AddressFormModal.make, {
                                              onSubmit: (function (address) {
                                                  Curry._2(onCustomAddressValidation, {
                                                        TAG: "CustomAddress",
                                                        _0: address
                                                      }, addressFormModal);
                                                }),
                                              isVisible: addressFormModal.isOpen,
                                              hide: addressFormModal.hide,
                                              defaultValue: Belt_Option.flatMap(value, (function (value) {
                                                      if (value.TAG === "GeocodeLocation") {
                                                        return ;
                                                      } else {
                                                        return value._0;
                                                      }
                                                    })),
                                              hideLine2: hideLine2
                                            }),
                                        JsxRuntime.jsx(AutocompleteAddress__HouseNumberMissingModal.make, {
                                              onSubmit: (function (houseNumber) {
                                                  Curry._1(houseNumberFormModal.hide, undefined);
                                                  Belt_Option.forEach(lastSuggestionSelected.current, (function (suggestion) {
                                                          var newAddressLabel = houseNumber + " " + suggestion.label;
                                                          Curry._1(setSearch, (function (param) {
                                                                  return [
                                                                          newAddressLabel,
                                                                          true
                                                                        ];
                                                                }));
                                                          Curry._1(setSuggestions, (function (param) {
                                                                  
                                                                }));
                                                          var geoSuggestion = suggestion.value;
                                                          if (geoSuggestion.TAG !== "Geo") {
                                                            return geocodeLocation(newAddressLabel);
                                                          }
                                                          if (shouldGeocodeLocation) {
                                                            return geocodeLocation(newAddressLabel);
                                                          }
                                                          var geoSuggestion$1 = geoSuggestion._0;
                                                          Curry._1(onSelect, {
                                                                TAG: "GeocodeLocation",
                                                                _0: {
                                                                  coordinates: {
                                                                    latitude: 0,
                                                                    longitude: 0
                                                                  },
                                                                  location: {
                                                                    line1: newAddressLabel,
                                                                    line2: undefined,
                                                                    postalCode: geoSuggestion$1.postalCode,
                                                                    city: geoSuggestion$1.city,
                                                                    country: geoSuggestion$1.country
                                                                  },
                                                                  administrativeArea: undefined,
                                                                  level: "streetAddress"
                                                                }
                                                              });
                                                        }));
                                                }),
                                              isVisible: houseNumberFormModal.isOpen,
                                              hide: houseNumberFormModal.hide
                                            })
                                      ]
                                    });
                        })
                    }),
                tmp,
                geocodeError.isOpen ? JsxRuntime.jsx("p", {
                        children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                              id: "_e7f1b608",
                              defaultMessage: "Une erreur est survenue lors du décodage de l'adresse"
                            }),
                        className: "text-danger-500"
                      }) : null
              ]
            });
}

var Coordinates;

var make = ApiComponents__AutocompleteAddress;

export {
  Coordinates ,
  Msg ,
  make ,
}
/* react Not a pure module */
