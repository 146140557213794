

import * as Axios from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/Axios.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Toolkit__Request.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__Utils from "../ColiswebApi__Utils.bs.js";
import * as ColiswebApi__V6_Geo from "../v6/ColiswebApi__V6_Geo.bs.js";
import * as ColiswebApi__V5_Utils from "./ColiswebApi__V5_Utils.bs.js";
import * as Decoders__UnitMeasure from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Decoders__UnitMeasure.bs.js";
import * as ColiswebApi__V5_Shared from "./ColiswebApi__V5_Shared.bs.js";
import * as Toolkit__Primitives_Array from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";

var arg = ColiswebApi__Env.v5ApiUrl;

var arg$1 = ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000;

function config(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  var partial_arg = true;
  return function (param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
    var tmp = {};
    if (param !== undefined) {
      tmp.url = param;
    }
    if (param$1 !== undefined) {
      tmp._method = param$1;
    }
    if (arg !== undefined) {
      tmp.baseURL = arg;
    }
    if (param$2 !== undefined) {
      tmp.transformRequest = Caml_option.valFromOption(param$2);
    }
    if (param$3 !== undefined) {
      tmp.transformResponse = Caml_option.valFromOption(param$3);
    }
    if (param$4 !== undefined) {
      tmp.headers = Caml_option.valFromOption(param$4);
    }
    if (param$5 !== undefined) {
      tmp.params = Caml_option.valFromOption(param$5);
    }
    if (param$6 !== undefined) {
      tmp.paramsSerializer = Caml_option.valFromOption(param$6);
    }
    if (param$7 !== undefined) {
      tmp.data = Caml_option.valFromOption(param$7);
    }
    if (arg$1 !== undefined) {
      tmp.timeout = arg$1;
    }
    if (partial_arg !== undefined) {
      tmp.withCredentials = partial_arg;
    }
    if (param$8 !== undefined) {
      tmp.adapter = Caml_option.valFromOption(param$8);
    }
    if (param$9 !== undefined) {
      tmp.auth = Caml_option.valFromOption(param$9);
    }
    if (param$10 !== undefined) {
      tmp.responseType = param$10;
    }
    if (param$11 !== undefined) {
      tmp.xsrfCookieName = param$11;
    }
    if (param$12 !== undefined) {
      tmp.xsrfHeaderName = param$12;
    }
    if (param$13 !== undefined) {
      tmp.onUploadProgress = Caml_option.valFromOption(param$13);
    }
    if (param$14 !== undefined) {
      tmp.onDownloadProgress = Caml_option.valFromOption(param$14);
    }
    if (param$15 !== undefined) {
      tmp.maxContentLength = param$15;
    }
    if (param$16 !== undefined) {
      tmp.validateStatus = Caml_option.valFromOption(param$16);
    }
    if (param$17 !== undefined) {
      tmp.maxRedirects = param$17;
    }
    if (param$18 !== undefined) {
      tmp.socketPath = param$18;
    }
    if (param$19 !== undefined) {
      tmp.proxy = Caml_option.valFromOption(param$19);
    }
    if (param$20 !== undefined) {
      tmp.cancelToken = Caml_option.valFromOption(param$20);
    }
    return tmp;
  };
}

function baseUrl(clientId, storeId) {
  return "/clients/" + (clientId + ("/stores/" + storeId));
}

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "firstName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lastName)
                  ],
                  [
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone1)
                  ],
                  [
                    "phone2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone2)
                  ]
                ]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var firstName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
  if (firstName.TAG === "Ok") {
    var lastName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
    if (lastName.TAG === "Ok") {
      var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var phone1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
        if (phone1.TAG === "Ok") {
          var phone2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone2"), null));
          if (phone2.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      firstName: firstName._0,
                      lastName: lastName._0,
                      email: email._0,
                      phone1: phone1._0,
                      phone2: phone2._0
                    }
                  };
          }
          var e = phone2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".phone2" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = phone1._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phone1" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = lastName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lastName" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = firstName._0;
  return {
          TAG: "Error",
          _0: {
            path: ".firstName" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

var response_encode = Spice.unitToJson;

var response_decode = Spice.unitFromJson;

function exec(param) {
  return Axios.WithResult.post(undefined, body_encode(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode, undefined, baseUrl(param[0], param[1]) + "/contacts");
}

var Config = {
  body_encode: body_encode,
  body_decode: body_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  exec: exec
};

var $$Request = Toolkit__Request.Make({
      exec: exec
    });

var CreateContact = {
  Config: Config,
  $$Request: $$Request
};

function body_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "firstName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lastName)
                  ],
                  [
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone1)
                  ],
                  [
                    "phone2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone2)
                  ]
                ]));
}

function body_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var firstName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
  if (firstName.TAG === "Ok") {
    var lastName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
    if (lastName.TAG === "Ok") {
      var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var phone1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
        if (phone1.TAG === "Ok") {
          var phone2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone2"), null));
          if (phone2.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      firstName: firstName._0,
                      lastName: lastName._0,
                      email: email._0,
                      phone1: phone1._0,
                      phone2: phone2._0
                    }
                  };
          }
          var e = phone2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".phone2" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = phone1._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phone1" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = lastName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lastName" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = firstName._0;
  return {
          TAG: "Error",
          _0: {
            path: ".firstName" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

var response_encode$1 = Spice.unitToJson;

var response_decode$1 = Spice.unitFromJson;

function exec$1(param) {
  return Axios.WithResult.put(undefined, body_encode$1(param[3]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$1, undefined, baseUrl(param[0], param[1]) + ("/contacts/" + param[2]));
}

var Config$1 = {
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  exec: exec$1
};

var $$Request$1 = Toolkit__Request.Make({
      exec: exec$1
    });

var UpdateContact = {
  Config: Config$1,
  $$Request: $$Request$1
};

var response_encode$2 = Spice.unitToJson;

var response_decode$2 = Spice.unitFromJson;

function exec$2(param) {
  return Axios.WithResult.$$delete(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$2, undefined, baseUrl(param[0], param[1]) + ("/contacts/" + param[2]));
}

var Config$2 = {
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  exec: exec$2
};

var $$Request$2 = Toolkit__Request.Make({
      exec: exec$2
    });

var DeleteContact = {
  Config: Config$2,
  $$Request: $$Request$2
};

var Contact = {
  CreateContact: CreateContact,
  UpdateContact: UpdateContact,
  DeleteContact: DeleteContact
};

function address_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "name",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.name)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ],
                  [
                    "liftInformation",
                    false,
                    ColiswebApi__V5_Utils.Lift.t_encode(v.lift)
                  ],
                  [
                    "latitude",
                    false,
                    Spice.floatToJson(v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.floatToJson(v.longitude)
                  ]
                ]));
}

function address_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
  if (postalCode.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
      if (country.TAG === "Ok") {
        var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
        if (line1.TAG === "Ok") {
          var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
          if (line2.TAG === "Ok") {
            var name = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
            if (name.TAG === "Ok") {
              var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
              if (comment.TAG === "Ok") {
                var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
                if (floor.TAG === "Ok") {
                  var lift = ColiswebApi__V5_Utils.Lift.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "liftInformation"), null));
                  if (lift.TAG === "Ok") {
                    var latitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
                    if (latitude.TAG === "Ok") {
                      var longitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
                      if (longitude.TAG === "Ok") {
                        return {
                                TAG: "Ok",
                                _0: {
                                  postalCode: postalCode._0,
                                  city: city._0,
                                  country: country._0,
                                  line1: line1._0,
                                  line2: line2._0,
                                  name: name._0,
                                  comment: comment._0,
                                  floor: floor._0,
                                  lift: lift._0,
                                  latitude: latitude._0,
                                  longitude: longitude._0
                                }
                              };
                      }
                      var e = longitude._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".longitude" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = latitude._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".latitude" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = lift._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: "." + ("liftInformation" + e$2.path),
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = floor._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".floor" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = comment._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".comment" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = name._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".name" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = line2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".line2" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = line1._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".line1" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = country._0;
      return {
              TAG: "Error",
              _0: {
                path: ".country" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = postalCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".postalCode" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function contact_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "firstName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lastName)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone1)
                  ],
                  [
                    "phone2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone2)
                  ],
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ContactIdString.t_encode, v.id)
                  ]
                ]));
}

function contact_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    var firstName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
    if (firstName.TAG === "Ok") {
      var lastName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
      if (lastName.TAG === "Ok") {
        var phone1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
        if (phone1.TAG === "Ok") {
          var phone2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone2"), null));
          if (phone2.TAG === "Ok") {
            var id = Curry._1(Identifiers.ContactIdString.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
            if (id.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        email: email._0,
                        firstName: firstName._0,
                        lastName: lastName._0,
                        phone1: phone1._0,
                        phone2: phone2._0,
                        id: id._0
                      }
                    };
            }
            var e = id._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".id" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = phone2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".phone2" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = phone1._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phone1" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = lastName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lastName" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = firstName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".firstName" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function retailerFlowType_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ]
                ]));
}

function retailerFlowType_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
      if (tag.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  name: name._0,
                  tag: tag._0
                }
              };
      }
      var e = tag._0;
      return {
              TAG: "Error",
              _0: {
                path: ".tag" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function holidaySchedule_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
      if (id.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  start: start._0,
                  end_: end_._0,
                  id: id._0
                }
              };
      }
      var e = id._0;
      return {
              TAG: "Error",
              _0: {
                path: ".id" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e$1.path),
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function workingHours_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var monday = Spice.arrayFromJson(schedule_decode, Belt_Option.getWithDefault(Js_dict.get(v, "monday"), null));
  if (monday.TAG === "Ok") {
    var tuesday = Spice.arrayFromJson(schedule_decode, Belt_Option.getWithDefault(Js_dict.get(v, "tuesday"), null));
    if (tuesday.TAG === "Ok") {
      var wednesday = Spice.arrayFromJson(schedule_decode, Belt_Option.getWithDefault(Js_dict.get(v, "wednesday"), null));
      if (wednesday.TAG === "Ok") {
        var thursday = Spice.arrayFromJson(schedule_decode, Belt_Option.getWithDefault(Js_dict.get(v, "thursday"), null));
        if (thursday.TAG === "Ok") {
          var friday = Spice.arrayFromJson(schedule_decode, Belt_Option.getWithDefault(Js_dict.get(v, "friday"), null));
          if (friday.TAG === "Ok") {
            var saturday = Spice.arrayFromJson(schedule_decode, Belt_Option.getWithDefault(Js_dict.get(v, "saturday"), null));
            if (saturday.TAG === "Ok") {
              var sunday = Spice.arrayFromJson(schedule_decode, Belt_Option.getWithDefault(Js_dict.get(v, "sunday"), null));
              if (sunday.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          monday: monday._0,
                          tuesday: tuesday._0,
                          wednesday: wednesday._0,
                          thursday: thursday._0,
                          friday: friday._0,
                          saturday: saturday._0,
                          sunday: sunday._0
                        }
                      };
              }
              var e = sunday._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".sunday" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = saturday._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".saturday" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = friday._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".friday" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = thursday._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".thursday" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = wednesday._0;
      return {
              TAG: "Error",
              _0: {
                path: ".wednesday" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = tuesday._0;
    return {
            TAG: "Error",
            _0: {
              path: ".tuesday" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = monday._0;
  return {
          TAG: "Error",
          _0: {
            path: ".monday" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function workingHours_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "monday",
                    false,
                    Spice.arrayToJson(schedule_encode, v.monday)
                  ],
                  [
                    "tuesday",
                    false,
                    Spice.arrayToJson(schedule_encode, v.tuesday)
                  ],
                  [
                    "wednesday",
                    false,
                    Spice.arrayToJson(schedule_encode, v.wednesday)
                  ],
                  [
                    "thursday",
                    false,
                    Spice.arrayToJson(schedule_encode, v.thursday)
                  ],
                  [
                    "friday",
                    false,
                    Spice.arrayToJson(schedule_encode, v.friday)
                  ],
                  [
                    "saturday",
                    false,
                    Spice.arrayToJson(schedule_encode, v.saturday)
                  ],
                  [
                    "sunday",
                    false,
                    Spice.arrayToJson(schedule_encode, v.sunday)
                  ]
                ]));
}

function holidaySchedule_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end_)
                  ],
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ]
                ]));
}

function schedule_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Spice.stringToJson(v.startHour)
                  ],
                  [
                    "end",
                    false,
                    Spice.stringToJson(v.endHour)
                  ]
                ]));
}

function schedule_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startHour = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (startHour.TAG === "Ok") {
    var endHour = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (endHour.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                startHour: startHour._0,
                endHour: endHour._0
              }
            };
    }
    var e = endHour._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = startHour._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("start" + e$1.path),
            message: e$1.message,
            value: e$1.value
          }
        };
}

function workSchedule_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "workingHours",
                    false,
                    workingHours_encode(v.workingHours)
                  ],
                  [
                    "holidays",
                    false,
                    Spice.arrayToJson(holidaySchedule_encode, v.holidays)
                  ]
                ]));
}

function config_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "salesContactFieldRequired",
                    false,
                    Spice.boolToJson(v.salesContactFieldRequired)
                  ],
                  [
                    "customerPricesFactor",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPricesFactor)
                  ]
                ]));
}

function workSchedule_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var workingHours = workingHours_decode(Belt_Option.getWithDefault(Js_dict.get(v, "workingHours"), null));
  if (workingHours.TAG === "Ok") {
    var holidays = Spice.arrayFromJson(holidaySchedule_decode, Belt_Option.getWithDefault(Js_dict.get(v, "holidays"), null));
    if (holidays.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                workingHours: workingHours._0,
                holidays: holidays._0
              }
            };
    }
    var e = holidays._0;
    return {
            TAG: "Error",
            _0: {
              path: ".holidays" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = workingHours._0;
  return {
          TAG: "Error",
          _0: {
            path: ".workingHours" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var salesContactFieldRequired = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "salesContactFieldRequired"), null));
  if (salesContactFieldRequired.TAG === "Ok") {
    var customerPricesFactor = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPricesFactor"), null));
    if (customerPricesFactor.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                salesContactFieldRequired: salesContactFieldRequired._0,
                customerPricesFactor: customerPricesFactor._0
              }
            };
    }
    var e = customerPricesFactor._0;
    return {
            TAG: "Error",
            _0: {
              path: ".customerPricesFactor" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = salesContactFieldRequired._0;
  return {
          TAG: "Error",
          _0: {
            path: ".salesContactFieldRequired" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "storeCode",
                    false,
                    Spice.optionToJson(Identifiers.StoreCode.t_encode, v.storeCode)
                  ],
                  [
                    "address",
                    false,
                    address_encode(v.address)
                  ],
                  [
                    "contacts",
                    false,
                    Spice.arrayToJson(contact_encode, v.contacts)
                  ],
                  [
                    "workSchedule",
                    false,
                    workSchedule_encode(v.workSchedule)
                  ],
                  [
                    "retailerFlowType",
                    false,
                    retailerFlowType_encode(v.retailerFlowType)
                  ],
                  [
                    "config",
                    false,
                    config_encode(v.config)
                  ]
                ]));
}

function response_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var storeCode = Spice.optionFromJson(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
      if (storeCode.TAG === "Ok") {
        var address = address_decode(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
        if (address.TAG === "Ok") {
          var contacts = Spice.arrayFromJson(contact_decode, Belt_Option.getWithDefault(Js_dict.get(v, "contacts"), null));
          if (contacts.TAG === "Ok") {
            var workSchedule = workSchedule_decode(Belt_Option.getWithDefault(Js_dict.get(v, "workSchedule"), null));
            if (workSchedule.TAG === "Ok") {
              var retailerFlowType = retailerFlowType_decode(Belt_Option.getWithDefault(Js_dict.get(v, "retailerFlowType"), null));
              if (retailerFlowType.TAG === "Ok") {
                var config = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "config"), config_decode), {
                      TAG: "Ok",
                      _0: {
                        salesContactFieldRequired: false,
                        customerPricesFactor: undefined
                      }
                    });
                if (config.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            id: id._0,
                            name: name._0,
                            storeCode: storeCode._0,
                            address: address._0,
                            contacts: contacts._0,
                            workSchedule: workSchedule._0,
                            retailerFlowType: retailerFlowType._0,
                            config: config._0
                          }
                        };
                }
                var e = config._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".config" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = retailerFlowType._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".retailerFlowType" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = workSchedule._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".workSchedule" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = contacts._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".contacts" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = address._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".address" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = storeCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".storeCode" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function exec$3(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$3, undefined, baseUrl(param[0], param[1]));
}

var Config$3 = {
  address_encode: address_encode,
  address_decode: address_decode,
  contact_encode: contact_encode,
  contact_decode: contact_decode,
  retailerFlowType_encode: retailerFlowType_encode,
  retailerFlowType_decode: retailerFlowType_decode,
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  config_encode: config_encode,
  config_decode: config_decode,
  workingHours_encode: workingHours_encode,
  workingHours_decode: workingHours_decode,
  schedule_encode: schedule_encode,
  schedule_decode: schedule_decode,
  workSchedule_encode: workSchedule_encode,
  workSchedule_decode: workSchedule_decode,
  holidaySchedule_encode: holidaySchedule_encode,
  holidaySchedule_decode: holidaySchedule_decode,
  exec: exec$3
};

var $$Request$3 = Toolkit__Request.Make({
      exec: exec$3
    });

var GetStore = {
  Config: Config$3,
  $$Request: $$Request$3
};

function coordinates_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "latitude",
                    false,
                    Spice.floatToJson(v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.floatToJson(v.longitude)
                  ],
                  [
                    "level",
                    false,
                    Curry._1(ColiswebApi__V6_Geo.GeocodeLocation.Level.t_encode, v.level)
                  ]
                ]));
}

function coordinates_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var latitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
  if (latitude.TAG === "Ok") {
    var longitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
    if (longitude.TAG === "Ok") {
      var level = Curry._1(ColiswebApi__V6_Geo.GeocodeLocation.Level.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "level"), null));
      if (level.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  latitude: latitude._0,
                  longitude: longitude._0,
                  level: level._0
                }
              };
      }
      var e = level._0;
      return {
              TAG: "Error",
              _0: {
                path: ".level" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = longitude._0;
    return {
            TAG: "Error",
            _0: {
              path: ".longitude" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = latitude._0;
  return {
          TAG: "Error",
          _0: {
            path: ".latitude" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function address_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
  if (postalCode.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
      if (country.TAG === "Ok") {
        var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
        if (line1.TAG === "Ok") {
          var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
          if (line2.TAG === "Ok") {
            var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
            if (comment.TAG === "Ok") {
              var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
              if (floor.TAG === "Ok") {
                var lift = ColiswebApi__V5_Utils.Lift.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "lift"), null));
                if (lift.TAG === "Ok") {
                  var coordinates = Spice.optionFromJson(coordinates_decode, Belt_Option.getWithDefault(Js_dict.get(v, "coordinates"), null));
                  if (coordinates.TAG === "Ok") {
                    return {
                            TAG: "Ok",
                            _0: {
                              postalCode: postalCode._0,
                              city: city._0,
                              country: country._0,
                              line1: line1._0,
                              line2: line2._0,
                              comment: comment._0,
                              floor: floor._0,
                              liftInformation: lift._0,
                              coordinates: coordinates._0
                            }
                          };
                  }
                  var e = coordinates._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".coordinates" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = lift._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".lift" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = floor._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".floor" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = comment._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".comment" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = line2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".line2" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = line1._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".line1" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = country._0;
      return {
              TAG: "Error",
              _0: {
                path: ".country" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = postalCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".postalCode" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

function address_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ],
                  [
                    "lift",
                    false,
                    ColiswebApi__V5_Utils.Lift.t_encode(v.liftInformation)
                  ],
                  [
                    "coordinates",
                    false,
                    Spice.optionToJson(coordinates_encode, v.coordinates)
                  ]
                ]));
}

function body_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "address",
                    false,
                    address_encode$1(v.address)
                  ]
                ]));
}

function body_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var address = address_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
    if (address.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                name: name._0,
                address: address._0
              }
            };
    }
    var e = address._0;
    return {
            TAG: "Error",
            _0: {
              path: ".address" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$4 = response_encode$3;

var response_decode$4 = response_decode$3;

function exec$4(param) {
  return Axios.WithResult.put(undefined, body_encode$2(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$4, (function (error) {
                return Belt_Option.mapWithDefault(error.response, {
                            NAME: "default",
                            VAL: error
                          }, (function (param) {
                              var match = param.data.type;
                              if (match === "impossible_to_geocode_address") {
                                return {
                                        NAME: "custom",
                                        VAL: "impossible_to_geocode_address"
                                      };
                              } else {
                                return {
                                        NAME: "custom",
                                        VAL: "unknown"
                                      };
                              }
                            }));
              }), baseUrl(param[0], param[1]));
}

var Config$4 = {
  body_encode: body_encode$2,
  body_decode: body_decode$2,
  address_encode: address_encode$1,
  address_decode: address_decode$1,
  coordinates_encode: coordinates_encode,
  coordinates_decode: coordinates_decode,
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  exec: exec$4
};

var $$Request$4 = Toolkit__Request.Make({
      exec: exec$4
    });

var UpdateStore = {
  Config: Config$4,
  $$Request: $$Request$4
};

function t_encode(v) {
  switch (v) {
    case "create" :
        return "create";
    case "update" :
        return "update";
    case "delete" :
        return "delete";
    
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("create" === v) {
          return {
                  TAG: "Ok",
                  _0: "create"
                };
        } else if ("update" === v) {
          return {
                  TAG: "Ok",
                  _0: "update"
                };
        } else if ("delete" === v) {
          return {
                  TAG: "Ok",
                  _0: "delete"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Action = {
  t_encode: t_encode,
  t_decode: t_decode
};

function timeSlot_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end_: end_._0
              }
            };
    }
    var e = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function timeSlot_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end_)
                  ]
                ]));
}

function action_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var action = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "action"), null));
  if (action.TAG === "Ok") {
    var id = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
    if (id.TAG === "Ok") {
      var timeSlot = Spice.optionFromJson(timeSlot_decode, Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
      if (timeSlot.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  action: action._0,
                  id: id._0,
                  timeSlot: timeSlot._0
                }
              };
      }
      var e = timeSlot._0;
      return {
              TAG: "Error",
              _0: {
                path: ".timeSlot" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = id._0;
    return {
            TAG: "Error",
            _0: {
              path: ".id" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = action._0;
  return {
          TAG: "Error",
          _0: {
            path: ".action" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function action_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "action",
                    false,
                    t_encode(v.action)
                  ],
                  [
                    "id",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.id)
                  ],
                  [
                    "timeSlot",
                    false,
                    Spice.optionToJson(timeSlot_encode, v.timeSlot)
                  ]
                ]));
}

function body_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "holidays",
                    false,
                    Spice.arrayToJson(action_encode, v.holidays)
                  ]]));
}

function body_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var holidays = Spice.arrayFromJson(action_decode, Belt_Option.getWithDefault(Js_dict.get(v, "holidays"), null));
  if (holidays.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              holidays: holidays._0
            }
          };
  }
  var e = holidays._0;
  return {
          TAG: "Error",
          _0: {
            path: ".holidays" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function holidaySchedule_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end_)
                  ]
                ]));
}

function holidaySchedule_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
    if (start.TAG === "Ok") {
      var end_ = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
      if (end_.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  start: start._0,
                  end_: end_._0
                }
              };
      }
      var e = end_._0;
      return {
              TAG: "Error",
              _0: {
                path: "." + ("end" + e.path),
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = start._0;
    return {
            TAG: "Error",
            _0: {
              path: ".start" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$5(v) {
  return Spice.arrayToJson(holidaySchedule_encode$1, v);
}

function response_decode$5(v) {
  return Spice.arrayFromJson(holidaySchedule_decode$1, v);
}

function exec$5(param) {
  return Axios.WithResult.post(undefined, body_encode$3(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$5, undefined, baseUrl(param[0], param[1]) + "/holidays");
}

var Config$5 = {
  body_encode: body_encode$3,
  body_decode: body_decode$3,
  action_encode: action_encode,
  action_decode: action_decode,
  timeSlot_encode: timeSlot_encode,
  timeSlot_decode: timeSlot_decode,
  response_encode: response_encode$5,
  response_decode: response_decode$5,
  holidaySchedule_encode: holidaySchedule_encode$1,
  holidaySchedule_decode: holidaySchedule_decode$1,
  exec: exec$5
};

var $$Request$5 = Toolkit__Request.Make({
      exec: exec$5
    });

var UpdateStoreHolidays = {
  Action: Action,
  Config: Config$5,
  $$Request: $$Request$5
};

function skill_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
    if (tag.TAG === "Ok") {
      var description = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        var isDefault = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isDefault"), null));
        if (isDefault.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    tag: tag._0,
                    description: description._0,
                    isDefault: isDefault._0
                  }
                };
        }
        var e = isDefault._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".isDefault" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = tag._0;
    return {
            TAG: "Error",
            _0: {
              path: ".tag" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function heftingOptions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var sidewalk = Spice.optionFromJson(ColiswebApi__V5_Utils.HeftingOption.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "sidewalk"), null));
  if (sidewalk.TAG === "Ok") {
    var inRoom = Spice.optionFromJson(ColiswebApi__V5_Utils.HeftingOption.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "inRoom"), null));
    if (inRoom.TAG === "Ok") {
      var trailed = Spice.optionFromJson(ColiswebApi__V5_Utils.HeftingOption.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "trailed"), null));
      if (trailed.TAG === "Ok") {
        var $$default = Spice.optionFromJson(ColiswebApi__V5_Utils.HeftingOption.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "default"), null));
        if ($$default.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    sidewalk: sidewalk._0,
                    inRoom: inRoom._0,
                    trailed: trailed._0,
                    default: $$default._0
                  }
                };
        }
        var e = $$default._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".default" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = trailed._0;
      return {
              TAG: "Error",
              _0: {
                path: ".trailed" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = inRoom._0;
    return {
            TAG: "Error",
            _0: {
              path: ".inRoom" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = sidewalk._0;
  return {
          TAG: "Error",
          _0: {
            path: ".sidewalk" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function heftingOptions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "sidewalk",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.HeftingOption.t_encode, v.sidewalk)
                  ],
                  [
                    "inRoom",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.HeftingOption.t_encode, v.inRoom)
                  ],
                  [
                    "trailed",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.HeftingOption.t_encode, v.trailed)
                  ],
                  [
                    "default",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.HeftingOption.t_encode, v.default)
                  ]
                ]));
}

function skill_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ],
                  [
                    "description",
                    false,
                    Spice.stringToJson(v.description)
                  ],
                  [
                    "isDefault",
                    false,
                    Spice.boolToJson(v.isDefault)
                  ]
                ]));
}

function response_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "heftingOptions",
                    false,
                    heftingOptions_encode(v.heftingOptions)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(skill_encode, v.skills)
                  ]
                ]));
}

function response_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var heftingOptions = heftingOptions_decode(Belt_Option.getWithDefault(Js_dict.get(v, "heftingOptions"), null));
  if (heftingOptions.TAG === "Ok") {
    var skills = Spice.arrayFromJson(skill_decode, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
    if (skills.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                heftingOptions: heftingOptions._0,
                skills: skills._0
              }
            };
    }
    var e = skills._0;
    return {
            TAG: "Error",
            _0: {
              path: ".skills" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = heftingOptions._0;
  return {
          TAG: "Error",
          _0: {
            path: ".heftingOptions" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function rawSkill_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
    if (tag.TAG === "Ok") {
      var description = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        var isHeftingOption = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isHeftingOption"), null));
        if (isHeftingOption.TAG === "Ok") {
          var isDefault = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isDefault"), null));
          if (isDefault.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      id: id._0,
                      tag: tag._0,
                      description: description._0,
                      isHeftingOption: isHeftingOption._0,
                      isDefault: isDefault._0
                    }
                  };
          }
          var e = isDefault._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".isDefault" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = isHeftingOption._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".isHeftingOption" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = tag._0;
    return {
            TAG: "Error",
            _0: {
              path: ".tag" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function rawSkill_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ],
                  [
                    "description",
                    false,
                    Spice.stringToJson(v.description)
                  ],
                  [
                    "isHeftingOption",
                    false,
                    Spice.boolToJson(v.isHeftingOption)
                  ],
                  [
                    "isDefault",
                    false,
                    Spice.boolToJson(v.isDefault)
                  ]
                ]));
}

function skills_encode(v) {
  return Spice.arrayToJson(rawSkill_encode, v);
}

function skills_decode(v) {
  return Spice.arrayFromJson(rawSkill_decode, v);
}

function exec$6(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (function (data) {
                var skills = Spice.arrayFromJson(rawSkill_decode, data);
                if (skills.TAG !== "Ok") {
                  return skills;
                }
                var skills$1 = skills._0;
                var getHeftingOption = function (option) {
                  return Curry._2(Toolkit__Primitives_Array.$$Array.get, Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, skills$1, (function (skill) {
                                    var tag = ColiswebApi__V5_Utils.HeftingOption.t_decode(skill.tag);
                                    if (tag.TAG === "Ok" && tag._0 === option) {
                                      return skill;
                                    }
                                    
                                  })), 0);
                };
                var inRoom = getHeftingOption("roomdelivery");
                var sidewalk = getHeftingOption("sidewalkdelivery");
                var trailed = getHeftingOption("traileddelivery");
                var tmp;
                var exit = 0;
                var exit$1 = 0;
                if (sidewalk !== undefined && sidewalk.isDefault) {
                  tmp = "sidewalkdelivery";
                } else {
                  exit$1 = 2;
                }
                if (exit$1 === 2) {
                  if (inRoom !== undefined && inRoom.isDefault) {
                    tmp = "roomdelivery";
                  } else {
                    exit = 1;
                  }
                }
                if (exit === 1) {
                  tmp = trailed !== undefined && trailed.isDefault ? "traileddelivery" : undefined;
                }
                return {
                        TAG: "Ok",
                        _0: {
                          heftingOptions: {
                            sidewalk: Belt_Option.map(sidewalk, (function (param) {
                                    return "sidewalkdelivery";
                                  })),
                            inRoom: Belt_Option.map(inRoom, (function (param) {
                                    return "roomdelivery";
                                  })),
                            trailed: Belt_Option.map(trailed, (function (param) {
                                    return "traileddelivery";
                                  })),
                            default: tmp
                          },
                          skills: Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, skills$1, (function (skill) {
                                  if (skill.isHeftingOption) {
                                    return ;
                                  } else {
                                    return {
                                            id: skill.id,
                                            tag: skill.tag,
                                            description: skill.description,
                                            isDefault: skill.isDefault
                                          };
                                  }
                                }))
                        }
                      };
              }), undefined, baseUrl(param[0], param[1]) + "/skills");
}

var Config$6 = {
  response_encode: response_encode$6,
  response_decode: response_decode$6,
  skill_encode: skill_encode,
  skill_decode: skill_decode,
  heftingOptions_encode: heftingOptions_encode,
  heftingOptions_decode: heftingOptions_decode,
  skills_encode: skills_encode,
  skills_decode: skills_decode,
  rawSkill_encode: rawSkill_encode,
  rawSkill_decode: rawSkill_decode,
  exec: exec$6
};

var $$Request$6 = Toolkit__Request.Make({
      exec: exec$6
    });

var GetEligibleOptions = {
  Config: Config$6,
  $$Request: $$Request$6
};

function t_encode$1(v) {
  switch (v) {
    case "client_not_found" :
        return "client_not_found";
    case "store_not_found" :
        return "store_not_found";
    case "uneligible_location" :
        return "uneligible_location";
    case "origin_geocode_failure" :
        return "origin_geocode_failure";
    case "destination_geocode_failure" :
        return "destination_geocode_failure";
    case "impossible_to_get_the_distance" :
        return "impossible_to_get_the_distance";
    case "invalid_postal_code" :
        return "invalid_postal_code";
    case "origin_missing_postal_code" :
        return "origin_missing_postal_code";
    case "destination_missing_postal_code" :
        return "destination_missing_postal_code";
    case "not_configured_to_order_deliveries" :
        return "not_configured_to_order_deliveries";
    
  }
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("uneligible_location" === v) {
          return {
                  TAG: "Ok",
                  _0: "uneligible_location"
                };
        } else if ("origin_geocode_failure" === v) {
          return {
                  TAG: "Ok",
                  _0: "origin_geocode_failure"
                };
        } else if ("destination_geocode_failure" === v) {
          return {
                  TAG: "Ok",
                  _0: "destination_geocode_failure"
                };
        } else if ("impossible_to_get_the_distance" === v) {
          return {
                  TAG: "Ok",
                  _0: "impossible_to_get_the_distance"
                };
        } else if ("invalid_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_postal_code"
                };
        } else if ("origin_missing_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "origin_missing_postal_code"
                };
        } else if ("destination_missing_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "destination_missing_postal_code"
                };
        } else if ("not_configured_to_order_deliveries" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_configured_to_order_deliveries"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error = {
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function bodyCoords_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lat",
                    false,
                    Spice.floatToJson(v.lat)
                  ],
                  [
                    "lng",
                    false,
                    Spice.floatToJson(v.lng)
                  ],
                  [
                    "level",
                    false,
                    Spice.stringToJson(v.level)
                  ]
                ]));
}

function administrativeArea_encode(v) {
  return ColiswebApi__V6_Geo.GeocodeLocation.administrativeArea_encode(v);
}

function administrativeArea_decode(v) {
  return ColiswebApi__V6_Geo.GeocodeLocation.administrativeArea_decode(v);
}

function bodyCoords_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lat = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lat"), null));
  if (lat.TAG === "Ok") {
    var lng = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lng"), null));
    if (lng.TAG === "Ok") {
      var level = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "level"), null));
      if (level.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  lat: lat._0,
                  lng: lng._0,
                  level: level._0
                }
              };
      }
      var e = level._0;
      return {
              TAG: "Error",
              _0: {
                path: ".level" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = lng._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lng" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = lat._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lat" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function location_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "coords",
                    false,
                    Spice.optionToJson(bodyCoords_encode, v.coords)
                  ],
                  [
                    "administrativeArea",
                    false,
                    Spice.optionToJson(administrativeArea_encode, v.administrativeArea)
                  ]
                ]));
}

function location_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
    if (postalCode.TAG === "Ok") {
      var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
      if (city.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
          if (storeId.TAG === "Ok") {
            var coords = Spice.optionFromJson(bodyCoords_decode, Belt_Option.getWithDefault(Js_dict.get(v, "coords"), null));
            if (coords.TAG === "Ok") {
              var administrativeArea = Spice.optionFromJson(administrativeArea_decode, Belt_Option.getWithDefault(Js_dict.get(v, "administrativeArea"), null));
              if (administrativeArea.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          line1: line1._0,
                          postalCode: postalCode._0,
                          city: city._0,
                          country: country._0,
                          storeId: storeId._0,
                          coords: coords._0,
                          administrativeArea: administrativeArea._0
                        }
                      };
              }
              var e = administrativeArea._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".administrativeArea" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = coords._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".coords" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = storeId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".storeId" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = city._0;
      return {
              TAG: "Error",
              _0: {
                path: ".city" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = postalCode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".postalCode" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function body_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "origin",
                    false,
                    location_encode(v.origin)
                  ],
                  [
                    "destination",
                    false,
                    location_encode(v.destination)
                  ]
                ]));
}

function body_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var origin = location_decode(Belt_Option.getWithDefault(Js_dict.get(v, "origin"), null));
  if (origin.TAG === "Ok") {
    var destination = location_decode(Belt_Option.getWithDefault(Js_dict.get(v, "destination"), null));
    if (destination.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                origin: origin._0,
                destination: destination._0
              }
            };
    }
    var e = destination._0;
    return {
            TAG: "Error",
            _0: {
              path: ".destination" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = origin._0;
  return {
          TAG: "Error",
          _0: {
            path: ".origin" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function responseCoords_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lat = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lat"), null));
  if (lat.TAG === "Ok") {
    var lng = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lng"), null));
    if (lng.TAG === "Ok") {
      var level = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "level"), null));
      if (level.TAG === "Ok") {
        var administrativeArea = Spice.optionFromJson(administrativeArea_decode, Belt_Option.getWithDefault(Js_dict.get(v, "administrativeArea"), null));
        if (administrativeArea.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    lat: lat._0,
                    lng: lng._0,
                    level: level._0,
                    administrativeArea: administrativeArea._0
                  }
                };
        }
        var e = administrativeArea._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".administrativeArea" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = level._0;
      return {
              TAG: "Error",
              _0: {
                path: ".level" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = lng._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lng" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = lat._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lat" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function responseCoords_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lat",
                    false,
                    Spice.floatToJson(v.lat)
                  ],
                  [
                    "lng",
                    false,
                    Spice.floatToJson(v.lng)
                  ],
                  [
                    "level",
                    false,
                    Spice.stringToJson(v.level)
                  ],
                  [
                    "administrativeArea",
                    false,
                    Spice.optionToJson(administrativeArea_encode, v.administrativeArea)
                  ]
                ]));
}

function response_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "origin",
                    false,
                    responseCoords_encode(v.origin)
                  ],
                  [
                    "destination",
                    false,
                    responseCoords_encode(v.destination)
                  ],
                  [
                    "distanceKm",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.Km.t_encode, v.distanceKm)
                  ]
                ]));
}

function response_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var origin = responseCoords_decode(Belt_Option.getWithDefault(Js_dict.get(v, "origin"), null));
  if (origin.TAG === "Ok") {
    var destination = responseCoords_decode(Belt_Option.getWithDefault(Js_dict.get(v, "destination"), null));
    if (destination.TAG === "Ok") {
      var distanceKm = Curry._1(Decoders__UnitMeasure.Dimension.Km.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "distanceKm"), null));
      if (distanceKm.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  origin: origin._0,
                  destination: destination._0,
                  distanceKm: distanceKm._0
                }
              };
      }
      var e = distanceKm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".distanceKm" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = destination._0;
    return {
            TAG: "Error",
            _0: {
              path: ".destination" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = origin._0;
  return {
          TAG: "Error",
          _0: {
            path: ".origin" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$7(param) {
  return Axios.WithResult.post(undefined, body_encode$4(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$7, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$1(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            v._0
                          ]
                        };
                }
              }), baseUrl(param[0], param[1]) + "/geographicEligibility");
}

var Config$7 = {
  body_encode: body_encode$4,
  body_decode: body_decode$4,
  location_encode: location_encode,
  location_decode: location_decode,
  administrativeArea_encode: administrativeArea_encode,
  administrativeArea_decode: administrativeArea_decode,
  bodyCoords_encode: bodyCoords_encode,
  bodyCoords_decode: bodyCoords_decode,
  response_encode: response_encode$7,
  response_decode: response_decode$7,
  responseCoords_encode: responseCoords_encode,
  responseCoords_decode: responseCoords_decode,
  exec: exec$7
};

var $$Request$7 = Toolkit__Request.Make({
      exec: exec$7
    });

var CheckEligibleLocation = {
  $$Error: $$Error,
  Config: Config$7,
  $$Request: $$Request$7
};

function t_encode$2(v) {
  switch (v) {
    case "store_not_found" :
        return "store_not_found";
    case "client_not_found" :
        return "client_not_found";
    case "invalid_postal_code" :
        return "invalid_postal_code";
    case "postal_code_not_found" :
        return "postal_code_not_found";
    case "empty_response_from_packing" :
        return "empty_response_from_packing";
    case "package_too_big" :
        return "package_too_big";
    case "packet_too_big" :
        return "packet_too_big";
    case "too_heavy_packet" :
        return "too_heavy_packet";
    case "distance_too_far" :
        return "distance_too_far";
    case "total_weight_too_heavy" :
        return "total_weight_too_heavy";
    case "too_heavy_single_packet" :
        return "too_heavy_single_packet";
    case "conditions_not_matched_for_delivery" :
        return "conditions_not_matched_for_delivery";
    case "not_configured_to_order_deliveries" :
        return "not_configured_to_order_deliveries";
    case "generated_calendar_is_empty" :
        return "generated_calendar_is_empty";
    case "invalid_required_skills" :
        return "invalid_required_skills";
    case "no_floor_or_lift_has_been_specified" :
        return "no_floor_or_lift_has_been_specified";
    case "impossible_to_get_the_distance" :
        return "impossible_to_get_the_distance";
    case "no_prices_found" :
        return "no_prices_found";
    case "contract_not_found_for_packaging" :
        return "contract_not_found_for_packaging";
    case "area_not_eligible" :
        return "area_not_eligible";
    case "total_weight_too_heavy_for_customer_price" :
        return "total_weight_too_heavy_for_customer_price";
    case "undefined_error_for_customer_price" :
        return "undefined_error_for_customer_price";
    
  }
}

function t_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("invalid_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_postal_code"
                };
        } else if ("postal_code_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "postal_code_not_found"
                };
        } else if ("empty_response_from_packing" === v) {
          return {
                  TAG: "Ok",
                  _0: "empty_response_from_packing"
                };
        } else if ("package_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_too_big"
                };
        } else if ("packet_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "packet_too_big"
                };
        } else if ("too_heavy_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_packet"
                };
        } else if ("distance_too_far" === v) {
          return {
                  TAG: "Ok",
                  _0: "distance_too_far"
                };
        } else if ("total_weight_too_heavy" === v) {
          return {
                  TAG: "Ok",
                  _0: "total_weight_too_heavy"
                };
        } else if ("too_heavy_single_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_single_packet"
                };
        } else if ("conditions_not_matched_for_delivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "conditions_not_matched_for_delivery"
                };
        } else if ("not_configured_to_order_deliveries" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_configured_to_order_deliveries"
                };
        } else if ("generated_calendar_is_empty" === v) {
          return {
                  TAG: "Ok",
                  _0: "generated_calendar_is_empty"
                };
        } else if ("invalid_required_skills" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_required_skills"
                };
        } else if ("no_floor_or_lift_has_been_specified" === v) {
          return {
                  TAG: "Ok",
                  _0: "no_floor_or_lift_has_been_specified"
                };
        } else if ("impossible_to_get_the_distance" === v) {
          return {
                  TAG: "Ok",
                  _0: "impossible_to_get_the_distance"
                };
        } else if ("no_prices_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "no_prices_found"
                };
        } else if ("contract_not_found_for_packaging" === v) {
          return {
                  TAG: "Ok",
                  _0: "contract_not_found_for_packaging"
                };
        } else if ("area_not_eligible" === v) {
          return {
                  TAG: "Ok",
                  _0: "area_not_eligible"
                };
        } else if ("total_weight_too_heavy_for_customer_price" === v) {
          return {
                  TAG: "Ok",
                  _0: "total_weight_too_heavy_for_customer_price"
                };
        } else if ("undefined_error_for_customer_price" === v) {
          return {
                  TAG: "Ok",
                  _0: "undefined_error_for_customer_price"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$1 = {
  t_encode: t_encode$2,
  t_decode: t_decode$2
};

function administrativeArea_encode$1(v) {
  return ColiswebApi__V6_Geo.GeocodeLocation.administrativeArea_encode(v);
}

function administrativeArea_decode$1(v) {
  return ColiswebApi__V6_Geo.GeocodeLocation.administrativeArea_decode(v);
}

function additionalInformation_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
  if (floor.TAG === "Ok") {
    var hasLift = ColiswebApi__V5_Utils.Lift.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "hasLift"), null));
    if (hasLift.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                floor: floor._0,
                hasLift: hasLift._0
              }
            };
    }
    var e = hasLift._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hasLift" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = floor._0;
  return {
          TAG: "Error",
          _0: {
            path: ".floor" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function additionalInformation_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ],
                  [
                    "hasLift",
                    false,
                    ColiswebApi__V5_Utils.Lift.t_encode(v.hasLift)
                  ]
                ]));
}

function address_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "latitude",
                    false,
                    Spice.floatToJson(v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.floatToJson(v.longitude)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "storeId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "additionalInformation",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(additionalInformation_encode, v.additionalInformation)
                  ],
                  [
                    "geocodingLevel",
                    false,
                    Spice.stringToJson(v.geocodingLevel)
                  ],
                  [
                    "administrativeArea",
                    false,
                    Spice.optionToJson(administrativeArea_encode$1, v.administrativeArea)
                  ]
                ]));
}

function packaging_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "numberOfPackets",
                    false,
                    Spice.intToJson(v.numberOfPackets)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.floatToJson(v.heightCm)
                  ],
                  [
                    "lengthCm",
                    false,
                    Spice.floatToJson(v.lengthCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.floatToJson(v.widthCm)
                  ],
                  [
                    "weightKg",
                    false,
                    Spice.floatToJson(v.weightKg)
                  ],
                  [
                    "maxPacketWeightKg",
                    false,
                    Spice.floatToJson(v.maxPacketWeightKg)
                  ],
                  [
                    "maxPacketLengthCm",
                    false,
                    Spice.floatToJson(v.maxPacketLengthCm)
                  ]
                ]));
}

function address_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var latitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
  if (latitude.TAG === "Ok") {
    var longitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
    if (longitude.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var storeId = Toolkit__Decoders.$$Option.t_decode(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
        if (storeId.TAG === "Ok") {
          var additionalInformation = Toolkit__Decoders.$$Option.t_decode(additionalInformation_decode, Belt_Option.getWithDefault(Js_dict.get(v, "additionalInformation"), null));
          if (additionalInformation.TAG === "Ok") {
            var geocodingLevel = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "geocodingLevel"), null));
            if (geocodingLevel.TAG === "Ok") {
              var administrativeArea = Spice.optionFromJson(administrativeArea_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "administrativeArea"), null));
              if (administrativeArea.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          latitude: latitude._0,
                          longitude: longitude._0,
                          postalCode: postalCode._0,
                          storeId: storeId._0,
                          additionalInformation: additionalInformation._0,
                          geocodingLevel: geocodingLevel._0,
                          administrativeArea: administrativeArea._0
                        }
                      };
              }
              var e = administrativeArea._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".administrativeArea" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = geocodingLevel._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".geocodingLevel" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = additionalInformation._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".additionalInformation" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = storeId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".storeId" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = longitude._0;
    return {
            TAG: "Error",
            _0: {
              path: ".longitude" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = latitude._0;
  return {
          TAG: "Error",
          _0: {
            path: ".latitude" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function packaging_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var numberOfPackets = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "numberOfPackets"), null));
  if (numberOfPackets.TAG === "Ok") {
    var heightCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
    if (heightCm.TAG === "Ok") {
      var lengthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
      if (lengthCm.TAG === "Ok") {
        var widthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
        if (widthCm.TAG === "Ok") {
          var weightKg = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "weightKg"), null));
          if (weightKg.TAG === "Ok") {
            var maxPacketWeightKg = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "maxPacketWeightKg"), null));
            if (maxPacketWeightKg.TAG === "Ok") {
              var maxPacketLengthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "maxPacketLengthCm"), null));
              if (maxPacketLengthCm.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          numberOfPackets: numberOfPackets._0,
                          heightCm: heightCm._0,
                          lengthCm: lengthCm._0,
                          widthCm: widthCm._0,
                          weightKg: weightKg._0,
                          maxPacketWeightKg: maxPacketWeightKg._0,
                          maxPacketLengthCm: maxPacketLengthCm._0
                        }
                      };
              }
              var e = maxPacketLengthCm._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".maxPacketLengthCm" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = maxPacketWeightKg._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".maxPacketWeightKg" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = weightKg._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".weightKg" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = widthCm._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".widthCm" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = lengthCm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lengthCm" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = heightCm._0;
    return {
            TAG: "Error",
            _0: {
              path: ".heightCm" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = numberOfPackets._0;
  return {
          TAG: "Error",
          _0: {
            path: ".numberOfPackets" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function body_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "startDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.startDate)
                  ],
                  [
                    "endDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.endDate)
                  ],
                  [
                    "pickupAddress",
                    false,
                    address_encode$2(v.pickupAddress)
                  ],
                  [
                    "shippingAddress",
                    false,
                    address_encode$2(v.shippingAddress)
                  ],
                  [
                    "packaging",
                    false,
                    packaging_encode(v.packaging)
                  ],
                  [
                    "requiredSkills",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.requiredSkills)
                  ]
                ]));
}

function body_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "startDate"), null));
  if (startDate.TAG === "Ok") {
    var endDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "endDate"), null));
    if (endDate.TAG === "Ok") {
      var pickupAddress = address_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "pickupAddress"), null));
      if (pickupAddress.TAG === "Ok") {
        var shippingAddress = address_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "shippingAddress"), null));
        if (shippingAddress.TAG === "Ok") {
          var packaging = packaging_decode(Belt_Option.getWithDefault(Js_dict.get(v, "packaging"), null));
          if (packaging.TAG === "Ok") {
            var requiredSkills = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "requiredSkills"), null));
            if (requiredSkills.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        startDate: startDate._0,
                        endDate: endDate._0,
                        pickupAddress: pickupAddress._0,
                        shippingAddress: shippingAddress._0,
                        packaging: packaging._0,
                        requiredSkills: requiredSkills._0
                      }
                    };
            }
            var e = requiredSkills._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".requiredSkills" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = packaging._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".packaging" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = shippingAddress._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".shippingAddress" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = pickupAddress._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pickupAddress" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = endDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".endDate" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = startDate._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startDate" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function calendar_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "startsAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.startsAt)
                  ],
                  [
                    "endsAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.endsAt)
                  ],
                  [
                    "now",
                    false,
                    Spice.boolToJson(v.now)
                  ],
                  [
                    "slotSizeMinutes",
                    false,
                    Spice.intToJson(v.slotSizeMinutes)
                  ],
                  [
                    "priceWithTaxes",
                    false,
                    Spice.stringToJson(v.priceWithTaxes)
                  ],
                  [
                    "priceWithoutTaxes",
                    false,
                    Spice.stringToJson(v.priceWithoutTaxes)
                  ],
                  [
                    "customerPriceWithTaxes",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithTaxes)
                  ],
                  [
                    "customerPriceWithoutTaxes",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithoutTaxes)
                  ],
                  [
                    "priceIsValid",
                    false,
                    Spice.boolToJson(v.priceIsValid)
                  ],
                  [
                    "suggestedSlot",
                    false,
                    Spice.boolToJson(v.suggestedSlot)
                  ],
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "isAvailable",
                    false,
                    Spice.boolToJson(v.isAvailable)
                  ]
                ]));
}

function calendar_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startsAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "startsAt"), null));
  if (startsAt.TAG === "Ok") {
    var endsAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "endsAt"), null));
    if (endsAt.TAG === "Ok") {
      var now = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "now"), null));
      if (now.TAG === "Ok") {
        var slotSizeMinutes = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "slotSizeMinutes"), null));
        if (slotSizeMinutes.TAG === "Ok") {
          var priceWithTaxes = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "priceWithTaxes"), null));
          if (priceWithTaxes.TAG === "Ok") {
            var priceWithoutTaxes = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxes"), null));
            if (priceWithoutTaxes.TAG === "Ok") {
              var customerPriceWithTaxes = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithTaxes"), null));
              if (customerPriceWithTaxes.TAG === "Ok") {
                var customerPriceWithoutTaxes = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxes"), null));
                if (customerPriceWithoutTaxes.TAG === "Ok") {
                  var priceIsValid = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "priceIsValid"), null));
                  if (priceIsValid.TAG === "Ok") {
                    var suggestedSlot = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "suggestedSlot"), null));
                    if (suggestedSlot.TAG === "Ok") {
                      var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
                      if (id.TAG === "Ok") {
                        var isAvailable = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isAvailable"), null));
                        if (isAvailable.TAG === "Ok") {
                          return {
                                  TAG: "Ok",
                                  _0: {
                                    startsAt: startsAt._0,
                                    endsAt: endsAt._0,
                                    now: now._0,
                                    slotSizeMinutes: slotSizeMinutes._0,
                                    priceWithTaxes: priceWithTaxes._0,
                                    priceWithoutTaxes: priceWithoutTaxes._0,
                                    customerPriceWithTaxes: customerPriceWithTaxes._0,
                                    customerPriceWithoutTaxes: customerPriceWithoutTaxes._0,
                                    priceIsValid: priceIsValid._0,
                                    suggestedSlot: suggestedSlot._0,
                                    id: id._0,
                                    isAvailable: isAvailable._0
                                  }
                                };
                        }
                        var e = isAvailable._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".isAvailable" + e.path,
                                  message: e.message,
                                  value: e.value
                                }
                              };
                      }
                      var e$1 = id._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".id" + e$1.path,
                                message: e$1.message,
                                value: e$1.value
                              }
                            };
                    }
                    var e$2 = suggestedSlot._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".suggestedSlot" + e$2.path,
                              message: e$2.message,
                              value: e$2.value
                            }
                          };
                  }
                  var e$3 = priceIsValid._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".priceIsValid" + e$3.path,
                            message: e$3.message,
                            value: e$3.value
                          }
                        };
                }
                var e$4 = customerPriceWithoutTaxes._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".customerPriceWithoutTaxes" + e$4.path,
                          message: e$4.message,
                          value: e$4.value
                        }
                      };
              }
              var e$5 = customerPriceWithTaxes._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".customerPriceWithTaxes" + e$5.path,
                        message: e$5.message,
                        value: e$5.value
                      }
                    };
            }
            var e$6 = priceWithoutTaxes._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".priceWithoutTaxes" + e$6.path,
                      message: e$6.message,
                      value: e$6.value
                    }
                  };
          }
          var e$7 = priceWithTaxes._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".priceWithTaxes" + e$7.path,
                    message: e$7.message,
                    value: e$7.value
                  }
                };
        }
        var e$8 = slotSizeMinutes._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".slotSizeMinutes" + e$8.path,
                  message: e$8.message,
                  value: e$8.value
                }
              };
      }
      var e$9 = now._0;
      return {
              TAG: "Error",
              _0: {
                path: ".now" + e$9.path,
                message: e$9.message,
                value: e$9.value
              }
            };
    }
    var e$10 = endsAt._0;
    return {
            TAG: "Error",
            _0: {
              path: ".endsAt" + e$10.path,
              message: e$10.message,
              value: e$10.value
            }
          };
  }
  var e$11 = startsAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startsAt" + e$11.path,
            message: e$11.message,
            value: e$11.value
          }
        };
}

function response_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "calendar",
                    false,
                    Spice.arrayToJson(calendar_encode, v.calendar)
                  ],
                  [
                    "deliveryRequestId",
                    false,
                    Spice.stringToJson(v.deliveryRequestId)
                  ]
                ]));
}

function response_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var calendar = Spice.arrayFromJson(calendar_decode, Belt_Option.getWithDefault(Js_dict.get(v, "calendar"), null));
  if (calendar.TAG === "Ok") {
    var deliveryRequestId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryRequestId"), null));
    if (deliveryRequestId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                calendar: calendar._0,
                deliveryRequestId: deliveryRequestId._0
              }
            };
    }
    var e = deliveryRequestId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryRequestId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = calendar._0;
  return {
          TAG: "Error",
          _0: {
            path: ".calendar" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$8(param) {
  return Axios.WithResult.post(undefined, body_encode$5(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$8, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$2(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            v._0
                          ]
                        };
                }
              }), baseUrl(param[0], param[1]) + "/deliveryOptions");
}

var Config$8 = {
  administrativeArea_encode: administrativeArea_encode$1,
  administrativeArea_decode: administrativeArea_decode$1,
  body_encode: body_encode$5,
  body_decode: body_decode$5,
  address_encode: address_encode$2,
  address_decode: address_decode$2,
  additionalInformation_encode: additionalInformation_encode,
  additionalInformation_decode: additionalInformation_decode,
  packaging_encode: packaging_encode,
  packaging_decode: packaging_decode,
  response_encode: response_encode$8,
  response_decode: response_decode$8,
  calendar_encode: calendar_encode,
  calendar_decode: calendar_decode,
  exec: exec$8
};

var $$Request$8 = Toolkit__Request.Make({
      exec: exec$8
    });

var GetEligibleTimeSlots = {
  $$Error: $$Error$1,
  Config: Config$8,
  $$Request: $$Request$8
};

var DeliveryEligibility = {
  GetEligibleOptions: GetEligibleOptions,
  CheckEligibleLocation: CheckEligibleLocation,
  GetEligibleTimeSlots: GetEligibleTimeSlots
};

function t_encode$3(v) {
  if (v === "inProgress") {
    return "inProgress";
  } else {
    return "terminated";
  }
}

function t_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("inProgress" === v) {
          return {
                  TAG: "Ok",
                  _0: "inProgress"
                };
        } else if ("terminated" === v) {
          return {
                  TAG: "Ok",
                  _0: "terminated"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString = t_encode$3;

var StateFilter = {
  t_encode: t_encode$3,
  t_decode: t_decode$3,
  toString: toString
};

function response_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "statusProvider",
                    false,
                    Spice.arrayToJson(ColiswebApi__V5_Utils.StatusProvider.t_encode, v.statusProvider)
                  ],
                  [
                    "statusOriginator",
                    false,
                    Spice.arrayToJson(ColiswebApi__V5_Utils.StatusOriginator.t_encode, v.statusOriginator)
                  ]
                ]));
}

function response_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var statusProvider = Spice.arrayFromJson(ColiswebApi__V5_Utils.StatusProvider.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
  if (statusProvider.TAG === "Ok") {
    var statusOriginator = Spice.arrayFromJson(ColiswebApi__V5_Utils.StatusOriginator.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "statusOriginator"), null));
    if (statusOriginator.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                statusProvider: statusProvider._0,
                statusOriginator: statusOriginator._0
              }
            };
    }
    var e = statusOriginator._0;
    return {
            TAG: "Error",
            _0: {
              path: ".statusOriginator" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = statusProvider._0;
  return {
          TAG: "Error",
          _0: {
            path: ".statusProvider" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$9(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Belt_Option.mapWithDefault(param[2], {}, (function (filter) {
                              return {
                                      filter: filter
                                    };
                            }))), undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$9, undefined, baseUrl(param[0], param[1]) + "/states");
}

var Config$9 = {
  StateFilter: StateFilter,
  response_encode: response_encode$9,
  response_decode: response_decode$9,
  exec: exec$9
};

var $$Request$9 = Toolkit__Request.Make({
      exec: exec$9
    });

var GetStates = {
  Config: Config$9,
  $$Request: $$Request$9
};

var storeUrl = baseUrl;

function baseUrl$1(clientId, storeId, deliveryId) {
  return baseUrl(clientId, storeId) + ("/deliveries/" + deliveryId);
}

function t_encode$4(v) {
  switch (v) {
    case "line1" :
        return "line1";
    case "line2" :
        return "line2";
    case "city" :
        return "city";
    case "country" :
        return "country";
    case "society" :
        return "society";
    case "floor" :
        return "floor";
    case "state_or_province" :
        return "state_or_province";
    case "postal_code" :
        return "postal_code";
    case "starts_at" :
        return "starts_at";
    case "ends_at" :
        return "ends_at";
    case "lift_information" :
        return "lift_information";
    case "additional_informations" :
        return "additional_informations";
    case "administrative_area_code2" :
        return "administrative_area_code2";
    case "administrative_area_code3" :
        return "administrative_area_code3";
    
  }
}

function t_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("line1" === v) {
          return {
                  TAG: "Ok",
                  _0: "line1"
                };
        } else if ("line2" === v) {
          return {
                  TAG: "Ok",
                  _0: "line2"
                };
        } else if ("city" === v) {
          return {
                  TAG: "Ok",
                  _0: "city"
                };
        } else if ("country" === v) {
          return {
                  TAG: "Ok",
                  _0: "country"
                };
        } else if ("society" === v) {
          return {
                  TAG: "Ok",
                  _0: "society"
                };
        } else if ("floor" === v) {
          return {
                  TAG: "Ok",
                  _0: "floor"
                };
        } else if ("state_or_province" === v) {
          return {
                  TAG: "Ok",
                  _0: "state_or_province"
                };
        } else if ("postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "postal_code"
                };
        } else if ("starts_at" === v) {
          return {
                  TAG: "Ok",
                  _0: "starts_at"
                };
        } else if ("ends_at" === v) {
          return {
                  TAG: "Ok",
                  _0: "ends_at"
                };
        } else if ("lift_information" === v) {
          return {
                  TAG: "Ok",
                  _0: "lift_information"
                };
        } else if ("additional_informations" === v) {
          return {
                  TAG: "Ok",
                  _0: "additional_informations"
                };
        } else if ("administrative_area_code2" === v) {
          return {
                  TAG: "Ok",
                  _0: "administrative_area_code2"
                };
        } else if ("administrative_area_code3" === v) {
          return {
                  TAG: "Ok",
                  _0: "administrative_area_code3"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var FieldName = {
  t_encode: t_encode$4,
  t_decode: t_decode$4
};

function t_encode$5(v) {
  switch (v) {
    case "destination_address" :
        return "destination_address";
    case "slot" :
        return "slot";
    case "origin_address" :
        return "origin_address";
    
  }
}

function t_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("destination_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "destination_address"
                };
        } else if ("slot" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot"
                };
        } else if ("origin_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "origin_address"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Type = {
  t_encode: t_encode$5,
  t_decode: t_decode$5
};

var ModificationHistory = {
  FieldName: FieldName,
  Type: Type
};

function priceComposition_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "amount",
                    false,
                    Spice.floatToJson(v.amount)
                  ],
                  [
                    "label",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.label)
                  ]
                ]));
}

function priceComposition_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var amount = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "amount"), null));
  if (amount.TAG === "Ok") {
    var label = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                amount: amount._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = amount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".amount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function customerSatisfaction_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "mark",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.mark)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "slotRespect",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.slotRespect)
                  ],
                  [
                    "carrierProfessionalism",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.carrierProfessionalism)
                  ],
                  [
                    "shippedProductState",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.shippedProductState)
                  ],
                  [
                    "indicationRespect",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.indicationRespect)
                  ],
                  [
                    "recommendation",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.recommendation)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "answered",
                    false,
                    Spice.boolToJson(v.answered)
                  ],
                  [
                    "updatedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.updatedAt)
                  ]
                ]));
}

function customerSatisfaction_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var mark = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "mark"), null));
  if (mark.TAG === "Ok") {
    var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
    if (comment.TAG === "Ok") {
      var slotRespect = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "slotRespect"), null));
      if (slotRespect.TAG === "Ok") {
        var carrierProfessionalism = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "carrierProfessionalism"), null));
        if (carrierProfessionalism.TAG === "Ok") {
          var shippedProductState = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "shippedProductState"), null));
          if (shippedProductState.TAG === "Ok") {
            var indicationRespect = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "indicationRespect"), null));
            if (indicationRespect.TAG === "Ok") {
              var recommendation = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "recommendation"), null));
              if (recommendation.TAG === "Ok") {
                var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
                if (createdAt.TAG === "Ok") {
                  var answered = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "answered"), Spice.boolFromJson), {
                        TAG: "Ok",
                        _0: false
                      });
                  if (answered.TAG === "Ok") {
                    var updatedAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
                    if (updatedAt.TAG === "Ok") {
                      return {
                              TAG: "Ok",
                              _0: {
                                mark: mark._0,
                                comment: comment._0,
                                slotRespect: slotRespect._0,
                                carrierProfessionalism: carrierProfessionalism._0,
                                shippedProductState: shippedProductState._0,
                                indicationRespect: indicationRespect._0,
                                recommendation: recommendation._0,
                                createdAt: createdAt._0,
                                answered: answered._0,
                                updatedAt: updatedAt._0
                              }
                            };
                    }
                    var e = updatedAt._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".updatedAt" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = answered._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".answered" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = createdAt._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".createdAt" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = recommendation._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".recommendation" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = indicationRespect._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".indicationRespect" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = shippedProductState._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".shippedProductState" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = carrierProfessionalism._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".carrierProfessionalism" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = slotRespect._0;
      return {
              TAG: "Error",
              _0: {
                path: ".slotRespect" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = comment._0;
    return {
            TAG: "Error",
            _0: {
              path: ".comment" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = mark._0;
  return {
          TAG: "Error",
          _0: {
            path: ".mark" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function nullableDimensions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lengthCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.lengthCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.widthCm)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.heightCm)
                  ],
                  [
                    "volumeM3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.volumeM3)
                  ]
                ]));
}

function nullableDimensions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lengthCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
  if (lengthCm.TAG === "Ok") {
    var widthCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
    if (widthCm.TAG === "Ok") {
      var heightCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
      if (heightCm.TAG === "Ok") {
        var volumeM3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "volumeM3"), null));
        if (volumeM3.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    lengthCm: lengthCm._0,
                    widthCm: widthCm._0,
                    heightCm: heightCm._0,
                    volumeM3: volumeM3._0
                  }
                };
        }
        var e = volumeM3._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".volumeM3" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = heightCm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".heightCm" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = widthCm._0;
    return {
            TAG: "Error",
            _0: {
              path: ".widthCm" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = lengthCm._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lengthCm" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function dimensions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lengthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
  if (lengthCm.TAG === "Ok") {
    var widthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
    if (widthCm.TAG === "Ok") {
      var heightCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
      if (heightCm.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  lengthCm: lengthCm._0,
                  widthCm: widthCm._0,
                  heightCm: heightCm._0
                }
              };
      }
      var e = heightCm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".heightCm" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = widthCm._0;
    return {
            TAG: "Error",
            _0: {
              path: ".widthCm" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = lengthCm._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lengthCm" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function packetBarcode_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              barcode: barcode._0
            }
          };
  }
  var e = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function dimensions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lengthCm",
                    false,
                    Spice.floatToJson(v.lengthCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.floatToJson(v.widthCm)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.floatToJson(v.heightCm)
                  ]
                ]));
}

function packetBarcode_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ]]));
}

function packaging_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "dimensions",
                    false,
                    nullableDimensions_encode(v.dimensions)
                  ],
                  [
                    "weightKg",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.weightKg)
                  ],
                  [
                    "packetsCount",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.packetsCount)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "heaviestWeightKg",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.heaviestWeightKg)
                  ],
                  [
                    "largestVolumeCm3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.largestVolumeCm3)
                  ],
                  [
                    "longestPacketLength",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.longestPacketLength)
                  ],
                  [
                    "biggestPacketDimensions",
                    false,
                    Spice.optionToJson(dimensions_encode, v.biggestPacketDimensions)
                  ],
                  [
                    "longestPacketDimensions",
                    false,
                    Spice.optionToJson(dimensions_encode, v.longestPacketDimensions)
                  ],
                  [
                    "volumeCm3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.volumeCm3)
                  ],
                  [
                    "packetsBarcode",
                    false,
                    Spice.arrayToJson(packetBarcode_encode, v.packetsBarcode)
                  ]
                ]));
}

function packaging_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var dimensions = nullableDimensions_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dimensions"), null));
  if (dimensions.TAG === "Ok") {
    var weightKg = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "weightKg"), null));
    if (weightKg.TAG === "Ok") {
      var packetsCount = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "packetsCount"), null));
      if (packetsCount.TAG === "Ok") {
        var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
        if (description.TAG === "Ok") {
          var heaviestWeightKg = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "heaviestWeightKg"), null));
          if (heaviestWeightKg.TAG === "Ok") {
            var largestVolumeCm3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "largestVolumeCm3"), null));
            if (largestVolumeCm3.TAG === "Ok") {
              var longestPacketLength = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "longestPacketLength"), null));
              if (longestPacketLength.TAG === "Ok") {
                var biggestPacketDimensions = Spice.optionFromJson(dimensions_decode, Belt_Option.getWithDefault(Js_dict.get(v, "biggestPacketDimensions"), null));
                if (biggestPacketDimensions.TAG === "Ok") {
                  var longestPacketDimensions = Spice.optionFromJson(dimensions_decode, Belt_Option.getWithDefault(Js_dict.get(v, "longestPacketDimensions"), null));
                  if (longestPacketDimensions.TAG === "Ok") {
                    var volumeCm3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "volumeCm3"), null));
                    if (volumeCm3.TAG === "Ok") {
                      var packetsBarcode = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "packetsBarcode"), (function (param) {
                                  return Spice.arrayFromJson(packetBarcode_decode, param);
                                })), {
                            TAG: "Ok",
                            _0: []
                          });
                      if (packetsBarcode.TAG === "Ok") {
                        return {
                                TAG: "Ok",
                                _0: {
                                  dimensions: dimensions._0,
                                  weightKg: weightKg._0,
                                  packetsCount: packetsCount._0,
                                  description: description._0,
                                  heaviestWeightKg: heaviestWeightKg._0,
                                  largestVolumeCm3: largestVolumeCm3._0,
                                  longestPacketLength: longestPacketLength._0,
                                  biggestPacketDimensions: biggestPacketDimensions._0,
                                  longestPacketDimensions: longestPacketDimensions._0,
                                  volumeCm3: volumeCm3._0,
                                  packetsBarcode: packetsBarcode._0
                                }
                              };
                      }
                      var e = packetsBarcode._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".packetsBarcode" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = volumeCm3._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".volumeCm3" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = longestPacketDimensions._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".longestPacketDimensions" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = biggestPacketDimensions._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".biggestPacketDimensions" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = longestPacketLength._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".longestPacketLength" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = largestVolumeCm3._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".largestVolumeCm3" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = heaviestWeightKg._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".heaviestWeightKg" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = description._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".description" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = packetsCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".packetsCount" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = weightKg._0;
    return {
            TAG: "Error",
            _0: {
              path: ".weightKg" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = dimensions._0;
  return {
          TAG: "Error",
          _0: {
            path: ".dimensions" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function signature_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function signature_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
  if (url.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                url: url._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = url._0;
  return {
          TAG: "Error",
          _0: {
            path: ".url" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function signatureTransition_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "mobileStateUpdatedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.mobileStateUpdatedAt)
                  ],
                  [
                    "problem",
                    false,
                    Spice.optionToJson(ColiswebApi__Utils.TransitionProblem.t_encode, v.problem)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "isSuccessful",
                    false,
                    Spice.boolToJson(v.isSuccessful)
                  ]
                ]));
}

function signatureTransition_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
  if (createdAt.TAG === "Ok") {
    var mobileStateUpdatedAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "mobileStateUpdatedAt"), null));
    if (mobileStateUpdatedAt.TAG === "Ok") {
      var problem = Spice.optionFromJson(ColiswebApi__Utils.TransitionProblem.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "problem"), null));
      if (problem.TAG === "Ok") {
        var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
        if (comment.TAG === "Ok") {
          var isSuccessful = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isSuccessful"), null));
          if (isSuccessful.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      createdAt: createdAt._0,
                      mobileStateUpdatedAt: mobileStateUpdatedAt._0,
                      problem: problem._0,
                      comment: comment._0,
                      isSuccessful: isSuccessful._0
                    }
                  };
          }
          var e = isSuccessful._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".isSuccessful" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = comment._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".comment" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = problem._0;
      return {
              TAG: "Error",
              _0: {
                path: ".problem" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = mobileStateUpdatedAt._0;
    return {
            TAG: "Error",
            _0: {
              path: ".mobileStateUpdatedAt" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = createdAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".createdAt" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function timeSlot_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end)
                  ]
                ]));
}

function timeSlot_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end: end_._0
              }
            };
    }
    var e = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function skill_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
    if (tag.TAG === "Ok") {
      var description = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        var isDefault = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "isDefault"), Spice.boolFromJson), {
              TAG: "Ok",
              _0: false
            });
        if (isDefault.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    tag: tag._0,
                    description: description._0,
                    isDefault: isDefault._0
                  }
                };
        }
        var e = isDefault._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".isDefault" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = tag._0;
    return {
            TAG: "Error",
            _0: {
              path: ".tag" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function skill_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ],
                  [
                    "description",
                    false,
                    Spice.stringToJson(v.description)
                  ],
                  [
                    "isDefault",
                    false,
                    Spice.boolToJson(v.isDefault)
                  ]
                ]));
}

function skills_encode$1(v) {
  return Spice.arrayToJson(skill_encode$1, v);
}

function skills_decode$1(v) {
  return Spice.arrayFromJson(skill_decode$1, v);
}

function transition_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "event",
                    false,
                    ColiswebApi__V5_Utils.FullStatusProvider.t_encode(v.event)
                  ],
                  [
                    "dateTime",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.dateTime)
                  ],
                  [
                    "mobileDateTime",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.mobileDateTime)
                  ],
                  [
                    "problem",
                    false,
                    Spice.optionToJson(ColiswebApi__Utils.TransitionProblem.t_encode, v.problem)
                  ]
                ]));
}

function transition_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var $$event = ColiswebApi__V5_Utils.FullStatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "event"), null));
  if ($$event.TAG === "Ok") {
    var dateTime = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dateTime"), null));
    if (dateTime.TAG === "Ok") {
      var mobileDateTime = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "mobileDateTime"), null));
      if (mobileDateTime.TAG === "Ok") {
        var problem = Spice.optionFromJson(ColiswebApi__Utils.TransitionProblem.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "problem"), null));
        if (problem.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    event: $$event._0,
                    dateTime: dateTime._0,
                    mobileDateTime: mobileDateTime._0,
                    problem: problem._0
                  }
                };
        }
        var e = problem._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".problem" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = mobileDateTime._0;
      return {
              TAG: "Error",
              _0: {
                path: ".mobileDateTime" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = dateTime._0;
    return {
            TAG: "Error",
            _0: {
              path: ".dateTime" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = $$event._0;
  return {
          TAG: "Error",
          _0: {
            path: ".event" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ],
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.DeliveryUuid.t_encode, v.uuid)
                  ],
                  [
                    "route",
                    false,
                    Spice.optionToJson(Identifiers.RouteId.t_encode, v.route)
                  ],
                  [
                    "routePlan",
                    false,
                    Spice.optionToJson(Identifiers.RoutePlanId.t_encode, v.routePlan)
                  ],
                  [
                    "ref1",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref1)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref2)
                  ],
                  [
                    "ref3",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref3)
                  ],
                  [
                    "ref4",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref4)
                  ],
                  [
                    "salesConsultantName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.salesConsultantName)
                  ],
                  [
                    "heftingOptions",
                    false,
                    Curry._1(ColiswebApi__V5_Shared.Delivery.HeftingOptions.t_encode, v.heftingOptions)
                  ],
                  [
                    "statusOriginator",
                    false,
                    ColiswebApi__V5_Utils.StatusOriginator.t_encode(v.statusOriginator)
                  ],
                  [
                    "statusProvider",
                    false,
                    ColiswebApi__V5_Utils.StatusProvider.t_encode(v.statusProvider)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "distanceKm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.distanceKm)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(skill_encode$1, v.skills)
                  ],
                  [
                    "timeSlot",
                    false,
                    timeSlot_encode$1(v.timeSlot)
                  ],
                  [
                    "pickup",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Shared.Delivery.Point.t_encode, v.pickup)
                  ],
                  [
                    "shipping",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Shared.Delivery.Point.t_encode, v.shipping)
                  ],
                  [
                    "customerSatisfaction",
                    false,
                    Spice.optionToJson(customerSatisfaction_encode, v.customerSatisfaction)
                  ],
                  [
                    "priceWithoutTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.priceWithoutTaxEur)
                  ],
                  [
                    "priceWithTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.priceWithTaxEur)
                  ],
                  [
                    "customerPriceWithoutTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithoutTaxEur)
                  ],
                  [
                    "customerPriceWithTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithTaxEur)
                  ],
                  [
                    "customerPriceWithoutTaxEurComposition",
                    false,
                    Spice.optionToJson((function (param) {
                            return Spice.arrayToJson(priceComposition_encode, param);
                          }), v.customerPriceWithoutTaxEurComposition)
                  ],
                  [
                    "packaging",
                    false,
                    Spice.optionToJson(packaging_encode$1, v.packaging)
                  ],
                  [
                    "pickupSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.pickupSignature)
                  ],
                  [
                    "shippingSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.shippingSignature)
                  ],
                  [
                    "returnSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.returnSignature)
                  ],
                  [
                    "lastPickupTransition",
                    false,
                    Spice.optionToJson(signatureTransition_encode, v.lastPickupTransition)
                  ],
                  [
                    "lastShippingTransition",
                    false,
                    Spice.optionToJson(signatureTransition_encode, v.lastShippingTransition)
                  ],
                  [
                    "lastReturnTransition",
                    false,
                    Spice.optionToJson(signatureTransition_encode, v.lastReturnTransition)
                  ],
                  [
                    "canUpdateSlot",
                    false,
                    Spice.boolToJson(v.canUpdateSlot)
                  ],
                  [
                    "canUpdatePickupAddress",
                    false,
                    Spice.boolToJson(v.canUpdatePickupAddress)
                  ],
                  [
                    "canUpdateShippingAddress",
                    false,
                    Spice.boolToJson(v.canUpdateShippingAddress)
                  ],
                  [
                    "deliveryStateTransitions",
                    false,
                    Spice.arrayToJson(transition_encode, v.deliveryStateTransitions)
                  ],
                  [
                    "isArchived",
                    false,
                    Spice.boolToJson(v.isArchived)
                  ],
                  [
                    "owner",
                    false,
                    Curry._1(ColiswebApi__V5_Shared.Delivery.Owner.t_encode, v.owner)
                  ],
                  [
                    "customerPricesFactor",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPricesFactor)
                  ],
                  [
                    "canReschedule",
                    false,
                    Spice.boolToJson(v.canReschedule)
                  ],
                  [
                    "canCancelDelivery",
                    false,
                    Spice.boolToJson(v.canCancelDelivery)
                  ],
                  [
                    "rescheduleDeliveryId",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryId.t_encode, v.rescheduleDeliveryId)
                  ],
                  [
                    "externalDeliveryCode",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.externalDeliveryCode)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function response_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var uuid = Curry._1(Identifiers.DeliveryUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
    if (uuid.TAG === "Ok") {
      var route = Spice.optionFromJson(Identifiers.RouteId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "route"), null));
      if (route.TAG === "Ok") {
        var routePlan = Spice.optionFromJson(Identifiers.RoutePlanId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "routePlan"), null));
        if (routePlan.TAG === "Ok") {
          var ref1 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
          if (ref1.TAG === "Ok") {
            var ref2 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
            if (ref2.TAG === "Ok") {
              var ref3 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref3"), null));
              if (ref3.TAG === "Ok") {
                var ref4 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref4"), null));
                if (ref4.TAG === "Ok") {
                  var salesConsultantName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "salesConsultantName"), null));
                  if (salesConsultantName.TAG === "Ok") {
                    var heftingOptions = Curry._1(ColiswebApi__V5_Shared.Delivery.HeftingOptions.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "heftingOptions"), null));
                    if (heftingOptions.TAG === "Ok") {
                      var statusOriginator = ColiswebApi__V5_Utils.StatusOriginator.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusOriginator"), null));
                      if (statusOriginator.TAG === "Ok") {
                        var statusProvider = ColiswebApi__V5_Utils.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
                        if (statusProvider.TAG === "Ok") {
                          var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
                          if (createdAt.TAG === "Ok") {
                            var distanceKm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "distanceKm"), null));
                            if (distanceKm.TAG === "Ok") {
                              var skills = Spice.arrayFromJson(skill_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                              if (skills.TAG === "Ok") {
                                var timeSlot = timeSlot_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
                                if (timeSlot.TAG === "Ok") {
                                  var pickup = Spice.optionFromJson(ColiswebApi__V5_Shared.Delivery.Point.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
                                  if (pickup.TAG === "Ok") {
                                    var shipping = Spice.optionFromJson(ColiswebApi__V5_Shared.Delivery.Point.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shipping"), null));
                                    if (shipping.TAG === "Ok") {
                                      var customerSatisfaction = Spice.optionFromJson(customerSatisfaction_decode, Belt_Option.getWithDefault(Js_dict.get(v, "customerSatisfaction"), null));
                                      if (customerSatisfaction.TAG === "Ok") {
                                        var priceWithoutTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEur"), null));
                                        if (priceWithoutTaxEur.TAG === "Ok") {
                                          var priceWithTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithTaxEur"), null));
                                          if (priceWithTaxEur.TAG === "Ok") {
                                            var customerPriceWithoutTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEur"), null));
                                            if (customerPriceWithoutTaxEur.TAG === "Ok") {
                                              var customerPriceWithTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithTaxEur"), null));
                                              if (customerPriceWithTaxEur.TAG === "Ok") {
                                                var customerPriceWithoutTaxEurComposition = Spice.optionFromJson((function (param) {
                                                        return Spice.arrayFromJson(priceComposition_decode, param);
                                                      }), Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEurComposition"), null));
                                                if (customerPriceWithoutTaxEurComposition.TAG === "Ok") {
                                                  var packaging = Spice.optionFromJson(packaging_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "packaging"), null));
                                                  if (packaging.TAG === "Ok") {
                                                    var pickupSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickupSignature"), null));
                                                    if (pickupSignature.TAG === "Ok") {
                                                      var shippingSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shippingSignature"), null));
                                                      if (shippingSignature.TAG === "Ok") {
                                                        var returnSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "returnSignature"), null));
                                                        if (returnSignature.TAG === "Ok") {
                                                          var lastPickupTransition = Spice.optionFromJson(signatureTransition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastPickupTransition"), null));
                                                          if (lastPickupTransition.TAG === "Ok") {
                                                            var lastShippingTransition = Spice.optionFromJson(signatureTransition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastShippingTransition"), null));
                                                            if (lastShippingTransition.TAG === "Ok") {
                                                              var lastReturnTransition = Spice.optionFromJson(signatureTransition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastReturnTransition"), null));
                                                              if (lastReturnTransition.TAG === "Ok") {
                                                                var canUpdateSlot = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canUpdateSlot"), null));
                                                                if (canUpdateSlot.TAG === "Ok") {
                                                                  var canUpdatePickupAddress = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canUpdatePickupAddress"), null));
                                                                  if (canUpdatePickupAddress.TAG === "Ok") {
                                                                    var canUpdateShippingAddress = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canUpdateShippingAddress"), null));
                                                                    if (canUpdateShippingAddress.TAG === "Ok") {
                                                                      var deliveryStateTransitions = Spice.arrayFromJson(transition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryStateTransitions"), null));
                                                                      if (deliveryStateTransitions.TAG === "Ok") {
                                                                        var isArchived = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isArchived"), null));
                                                                        if (isArchived.TAG === "Ok") {
                                                                          var owner = Curry._1(ColiswebApi__V5_Shared.Delivery.Owner.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "owner"), null));
                                                                          if (owner.TAG === "Ok") {
                                                                            var customerPricesFactor = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPricesFactor"), null));
                                                                            if (customerPricesFactor.TAG === "Ok") {
                                                                              var canReschedule = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "canReschedule"), Spice.boolFromJson), {
                                                                                    TAG: "Ok",
                                                                                    _0: false
                                                                                  });
                                                                              if (canReschedule.TAG === "Ok") {
                                                                                var canCancelDelivery = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canCancelDelivery"), null));
                                                                                if (canCancelDelivery.TAG === "Ok") {
                                                                                  var rescheduleDeliveryId = Spice.optionFromJson(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "rescheduleDeliveryId"), null));
                                                                                  if (rescheduleDeliveryId.TAG === "Ok") {
                                                                                    var externalDeliveryCode = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "externalDeliveryCode"), null));
                                                                                    if (externalDeliveryCode.TAG === "Ok") {
                                                                                      var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
                                                                                      if (clientId.TAG === "Ok") {
                                                                                        return {
                                                                                                TAG: "Ok",
                                                                                                _0: {
                                                                                                  id: id._0,
                                                                                                  uuid: uuid._0,
                                                                                                  route: route._0,
                                                                                                  routePlan: routePlan._0,
                                                                                                  ref1: ref1._0,
                                                                                                  ref2: ref2._0,
                                                                                                  ref3: ref3._0,
                                                                                                  ref4: ref4._0,
                                                                                                  salesConsultantName: salesConsultantName._0,
                                                                                                  heftingOptions: heftingOptions._0,
                                                                                                  statusOriginator: statusOriginator._0,
                                                                                                  statusProvider: statusProvider._0,
                                                                                                  createdAt: createdAt._0,
                                                                                                  distanceKm: distanceKm._0,
                                                                                                  skills: skills._0,
                                                                                                  timeSlot: timeSlot._0,
                                                                                                  pickup: pickup._0,
                                                                                                  shipping: shipping._0,
                                                                                                  customerSatisfaction: customerSatisfaction._0,
                                                                                                  priceWithoutTaxEur: priceWithoutTaxEur._0,
                                                                                                  priceWithTaxEur: priceWithTaxEur._0,
                                                                                                  customerPriceWithoutTaxEur: customerPriceWithoutTaxEur._0,
                                                                                                  customerPriceWithTaxEur: customerPriceWithTaxEur._0,
                                                                                                  customerPriceWithoutTaxEurComposition: customerPriceWithoutTaxEurComposition._0,
                                                                                                  packaging: packaging._0,
                                                                                                  pickupSignature: pickupSignature._0,
                                                                                                  shippingSignature: shippingSignature._0,
                                                                                                  returnSignature: returnSignature._0,
                                                                                                  lastPickupTransition: lastPickupTransition._0,
                                                                                                  lastShippingTransition: lastShippingTransition._0,
                                                                                                  lastReturnTransition: lastReturnTransition._0,
                                                                                                  canUpdateSlot: canUpdateSlot._0,
                                                                                                  canUpdatePickupAddress: canUpdatePickupAddress._0,
                                                                                                  canUpdateShippingAddress: canUpdateShippingAddress._0,
                                                                                                  deliveryStateTransitions: deliveryStateTransitions._0,
                                                                                                  isArchived: isArchived._0,
                                                                                                  owner: owner._0,
                                                                                                  customerPricesFactor: customerPricesFactor._0,
                                                                                                  canReschedule: canReschedule._0,
                                                                                                  canCancelDelivery: canCancelDelivery._0,
                                                                                                  rescheduleDeliveryId: rescheduleDeliveryId._0,
                                                                                                  externalDeliveryCode: externalDeliveryCode._0,
                                                                                                  clientId: clientId._0
                                                                                                }
                                                                                              };
                                                                                      }
                                                                                      var e = clientId._0;
                                                                                      return {
                                                                                              TAG: "Error",
                                                                                              _0: {
                                                                                                path: ".clientId" + e.path,
                                                                                                message: e.message,
                                                                                                value: e.value
                                                                                              }
                                                                                            };
                                                                                    }
                                                                                    var e$1 = externalDeliveryCode._0;
                                                                                    return {
                                                                                            TAG: "Error",
                                                                                            _0: {
                                                                                              path: ".externalDeliveryCode" + e$1.path,
                                                                                              message: e$1.message,
                                                                                              value: e$1.value
                                                                                            }
                                                                                          };
                                                                                  }
                                                                                  var e$2 = rescheduleDeliveryId._0;
                                                                                  return {
                                                                                          TAG: "Error",
                                                                                          _0: {
                                                                                            path: ".rescheduleDeliveryId" + e$2.path,
                                                                                            message: e$2.message,
                                                                                            value: e$2.value
                                                                                          }
                                                                                        };
                                                                                }
                                                                                var e$3 = canCancelDelivery._0;
                                                                                return {
                                                                                        TAG: "Error",
                                                                                        _0: {
                                                                                          path: ".canCancelDelivery" + e$3.path,
                                                                                          message: e$3.message,
                                                                                          value: e$3.value
                                                                                        }
                                                                                      };
                                                                              }
                                                                              var e$4 = canReschedule._0;
                                                                              return {
                                                                                      TAG: "Error",
                                                                                      _0: {
                                                                                        path: ".canReschedule" + e$4.path,
                                                                                        message: e$4.message,
                                                                                        value: e$4.value
                                                                                      }
                                                                                    };
                                                                            }
                                                                            var e$5 = customerPricesFactor._0;
                                                                            return {
                                                                                    TAG: "Error",
                                                                                    _0: {
                                                                                      path: ".customerPricesFactor" + e$5.path,
                                                                                      message: e$5.message,
                                                                                      value: e$5.value
                                                                                    }
                                                                                  };
                                                                          }
                                                                          var e$6 = owner._0;
                                                                          return {
                                                                                  TAG: "Error",
                                                                                  _0: {
                                                                                    path: ".owner" + e$6.path,
                                                                                    message: e$6.message,
                                                                                    value: e$6.value
                                                                                  }
                                                                                };
                                                                        }
                                                                        var e$7 = isArchived._0;
                                                                        return {
                                                                                TAG: "Error",
                                                                                _0: {
                                                                                  path: ".isArchived" + e$7.path,
                                                                                  message: e$7.message,
                                                                                  value: e$7.value
                                                                                }
                                                                              };
                                                                      }
                                                                      var e$8 = deliveryStateTransitions._0;
                                                                      return {
                                                                              TAG: "Error",
                                                                              _0: {
                                                                                path: ".deliveryStateTransitions" + e$8.path,
                                                                                message: e$8.message,
                                                                                value: e$8.value
                                                                              }
                                                                            };
                                                                    }
                                                                    var e$9 = canUpdateShippingAddress._0;
                                                                    return {
                                                                            TAG: "Error",
                                                                            _0: {
                                                                              path: ".canUpdateShippingAddress" + e$9.path,
                                                                              message: e$9.message,
                                                                              value: e$9.value
                                                                            }
                                                                          };
                                                                  }
                                                                  var e$10 = canUpdatePickupAddress._0;
                                                                  return {
                                                                          TAG: "Error",
                                                                          _0: {
                                                                            path: ".canUpdatePickupAddress" + e$10.path,
                                                                            message: e$10.message,
                                                                            value: e$10.value
                                                                          }
                                                                        };
                                                                }
                                                                var e$11 = canUpdateSlot._0;
                                                                return {
                                                                        TAG: "Error",
                                                                        _0: {
                                                                          path: ".canUpdateSlot" + e$11.path,
                                                                          message: e$11.message,
                                                                          value: e$11.value
                                                                        }
                                                                      };
                                                              }
                                                              var e$12 = lastReturnTransition._0;
                                                              return {
                                                                      TAG: "Error",
                                                                      _0: {
                                                                        path: ".lastReturnTransition" + e$12.path,
                                                                        message: e$12.message,
                                                                        value: e$12.value
                                                                      }
                                                                    };
                                                            }
                                                            var e$13 = lastShippingTransition._0;
                                                            return {
                                                                    TAG: "Error",
                                                                    _0: {
                                                                      path: ".lastShippingTransition" + e$13.path,
                                                                      message: e$13.message,
                                                                      value: e$13.value
                                                                    }
                                                                  };
                                                          }
                                                          var e$14 = lastPickupTransition._0;
                                                          return {
                                                                  TAG: "Error",
                                                                  _0: {
                                                                    path: ".lastPickupTransition" + e$14.path,
                                                                    message: e$14.message,
                                                                    value: e$14.value
                                                                  }
                                                                };
                                                        }
                                                        var e$15 = returnSignature._0;
                                                        return {
                                                                TAG: "Error",
                                                                _0: {
                                                                  path: ".returnSignature" + e$15.path,
                                                                  message: e$15.message,
                                                                  value: e$15.value
                                                                }
                                                              };
                                                      }
                                                      var e$16 = shippingSignature._0;
                                                      return {
                                                              TAG: "Error",
                                                              _0: {
                                                                path: ".shippingSignature" + e$16.path,
                                                                message: e$16.message,
                                                                value: e$16.value
                                                              }
                                                            };
                                                    }
                                                    var e$17 = pickupSignature._0;
                                                    return {
                                                            TAG: "Error",
                                                            _0: {
                                                              path: ".pickupSignature" + e$17.path,
                                                              message: e$17.message,
                                                              value: e$17.value
                                                            }
                                                          };
                                                  }
                                                  var e$18 = packaging._0;
                                                  return {
                                                          TAG: "Error",
                                                          _0: {
                                                            path: ".packaging" + e$18.path,
                                                            message: e$18.message,
                                                            value: e$18.value
                                                          }
                                                        };
                                                }
                                                var e$19 = customerPriceWithoutTaxEurComposition._0;
                                                return {
                                                        TAG: "Error",
                                                        _0: {
                                                          path: ".customerPriceWithoutTaxEurComposition" + e$19.path,
                                                          message: e$19.message,
                                                          value: e$19.value
                                                        }
                                                      };
                                              }
                                              var e$20 = customerPriceWithTaxEur._0;
                                              return {
                                                      TAG: "Error",
                                                      _0: {
                                                        path: ".customerPriceWithTaxEur" + e$20.path,
                                                        message: e$20.message,
                                                        value: e$20.value
                                                      }
                                                    };
                                            }
                                            var e$21 = customerPriceWithoutTaxEur._0;
                                            return {
                                                    TAG: "Error",
                                                    _0: {
                                                      path: ".customerPriceWithoutTaxEur" + e$21.path,
                                                      message: e$21.message,
                                                      value: e$21.value
                                                    }
                                                  };
                                          }
                                          var e$22 = priceWithTaxEur._0;
                                          return {
                                                  TAG: "Error",
                                                  _0: {
                                                    path: ".priceWithTaxEur" + e$22.path,
                                                    message: e$22.message,
                                                    value: e$22.value
                                                  }
                                                };
                                        }
                                        var e$23 = priceWithoutTaxEur._0;
                                        return {
                                                TAG: "Error",
                                                _0: {
                                                  path: ".priceWithoutTaxEur" + e$23.path,
                                                  message: e$23.message,
                                                  value: e$23.value
                                                }
                                              };
                                      }
                                      var e$24 = customerSatisfaction._0;
                                      return {
                                              TAG: "Error",
                                              _0: {
                                                path: ".customerSatisfaction" + e$24.path,
                                                message: e$24.message,
                                                value: e$24.value
                                              }
                                            };
                                    }
                                    var e$25 = shipping._0;
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              path: ".shipping" + e$25.path,
                                              message: e$25.message,
                                              value: e$25.value
                                            }
                                          };
                                  }
                                  var e$26 = pickup._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".pickup" + e$26.path,
                                            message: e$26.message,
                                            value: e$26.value
                                          }
                                        };
                                }
                                var e$27 = timeSlot._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".timeSlot" + e$27.path,
                                          message: e$27.message,
                                          value: e$27.value
                                        }
                                      };
                              }
                              var e$28 = skills._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".skills" + e$28.path,
                                        message: e$28.message,
                                        value: e$28.value
                                      }
                                    };
                            }
                            var e$29 = distanceKm._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".distanceKm" + e$29.path,
                                      message: e$29.message,
                                      value: e$29.value
                                    }
                                  };
                          }
                          var e$30 = createdAt._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".createdAt" + e$30.path,
                                    message: e$30.message,
                                    value: e$30.value
                                  }
                                };
                        }
                        var e$31 = statusProvider._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".statusProvider" + e$31.path,
                                  message: e$31.message,
                                  value: e$31.value
                                }
                              };
                      }
                      var e$32 = statusOriginator._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".statusOriginator" + e$32.path,
                                message: e$32.message,
                                value: e$32.value
                              }
                            };
                    }
                    var e$33 = heftingOptions._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".heftingOptions" + e$33.path,
                              message: e$33.message,
                              value: e$33.value
                            }
                          };
                  }
                  var e$34 = salesConsultantName._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".salesConsultantName" + e$34.path,
                            message: e$34.message,
                            value: e$34.value
                          }
                        };
                }
                var e$35 = ref4._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".ref4" + e$35.path,
                          message: e$35.message,
                          value: e$35.value
                        }
                      };
              }
              var e$36 = ref3._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".ref3" + e$36.path,
                        message: e$36.message,
                        value: e$36.value
                      }
                    };
            }
            var e$37 = ref2._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".ref2" + e$37.path,
                      message: e$37.message,
                      value: e$37.value
                    }
                  };
          }
          var e$38 = ref1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".ref1" + e$38.path,
                    message: e$38.message,
                    value: e$38.value
                  }
                };
        }
        var e$39 = routePlan._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".routePlan" + e$39.path,
                  message: e$39.message,
                  value: e$39.value
                }
              };
      }
      var e$40 = route._0;
      return {
              TAG: "Error",
              _0: {
                path: ".route" + e$40.path,
                message: e$40.message,
                value: e$40.value
              }
            };
    }
    var e$41 = uuid._0;
    return {
            TAG: "Error",
            _0: {
              path: ".uuid" + e$41.path,
              message: e$41.message,
              value: e$41.value
            }
          };
  }
  var e$42 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$42.path,
            message: e$42.message,
            value: e$42.value
          }
        };
}

function exec$10(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$10, undefined, baseUrl$1(param[0], param[1], param[2]));
}

var Config$10 = {
  priceComposition_encode: priceComposition_encode,
  priceComposition_decode: priceComposition_decode,
  customerSatisfaction_encode: customerSatisfaction_encode,
  customerSatisfaction_decode: customerSatisfaction_decode,
  nullableDimensions_encode: nullableDimensions_encode,
  nullableDimensions_decode: nullableDimensions_decode,
  packaging_encode: packaging_encode$1,
  packaging_decode: packaging_decode$1,
  packetBarcode_encode: packetBarcode_encode,
  packetBarcode_decode: packetBarcode_decode,
  dimensions_encode: dimensions_encode,
  dimensions_decode: dimensions_decode,
  signature_encode: signature_encode,
  signature_decode: signature_decode,
  signatureTransition_encode: signatureTransition_encode,
  signatureTransition_decode: signatureTransition_decode,
  timeSlot_encode: timeSlot_encode$1,
  timeSlot_decode: timeSlot_decode$1,
  skills_encode: skills_encode$1,
  skills_decode: skills_decode$1,
  skill_encode: skill_encode$1,
  skill_decode: skill_decode$1,
  transition_encode: transition_encode,
  transition_decode: transition_decode,
  response_encode: response_encode$10,
  response_decode: response_decode$10,
  exec: exec$10
};

var $$Request$10 = Toolkit__Request.Make({
      exec: exec$10
    });

var GetDelivery = {
  Config: Config$10,
  $$Request: $$Request$10
};

function t_encode$6(v) {
  switch (v) {
    case "store_not_found" :
        return "store_not_found";
    case "client_not_found" :
        return "client_not_found";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "empty_response_from_packing" :
        return "empty_response_from_packing";
    case "package_too_big" :
        return "package_too_big";
    case "packet_too_big" :
        return "packet_too_big";
    case "distance_too_far" :
        return "distance_too_far";
    case "total_weight_too_heavy" :
        return "total_weight_too_heavy";
    case "too_heavy_single_packet" :
        return "too_heavy_single_packet";
    case "conditions_not_matched_for_delivery" :
        return "conditions_not_matched_for_delivery";
    case "invalid_postal_code" :
        return "invalid_postal_code";
    case "not_configured_to_order_deliveries" :
        return "not_configured_to_order_deliveries";
    case "generated_calendar_is_empty" :
        return "generated_calendar_is_empty";
    case "too_heavy_packet" :
        return "too_heavy_packet";
    case "area_not_eligible" :
        return "area_not_eligible";
    case "unhandled" :
        return "unhandled";
    
  }
}

function t_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("empty_response_from_packing" === v) {
          return {
                  TAG: "Ok",
                  _0: "empty_response_from_packing"
                };
        } else if ("package_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_too_big"
                };
        } else if ("packet_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "packet_too_big"
                };
        } else if ("distance_too_far" === v) {
          return {
                  TAG: "Ok",
                  _0: "distance_too_far"
                };
        } else if ("total_weight_too_heavy" === v) {
          return {
                  TAG: "Ok",
                  _0: "total_weight_too_heavy"
                };
        } else if ("too_heavy_single_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_single_packet"
                };
        } else if ("conditions_not_matched_for_delivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "conditions_not_matched_for_delivery"
                };
        } else if ("invalid_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_postal_code"
                };
        } else if ("not_configured_to_order_deliveries" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_configured_to_order_deliveries"
                };
        } else if ("generated_calendar_is_empty" === v) {
          return {
                  TAG: "Ok",
                  _0: "generated_calendar_is_empty"
                };
        } else if ("too_heavy_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_packet"
                };
        } else if ("area_not_eligible" === v) {
          return {
                  TAG: "Ok",
                  _0: "area_not_eligible"
                };
        } else if ("unhandled" === v) {
          return {
                  TAG: "Ok",
                  _0: "unhandled"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$2 = {
  t_encode: t_encode$6,
  t_decode: t_decode$6
};

function body_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "startDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.startDate)
                  ],
                  [
                    "endDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.endDate)
                  ]
                ]));
}

function body_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "startDate"), null));
  if (startDate.TAG === "Ok") {
    var endDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "endDate"), null));
    if (endDate.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                startDate: startDate._0,
                endDate: endDate._0
              }
            };
    }
    var e = endDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".endDate" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = startDate._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startDate" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "updateDeliverySlotRequestId",
                    false,
                    Spice.stringToJson(v.updateDeliverySlotRequestId)
                  ],
                  [
                    "calendar",
                    false,
                    Spice.arrayToJson(calendar_encode, v.calendar)
                  ]
                ]));
}

function response_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var updateDeliverySlotRequestId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "updateDeliverySlotRequestId"), null));
  if (updateDeliverySlotRequestId.TAG === "Ok") {
    var calendar = Spice.arrayFromJson(calendar_decode, Belt_Option.getWithDefault(Js_dict.get(v, "calendar"), null));
    if (calendar.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                updateDeliverySlotRequestId: updateDeliverySlotRequestId._0,
                calendar: calendar._0
              }
            };
    }
    var e = calendar._0;
    return {
            TAG: "Error",
            _0: {
              path: ".calendar" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = updateDeliverySlotRequestId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".updateDeliverySlotRequestId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$11(param) {
  return Axios.WithResult.post(undefined, body_encode$6(param[3]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$11, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$6(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "custom",
                          VAL: "unhandled"
                        };
                }
              }), baseUrl$1(param[0], param[1], param[2]) + "/getEligibleSlots");
}

var Config$11 = {
  body_encode: body_encode$6,
  body_decode: body_decode$6,
  response_encode: response_encode$11,
  response_decode: response_decode$11,
  exec: exec$11
};

var $$Request$11 = Toolkit__Request.Make({
      exec: exec$11
    });

var GetEligibleTimeSlots$1 = {
  $$Error: $$Error$2,
  Config: Config$11,
  $$Request: $$Request$11
};

function t_encode$7(v) {
  switch (v) {
    case "the_request_is_expired" :
        return "the_request_is_expired";
    case "cannot_update_slot" :
        return "cannot_update_slot";
    case "update_slot_not_found" :
        return "update_slot_not_found";
    case "client_not_found" :
        return "client_not_found";
    case "store_not_found" :
        return "store_not_found";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "empty_response_from_packing" :
        return "empty_response_from_packing";
    case "package_too_big" :
        return "package_too_big";
    case "packet_too_big" :
        return "packet_too_big";
    case "distance_too_far" :
        return "distance_too_far";
    case "total_weight_too_heavy" :
        return "total_weight_too_heavy";
    case "too_heavy_single_packet" :
        return "too_heavy_single_packet";
    case "conditions_not_matched_for_delivery" :
        return "conditions_not_matched_for_delivery";
    case "invalid_postal_code" :
        return "invalid_postal_code";
    case "not_configured_to_order_deliveries" :
        return "not_configured_to_order_deliveries";
    case "slot_too_wide" :
        return "slot_too_wide";
    case "slot_too_narrow" :
        return "slot_too_narrow";
    case "slot_out_of_openings" :
        return "slot_out_of_openings";
    case "area_not_eligible" :
        return "area_not_eligible";
    
  }
}

function t_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("the_request_is_expired" === v) {
          return {
                  TAG: "Ok",
                  _0: "the_request_is_expired"
                };
        } else if ("cannot_update_slot" === v) {
          return {
                  TAG: "Ok",
                  _0: "cannot_update_slot"
                };
        } else if ("update_slot_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "update_slot_not_found"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("empty_response_from_packing" === v) {
          return {
                  TAG: "Ok",
                  _0: "empty_response_from_packing"
                };
        } else if ("package_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_too_big"
                };
        } else if ("packet_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "packet_too_big"
                };
        } else if ("distance_too_far" === v) {
          return {
                  TAG: "Ok",
                  _0: "distance_too_far"
                };
        } else if ("total_weight_too_heavy" === v) {
          return {
                  TAG: "Ok",
                  _0: "total_weight_too_heavy"
                };
        } else if ("too_heavy_single_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_single_packet"
                };
        } else if ("conditions_not_matched_for_delivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "conditions_not_matched_for_delivery"
                };
        } else if ("invalid_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_postal_code"
                };
        } else if ("not_configured_to_order_deliveries" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_configured_to_order_deliveries"
                };
        } else if ("slot_too_wide" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_too_wide"
                };
        } else if ("slot_too_narrow" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_too_narrow"
                };
        } else if ("slot_out_of_openings" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_out_of_openings"
                };
        } else if ("area_not_eligible" === v) {
          return {
                  TAG: "Ok",
                  _0: "area_not_eligible"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$3 = {
  t_encode: t_encode$7,
  t_decode: t_decode$7
};

function body_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "updateDeliverySlotRequestId",
                    false,
                    Spice.stringToJson(v.updateDeliverySlotRequestId)
                  ],
                  [
                    "slotOptionId",
                    false,
                    Spice.stringToJson(v.slotOptionId)
                  ]
                ]));
}

function body_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var updateDeliverySlotRequestId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "updateDeliverySlotRequestId"), null));
  if (updateDeliverySlotRequestId.TAG === "Ok") {
    var slotOptionId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "slotOptionId"), null));
    if (slotOptionId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                updateDeliverySlotRequestId: updateDeliverySlotRequestId._0,
                slotOptionId: slotOptionId._0
              }
            };
    }
    var e = slotOptionId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".slotOptionId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = updateDeliverySlotRequestId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".updateDeliverySlotRequestId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$12 = Spice.unitToJson;

var response_decode$12 = Spice.unitFromJson;

function exec$12(param) {
  return Axios.WithResult.put(undefined, body_encode$7(param[3]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$12, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$7(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            v._0
                          ]
                        };
                }
              }), baseUrl$1(param[0], param[1], param[2]) + "/updateSlot");
}

var Config$12 = {
  body_encode: body_encode$7,
  body_decode: body_decode$7,
  response_encode: response_encode$12,
  response_decode: response_decode$12,
  exec: exec$12
};

var $$Request$12 = Toolkit__Request.Make({
      exec: exec$12
    });

var UpdateTimeSlot = {
  $$Error: $$Error$3,
  Config: Config$12,
  $$Request: $$Request$12
};

function t_encode$8(v) {
  switch (v) {
    case "line1" :
        return "line1";
    case "line2" :
        return "line2";
    case "city" :
        return "city";
    case "country" :
        return "country";
    case "society" :
        return "society";
    case "floor" :
        return "floor";
    case "state_or_province" :
        return "state_or_province";
    case "postal_code" :
        return "postal_code";
    case "starts_at" :
        return "starts_at";
    case "ends_at" :
        return "ends_at";
    case "lift_information" :
        return "lift_information";
    case "additional_informations" :
        return "additional_informations";
    case "administrative_area_code2" :
        return "administrative_area_code2";
    case "administrative_area_code3" :
        return "administrative_area_code3";
    
  }
}

function t_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("line1" === v) {
          return {
                  TAG: "Ok",
                  _0: "line1"
                };
        } else if ("line2" === v) {
          return {
                  TAG: "Ok",
                  _0: "line2"
                };
        } else if ("city" === v) {
          return {
                  TAG: "Ok",
                  _0: "city"
                };
        } else if ("country" === v) {
          return {
                  TAG: "Ok",
                  _0: "country"
                };
        } else if ("society" === v) {
          return {
                  TAG: "Ok",
                  _0: "society"
                };
        } else if ("floor" === v) {
          return {
                  TAG: "Ok",
                  _0: "floor"
                };
        } else if ("state_or_province" === v) {
          return {
                  TAG: "Ok",
                  _0: "state_or_province"
                };
        } else if ("postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "postal_code"
                };
        } else if ("starts_at" === v) {
          return {
                  TAG: "Ok",
                  _0: "starts_at"
                };
        } else if ("ends_at" === v) {
          return {
                  TAG: "Ok",
                  _0: "ends_at"
                };
        } else if ("lift_information" === v) {
          return {
                  TAG: "Ok",
                  _0: "lift_information"
                };
        } else if ("additional_informations" === v) {
          return {
                  TAG: "Ok",
                  _0: "additional_informations"
                };
        } else if ("administrative_area_code2" === v) {
          return {
                  TAG: "Ok",
                  _0: "administrative_area_code2"
                };
        } else if ("administrative_area_code3" === v) {
          return {
                  TAG: "Ok",
                  _0: "administrative_area_code3"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var FieldName$1 = {
  t_encode: t_encode$8,
  t_decode: t_decode$8
};

function t_encode$9(v) {
  switch (v) {
    case "destination_address" :
        return "destination_address";
    case "origin_address" :
        return "origin_address";
    case "slot" :
        return "slot";
    
  }
}

function t_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("destination_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "destination_address"
                };
        } else if ("origin_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "origin_address"
                };
        } else if ("slot" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var ChangeType = {
  t_encode: t_encode$9,
  t_decode: t_decode$9
};

function t_encode$10(v) {
  switch (v) {
    case "admin" :
        return "admin";
    case "sav_admin" :
        return "sav_admin";
    case "client_admin" :
        return "client_admin";
    case "store_admin" :
        return "store_admin";
    case "transporter_admin" :
        return "transporter_admin";
    case "e_retailer" :
        return "e_retailer";
    case "carrier" :
        return "carrier";
    case "tech_admin" :
        return "tech_admin";
    case "super_admin" :
        return "super_admin";
    case "transporter_pole_admin" :
        return "transporter_pole_admin";
    case "customer" :
        return "customer";
    case "supplier" :
        return "supplier";
    
  }
}

function t_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "admin"
                };
        } else if ("sav_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "sav_admin"
                };
        } else if ("client_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_admin"
                };
        } else if ("store_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_admin"
                };
        } else if ("transporter_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporter_admin"
                };
        } else if ("e_retailer" === v) {
          return {
                  TAG: "Ok",
                  _0: "e_retailer"
                };
        } else if ("carrier" === v) {
          return {
                  TAG: "Ok",
                  _0: "carrier"
                };
        } else if ("tech_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "tech_admin"
                };
        } else if ("super_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "super_admin"
                };
        } else if ("transporter_pole_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporter_pole_admin"
                };
        } else if ("customer" === v) {
          return {
                  TAG: "Ok",
                  _0: "customer"
                };
        } else if ("supplier" === v) {
          return {
                  TAG: "Ok",
                  _0: "supplier"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var UserRole = {
  t_encode: t_encode$10,
  t_decode: t_decode$10
};

function t_encode$11(v) {
  switch (v) {
    case "customer_missed_sms" :
        return "customer_missed_sms";
    case "client_request" :
        return "client_request";
    case "store_request" :
        return "store_request";
    case "late_traction" :
        return "late_traction";
    case "client_workflow_error" :
        return "client_workflow_error";
    case "missing_order" :
        return "missing_order";
    case "transporter_late" :
        return "transporter_late";
    case "lack_of_capacity" :
        return "lack_of_capacity";
    case "internal_workflow_error" :
        return "internal_workflow_error";
    case "internal_late_traction" :
        return "internal_late_traction";
    case "other" :
        return "other";
    case "inaccessible_lez" :
        return "inaccessible_lez";
    case "refined" :
        return "refined";
    
  }
}

function t_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("customer_missed_sms" === v) {
          return {
                  TAG: "Ok",
                  _0: "customer_missed_sms"
                };
        } else if ("client_request" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_request"
                };
        } else if ("store_request" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_request"
                };
        } else if ("late_traction" === v) {
          return {
                  TAG: "Ok",
                  _0: "late_traction"
                };
        } else if ("client_workflow_error" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_workflow_error"
                };
        } else if ("missing_order" === v) {
          return {
                  TAG: "Ok",
                  _0: "missing_order"
                };
        } else if ("transporter_late" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporter_late"
                };
        } else if ("lack_of_capacity" === v) {
          return {
                  TAG: "Ok",
                  _0: "lack_of_capacity"
                };
        } else if ("internal_workflow_error" === v) {
          return {
                  TAG: "Ok",
                  _0: "internal_workflow_error"
                };
        } else if ("internal_late_traction" === v) {
          return {
                  TAG: "Ok",
                  _0: "internal_late_traction"
                };
        } else if ("other" === v) {
          return {
                  TAG: "Ok",
                  _0: "other"
                };
        } else if ("inaccessible_lez" === v) {
          return {
                  TAG: "Ok",
                  _0: "inaccessible_lez"
                };
        } else if ("refined" === v) {
          return {
                  TAG: "Ok",
                  _0: "refined"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var ModificationReason = {
  t_encode: t_encode$11,
  t_decode: t_decode$11
};

function modification_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "fieldName",
                    false,
                    t_encode$8(v.fieldName)
                  ],
                  [
                    "valueBefore",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.valueBefore)
                  ],
                  [
                    "valueAfter",
                    false,
                    Spice.stringToJson(v.valueAfter)
                  ]
                ]));
}

function modification_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var fieldName = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "fieldName"), null));
  if (fieldName.TAG === "Ok") {
    var valueBefore = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "valueBefore"), null));
    if (valueBefore.TAG === "Ok") {
      var valueAfter = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "valueAfter"), null));
      if (valueAfter.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  fieldName: fieldName._0,
                  valueBefore: valueBefore._0,
                  valueAfter: valueAfter._0
                }
              };
      }
      var e = valueAfter._0;
      return {
              TAG: "Error",
              _0: {
                path: ".valueAfter" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = valueBefore._0;
    return {
            TAG: "Error",
            _0: {
              path: ".valueBefore" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = fieldName._0;
  return {
          TAG: "Error",
          _0: {
            path: ".fieldName" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function modificationHistory_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "modifiedBy",
                    false,
                    Spice.optionToJson(t_encode$10, v.modifiedBy)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "modificationType",
                    false,
                    t_encode$9(v.modificationType)
                  ],
                  [
                    "modifications",
                    false,
                    Spice.arrayToJson(modification_encode, v.modifications)
                  ],
                  [
                    "modificationReason",
                    false,
                    Spice.optionToJson(t_encode$11, v.modificationReason)
                  ]
                ]));
}

function modificationHistory_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
  if (createdAt.TAG === "Ok") {
    var modifiedBy = Spice.optionFromJson(t_decode$10, Belt_Option.getWithDefault(Js_dict.get(v, "modifiedBy"), null));
    if (modifiedBy.TAG === "Ok") {
      var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var modificationType = t_decode$9(Belt_Option.getWithDefault(Js_dict.get(v, "modificationType"), null));
        if (modificationType.TAG === "Ok") {
          var modifications = Spice.arrayFromJson(modification_decode, Belt_Option.getWithDefault(Js_dict.get(v, "modifications"), null));
          if (modifications.TAG === "Ok") {
            var modificationReason = Spice.optionFromJson(t_decode$11, Belt_Option.getWithDefault(Js_dict.get(v, "modificationReason"), null));
            if (modificationReason.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        createdAt: createdAt._0,
                        modifiedBy: modifiedBy._0,
                        email: email._0,
                        modificationType: modificationType._0,
                        modifications: modifications._0,
                        modificationReason: modificationReason._0
                      }
                    };
            }
            var e = modificationReason._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".modificationReason" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = modifications._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".modifications" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = modificationType._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".modificationType" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = modifiedBy._0;
    return {
            TAG: "Error",
            _0: {
              path: ".modifiedBy" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = createdAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".createdAt" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function response_encode$13(v) {
  return Spice.arrayToJson(modificationHistory_encode, v);
}

function response_decode$13(v) {
  return Spice.arrayFromJson(modificationHistory_decode, v);
}

function exec$13(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$13, undefined, baseUrl$1(param[0], param[1], param[2]) + "/modificationsHistory");
}

var Config$13 = {
  response_encode: response_encode$13,
  response_decode: response_decode$13,
  modificationHistory_encode: modificationHistory_encode,
  modificationHistory_decode: modificationHistory_decode,
  modification_encode: modification_encode,
  modification_decode: modification_decode,
  exec: exec$13
};

var $$Request$13 = Toolkit__Request.Make({
      exec: exec$13
    });

var GetChangesHistory = {
  FieldName: FieldName$1,
  ChangeType: ChangeType,
  UserRole: UserRole,
  ModificationReason: ModificationReason,
  Config: Config$13,
  $$Request: $$Request$13
};

function packet_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.PacketId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
    if (description.TAG === "Ok") {
      var weightKg = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "weightKg"), null));
      if (weightKg.TAG === "Ok") {
        var heightCm = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
        if (heightCm.TAG === "Ok") {
          var widthCm = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
          if (widthCm.TAG === "Ok") {
            var lengthCm = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
            if (lengthCm.TAG === "Ok") {
              var volumeM3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "volumeM3"), null));
              if (volumeM3.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          id: id._0,
                          description: description._0,
                          weightKg: weightKg._0,
                          heightCm: heightCm._0,
                          widthCm: widthCm._0,
                          lengthCm: lengthCm._0,
                          volumeM3: volumeM3._0
                        }
                      };
              }
              var e = volumeM3._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".volumeM3" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = lengthCm._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".lengthCm" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = widthCm._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".widthCm" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = heightCm._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".heightCm" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = weightKg._0;
      return {
              TAG: "Error",
              _0: {
                path: ".weightKg" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = description._0;
    return {
            TAG: "Error",
            _0: {
              path: ".description" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function packet_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.PacketId.t_encode, v.id)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "weightKg",
                    false,
                    Spice.stringToJson(v.weightKg)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.heightCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.widthCm)
                  ],
                  [
                    "lengthCm",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lengthCm)
                  ],
                  [
                    "volumeM3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.volumeM3)
                  ]
                ]));
}

function response_encode$14(v) {
  return Spice.arrayToJson(packet_encode, v);
}

function response_decode$14(v) {
  return Spice.arrayFromJson(packet_decode, v);
}

function exec$14(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$14, undefined, baseUrl$1(param[0], param[1], param[2]) + "/packets");
}

var Config$14 = {
  response_encode: response_encode$14,
  response_decode: response_decode$14,
  packet_encode: packet_encode,
  packet_decode: packet_decode,
  exec: exec$14
};

var $$Request$14 = Toolkit__Request.Make({
      exec: exec$14
    });

var GetPackets = {
  Config: Config$14,
  $$Request: $$Request$14
};

function picture_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.PictureId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
    if (url.TAG === "Ok") {
      var status = ColiswebApi__V5_Shared.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
      if (status.TAG === "Ok") {
        var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
        if (createdAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    url: url._0,
                    status: status._0,
                    createdAt: createdAt._0
                  }
                };
        }
        var e = createdAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".createdAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = status._0;
      return {
              TAG: "Error",
              _0: {
                path: ".status" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = url._0;
    return {
            TAG: "Error",
            _0: {
              path: ".url" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function picture_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.PictureId.t_encode, v.id)
                  ],
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "status",
                    false,
                    ColiswebApi__V5_Shared.StatusProvider.t_encode(v.status)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ]
                ]));
}

function response_encode$15(v) {
  return Spice.arrayToJson(picture_encode, v);
}

function response_decode$15(v) {
  return Spice.arrayFromJson(picture_decode, v);
}

function exec$15(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$15, undefined, baseUrl$1(param[0], param[1], param[2]) + "/pictures");
}

var Config$15 = {
  response_encode: response_encode$15,
  response_decode: response_decode$15,
  picture_encode: picture_encode,
  picture_decode: picture_decode,
  exec: exec$15
};

var $$Request$15 = Toolkit__Request.Make({
      exec: exec$15
    });

var GetPictures = {
  Config: Config$15,
  $$Request: $$Request$15
};

function comment_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.CommentId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var message = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "message"), null));
    if (message.TAG === "Ok") {
      var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
      if (createdAt.TAG === "Ok") {
        var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
        if (updatedAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    message: message._0,
                    createdAt: createdAt._0,
                    updatedAt: updatedAt._0
                  }
                };
        }
        var e = updatedAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".updatedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = createdAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".createdAt" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = message._0;
    return {
            TAG: "Error",
            _0: {
              path: ".message" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function comment_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.CommentId.t_encode, v.id)
                  ],
                  [
                    "message",
                    false,
                    Spice.stringToJson(v.message)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ]
                ]));
}

function response_encode$16(v) {
  return Spice.arrayToJson(comment_encode, v);
}

function response_decode$16(v) {
  return Spice.arrayFromJson(comment_decode, v);
}

function exec$16(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$16, undefined, baseUrl$1(param[0], param[1], param[2]) + "/comments");
}

var Config$16 = {
  response_encode: response_encode$16,
  response_decode: response_decode$16,
  comment_encode: comment_encode,
  comment_decode: comment_decode,
  exec: exec$16
};

var $$Request$16 = Toolkit__Request.Make({
      exec: exec$16
    });

var GetComments = {
  Config: Config$16,
  $$Request: $$Request$16
};

function t_encode$12(v) {
  switch (v) {
    case "no_floor_or_lift_has_been_specified" :
        return "no_floor_or_lift_has_been_specified";
    case "not_authorized_to_update_address" :
        return "not_authorized_to_update_address";
    case "client_not_found" :
        return "client_not_found";
    case "store_not_found" :
        return "store_not_found";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "empty_response_from_packing" :
        return "empty_response_from_packing";
    case "package_too_big" :
        return "package_too_big";
    case "packet_too_big" :
        return "packet_too_big";
    case "distance_too_far" :
        return "distance_too_far";
    case "total_weight_too_heavy" :
        return "total_weight_too_heavy";
    case "too_heavy_single_packet" :
        return "too_heavy_single_packet";
    case "conditions_not_matched_for_delivery" :
        return "conditions_not_matched_for_delivery";
    case "invalid_postal_code" :
        return "invalid_postal_code";
    case "not_configured_to_order_deliveries" :
        return "not_configured_to_order_deliveries";
    case "slot_too_wide" :
        return "slot_too_wide";
    case "slot_too_narrow" :
        return "slot_too_narrow";
    case "slot_out_of_openings" :
        return "slot_out_of_openings";
    case "out_of_area" :
        return "out_of_area";
    case "area_not_eligible" :
        return "area_not_eligible";
    case "unhandledError" :
        return "unhandledError";
    
  }
}

function t_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("no_floor_or_lift_has_been_specified" === v) {
          return {
                  TAG: "Ok",
                  _0: "no_floor_or_lift_has_been_specified"
                };
        } else if ("not_authorized_to_update_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_authorized_to_update_address"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("empty_response_from_packing" === v) {
          return {
                  TAG: "Ok",
                  _0: "empty_response_from_packing"
                };
        } else if ("package_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_too_big"
                };
        } else if ("packet_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "packet_too_big"
                };
        } else if ("distance_too_far" === v) {
          return {
                  TAG: "Ok",
                  _0: "distance_too_far"
                };
        } else if ("total_weight_too_heavy" === v) {
          return {
                  TAG: "Ok",
                  _0: "total_weight_too_heavy"
                };
        } else if ("too_heavy_single_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_single_packet"
                };
        } else if ("conditions_not_matched_for_delivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "conditions_not_matched_for_delivery"
                };
        } else if ("invalid_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_postal_code"
                };
        } else if ("not_configured_to_order_deliveries" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_configured_to_order_deliveries"
                };
        } else if ("slot_too_wide" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_too_wide"
                };
        } else if ("slot_too_narrow" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_too_narrow"
                };
        } else if ("slot_out_of_openings" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_out_of_openings"
                };
        } else if ("out_of_area" === v) {
          return {
                  TAG: "Ok",
                  _0: "out_of_area"
                };
        } else if ("area_not_eligible" === v) {
          return {
                  TAG: "Ok",
                  _0: "area_not_eligible"
                };
        } else if ("unhandledError" === v) {
          return {
                  TAG: "Ok",
                  _0: "unhandledError"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$4 = {
  t_encode: t_encode$12,
  t_decode: t_decode$12
};

function t_encode$13(v) {
  if (v === "pickup") {
    return "pickup";
  } else {
    return "shipping";
  }
}

function t_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("pickup" === v) {
          return {
                  TAG: "Ok",
                  _0: "pickup"
                };
        } else if ("shipping" === v) {
          return {
                  TAG: "Ok",
                  _0: "shipping"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var AddressType = {
  t_encode: t_encode$13,
  t_decode: t_decode$13
};

function coordinates_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lat",
                    false,
                    Spice.floatToJson(v.lat)
                  ],
                  [
                    "lng",
                    false,
                    Spice.floatToJson(v.lng)
                  ]
                ]));
}

function coordinates_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lat = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lat"), null));
  if (lat.TAG === "Ok") {
    var lng = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lng"), null));
    if (lng.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                lat: lat._0,
                lng: lng._0
              }
            };
    }
    var e = lng._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lng" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = lat._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lat" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function address_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "comment",
                    false,
                    Spice.stringToJson(v.comment)
                  ],
                  [
                    "coords",
                    false,
                    coordinates_encode$1(v.coords)
                  ],
                  [
                    "hasLift",
                    false,
                    ColiswebApi__V5_Utils.Lift.t_encode(v.hasLift)
                  ],
                  [
                    "floor",
                    false,
                    Spice.stringToJson(v.floor)
                  ]
                ]));
}

function contact_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "firstName",
                    false,
                    Spice.stringToJson(v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.stringToJson(v.lastName)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.stringToJson(v.phone1)
                  ]
                ]));
}

function address_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var comment = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
          if (comment.TAG === "Ok") {
            var coords = coordinates_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "coords"), null));
            if (coords.TAG === "Ok") {
              var hasLift = ColiswebApi__V5_Utils.Lift.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "hasLift"), null));
              if (hasLift.TAG === "Ok") {
                var floor = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
                if (floor.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            line1: line1._0,
                            city: city._0,
                            postalCode: postalCode._0,
                            country: country._0,
                            comment: comment._0,
                            coords: coords._0,
                            hasLift: hasLift._0,
                            floor: floor._0
                          }
                        };
                }
                var e = floor._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".floor" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = hasLift._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".hasLift" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = coords._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".coords" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = comment._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".comment" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function contact_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var firstName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
  if (firstName.TAG === "Ok") {
    var lastName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
    if (lastName.TAG === "Ok") {
      var phone1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
      if (phone1.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  firstName: firstName._0,
                  lastName: lastName._0,
                  phone1: phone1._0
                }
              };
      }
      var e = phone1._0;
      return {
              TAG: "Error",
              _0: {
                path: ".phone1" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = lastName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lastName" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = firstName._0;
  return {
          TAG: "Error",
          _0: {
            path: ".firstName" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function body_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    t_encode$13(v.type_)
                  ],
                  [
                    "contact",
                    false,
                    contact_encode$1(v.contact)
                  ],
                  [
                    "address",
                    false,
                    address_encode$3(v.address)
                  ]
                ]));
}

function body_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$13(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var contact = contact_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "contact"), null));
    if (contact.TAG === "Ok") {
      var address = address_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
      if (address.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  type_: type_._0,
                  contact: contact._0,
                  address: address._0
                }
              };
      }
      var e = address._0;
      return {
              TAG: "Error",
              _0: {
                path: ".address" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = contact._0;
    return {
            TAG: "Error",
            _0: {
              path: ".contact" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$2.path),
            message: e$2.message,
            value: e$2.value
          }
        };
}

var response_encode$17 = Spice.unitToJson;

var response_decode$17 = Spice.unitFromJson;

function exec$17(param) {
  return Axios.WithResult.put(undefined, body_encode$8(param[3]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$17, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$12(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "custom",
                          VAL: "unhandledError"
                        };
                }
              }), baseUrl$1(param[0], param[1], param[2]) + "/updateAddressPoint");
}

var Config$17 = {
  body_encode: body_encode$8,
  body_decode: body_decode$8,
  address_encode: address_encode$3,
  address_decode: address_decode$3,
  contact_encode: contact_encode$1,
  contact_decode: contact_decode$1,
  coordinates_encode: coordinates_encode$1,
  coordinates_decode: coordinates_decode$1,
  response_encode: response_encode$17,
  response_decode: response_decode$17,
  exec: exec$17
};

var $$Request$17 = Toolkit__Request.Make({
      exec: exec$17
    });

var UpdateLocation = {
  $$Error: $$Error$4,
  AddressType: AddressType,
  Config: Config$17,
  $$Request: $$Request$17
};

function body_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "message",
                    false,
                    Spice.stringToJson(v.message)
                  ]]));
}

function body_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var message = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "message"), null));
  if (message.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              message: message._0
            }
          };
  }
  var e = message._0;
  return {
          TAG: "Error",
          _0: {
            path: ".message" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$18 = Spice.unitToJson;

var response_decode$18 = Spice.unitFromJson;

function exec$18(param) {
  return Axios.WithResult.post(undefined, body_encode$9(param[3]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$18, undefined, baseUrl$1(param[0], param[1], param[2]) + "/comments");
}

var Config$18 = {
  body_encode: body_encode$9,
  body_decode: body_decode$9,
  response_encode: response_encode$18,
  response_decode: response_decode$18,
  exec: exec$18
};

var $$Request$18 = Toolkit__Request.Make({
      exec: exec$18
    });

var CreateComment = {
  Config: Config$18,
  $$Request: $$Request$18
};

function contact_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "firstName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lastName)
                  ],
                  [
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone1)
                  ],
                  [
                    "phone2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone2)
                  ]
                ]));
}

function address_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.stringToJson(v.line2)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "hasLift",
                    false,
                    ColiswebApi__V5_Utils.Lift.t_encode(v.hasLift)
                  ],
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ],
                  [
                    "comment",
                    false,
                    Spice.stringToJson(v.comment)
                  ]
                ]));
}

function address_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var line2 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
    if (line2.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
        if (city.TAG === "Ok") {
          var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
          if (country.TAG === "Ok") {
            var hasLift = ColiswebApi__V5_Utils.Lift.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "hasLift"), null));
            if (hasLift.TAG === "Ok") {
              var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
              if (floor.TAG === "Ok") {
                var comment = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
                if (comment.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            line1: line1._0,
                            line2: line2._0,
                            postalCode: postalCode._0,
                            city: city._0,
                            country: country._0,
                            hasLift: hasLift._0,
                            floor: floor._0,
                            comment: comment._0
                          }
                        };
                }
                var e = comment._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".comment" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = floor._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".floor" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = hasLift._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".hasLift" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = country._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".country" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = city._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".city" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = line2._0;
    return {
            TAG: "Error",
            _0: {
              path: ".line2" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function contact_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var firstName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
  if (firstName.TAG === "Ok") {
    var lastName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
    if (lastName.TAG === "Ok") {
      var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var phone1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
        if (phone1.TAG === "Ok") {
          var phone2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone2"), null));
          if (phone2.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      firstName: firstName._0,
                      lastName: lastName._0,
                      email: email._0,
                      phone1: phone1._0,
                      phone2: phone2._0
                    }
                  };
          }
          var e = phone2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".phone2" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = phone1._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phone1" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = lastName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lastName" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = firstName._0;
  return {
          TAG: "Error",
          _0: {
            path: ".firstName" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function packaging_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "itemCount",
                    false,
                    Spice.intToJson(v.itemCount)
                  ],
                  [
                    "description",
                    false,
                    Spice.stringToJson(v.description)
                  ],
                  [
                    "maxPacketLengthCm",
                    false,
                    Spice.floatToJson(v.maxPacketLengthCm)
                  ]
                ]));
}

function point_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "contact",
                    false,
                    Spice.optionToJson(contact_encode$2, v.contact)
                  ],
                  [
                    "address",
                    false,
                    address_encode$4(v.address)
                  ]
                ]));
}

function packaging_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var itemCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "itemCount"), null));
  if (itemCount.TAG === "Ok") {
    var description = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
    if (description.TAG === "Ok") {
      var maxPacketLengthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "maxPacketLengthCm"), null));
      if (maxPacketLengthCm.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  itemCount: itemCount._0,
                  description: description._0,
                  maxPacketLengthCm: maxPacketLengthCm._0
                }
              };
      }
      var e = maxPacketLengthCm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".maxPacketLengthCm" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = description._0;
    return {
            TAG: "Error",
            _0: {
              path: ".description" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = itemCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".itemCount" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function point_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var contact = Spice.optionFromJson(contact_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "contact"), null));
  if (contact.TAG === "Ok") {
    var address = address_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
    if (address.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                contact: contact._0,
                address: address._0
              }
            };
    }
    var e = address._0;
    return {
            TAG: "Error",
            _0: {
              path: ".address" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = contact._0;
  return {
          TAG: "Error",
          _0: {
            path: ".contact" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "slotOptionId",
                    false,
                    Spice.stringToJson(v.slotOptionId)
                  ],
                  [
                    "deliveryRequestId",
                    false,
                    Spice.stringToJson(v.deliveryRequestId)
                  ],
                  [
                    "pickupPoint",
                    false,
                    Spice.optionToJson(point_encode, v.pickupPoint)
                  ],
                  [
                    "shippingPoint",
                    false,
                    Spice.optionToJson(point_encode, v.shippingPoint)
                  ],
                  [
                    "packaging",
                    false,
                    packaging_encode$2(v.packaging)
                  ],
                  [
                    "reference1",
                    false,
                    Spice.stringToJson(v.reference1)
                  ],
                  [
                    "salesConsultantName",
                    false,
                    Spice.stringToJson(v.salesConsultantName)
                  ]
                ]));
}

function body_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var slotOptionId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "slotOptionId"), null));
  if (slotOptionId.TAG === "Ok") {
    var deliveryRequestId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryRequestId"), null));
    if (deliveryRequestId.TAG === "Ok") {
      var pickupPoint = Spice.optionFromJson(point_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickupPoint"), null));
      if (pickupPoint.TAG === "Ok") {
        var shippingPoint = Spice.optionFromJson(point_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shippingPoint"), null));
        if (shippingPoint.TAG === "Ok") {
          var packaging = packaging_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "packaging"), null));
          if (packaging.TAG === "Ok") {
            var reference1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "reference1"), null));
            if (reference1.TAG === "Ok") {
              var salesConsultantName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "salesConsultantName"), null));
              if (salesConsultantName.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          slotOptionId: slotOptionId._0,
                          deliveryRequestId: deliveryRequestId._0,
                          pickupPoint: pickupPoint._0,
                          shippingPoint: shippingPoint._0,
                          packaging: packaging._0,
                          reference1: reference1._0,
                          salesConsultantName: salesConsultantName._0
                        }
                      };
              }
              var e = salesConsultantName._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".salesConsultantName" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = reference1._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".reference1" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = packaging._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".packaging" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = shippingPoint._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".shippingPoint" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = pickupPoint._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pickupPoint" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = deliveryRequestId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryRequestId" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = slotOptionId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".slotOptionId" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function response_encode$19(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveryId",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.deliveryId)
                  ]]));
}

function response_decode$19(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryId = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
  if (deliveryId.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveryId: deliveryId._0
            }
          };
  }
  var e = deliveryId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryId" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$19(param) {
  return Axios.WithResult.post(undefined, body_encode$10(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$19, (function (err) {
                var match = err.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: err
                        };
                }
                var match$1 = match.data.type;
                if (match$1 === "the_request_is_expired") {
                  return {
                          NAME: "custom",
                          VAL: "deliveryPriceExpired"
                        };
                } else {
                  return {
                          NAME: "default",
                          VAL: err
                        };
                }
              }), baseUrl(param[0], param[1]) + "/createDelivery");
}

var Config$19 = {
  body_encode: body_encode$10,
  body_decode: body_decode$10,
  packaging_encode: packaging_encode$2,
  packaging_decode: packaging_decode$2,
  point_encode: point_encode,
  point_decode: point_decode,
  contact_encode: contact_encode$2,
  contact_decode: contact_decode$2,
  address_encode: address_encode$4,
  address_decode: address_decode$4,
  response_encode: response_encode$19,
  response_decode: response_decode$19,
  exec: exec$19
};

var $$Request$19 = Toolkit__Request.Make({
      exec: exec$19
    });

var CreateDelivery = {
  Config: Config$19,
  $$Request: $$Request$19
};

var response_encode$20 = Spice.unitToJson;

var response_decode$20 = Spice.unitFromJson;

function exec$20(param) {
  return Axios.WithResult.put(undefined, {}, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$20, undefined, baseUrl$1(param[0], param[1], param[2]) + "/cancel");
}

var Config$20 = {
  response_encode: response_encode$20,
  response_decode: response_decode$20,
  exec: exec$20
};

var $$Request$20 = Toolkit__Request.Make({
      exec: exec$20
    });

var CancelDelivery = {
  Config: Config$20,
  $$Request: $$Request$20
};

function priceComposition_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "amount",
                    false,
                    Spice.floatToJson(v.amount)
                  ],
                  [
                    "label",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.label)
                  ]
                ]));
}

function priceComposition_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var amount = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "amount"), null));
  if (amount.TAG === "Ok") {
    var label = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                amount: amount._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = amount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".amount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$21(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "priceWithoutTaxEur",
                    false,
                    Spice.floatToJson(v.priceWithoutTaxEur)
                  ],
                  [
                    "priceWithTaxEur",
                    false,
                    Spice.floatToJson(v.priceWithTaxEur)
                  ],
                  [
                    "priceWithoutTaxEurComposition",
                    false,
                    Spice.arrayToJson(priceComposition_encode$1, v.priceWithoutTaxEurComposition)
                  ],
                  [
                    "customerPriceWithTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithTaxEur)
                  ],
                  [
                    "customerPriceWithoutTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithoutTaxEur)
                  ],
                  [
                    "customerPriceWithoutTaxEurComposition",
                    false,
                    Spice.optionToJson((function (param) {
                            return Spice.arrayToJson(priceComposition_encode$1, param);
                          }), v.customerPriceWithoutTaxEurComposition)
                  ]
                ]));
}

function response_decode$21(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var priceWithoutTaxEur = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEur"), null));
  if (priceWithoutTaxEur.TAG === "Ok") {
    var priceWithTaxEur = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "priceWithTaxEur"), null));
    if (priceWithTaxEur.TAG === "Ok") {
      var priceWithoutTaxEurComposition = Spice.arrayFromJson(priceComposition_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEurComposition"), null));
      if (priceWithoutTaxEurComposition.TAG === "Ok") {
        var customerPriceWithTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithTaxEur"), null));
        if (customerPriceWithTaxEur.TAG === "Ok") {
          var customerPriceWithoutTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEur"), null));
          if (customerPriceWithoutTaxEur.TAG === "Ok") {
            var customerPriceWithoutTaxEurComposition = Spice.optionFromJson((function (param) {
                    return Spice.arrayFromJson(priceComposition_decode$1, param);
                  }), Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEurComposition"), null));
            if (customerPriceWithoutTaxEurComposition.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        priceWithoutTaxEur: priceWithoutTaxEur._0,
                        priceWithTaxEur: priceWithTaxEur._0,
                        priceWithoutTaxEurComposition: priceWithoutTaxEurComposition._0,
                        customerPriceWithTaxEur: customerPriceWithTaxEur._0,
                        customerPriceWithoutTaxEur: customerPriceWithoutTaxEur._0,
                        customerPriceWithoutTaxEurComposition: customerPriceWithoutTaxEurComposition._0
                      }
                    };
            }
            var e = customerPriceWithoutTaxEurComposition._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".customerPriceWithoutTaxEurComposition" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = customerPriceWithoutTaxEur._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".customerPriceWithoutTaxEur" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = customerPriceWithTaxEur._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".customerPriceWithTaxEur" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = priceWithoutTaxEurComposition._0;
      return {
              TAG: "Error",
              _0: {
                path: ".priceWithoutTaxEurComposition" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = priceWithTaxEur._0;
    return {
            TAG: "Error",
            _0: {
              path: ".priceWithTaxEur" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = priceWithoutTaxEur._0;
  return {
          TAG: "Error",
          _0: {
            path: ".priceWithoutTaxEur" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function exec$21(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$21, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var match$1 = match.data.type;
                if (match$1 === "the_request_is_expired") {
                  return {
                          NAME: "custom",
                          VAL: "deliveryPriceExpired"
                        };
                } else {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
              }), baseUrl(param[0], param[1]) + ("/getSlotOptionPriceComposition?slotOptionId=" + (param[2] + ("&deliveryRequestId=" + param[3]))));
}

var Config$21 = {
  priceComposition_encode: priceComposition_encode$1,
  priceComposition_decode: priceComposition_decode$1,
  response_encode: response_encode$21,
  response_decode: response_decode$21,
  exec: exec$21
};

var $$Request$21 = Toolkit__Request.Make({
      exec: exec$21
    });

var GetSlotOptionPriceComposition = {
  Config: Config$21,
  $$Request: $$Request$21
};

function t_encode$14(v) {
  switch (v) {
    case "store_not_found" :
        return "store_not_found";
    case "client_not_found" :
        return "client_not_found";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "not_authorized_to_reschedule" :
        return "not_authorized_to_reschedule";
    case "impossible_to_reschedule" :
        return "impossible_to_reschedule";
    
  }
}

function t_decode$14(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("not_authorized_to_reschedule" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_authorized_to_reschedule"
                };
        } else if ("impossible_to_reschedule" === v) {
          return {
                  TAG: "Ok",
                  _0: "impossible_to_reschedule"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$5 = {
  t_encode: t_encode$14,
  t_decode: t_decode$14
};

function body_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "startDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.startDate)
                  ],
                  [
                    "endDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.endDate)
                  ]
                ]));
}

function body_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "startDate"), null));
  if (startDate.TAG === "Ok") {
    var endDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "endDate"), null));
    if (endDate.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                startDate: startDate._0,
                endDate: endDate._0
              }
            };
    }
    var e = endDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".endDate" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = startDate._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startDate" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$22(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "rescheduleDeliverySlotRequestId",
                    false,
                    Spice.stringToJson(v.rescheduleDeliverySlotRequestId)
                  ],
                  [
                    "calendar",
                    false,
                    Spice.arrayToJson(calendar_encode, v.calendar)
                  ]
                ]));
}

function response_decode$22(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var rescheduleDeliverySlotRequestId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "rescheduleDeliverySlotRequestId"), null));
  if (rescheduleDeliverySlotRequestId.TAG === "Ok") {
    var calendar = Spice.arrayFromJson(calendar_decode, Belt_Option.getWithDefault(Js_dict.get(v, "calendar"), null));
    if (calendar.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                rescheduleDeliverySlotRequestId: rescheduleDeliverySlotRequestId._0,
                calendar: calendar._0
              }
            };
    }
    var e = calendar._0;
    return {
            TAG: "Error",
            _0: {
              path: ".calendar" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = rescheduleDeliverySlotRequestId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".rescheduleDeliverySlotRequestId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$22(param) {
  return Axios.WithResult.post(undefined, body_encode$11(param[3]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$22, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$14(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            v._0
                          ]
                        };
                }
              }), baseUrl$1(param[0], param[1], param[2]) + "/reschedulingCalendar");
}

var Config$22 = {
  body_encode: body_encode$11,
  body_decode: body_decode$11,
  response_encode: response_encode$22,
  response_decode: response_decode$22,
  exec: exec$22
};

var $$Request$22 = Toolkit__Request.Make({
      exec: exec$22
    });

var GetReschedulingCalendar = {
  $$Error: $$Error$5,
  Config: Config$22,
  $$Request: $$Request$22
};

function t_encode$15(v) {
  switch (v) {
    case "store_not_found" :
        return "store_not_found";
    case "client_not_found" :
        return "client_not_found";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "reschedule_slot_not_found" :
        return "reschedule_slot_not_found";
    case "the_request_is_expired" :
        return "the_request_is_expired";
    case "rescheduling_not_authorized" :
        return "rescheduling_not_authorized";
    
  }
}

function t_decode$15(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("reschedule_slot_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "reschedule_slot_not_found"
                };
        } else if ("the_request_is_expired" === v) {
          return {
                  TAG: "Ok",
                  _0: "the_request_is_expired"
                };
        } else if ("rescheduling_not_authorized" === v) {
          return {
                  TAG: "Ok",
                  _0: "rescheduling_not_authorized"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$6 = {
  t_encode: t_encode$15,
  t_decode: t_decode$15
};

function body_encode$12(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "rescheduleDeliverySlotRequestId",
                    false,
                    Spice.stringToJson(v.rescheduleDeliverySlotRequestId)
                  ],
                  [
                    "slotOptionId",
                    false,
                    Spice.stringToJson(v.slotOptionId)
                  ],
                  [
                    "notifyPickup",
                    false,
                    Spice.boolToJson(v.notifyPickup)
                  ],
                  [
                    "notifyDropoff",
                    false,
                    Spice.boolToJson(v.notifyDropoff)
                  ]
                ]));
}

function body_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var rescheduleDeliverySlotRequestId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "rescheduleDeliverySlotRequestId"), null));
  if (rescheduleDeliverySlotRequestId.TAG === "Ok") {
    var slotOptionId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "slotOptionId"), null));
    if (slotOptionId.TAG === "Ok") {
      var notifyPickup = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "notifyPickup"), null));
      if (notifyPickup.TAG === "Ok") {
        var notifyDropoff = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "notifyDropoff"), null));
        if (notifyDropoff.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    rescheduleDeliverySlotRequestId: rescheduleDeliverySlotRequestId._0,
                    slotOptionId: slotOptionId._0,
                    notifyPickup: notifyPickup._0,
                    notifyDropoff: notifyDropoff._0
                  }
                };
        }
        var e = notifyDropoff._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".notifyDropoff" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = notifyPickup._0;
      return {
              TAG: "Error",
              _0: {
                path: ".notifyPickup" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = slotOptionId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".slotOptionId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = rescheduleDeliverySlotRequestId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".rescheduleDeliverySlotRequestId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$23(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveryId",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.deliveryId)
                  ]]));
}

function response_decode$23(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryId = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
  if (deliveryId.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveryId: deliveryId._0
            }
          };
  }
  var e = deliveryId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryId" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$23(param) {
  return Axios.WithResult.post(undefined, body_encode$12(param[3]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$23, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$15(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            v._0
                          ]
                        };
                }
              }), baseUrl$1(param[0], param[1], param[2]) + "/reschedule");
}

var Config$23 = {
  body_encode: body_encode$12,
  body_decode: body_decode$12,
  response_encode: response_encode$23,
  response_decode: response_decode$23,
  exec: exec$23
};

var $$Request$23 = Toolkit__Request.Make({
      exec: exec$23
    });

var RescheduleDelivery = {
  $$Error: $$Error$6,
  Config: Config$23,
  $$Request: $$Request$23
};

var Delivery = {
  storeUrl: storeUrl,
  baseUrl: baseUrl$1,
  ModificationHistory: ModificationHistory,
  GetDelivery: GetDelivery,
  GetEligibleTimeSlots: GetEligibleTimeSlots$1,
  UpdateTimeSlot: UpdateTimeSlot,
  GetChangesHistory: GetChangesHistory,
  GetPackets: GetPackets,
  GetPictures: GetPictures,
  GetComments: GetComments,
  UpdateLocation: UpdateLocation,
  CreateComment: CreateComment,
  CreateDelivery: CreateDelivery,
  CancelDelivery: CancelDelivery,
  GetSlotOptionPriceComposition: GetSlotOptionPriceComposition,
  GetReschedulingCalendar: GetReschedulingCalendar,
  RescheduleDelivery: RescheduleDelivery
};

function timeSlot_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end)
                  ]
                ]));
}

function timeSlot_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end: end_._0
              }
            };
    }
    var e = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function filters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "timeSlot",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(timeSlot_encode$2, v.timeSlot)
                  ],
                  [
                    "deliveryId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.DeliveryId.t_encode, v.deliveryId)
                  ],
                  [
                    "ref",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.ref)
                  ],
                  [
                    "pickup",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.pickup)
                  ],
                  [
                    "dropoff",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.dropoff)
                  ],
                  [
                    "salesConsultantName",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.salesConsultantName)
                  ],
                  [
                    "statusProvider",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(ColiswebApi__V5_Utils.StatusProvider.t_encode, param);
                          }), v.statusProvider)
                  ],
                  [
                    "statusOriginator",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(ColiswebApi__V5_Utils.StatusOriginator.t_encode, param);
                          }), v.statusOriginator)
                  ]
                ]));
}

function filters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var timeSlot = Toolkit__Decoders.$$Option.t_decode(timeSlot_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
  if (timeSlot.TAG === "Ok") {
    var deliveryId = Toolkit__Decoders.$$Option.t_decode(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
    if (deliveryId.TAG === "Ok") {
      var ref = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "ref"), null));
      if (ref.TAG === "Ok") {
        var pickup = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
        if (pickup.TAG === "Ok") {
          var dropoff = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "dropoff"), null));
          if (dropoff.TAG === "Ok") {
            var salesConsultantName = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "salesConsultantName"), null));
            if (salesConsultantName.TAG === "Ok") {
              var statusProvider = Toolkit__Decoders.$$Option.t_decode((function (param) {
                      return Spice.arrayFromJson(ColiswebApi__V5_Utils.StatusProvider.t_decode, param);
                    }), Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
              if (statusProvider.TAG === "Ok") {
                var statusOriginator = Toolkit__Decoders.$$Option.t_decode((function (param) {
                        return Spice.arrayFromJson(ColiswebApi__V5_Utils.StatusOriginator.t_decode, param);
                      }), Belt_Option.getWithDefault(Js_dict.get(v, "statusOriginator"), null));
                if (statusOriginator.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            timeSlot: timeSlot._0,
                            deliveryId: deliveryId._0,
                            ref: ref._0,
                            pickup: pickup._0,
                            dropoff: dropoff._0,
                            salesConsultantName: salesConsultantName._0,
                            statusProvider: statusProvider._0,
                            statusOriginator: statusOriginator._0
                          }
                        };
                }
                var e = statusOriginator._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".statusOriginator" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = statusProvider._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".statusProvider" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = salesConsultantName._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".salesConsultantName" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = dropoff._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".dropoff" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = pickup._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".pickup" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = ref._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ref" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = deliveryId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryId" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = timeSlot._0;
  return {
          TAG: "Error",
          _0: {
            path: ".timeSlot" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function sort_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "timeSlot",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.timeSlot)
                  ],
                  [
                    "pickup",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.pickup)
                  ],
                  [
                    "dropoff",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.dropoff)
                  ]
                ]));
}

function sort_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var timeSlot = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
  if (timeSlot.TAG === "Ok") {
    var pickup = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
    if (pickup.TAG === "Ok") {
      var dropoff = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "dropoff"), null));
      if (dropoff.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  timeSlot: timeSlot._0,
                  pickup: pickup._0,
                  dropoff: dropoff._0
                }
              };
      }
      var e = dropoff._0;
      return {
              TAG: "Error",
              _0: {
                path: ".dropoff" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = pickup._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pickup" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = timeSlot._0;
  return {
          TAG: "Error",
          _0: {
            path: ".timeSlot" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function body_encode$13(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode(v.sort)
                  ],
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function body_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
      if (page.TAG === "Ok") {
        var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
        if (pageSize.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    filters: filters._0,
                    sort: sort._0,
                    page: page._0,
                    pageSize: pageSize._0
                  }
                };
        }
        var e = pageSize._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".pageSize" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = page._0;
      return {
              TAG: "Error",
              _0: {
                path: ".page" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function packetBarcode_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              barcode: barcode._0
            }
          };
  }
  var e = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function packetBarcode_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ]]));
}

function packaging_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "weightKg",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.weightKg)
                  ],
                  [
                    "packetsCount",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.packetsCount)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "packetsBarcode",
                    false,
                    Spice.arrayToJson(packetBarcode_encode$1, v.packetsBarcode)
                  ]
                ]));
}

function packaging_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var weightKg = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "weightKg"), null));
  if (weightKg.TAG === "Ok") {
    var packetsCount = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "packetsCount"), null));
    if (packetsCount.TAG === "Ok") {
      var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        var packetsBarcode = Spice.arrayFromJson(packetBarcode_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "packetsBarcode"), null));
        if (packetsBarcode.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    weightKg: weightKg._0,
                    packetsCount: packetsCount._0,
                    description: description._0,
                    packetsBarcode: packetsBarcode._0
                  }
                };
        }
        var e = packetsBarcode._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".packetsBarcode" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = packetsCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".packetsCount" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = weightKg._0;
  return {
          TAG: "Error",
          _0: {
            path: ".weightKg" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function point_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.name)
                  ],
                  [
                    "firstName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lastName)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.city)
                  ],
                  [
                    "isDeleted",
                    false,
                    Spice.boolToJson(v.isDeleted)
                  ],
                  [
                    "country",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.country)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone1)
                  ],
                  [
                    "phone2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone2)
                  ],
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "hubSlugId",
                    false,
                    Spice.optionToJson(Identifiers.HubSlugId.t_encode, v.hubSlugId)
                  ]
                ]));
}

function point_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var firstName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
    if (firstName.TAG === "Ok") {
      var lastName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
      if (lastName.TAG === "Ok") {
        var postalCode = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
        if (postalCode.TAG === "Ok") {
          var city = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "city"), (function (param) {
                      return Spice.optionFromJson(Spice.stringFromJson, param);
                    })), {
                TAG: "Ok",
                _0: undefined
              });
          if (city.TAG === "Ok") {
            var isDeleted = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "isDeleted"), Spice.boolFromJson), {
                  TAG: "Ok",
                  _0: false
                });
            if (isDeleted.TAG === "Ok") {
              var country = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
              if (country.TAG === "Ok") {
                var phone1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
                if (phone1.TAG === "Ok") {
                  var phone2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone2"), null));
                  if (phone2.TAG === "Ok") {
                    var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
                    if (line1.TAG === "Ok") {
                      var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
                      if (line2.TAG === "Ok") {
                        var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
                        if (comment.TAG === "Ok") {
                          var hubSlugId = Spice.optionFromJson(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubSlugId"), null));
                          if (hubSlugId.TAG === "Ok") {
                            return {
                                    TAG: "Ok",
                                    _0: {
                                      name: name._0,
                                      firstName: firstName._0,
                                      lastName: lastName._0,
                                      postalCode: postalCode._0,
                                      city: city._0,
                                      isDeleted: isDeleted._0,
                                      country: country._0,
                                      phone1: phone1._0,
                                      phone2: phone2._0,
                                      line1: line1._0,
                                      line2: line2._0,
                                      comment: comment._0,
                                      hubSlugId: hubSlugId._0
                                    }
                                  };
                          }
                          var e = hubSlugId._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".hubSlugId" + e.path,
                                    message: e.message,
                                    value: e.value
                                  }
                                };
                        }
                        var e$1 = comment._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".comment" + e$1.path,
                                  message: e$1.message,
                                  value: e$1.value
                                }
                              };
                      }
                      var e$2 = line2._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".line2" + e$2.path,
                                message: e$2.message,
                                value: e$2.value
                              }
                            };
                    }
                    var e$3 = line1._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".line1" + e$3.path,
                              message: e$3.message,
                              value: e$3.value
                            }
                          };
                  }
                  var e$4 = phone2._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".phone2" + e$4.path,
                            message: e$4.message,
                            value: e$4.value
                          }
                        };
                }
                var e$5 = phone1._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".phone1" + e$5.path,
                          message: e$5.message,
                          value: e$5.value
                        }
                      };
              }
              var e$6 = country._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".country" + e$6.path,
                        message: e$6.message,
                        value: e$6.value
                      }
                    };
            }
            var e$7 = isDeleted._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".isDeleted" + e$7.path,
                      message: e$7.message,
                      value: e$7.value
                    }
                  };
          }
          var e$8 = city._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".city" + e$8.path,
                    message: e$8.message,
                    value: e$8.value
                  }
                };
        }
        var e$9 = postalCode._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".postalCode" + e$9.path,
                  message: e$9.message,
                  value: e$9.value
                }
              };
      }
      var e$10 = lastName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lastName" + e$10.path,
                message: e$10.message,
                value: e$10.value
              }
            };
    }
    var e$11 = firstName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".firstName" + e$11.path,
              message: e$11.message,
              value: e$11.value
            }
          };
  }
  var e$12 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$12.path,
            message: e$12.message,
            value: e$12.value
          }
        };
}

function owner_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "clientId",
                    false,
                    Spice.optionToJson(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function owner_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
  if (storeId.TAG === "Ok") {
    var clientId = Spice.optionFromJson(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                storeId: storeId._0,
                clientId: clientId._0
              }
            };
    }
    var e = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = storeId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".storeId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function signature_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function signature_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
  if (url.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                url: url._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = url._0;
  return {
          TAG: "Error",
          _0: {
            path: ".url" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function signatureTransition_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "mobileStateUpdatedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.mobileStateUpdatedAt)
                  ],
                  [
                    "problem",
                    false,
                    Spice.optionToJson(ColiswebApi__Utils.TransitionProblem.t_encode, v.problem)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "isSuccessful",
                    false,
                    Spice.boolToJson(v.isSuccessful)
                  ]
                ]));
}

function signatureTransition_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
  if (createdAt.TAG === "Ok") {
    var mobileStateUpdatedAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "mobileStateUpdatedAt"), null));
    if (mobileStateUpdatedAt.TAG === "Ok") {
      var problem = Spice.optionFromJson(ColiswebApi__Utils.TransitionProblem.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "problem"), null));
      if (problem.TAG === "Ok") {
        var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
        if (comment.TAG === "Ok") {
          var isSuccessful = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isSuccessful"), null));
          if (isSuccessful.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      createdAt: createdAt._0,
                      mobileStateUpdatedAt: mobileStateUpdatedAt._0,
                      problem: problem._0,
                      comment: comment._0,
                      isSuccessful: isSuccessful._0
                    }
                  };
          }
          var e = isSuccessful._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".isSuccessful" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = comment._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".comment" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = problem._0;
      return {
              TAG: "Error",
              _0: {
                path: ".problem" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = mobileStateUpdatedAt._0;
    return {
            TAG: "Error",
            _0: {
              path: ".mobileStateUpdatedAt" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = createdAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".createdAt" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function priceComposition_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var amount = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "amount"), null));
  if (amount.TAG === "Ok") {
    var label = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                amount: amount._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = amount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".amount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function priceComposition_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "amount",
                    false,
                    Spice.floatToJson(v.amount)
                  ],
                  [
                    "label",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.label)
                  ]
                ]));
}

function delivery_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ],
                  [
                    "isArchived",
                    false,
                    Spice.boolToJson(v.isArchived)
                  ],
                  [
                    "ref1",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref1)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref2)
                  ],
                  [
                    "ref3",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref3)
                  ],
                  [
                    "ref4",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref4)
                  ],
                  [
                    "originatorName",
                    false,
                    Spice.stringToJson(v.originatorName)
                  ],
                  [
                    "originatorId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.originatorId)
                  ],
                  [
                    "pickup",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Shared.Delivery.Point.t_encode, v.pickup)
                  ],
                  [
                    "shipping",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Shared.Delivery.Point.t_encode, v.shipping)
                  ],
                  [
                    "return",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Shared.Delivery.Point.t_encode, v.return)
                  ],
                  [
                    "haveStoreComments",
                    false,
                    Spice.boolToJson(v.haveStoreComments)
                  ],
                  [
                    "timeSlot",
                    false,
                    timeSlot_encode$2(v.timeSlot)
                  ],
                  [
                    "previousTimeSlot",
                    false,
                    Spice.optionToJson(timeSlot_encode$2, v.previousTimeSlot)
                  ],
                  [
                    "salesConsultantName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.salesConsultantName)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "statusOriginator",
                    false,
                    ColiswebApi__V5_Utils.StatusOriginator.t_encode(v.statusOriginator)
                  ],
                  [
                    "statusProvider",
                    false,
                    ColiswebApi__V5_Utils.StatusProvider.t_encode(v.statusProvider)
                  ],
                  [
                    "priceWithoutTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.priceWithoutTaxEur)
                  ],
                  [
                    "priceWithTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.priceWithTaxEur)
                  ],
                  [
                    "priceWithoutTaxEurComposition",
                    false,
                    Spice.arrayToJson(priceComposition_encode$2, v.priceWithoutTaxEurComposition)
                  ],
                  [
                    "customerPriceWithoutTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithoutTaxEur)
                  ],
                  [
                    "customerPriceWithTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithTaxEur)
                  ],
                  [
                    "customerPriceWithoutTaxEurComposition",
                    false,
                    Spice.optionToJson((function (param) {
                            return Spice.arrayToJson(priceComposition_encode$2, param);
                          }), v.customerPriceWithoutTaxEurComposition)
                  ],
                  [
                    "hasBeenRescheduled",
                    false,
                    Spice.boolToJson(v.hasBeenRescheduled)
                  ],
                  [
                    "fromRescheduledDeliveryId",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryId.t_encode, v.fromRescheduledDeliveryId)
                  ],
                  [
                    "heftingOptions",
                    false,
                    Curry._1(ColiswebApi__V5_Shared.Delivery.HeftingOptions.t_encode, v.heftingOptions)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.skills)
                  ],
                  [
                    "owner",
                    false,
                    Curry._1(ColiswebApi__V5_Shared.Delivery.Owner.t_encode, v.owner)
                  ],
                  [
                    "pickupSignature",
                    false,
                    Spice.optionToJson(signature_encode$1, v.pickupSignature)
                  ],
                  [
                    "shippingSignature",
                    false,
                    Spice.optionToJson(signature_encode$1, v.shippingSignature)
                  ],
                  [
                    "returnSignature",
                    false,
                    Spice.optionToJson(signature_encode$1, v.returnSignature)
                  ],
                  [
                    "lastPickupTransition",
                    false,
                    Spice.optionToJson(signatureTransition_encode$1, v.lastPickupTransition)
                  ],
                  [
                    "lastShippingTransition",
                    false,
                    Spice.optionToJson(signatureTransition_encode$1, v.lastShippingTransition)
                  ],
                  [
                    "lastReturnTransition",
                    false,
                    Spice.optionToJson(signatureTransition_encode$1, v.lastReturnTransition)
                  ],
                  [
                    "packaging",
                    false,
                    Spice.optionToJson(packaging_encode$3, v.packaging)
                  ],
                  [
                    "externalDeliveryCode",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.externalDeliveryCode)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function delivery_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var isArchived = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isArchived"), null));
    if (isArchived.TAG === "Ok") {
      var ref1 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
      if (ref1.TAG === "Ok") {
        var ref2 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
        if (ref2.TAG === "Ok") {
          var ref3 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref3"), null));
          if (ref3.TAG === "Ok") {
            var ref4 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref4"), null));
            if (ref4.TAG === "Ok") {
              var originatorName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "originatorName"), null));
              if (originatorName.TAG === "Ok") {
                var originatorId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "originatorId"), null));
                if (originatorId.TAG === "Ok") {
                  var pickup = Spice.optionFromJson(ColiswebApi__V5_Shared.Delivery.Point.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
                  if (pickup.TAG === "Ok") {
                    var shipping = Spice.optionFromJson(ColiswebApi__V5_Shared.Delivery.Point.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shipping"), null));
                    if (shipping.TAG === "Ok") {
                      var $$return = Spice.optionFromJson(ColiswebApi__V5_Shared.Delivery.Point.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "return"), null));
                      if ($$return.TAG === "Ok") {
                        var haveStoreComments = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "haveStoreComments"), null));
                        if (haveStoreComments.TAG === "Ok") {
                          var timeSlot = timeSlot_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
                          if (timeSlot.TAG === "Ok") {
                            var previousTimeSlot = Spice.optionFromJson(timeSlot_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "previousTimeSlot"), null));
                            if (previousTimeSlot.TAG === "Ok") {
                              var salesConsultantName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "salesConsultantName"), null));
                              if (salesConsultantName.TAG === "Ok") {
                                var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
                                if (createdAt.TAG === "Ok") {
                                  var statusOriginator = ColiswebApi__V5_Utils.StatusOriginator.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusOriginator"), null));
                                  if (statusOriginator.TAG === "Ok") {
                                    var statusProvider = ColiswebApi__V5_Utils.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
                                    if (statusProvider.TAG === "Ok") {
                                      var priceWithoutTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEur"), null));
                                      if (priceWithoutTaxEur.TAG === "Ok") {
                                        var priceWithTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithTaxEur"), null));
                                        if (priceWithTaxEur.TAG === "Ok") {
                                          var priceWithoutTaxEurComposition = Spice.arrayFromJson(priceComposition_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEurComposition"), null));
                                          if (priceWithoutTaxEurComposition.TAG === "Ok") {
                                            var customerPriceWithoutTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEur"), null));
                                            if (customerPriceWithoutTaxEur.TAG === "Ok") {
                                              var customerPriceWithTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithTaxEur"), null));
                                              if (customerPriceWithTaxEur.TAG === "Ok") {
                                                var customerPriceWithoutTaxEurComposition = Spice.optionFromJson((function (param) {
                                                        return Spice.arrayFromJson(priceComposition_decode$2, param);
                                                      }), Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEurComposition"), null));
                                                if (customerPriceWithoutTaxEurComposition.TAG === "Ok") {
                                                  var hasBeenRescheduled = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "hasBeenRescheduled"), null));
                                                  if (hasBeenRescheduled.TAG === "Ok") {
                                                    var fromRescheduledDeliveryId = Spice.optionFromJson(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "fromRescheduledDeliveryId"), null));
                                                    if (fromRescheduledDeliveryId.TAG === "Ok") {
                                                      var heftingOptions = Curry._1(ColiswebApi__V5_Shared.Delivery.HeftingOptions.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "heftingOptions"), null));
                                                      if (heftingOptions.TAG === "Ok") {
                                                        var skills = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                                                        if (skills.TAG === "Ok") {
                                                          var owner = Curry._1(ColiswebApi__V5_Shared.Delivery.Owner.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "owner"), null));
                                                          if (owner.TAG === "Ok") {
                                                            var pickupSignature = Spice.optionFromJson(signature_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "pickupSignature"), null));
                                                            if (pickupSignature.TAG === "Ok") {
                                                              var shippingSignature = Spice.optionFromJson(signature_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "shippingSignature"), null));
                                                              if (shippingSignature.TAG === "Ok") {
                                                                var returnSignature = Spice.optionFromJson(signature_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "returnSignature"), null));
                                                                if (returnSignature.TAG === "Ok") {
                                                                  var lastPickupTransition = Spice.optionFromJson(signatureTransition_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "lastPickupTransition"), null));
                                                                  if (lastPickupTransition.TAG === "Ok") {
                                                                    var lastShippingTransition = Spice.optionFromJson(signatureTransition_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "lastShippingTransition"), null));
                                                                    if (lastShippingTransition.TAG === "Ok") {
                                                                      var lastReturnTransition = Spice.optionFromJson(signatureTransition_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "lastReturnTransition"), null));
                                                                      if (lastReturnTransition.TAG === "Ok") {
                                                                        var packaging = Spice.optionFromJson(packaging_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "packaging"), null));
                                                                        if (packaging.TAG === "Ok") {
                                                                          var externalDeliveryCode = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "externalDeliveryCode"), null));
                                                                          if (externalDeliveryCode.TAG === "Ok") {
                                                                            var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
                                                                            if (clientId.TAG === "Ok") {
                                                                              return {
                                                                                      TAG: "Ok",
                                                                                      _0: {
                                                                                        id: id._0,
                                                                                        isArchived: isArchived._0,
                                                                                        ref1: ref1._0,
                                                                                        ref2: ref2._0,
                                                                                        ref3: ref3._0,
                                                                                        ref4: ref4._0,
                                                                                        originatorName: originatorName._0,
                                                                                        originatorId: originatorId._0,
                                                                                        pickup: pickup._0,
                                                                                        shipping: shipping._0,
                                                                                        return: $$return._0,
                                                                                        haveStoreComments: haveStoreComments._0,
                                                                                        timeSlot: timeSlot._0,
                                                                                        previousTimeSlot: previousTimeSlot._0,
                                                                                        salesConsultantName: salesConsultantName._0,
                                                                                        createdAt: createdAt._0,
                                                                                        statusOriginator: statusOriginator._0,
                                                                                        statusProvider: statusProvider._0,
                                                                                        priceWithoutTaxEur: priceWithoutTaxEur._0,
                                                                                        priceWithTaxEur: priceWithTaxEur._0,
                                                                                        priceWithoutTaxEurComposition: priceWithoutTaxEurComposition._0,
                                                                                        customerPriceWithoutTaxEur: customerPriceWithoutTaxEur._0,
                                                                                        customerPriceWithTaxEur: customerPriceWithTaxEur._0,
                                                                                        customerPriceWithoutTaxEurComposition: customerPriceWithoutTaxEurComposition._0,
                                                                                        hasBeenRescheduled: hasBeenRescheduled._0,
                                                                                        fromRescheduledDeliveryId: fromRescheduledDeliveryId._0,
                                                                                        heftingOptions: heftingOptions._0,
                                                                                        skills: skills._0,
                                                                                        owner: owner._0,
                                                                                        pickupSignature: pickupSignature._0,
                                                                                        shippingSignature: shippingSignature._0,
                                                                                        returnSignature: returnSignature._0,
                                                                                        lastPickupTransition: lastPickupTransition._0,
                                                                                        lastShippingTransition: lastShippingTransition._0,
                                                                                        lastReturnTransition: lastReturnTransition._0,
                                                                                        packaging: packaging._0,
                                                                                        externalDeliveryCode: externalDeliveryCode._0,
                                                                                        clientId: clientId._0
                                                                                      }
                                                                                    };
                                                                            }
                                                                            var e = clientId._0;
                                                                            return {
                                                                                    TAG: "Error",
                                                                                    _0: {
                                                                                      path: ".clientId" + e.path,
                                                                                      message: e.message,
                                                                                      value: e.value
                                                                                    }
                                                                                  };
                                                                          }
                                                                          var e$1 = externalDeliveryCode._0;
                                                                          return {
                                                                                  TAG: "Error",
                                                                                  _0: {
                                                                                    path: ".externalDeliveryCode" + e$1.path,
                                                                                    message: e$1.message,
                                                                                    value: e$1.value
                                                                                  }
                                                                                };
                                                                        }
                                                                        var e$2 = packaging._0;
                                                                        return {
                                                                                TAG: "Error",
                                                                                _0: {
                                                                                  path: ".packaging" + e$2.path,
                                                                                  message: e$2.message,
                                                                                  value: e$2.value
                                                                                }
                                                                              };
                                                                      }
                                                                      var e$3 = lastReturnTransition._0;
                                                                      return {
                                                                              TAG: "Error",
                                                                              _0: {
                                                                                path: ".lastReturnTransition" + e$3.path,
                                                                                message: e$3.message,
                                                                                value: e$3.value
                                                                              }
                                                                            };
                                                                    }
                                                                    var e$4 = lastShippingTransition._0;
                                                                    return {
                                                                            TAG: "Error",
                                                                            _0: {
                                                                              path: ".lastShippingTransition" + e$4.path,
                                                                              message: e$4.message,
                                                                              value: e$4.value
                                                                            }
                                                                          };
                                                                  }
                                                                  var e$5 = lastPickupTransition._0;
                                                                  return {
                                                                          TAG: "Error",
                                                                          _0: {
                                                                            path: ".lastPickupTransition" + e$5.path,
                                                                            message: e$5.message,
                                                                            value: e$5.value
                                                                          }
                                                                        };
                                                                }
                                                                var e$6 = returnSignature._0;
                                                                return {
                                                                        TAG: "Error",
                                                                        _0: {
                                                                          path: ".returnSignature" + e$6.path,
                                                                          message: e$6.message,
                                                                          value: e$6.value
                                                                        }
                                                                      };
                                                              }
                                                              var e$7 = shippingSignature._0;
                                                              return {
                                                                      TAG: "Error",
                                                                      _0: {
                                                                        path: ".shippingSignature" + e$7.path,
                                                                        message: e$7.message,
                                                                        value: e$7.value
                                                                      }
                                                                    };
                                                            }
                                                            var e$8 = pickupSignature._0;
                                                            return {
                                                                    TAG: "Error",
                                                                    _0: {
                                                                      path: ".pickupSignature" + e$8.path,
                                                                      message: e$8.message,
                                                                      value: e$8.value
                                                                    }
                                                                  };
                                                          }
                                                          var e$9 = owner._0;
                                                          return {
                                                                  TAG: "Error",
                                                                  _0: {
                                                                    path: ".owner" + e$9.path,
                                                                    message: e$9.message,
                                                                    value: e$9.value
                                                                  }
                                                                };
                                                        }
                                                        var e$10 = skills._0;
                                                        return {
                                                                TAG: "Error",
                                                                _0: {
                                                                  path: ".skills" + e$10.path,
                                                                  message: e$10.message,
                                                                  value: e$10.value
                                                                }
                                                              };
                                                      }
                                                      var e$11 = heftingOptions._0;
                                                      return {
                                                              TAG: "Error",
                                                              _0: {
                                                                path: ".heftingOptions" + e$11.path,
                                                                message: e$11.message,
                                                                value: e$11.value
                                                              }
                                                            };
                                                    }
                                                    var e$12 = fromRescheduledDeliveryId._0;
                                                    return {
                                                            TAG: "Error",
                                                            _0: {
                                                              path: ".fromRescheduledDeliveryId" + e$12.path,
                                                              message: e$12.message,
                                                              value: e$12.value
                                                            }
                                                          };
                                                  }
                                                  var e$13 = hasBeenRescheduled._0;
                                                  return {
                                                          TAG: "Error",
                                                          _0: {
                                                            path: ".hasBeenRescheduled" + e$13.path,
                                                            message: e$13.message,
                                                            value: e$13.value
                                                          }
                                                        };
                                                }
                                                var e$14 = customerPriceWithoutTaxEurComposition._0;
                                                return {
                                                        TAG: "Error",
                                                        _0: {
                                                          path: ".customerPriceWithoutTaxEurComposition" + e$14.path,
                                                          message: e$14.message,
                                                          value: e$14.value
                                                        }
                                                      };
                                              }
                                              var e$15 = customerPriceWithTaxEur._0;
                                              return {
                                                      TAG: "Error",
                                                      _0: {
                                                        path: ".customerPriceWithTaxEur" + e$15.path,
                                                        message: e$15.message,
                                                        value: e$15.value
                                                      }
                                                    };
                                            }
                                            var e$16 = customerPriceWithoutTaxEur._0;
                                            return {
                                                    TAG: "Error",
                                                    _0: {
                                                      path: ".customerPriceWithoutTaxEur" + e$16.path,
                                                      message: e$16.message,
                                                      value: e$16.value
                                                    }
                                                  };
                                          }
                                          var e$17 = priceWithoutTaxEurComposition._0;
                                          return {
                                                  TAG: "Error",
                                                  _0: {
                                                    path: ".priceWithoutTaxEurComposition" + e$17.path,
                                                    message: e$17.message,
                                                    value: e$17.value
                                                  }
                                                };
                                        }
                                        var e$18 = priceWithTaxEur._0;
                                        return {
                                                TAG: "Error",
                                                _0: {
                                                  path: ".priceWithTaxEur" + e$18.path,
                                                  message: e$18.message,
                                                  value: e$18.value
                                                }
                                              };
                                      }
                                      var e$19 = priceWithoutTaxEur._0;
                                      return {
                                              TAG: "Error",
                                              _0: {
                                                path: ".priceWithoutTaxEur" + e$19.path,
                                                message: e$19.message,
                                                value: e$19.value
                                              }
                                            };
                                    }
                                    var e$20 = statusProvider._0;
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              path: ".statusProvider" + e$20.path,
                                              message: e$20.message,
                                              value: e$20.value
                                            }
                                          };
                                  }
                                  var e$21 = statusOriginator._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".statusOriginator" + e$21.path,
                                            message: e$21.message,
                                            value: e$21.value
                                          }
                                        };
                                }
                                var e$22 = createdAt._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".createdAt" + e$22.path,
                                          message: e$22.message,
                                          value: e$22.value
                                        }
                                      };
                              }
                              var e$23 = salesConsultantName._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".salesConsultantName" + e$23.path,
                                        message: e$23.message,
                                        value: e$23.value
                                      }
                                    };
                            }
                            var e$24 = previousTimeSlot._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".previousTimeSlot" + e$24.path,
                                      message: e$24.message,
                                      value: e$24.value
                                    }
                                  };
                          }
                          var e$25 = timeSlot._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".timeSlot" + e$25.path,
                                    message: e$25.message,
                                    value: e$25.value
                                  }
                                };
                        }
                        var e$26 = haveStoreComments._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".haveStoreComments" + e$26.path,
                                  message: e$26.message,
                                  value: e$26.value
                                }
                              };
                      }
                      var e$27 = $$return._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".return" + e$27.path,
                                message: e$27.message,
                                value: e$27.value
                              }
                            };
                    }
                    var e$28 = shipping._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".shipping" + e$28.path,
                              message: e$28.message,
                              value: e$28.value
                            }
                          };
                  }
                  var e$29 = pickup._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".pickup" + e$29.path,
                            message: e$29.message,
                            value: e$29.value
                          }
                        };
                }
                var e$30 = originatorId._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".originatorId" + e$30.path,
                          message: e$30.message,
                          value: e$30.value
                        }
                      };
              }
              var e$31 = originatorName._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".originatorName" + e$31.path,
                        message: e$31.message,
                        value: e$31.value
                      }
                    };
            }
            var e$32 = ref4._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".ref4" + e$32.path,
                      message: e$32.message,
                      value: e$32.value
                    }
                  };
          }
          var e$33 = ref3._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".ref3" + e$33.path,
                    message: e$33.message,
                    value: e$33.value
                  }
                };
        }
        var e$34 = ref2._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".ref2" + e$34.path,
                  message: e$34.message,
                  value: e$34.value
                }
              };
      }
      var e$35 = ref1._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ref1" + e$35.path,
                message: e$35.message,
                value: e$35.value
              }
            };
    }
    var e$36 = isArchived._0;
    return {
            TAG: "Error",
            _0: {
              path: ".isArchived" + e$36.path,
              message: e$36.message,
              value: e$36.value
            }
          };
  }
  var e$37 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$37.path,
            message: e$37.message,
            value: e$37.value
          }
        };
}

function heftingOptions_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "isInRoom",
                    false,
                    Spice.boolToJson(v.isInRoom)
                  ],
                  [
                    "isSidewalk",
                    false,
                    Spice.boolToJson(v.isSidewalk)
                  ],
                  [
                    "isTrailed",
                    false,
                    Spice.boolToJson(v.isTrailed)
                  ]
                ]));
}

function heftingOptions_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var isInRoom = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isInRoom"), null));
  if (isInRoom.TAG === "Ok") {
    var isSidewalk = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isSidewalk"), null));
    if (isSidewalk.TAG === "Ok") {
      var isTrailed = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isTrailed"), null));
      if (isTrailed.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  isInRoom: isInRoom._0,
                  isSidewalk: isSidewalk._0,
                  isTrailed: isTrailed._0
                }
              };
      }
      var e = isTrailed._0;
      return {
              TAG: "Error",
              _0: {
                path: ".isTrailed" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = isSidewalk._0;
    return {
            TAG: "Error",
            _0: {
              path: ".isSidewalk" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = isInRoom._0;
  return {
          TAG: "Error",
          _0: {
            path: ".isInRoom" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$24(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "deliveries",
                    false,
                    Spice.arrayToJson(delivery_encode, v.deliveries)
                  ],
                  [
                    "deliveriesCount",
                    false,
                    Spice.intToJson(v.deliveriesCount)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ]
                ]));
}

function response_decode$24(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveries = Spice.arrayFromJson(delivery_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveries"), null));
  if (deliveries.TAG === "Ok") {
    var deliveriesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveriesCount"), null));
    if (deliveriesCount.TAG === "Ok") {
      var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
      if (pagesCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  deliveries: deliveries._0,
                  deliveriesCount: deliveriesCount._0,
                  pagesCount: pagesCount._0
                }
              };
      }
      var e = pagesCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagesCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = deliveriesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveriesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = deliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveries" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$24(param) {
  return Axios.WithResult.post(undefined, body_encode$13(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$24, undefined, baseUrl(param[0], param[1]) + "/deliveries");
}

var Config$24 = {
  timeSlot_encode: timeSlot_encode$2,
  timeSlot_decode: timeSlot_decode$2,
  filters_encode: filters_encode,
  filters_decode: filters_decode,
  sort_encode: sort_encode,
  sort_decode: sort_decode,
  body_encode: body_encode$13,
  body_decode: body_decode$13,
  packaging_encode: packaging_encode$3,
  packaging_decode: packaging_decode$3,
  packetBarcode_encode: packetBarcode_encode$1,
  packetBarcode_decode: packetBarcode_decode$1,
  point_encode: point_encode$1,
  point_decode: point_decode$1,
  owner_encode: owner_encode,
  owner_decode: owner_decode,
  signature_encode: signature_encode$1,
  signature_decode: signature_decode$1,
  signatureTransition_encode: signatureTransition_encode$1,
  signatureTransition_decode: signatureTransition_decode$1,
  delivery_encode: delivery_encode,
  delivery_decode: delivery_decode,
  priceComposition_encode: priceComposition_encode$2,
  priceComposition_decode: priceComposition_decode$2,
  heftingOptions_encode: heftingOptions_encode$1,
  heftingOptions_decode: heftingOptions_decode$1,
  response_encode: response_encode$24,
  response_decode: response_decode$24,
  exec: exec$24
};

var $$Request$24 = Toolkit__Request.Make({
      exec: exec$24
    });

var GetDeliveries = {
  Config: Config$24,
  $$Request: $$Request$24
};

var response_encode$25 = Spice.unitToJson;

var response_decode$25 = Spice.unitFromJson;

function exec$25(param) {
  return Axios.WithResult.post(undefined, Object.assign(body_encode$13(param[2]), {
                  email: param[3]
                }), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$25, undefined, baseUrl(param[0], param[1]) + "/deliveries/export");
}

var Config$25 = {
  response_encode: response_encode$25,
  response_decode: response_decode$25,
  exec: exec$25
};

var $$Request$25 = Toolkit__Request.Make({
      exec: exec$25
    });

var PostExcelExport = {
  Config: Config$25,
  $$Request: $$Request$25
};

var Coordinates;

export {
  Coordinates ,
  config ,
  baseUrl ,
  Contact ,
  GetStore ,
  UpdateStore ,
  UpdateStoreHolidays ,
  DeliveryEligibility ,
  GetStates ,
  Delivery ,
  GetDeliveries ,
  PostExcelExport ,
}
/* Request Not a pure module */
