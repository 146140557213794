

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";

function t_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end)
                  ]
                ]));
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end: end_._0
              }
            };
    }
    var e = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function isEqual(a, b) {
  if (a.start.toString() === b.start.toString()) {
    return a.end.toString() === b.end.toString();
  } else {
    return false;
  }
}

var TimeSlot = {
  t_encode: t_encode,
  t_decode: t_decode,
  isEqual: isEqual
};

function t_encode$1(v) {
  switch (v) {
    case "api" :
        return "api";
    case "extranet" :
        return "extranet";
    case "woop" :
        return "woop";
    
  }
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("api" === v) {
          return {
                  TAG: "Ok",
                  _0: "api"
                };
        } else if ("extranet" === v) {
          return {
                  TAG: "Ok",
                  _0: "extranet"
                };
        } else if ("woop" === v) {
          return {
                  TAG: "Ok",
                  _0: "woop"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Channel = {
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function t_encode$2(v) {
  switch (v) {
    case "preOrdered" :
        return "preOrdered";
    case "confirmed" :
        return "confirmed";
    case "pickedUp" :
        return "pickedUp";
    case "delivered" :
        return "delivered";
    case "pickupFailed" :
        return "pickupFailed";
    case "deliveryFailed" :
        return "deliveryFailed";
    case "deliveryReturned" :
        return "deliveryReturned";
    case "deliveryCanceled" :
        return "deliveryCanceled";
    case "deliveryReturnFailed" :
        return "deliveryReturnFailed";
    case "nonDeliverable" :
        return "nonDeliverable";
    
  }
}

function t_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("preOrdered" === v) {
          return {
                  TAG: "Ok",
                  _0: "preOrdered"
                };
        } else if ("confirmed" === v) {
          return {
                  TAG: "Ok",
                  _0: "confirmed"
                };
        } else if ("pickedUp" === v) {
          return {
                  TAG: "Ok",
                  _0: "pickedUp"
                };
        } else if ("delivered" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivered"
                };
        } else if ("pickupFailed" === v) {
          return {
                  TAG: "Ok",
                  _0: "pickupFailed"
                };
        } else if ("deliveryFailed" === v) {
          return {
                  TAG: "Ok",
                  _0: "deliveryFailed"
                };
        } else if ("deliveryReturned" === v) {
          return {
                  TAG: "Ok",
                  _0: "deliveryReturned"
                };
        } else if ("deliveryCanceled" === v) {
          return {
                  TAG: "Ok",
                  _0: "deliveryCanceled"
                };
        } else if ("deliveryReturnFailed" === v) {
          return {
                  TAG: "Ok",
                  _0: "deliveryReturnFailed"
                };
        } else if ("nonDeliverable" === v) {
          return {
                  TAG: "Ok",
                  _0: "nonDeliverable"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString = t_encode$2;

function isOriginRelated(status) {
  switch (status) {
    case "delivered" :
    case "deliveryFailed" :
    case "deliveryReturned" :
    case "deliveryReturnFailed" :
        return false;
    default:
      return true;
  }
}

function isDestinationRelated(status) {
  switch (status) {
    case "delivered" :
    case "deliveryFailed" :
        return true;
    default:
      return false;
  }
}

function isFailure(status) {
  switch (status) {
    case "pickupFailed" :
    case "deliveryFailed" :
    case "deliveryReturnFailed" :
    case "nonDeliverable" :
        return true;
    default:
      return false;
  }
}

function isSuccess(status) {
  switch (status) {
    case "pickupFailed" :
    case "deliveryFailed" :
    case "deliveryCanceled" :
    case "deliveryReturnFailed" :
        return false;
    default:
      return true;
  }
}

function isInformative(status) {
  return false;
}

function isReturnRelated(status) {
  switch (status) {
    case "deliveryReturned" :
    case "deliveryReturnFailed" :
        return true;
    default:
      return false;
  }
}

function isTerminatedFailure(status) {
  switch (status) {
    case "pickupFailed" :
    case "deliveryReturned" :
    case "deliveryReturnFailed" :
    case "nonDeliverable" :
        return true;
    default:
      return false;
  }
}

var StatusProvider = {
  t_encode: t_encode$2,
  t_decode: t_decode$2,
  toString: toString,
  isOriginRelated: isOriginRelated,
  isDestinationRelated: isDestinationRelated,
  isFailure: isFailure,
  isSuccess: isSuccess,
  isInformative: isInformative,
  isReturnRelated: isReturnRelated,
  isTerminatedFailure: isTerminatedFailure
};

function t_encode$3(v) {
  switch (v) {
    case "confirmed" :
        return "confirmed";
    case "arrival_at_pickup_point" :
        return "arrival_at_pickup_point";
    case "arrival_at_shipping_point" :
        return "arrival_at_shipping_point";
    case "package_withdrawn" :
        return "package_withdrawn";
    case "package_withdrawal_pb_ok" :
        return "package_withdrawal_pb_ok";
    case "delivery_failed" :
        return "delivery_failed";
    case "delivered" :
        return "delivered";
    case "package_withdrawal_failed" :
        return "package_withdrawal_failed";
    case "delivery_pb_ok" :
        return "delivery_pb_ok";
    case "package_returned" :
        return "package_returned";
    case "package_return_failed" :
        return "package_return_failed";
    case "non_deliverable" :
        return "non_deliverable";
    case "canceled" :
        return "canceled";
    case "idle" :
        return "idle";
    case "course_accepted" :
        return "course_accepted";
    case "course_refused" :
        return "course_refused";
    
  }
}

function t_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("confirmed" === v) {
          return {
                  TAG: "Ok",
                  _0: "confirmed"
                };
        } else if ("arrival_at_pickup_point" === v) {
          return {
                  TAG: "Ok",
                  _0: "arrival_at_pickup_point"
                };
        } else if ("arrival_at_shipping_point" === v) {
          return {
                  TAG: "Ok",
                  _0: "arrival_at_shipping_point"
                };
        } else if ("package_withdrawn" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_withdrawn"
                };
        } else if ("package_withdrawal_pb_ok" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_withdrawal_pb_ok"
                };
        } else if ("delivery_failed" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_failed"
                };
        } else if ("delivered" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivered"
                };
        } else if ("package_withdrawal_failed" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_withdrawal_failed"
                };
        } else if ("delivery_pb_ok" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_pb_ok"
                };
        } else if ("package_returned" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_returned"
                };
        } else if ("package_return_failed" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_return_failed"
                };
        } else if ("non_deliverable" === v) {
          return {
                  TAG: "Ok",
                  _0: "non_deliverable"
                };
        } else if ("canceled" === v) {
          return {
                  TAG: "Ok",
                  _0: "canceled"
                };
        } else if ("idle" === v) {
          return {
                  TAG: "Ok",
                  _0: "idle"
                };
        } else if ("course_accepted" === v) {
          return {
                  TAG: "Ok",
                  _0: "course_accepted"
                };
        } else if ("course_refused" === v) {
          return {
                  TAG: "Ok",
                  _0: "course_refused"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function isReturnRelated$1(status) {
  switch (status) {
    case "package_returned" :
    case "package_return_failed" :
        return true;
    default:
      return false;
  }
}

var FullStatusProvider = {
  t_encode: t_encode$3,
  t_decode: t_decode$3,
  isReturnRelated: isReturnRelated$1
};

function t_encode$4(v) {
  switch (v) {
    case "idle" :
        return "idle";
    case "preparing" :
        return "preparing";
    case "ready_to_pick_up" :
        return "ready_to_pick_up";
    case "picked_up" :
        return "picked_up";
    case "package_returned" :
        return "package_returned";
    case "canceled" :
        return "canceled";
    
  }
}

function t_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("idle" === v) {
          return {
                  TAG: "Ok",
                  _0: "idle"
                };
        } else if ("preparing" === v) {
          return {
                  TAG: "Ok",
                  _0: "preparing"
                };
        } else if ("ready_to_pick_up" === v) {
          return {
                  TAG: "Ok",
                  _0: "ready_to_pick_up"
                };
        } else if ("picked_up" === v) {
          return {
                  TAG: "Ok",
                  _0: "picked_up"
                };
        } else if ("package_returned" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_returned"
                };
        } else if ("canceled" === v) {
          return {
                  TAG: "Ok",
                  _0: "canceled"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var StatusOriginator = {
  t_encode: t_encode$4,
  t_decode: t_decode$4
};

function t_encode$5(v) {
  switch (v) {
    case "idle" :
        return "idle";
    case "confirmed" :
        return "confirmed";
    case "pre_ordered" :
        return "pre_ordered";
    case "canceled" :
        return "canceled";
    
  }
}

function t_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("idle" === v) {
          return {
                  TAG: "Ok",
                  _0: "idle"
                };
        } else if ("confirmed" === v) {
          return {
                  TAG: "Ok",
                  _0: "confirmed"
                };
        } else if ("pre_ordered" === v) {
          return {
                  TAG: "Ok",
                  _0: "pre_ordered"
                };
        } else if ("canceled" === v) {
          return {
                  TAG: "Ok",
                  _0: "canceled"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var GlobalState = {
  t_encode: t_encode$5,
  t_decode: t_decode$5
};

function t_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.SkillId.t_encode, v.id)
                  ],
                  [
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ],
                  [
                    "description",
                    false,
                    Spice.stringToJson(v.description)
                  ]
                ]));
}

function t_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.SkillId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
    if (tag.TAG === "Ok") {
      var description = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  tag: tag._0,
                  description: description._0
                }
              };
      }
      var e = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = tag._0;
    return {
            TAG: "Error",
            _0: {
              path: ".tag" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var Skill = {
  t_encode: t_encode$6,
  t_decode: t_decode$6
};

function t_encode$7(v) {
  switch (v) {
    case "maybe_lift" :
        return "maybe_lift";
    case "with_lift" :
        return "with_lift";
    case "no_lift" :
        return "no_lift";
    
  }
}

function t_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("maybe_lift" === v) {
          return {
                  TAG: "Ok",
                  _0: "maybe_lift"
                };
        } else if ("with_lift" === v) {
          return {
                  TAG: "Ok",
                  _0: "with_lift"
                };
        } else if ("no_lift" === v) {
          return {
                  TAG: "Ok",
                  _0: "no_lift"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function toBoolean(value) {
  switch (value) {
    case "with_lift" :
        return true;
    case "maybe_lift" :
    case "no_lift" :
        return false;
    
  }
}

function toString$1(value) {
  switch (value) {
    case "maybe_lift" :
        return "maybe_lift";
    case "with_lift" :
        return "with_lift";
    case "no_lift" :
        return "no_lift";
    
  }
}

var Lift = {
  t_encode: t_encode$7,
  t_decode: t_decode$7,
  toBoolean: toBoolean,
  toString: toString$1
};

function t_encode$8(v) {
  switch (v) {
    case "consumer" :
        return "consumer";
    case "store" :
        return "store";
    case "company" :
        return "company";
    case "hub" :
        return "hub";
    
  }
}

function t_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("consumer" === v) {
          return {
                  TAG: "Ok",
                  _0: "consumer"
                };
        } else if ("store" === v) {
          return {
                  TAG: "Ok",
                  _0: "store"
                };
        } else if ("company" === v) {
          return {
                  TAG: "Ok",
                  _0: "company"
                };
        } else if ("hub" === v) {
          return {
                  TAG: "Ok",
                  _0: "hub"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var LocationType = {
  t_encode: t_encode$8,
  t_decode: t_decode$8
};

function t_encode$9(v) {
  switch (v) {
    case "sidewalkdelivery" :
        return "sidewalkdelivery";
    case "roomdelivery" :
        return "roomdelivery";
    case "traileddelivery" :
        return "traileddelivery";
    
  }
}

function t_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("sidewalkdelivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "sidewalkdelivery"
                };
        } else if ("roomdelivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "roomdelivery"
                };
        } else if ("traileddelivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "traileddelivery"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var HeftingOption = {
  t_encode: t_encode$9,
  t_decode: t_decode$9
};

export {
  TimeSlot ,
  Channel ,
  StatusProvider ,
  FullStatusProvider ,
  StatusOriginator ,
  GlobalState ,
  Skill ,
  Lift ,
  LocationType ,
  HeftingOption ,
}
/* Identifiers Not a pure module */
