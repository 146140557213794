

import * as Ky from "ky";
import * as Axios from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/Axios.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../node_modules/rescript/lib/es6/js_dict.js";
import * as $$Promise from "../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as Js_string from "../../../node_modules/rescript/lib/es6/js_string.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "./Identifiers.bs.js";
import * as ColiswebApi__Env from "./ColiswebApi__Env.bs.js";
import * as Toolkit__Request from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Toolkit__Request.bs.js";
import * as Toolkit__Primitives_Array from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";

var headers = {};

headers["Authorization"] = ColiswebApi__Env.unleashToken;

var prefixBaseUrl = ColiswebApi__Env.unleashUrl;

var kyInstance = Ky.default.create({
      prefixUrl: prefixBaseUrl,
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      headers: Caml_option.some(headers),
      credentials: "include",
      mode: "cors"
    });

var arg = ColiswebApi__Env.unleashUrl;

var arg$1 = ColiswebApi__Env.isNodeDevelopment ? 1000 : 20000;

function config(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  var partial_arg = true;
  return function (param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
    var tmp = {};
    if (param !== undefined) {
      tmp.url = param;
    }
    if (param$1 !== undefined) {
      tmp._method = param$1;
    }
    if (arg !== undefined) {
      tmp.baseURL = arg;
    }
    if (param$2 !== undefined) {
      tmp.transformRequest = Caml_option.valFromOption(param$2);
    }
    if (param$3 !== undefined) {
      tmp.transformResponse = Caml_option.valFromOption(param$3);
    }
    if (param$4 !== undefined) {
      tmp.headers = Caml_option.valFromOption(param$4);
    }
    if (param$5 !== undefined) {
      tmp.params = Caml_option.valFromOption(param$5);
    }
    if (param$6 !== undefined) {
      tmp.paramsSerializer = Caml_option.valFromOption(param$6);
    }
    if (param$7 !== undefined) {
      tmp.data = Caml_option.valFromOption(param$7);
    }
    if (arg$1 !== undefined) {
      tmp.timeout = arg$1;
    }
    if (partial_arg !== undefined) {
      tmp.withCredentials = partial_arg;
    }
    if (param$8 !== undefined) {
      tmp.adapter = Caml_option.valFromOption(param$8);
    }
    if (param$9 !== undefined) {
      tmp.auth = Caml_option.valFromOption(param$9);
    }
    if (param$10 !== undefined) {
      tmp.responseType = param$10;
    }
    if (param$11 !== undefined) {
      tmp.xsrfCookieName = param$11;
    }
    if (param$12 !== undefined) {
      tmp.xsrfHeaderName = param$12;
    }
    if (param$13 !== undefined) {
      tmp.onUploadProgress = Caml_option.valFromOption(param$13);
    }
    if (param$14 !== undefined) {
      tmp.onDownloadProgress = Caml_option.valFromOption(param$14);
    }
    if (param$15 !== undefined) {
      tmp.maxContentLength = param$15;
    }
    if (param$16 !== undefined) {
      tmp.validateStatus = Caml_option.valFromOption(param$16);
    }
    if (param$17 !== undefined) {
      tmp.maxRedirects = param$17;
    }
    if (param$18 !== undefined) {
      tmp.socketPath = param$18;
    }
    if (param$19 !== undefined) {
      tmp.proxy = Caml_option.valFromOption(param$19);
    }
    if (param$20 !== undefined) {
      tmp.cancelToken = Caml_option.valFromOption(param$20);
    }
    return tmp;
  };
}

function feature_encode(encoder_strategy, v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "description",
                    true,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "enabled",
                    false,
                    Spice.boolToJson(v.enabled)
                  ],
                  [
                    "strategies",
                    false,
                    Spice.arrayToJson(encoder_strategy, v.strategies)
                  ]
                ]));
}

function feature_decode(decoder_strategy, v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
    if (description.TAG === "Ok") {
      var enabled = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "enabled"), null));
      if (enabled.TAG === "Ok") {
        var strategies = Spice.arrayFromJson(decoder_strategy, Belt_Option.getWithDefault(Js_dict.get(v, "strategies"), null));
        if (strategies.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    name: name._0,
                    description: description._0,
                    enabled: enabled._0,
                    strategies: strategies._0
                  }
                };
        }
        var e = strategies._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".strategies" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = enabled._0;
      return {
              TAG: "Error",
              _0: {
                path: ".enabled" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = description._0;
    return {
            TAG: "Error",
            _0: {
              path: ".description" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function parameters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var storeIds = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "storeIds"), null));
  if (storeIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              storeIds: storeIds._0
            }
          };
  }
  var e = storeIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".storeIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function parameters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "storeIds",
                    false,
                    Spice.stringToJson(v.storeIds)
                  ]]));
}

function strategy_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "parameters",
                    false,
                    parameters_encode(v.parameters)
                  ]
                ]));
}

function strategy_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var parameters = parameters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "parameters"), null));
    if (parameters.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                name: name._0,
                parameters: parameters._0
              }
            };
    }
    var e = parameters._0;
    return {
            TAG: "Error",
            _0: {
              path: ".parameters" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function boundFeature_encode(v) {
  return feature_encode(strategy_encode, v);
}

function boundFeature_decode(v) {
  return feature_decode(strategy_decode, v);
}

function response_encode(v) {
  return Spice.arrayToJson(Identifiers.StoreId.t_encode, v);
}

function response_decode(v) {
  return Spice.arrayFromJson(Identifiers.StoreId.t_decode, v);
}

function exec(param) {
  return $$Promise.map(Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, Caml_option.some(headers), undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), boundFeature_decode, undefined, "/odin-allowed-stores"), (function (result) {
                if (result.TAG !== "Ok") {
                  return {
                          TAG: "Ok",
                          _0: []
                        };
                }
                var strategies = result._0.strategies;
                if (strategies.length !== 1) {
                  return {
                          TAG: "Ok",
                          _0: []
                        };
                }
                var strategy = strategies[0];
                var storeIds = Curry._2(Toolkit__Primitives_Array.$$Array.map, Js_string.split(",", strategy.parameters.storeIds), (function (prim) {
                        return prim;
                      }));
                return {
                        TAG: "Ok",
                        _0: storeIds
                      };
              }));
}

var Config = {
  strategy_encode: strategy_encode,
  strategy_decode: strategy_decode,
  parameters_encode: parameters_encode,
  parameters_decode: parameters_decode,
  boundFeature_encode: boundFeature_encode,
  boundFeature_decode: boundFeature_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  exec: exec
};

var $$Request$1 = Toolkit__Request.Make({
      exec: exec
    });

var FetchAllowedStores = {
  Config: Config,
  $$Request: $$Request$1
};

function parameters_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientIds = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "clientIds"), null));
  if (clientIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              clientIds: clientIds._0
            }
          };
  }
  var e = clientIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function parameters_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "clientIds",
                    false,
                    Spice.stringToJson(v.clientIds)
                  ]]));
}

function strategy_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "parameters",
                    false,
                    parameters_encode$1(v.parameters)
                  ]
                ]));
}

function strategy_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var parameters = parameters_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "parameters"), null));
    if (parameters.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                name: name._0,
                parameters: parameters._0
              }
            };
    }
    var e = parameters._0;
    return {
            TAG: "Error",
            _0: {
              path: ".parameters" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function boundFeature_encode$1(v) {
  return feature_encode(strategy_encode$1, v);
}

function boundFeature_decode$1(v) {
  return feature_decode(strategy_decode$1, v);
}

function response_encode$1(v) {
  return Spice.arrayToJson(Identifiers.ClientId.t_encode, v);
}

function response_decode$1(v) {
  return Spice.arrayFromJson(Identifiers.ClientId.t_decode, v);
}

function exec$1(param) {
  return $$Promise.map(Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, Caml_option.some(headers), undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), boundFeature_decode$1, undefined, "/odin-allowed-clients"), (function (result) {
                if (result.TAG !== "Ok") {
                  return {
                          TAG: "Ok",
                          _0: []
                        };
                }
                var strategies = result._0.strategies;
                if (strategies.length !== 1) {
                  return {
                          TAG: "Ok",
                          _0: []
                        };
                }
                var strategy = strategies[0];
                var clientIds = Curry._2(Toolkit__Primitives_Array.$$Array.map, Js_string.split(",", strategy.parameters.clientIds), (function (prim) {
                        return prim;
                      }));
                return {
                        TAG: "Ok",
                        _0: clientIds
                      };
              }));
}

var Config$1 = {
  strategy_encode: strategy_encode$1,
  strategy_decode: strategy_decode$1,
  parameters_encode: parameters_encode$1,
  parameters_decode: parameters_decode$1,
  boundFeature_encode: boundFeature_encode$1,
  boundFeature_decode: boundFeature_decode$1,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  exec: exec$1
};

var $$Request$2 = Toolkit__Request.Make({
      exec: exec$1
    });

var FetchAllowedClients = {
  Config: Config$1,
  $$Request: $$Request$2
};

function parameters_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "storeIds",
                    false,
                    Spice.stringToJson(v.storeIds)
                  ]]));
}

function parameters_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var storeIds = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "storeIds"), null));
  if (storeIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              storeIds: storeIds._0
            }
          };
  }
  var e = storeIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".storeIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function strategy_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "parameters",
                    false,
                    parameters_encode$2(v.parameters)
                  ]
                ]));
}

function strategy_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var parameters = parameters_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "parameters"), null));
    if (parameters.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                name: name._0,
                parameters: parameters._0
              }
            };
    }
    var e = parameters._0;
    return {
            TAG: "Error",
            _0: {
              path: ".parameters" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function boundFeature_encode$2(v) {
  return feature_encode(strategy_encode$2, v);
}

function boundFeature_decode$2(v) {
  return feature_decode(strategy_decode$2, v);
}

function response_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "valid",
                    false,
                    Spice.boolToJson(v.valid)
                  ]]));
}

function response_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var valid = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "valid"), null));
  if (valid.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              valid: valid._0
            }
          };
  }
  var e = valid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".valid" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$2(storeId) {
  return $$Promise.map(Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, Caml_option.some(headers), undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), boundFeature_decode$2, undefined, "/odin-allowed-stores"), (function (res) {
                if (res.TAG !== "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                var match = res._0;
                var strategies = match.strategies;
                if (!match.enabled) {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                if (strategies.length !== 1) {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                var strategy = strategies[0];
                var storeIds = Curry._2(Toolkit__Primitives_Array.$$Array.map, Js_string.split(",", strategy.parameters.storeIds), (function (prim) {
                        return prim;
                      }));
                return {
                        TAG: "Ok",
                        _0: {
                          valid: Curry._2(Toolkit__Primitives_Array.$$Array.some, storeIds, (function (id) {
                                  return id === storeId;
                                }))
                        }
                      };
              }));
}

var Config$2 = {
  strategy_encode: strategy_encode$2,
  strategy_decode: strategy_decode$2,
  parameters_encode: parameters_encode$2,
  parameters_decode: parameters_decode$2,
  boundFeature_encode: boundFeature_encode$2,
  boundFeature_decode: boundFeature_decode$2,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  exec: exec$2
};

var $$Request$3 = Toolkit__Request.Make({
      exec: exec$2
    });

var AccessRestrictedByStoreId = {
  Config: Config$2,
  $$Request: $$Request$3
};

function parameters_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientIds = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "clientIds"), null));
  if (clientIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              clientIds: clientIds._0
            }
          };
  }
  var e = clientIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function parameters_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "clientIds",
                    false,
                    Spice.stringToJson(v.clientIds)
                  ]]));
}

function strategy_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "parameters",
                    false,
                    parameters_encode$3(v.parameters)
                  ]
                ]));
}

function strategy_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var parameters = parameters_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "parameters"), null));
    if (parameters.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                name: name._0,
                parameters: parameters._0
              }
            };
    }
    var e = parameters._0;
    return {
            TAG: "Error",
            _0: {
              path: ".parameters" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function boundFeature_encode$3(v) {
  return feature_encode(strategy_encode$3, v);
}

function boundFeature_decode$3(v) {
  return feature_decode(strategy_decode$3, v);
}

function response_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "valid",
                    false,
                    Spice.boolToJson(v.valid)
                  ]]));
}

function response_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var valid = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "valid"), null));
  if (valid.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              valid: valid._0
            }
          };
  }
  var e = valid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".valid" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$3(clientId) {
  return $$Promise.map(Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, Caml_option.some(headers), undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), boundFeature_decode$3, undefined, "/odin-allowed-clients"), (function (res) {
                if (res.TAG !== "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                var match = res._0;
                var strategies = match.strategies;
                if (!match.enabled) {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                if (strategies.length !== 1) {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                var strategy = strategies[0];
                var clientIds = Curry._2(Toolkit__Primitives_Array.$$Array.map, Js_string.split(",", strategy.parameters.clientIds), (function (prim) {
                        return prim;
                      }));
                return {
                        TAG: "Ok",
                        _0: {
                          valid: Curry._2(Toolkit__Primitives_Array.$$Array.some, clientIds, (function (id) {
                                  return id === clientId;
                                }))
                        }
                      };
              }));
}

var Config$3 = {
  strategy_encode: strategy_encode$3,
  strategy_decode: strategy_decode$3,
  parameters_encode: parameters_encode$3,
  parameters_decode: parameters_decode$3,
  boundFeature_encode: boundFeature_encode$3,
  boundFeature_decode: boundFeature_decode$3,
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  exec: exec$3
};

var $$Request$4 = Toolkit__Request.Make({
      exec: exec$3
    });

var AccessRestrictedByClientId = {
  Config: Config$3,
  $$Request: $$Request$4
};

function parameters_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "transporterIds",
                    false,
                    Spice.stringToJson(v.transporterIds)
                  ]]));
}

function parameters_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transporterIds = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "transporterIds"), null));
  if (transporterIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              transporterIds: transporterIds._0
            }
          };
  }
  var e = transporterIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transporterIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function strategy_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "parameters",
                    false,
                    parameters_encode$4(v.parameters)
                  ]
                ]));
}

function strategy_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var parameters = parameters_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "parameters"), null));
    if (parameters.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                name: name._0,
                parameters: parameters._0
              }
            };
    }
    var e = parameters._0;
    return {
            TAG: "Error",
            _0: {
              path: ".parameters" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function boundFeature_encode$4(v) {
  return feature_encode(strategy_encode$4, v);
}

function boundFeature_decode$4(v) {
  return feature_decode(strategy_decode$4, v);
}

function response_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "valid",
                    false,
                    Spice.boolToJson(v.valid)
                  ]]));
}

function response_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var valid = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "valid"), null));
  if (valid.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              valid: valid._0
            }
          };
  }
  var e = valid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".valid" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$4(clientId) {
  return $$Promise.map(Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, Caml_option.some(headers), undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), boundFeature_decode$4, undefined, "/odin-allowed-transporters"), (function (res) {
                if (res.TAG !== "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: false
                          }
                        };
                }
                var match = res._0;
                var strategies = match.strategies;
                if (!match.enabled) {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                if (strategies.length !== 1) {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                var strategy = strategies[0];
                var transporters = Curry._2(Toolkit__Primitives_Array.$$Array.map, Js_string.split(",", strategy.parameters.transporterIds), (function (prim) {
                        return prim;
                      }));
                return {
                        TAG: "Ok",
                        _0: {
                          valid: Curry._2(Toolkit__Primitives_Array.$$Array.some, transporters, (function (id) {
                                  return id === clientId;
                                }))
                        }
                      };
              }));
}

var Config$4 = {
  strategy_encode: strategy_encode$4,
  strategy_decode: strategy_decode$4,
  parameters_encode: parameters_encode$4,
  parameters_decode: parameters_decode$4,
  boundFeature_encode: boundFeature_encode$4,
  boundFeature_decode: boundFeature_decode$4,
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  exec: exec$4
};

var $$Request$5 = Toolkit__Request.Make({
      exec: exec$4
    });

var AccessRestrictedByTransporterId = {
  Config: Config$4,
  $$Request: $$Request$5
};

function parameters_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]]));
}

function parameters_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              name: name._0
            }
          };
  }
  var e = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function strategy_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "parameters",
                    false,
                    parameters_encode$5(v.parameters)
                  ]
                ]));
}

function strategy_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var parameters = parameters_decode$5(Belt_Option.getWithDefault(Js_dict.get(v, "parameters"), null));
    if (parameters.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                name: name._0,
                parameters: parameters._0
              }
            };
    }
    var e = parameters._0;
    return {
            TAG: "Error",
            _0: {
              path: ".parameters" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function boundFeature_encode$5(v) {
  return feature_encode(strategy_encode$5, v);
}

function boundFeature_decode$5(v) {
  return feature_decode(strategy_decode$5, v);
}

function response_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "valid",
                    false,
                    Spice.boolToJson(v.valid)
                  ]]));
}

function response_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var valid = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "valid"), null));
  if (valid.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              valid: valid._0
            }
          };
  }
  var e = valid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".valid" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$5(currentUsername) {
  return $$Promise.map(Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, Caml_option.some(headers), undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), boundFeature_decode$5, undefined, "/restrict_routeplan_edition_by_username"), (function (res) {
                if (res.TAG !== "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: false
                          }
                        };
                }
                var match = res._0;
                var strategies = match.strategies;
                if (!match.enabled) {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                if (strategies.length !== 1) {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                var strategy = strategies[0];
                var usernames = Curry._2(Toolkit__Primitives_Array.$$Array.map, strategy.parameters.name.split(","), (function (prim) {
                        return prim.toLowerCase();
                      }));
                return {
                        TAG: "Ok",
                        _0: {
                          valid: Curry._2(Toolkit__Primitives_Array.$$Array.some, usernames, (function (name) {
                                  return name === currentUsername.toLowerCase();
                                }))
                        }
                      };
              }));
}

var Config$5 = {
  parameters_encode: parameters_encode$5,
  parameters_decode: parameters_decode$5,
  strategy_encode: strategy_encode$5,
  strategy_decode: strategy_decode$5,
  boundFeature_encode: boundFeature_encode$5,
  boundFeature_decode: boundFeature_decode$5,
  response_encode: response_encode$5,
  response_decode: response_decode$5,
  exec: exec$5
};

var $$Request$6 = Toolkit__Request.Make({
      exec: exec$5
    });

var RoutePlanEditRestrictedByUsername = {
  Config: Config$5,
  $$Request: $$Request$6
};

function parameters_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]]));
}

function parameters_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              name: name._0
            }
          };
  }
  var e = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function strategy_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "parameters",
                    false,
                    parameters_encode$6(v.parameters)
                  ]
                ]));
}

function strategy_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var parameters = parameters_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "parameters"), null));
    if (parameters.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                name: name._0,
                parameters: parameters._0
              }
            };
    }
    var e = parameters._0;
    return {
            TAG: "Error",
            _0: {
              path: ".parameters" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function boundFeature_encode$6(v) {
  return feature_encode(strategy_encode$6, v);
}

function boundFeature_decode$6(v) {
  return feature_decode(strategy_decode$6, v);
}

function response_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "valid",
                    false,
                    Spice.boolToJson(v.valid)
                  ]]));
}

function response_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var valid = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "valid"), null));
  if (valid.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              valid: valid._0
            }
          };
  }
  var e = valid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".valid" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$6(currentUsername) {
  return $$Promise.map(Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, Caml_option.some(headers), undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), boundFeature_decode$6, undefined, "/restrict_preselection_job_retry_by_username"), (function (res) {
                if (res.TAG !== "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: false
                          }
                        };
                }
                var match = res._0;
                var strategies = match.strategies;
                if (!match.enabled) {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                if (strategies.length !== 1) {
                  return {
                          TAG: "Ok",
                          _0: {
                            valid: true
                          }
                        };
                }
                var strategy = strategies[0];
                var usernames = Curry._2(Toolkit__Primitives_Array.$$Array.map, strategy.parameters.name.split(","), (function (prim) {
                        return prim.toLowerCase();
                      }));
                return {
                        TAG: "Ok",
                        _0: {
                          valid: Curry._2(Toolkit__Primitives_Array.$$Array.some, usernames, (function (name) {
                                  return name === currentUsername.toLowerCase();
                                }))
                        }
                      };
              }));
}

var Config$6 = {
  parameters_encode: parameters_encode$6,
  parameters_decode: parameters_decode$6,
  strategy_encode: strategy_encode$6,
  strategy_decode: strategy_decode$6,
  boundFeature_encode: boundFeature_encode$6,
  boundFeature_decode: boundFeature_decode$6,
  response_encode: response_encode$6,
  response_decode: response_decode$6,
  exec: exec$6
};

var $$Request$7 = Toolkit__Request.Make({
      exec: exec$6
    });

var PreselectionJobRetryRestrictedByUsername = {
  Config: Config$6,
  $$Request: $$Request$7
};

function messageParameters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "text",
                    false,
                    Spice.stringToJson(v.text)
                  ]]));
}

function messageParameters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var text = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "text"), null));
  if (text.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              text: text._0
            }
          };
  }
  var e = text._0;
  return {
          TAG: "Error",
          _0: {
            path: ".text" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function strategy_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "parameters",
                    false,
                    messageParameters_encode(v.parameters)
                  ]]));
}

function strategy_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var parameters = messageParameters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "parameters"), null));
  if (parameters.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              parameters: parameters._0
            }
          };
  }
  var e = parameters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".parameters" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode$7(v) {
  return feature_encode(strategy_encode$7, v);
}

function response_decode$7(v) {
  return feature_decode(strategy_decode$7, v);
}

function config$1(backOffice) {
  return {
          kyInstance: kyInstance,
          path: "communication_banner_bo_" + backOffice,
          requestOptions: {
            method: "GET"
          },
          key: [
            "UnleashCommunicationBanner",
            backOffice
          ]
        };
}

var CommunicationBanner = {
  messageParameters_encode: messageParameters_encode,
  messageParameters_decode: messageParameters_decode,
  strategy_encode: strategy_encode$7,
  strategy_decode: strategy_decode$7,
  response_encode: response_encode$7,
  response_decode: response_decode$7,
  config: config$1
};

function parameters_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "freightForwardersIds",
                    false,
                    Spice.stringToJson(v.freightForwardersIds)
                  ]]));
}

function parameters_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var freightForwardersIds = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "freightForwardersIds"), null));
  if (freightForwardersIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              freightForwardersIds: freightForwardersIds._0
            }
          };
  }
  var e = freightForwardersIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".freightForwardersIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function strategy_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "parameters",
                    false,
                    parameters_encode$7(v.parameters)
                  ]]));
}

function strategy_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var parameters = parameters_decode$7(Belt_Option.getWithDefault(Js_dict.get(v, "parameters"), null));
  if (parameters.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              parameters: parameters._0
            }
          };
  }
  var e = parameters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".parameters" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function unleashResponse_encode(v) {
  return feature_encode(strategy_encode$8, v);
}

function unleashResponse_decode(v) {
  return feature_decode(strategy_decode$8, v);
}

function response_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "valid",
                    false,
                    Spice.boolToJson(v.valid)
                  ]]));
}

function response_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var valid = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "valid"), null));
  if (valid.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              valid: valid._0
            }
          };
  }
  var e = valid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".valid" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$2(transporterFreightForwarderId) {
  return {
          kyInstance: kyInstance,
          path: "restrict_transporters_deliveries_list_auto_refusal_by_freightForwarder_id",
          requestOptions: {
            method: "GET"
          },
          key: [
            "UnleashRestrictFreightId",
            transporterFreightForwarderId
          ],
          mapRawResponse: (function (res) {
              var match = $$Request.decodeRawResponse(res, unleashResponse_decode);
              var tmp;
              if (match.TAG === "Ok") {
                var match$1 = match._0;
                var strategies = match$1.strategies;
                if (match$1.enabled && strategies.length === 1) {
                  var strategy = strategies[0];
                  var ids = Curry._2(Toolkit__Primitives_Array.$$Array.map, strategy.parameters.freightForwardersIds.split(","), (function (prim) {
                          return prim;
                        }));
                  tmp = {
                    TAG: "Ok",
                    _0: {
                      valid: Curry._2(Toolkit__Primitives_Array.$$Array.some, ids, (function (id) {
                              return id === transporterFreightForwarderId;
                            }))
                    }
                  };
                } else {
                  tmp = {
                    TAG: "Ok",
                    _0: {
                      valid: true
                    }
                  };
                }
              } else {
                tmp = {
                  TAG: "Ok",
                  _0: {
                    valid: false
                  }
                };
              }
              return $$Promise.resolved(tmp);
            })
        };
}

var DeliveryListAutoRefusalRestrictedByFreightForwarder = {
  parameters_encode: parameters_encode$7,
  parameters_decode: parameters_decode$7,
  strategy_encode: strategy_encode$8,
  strategy_decode: strategy_decode$8,
  unleashResponse_encode: unleashResponse_encode,
  unleashResponse_decode: unleashResponse_decode,
  response_encode: response_encode$8,
  response_decode: response_decode$8,
  config: config$2
};

export {
  headers ,
  config ,
  feature_encode ,
  feature_decode ,
  FetchAllowedStores ,
  FetchAllowedClients ,
  AccessRestrictedByStoreId ,
  AccessRestrictedByClientId ,
  AccessRestrictedByTransporterId ,
  RoutePlanEditRestrictedByUsername ,
  PreselectionJobRetryRestrictedByUsername ,
  CommunicationBanner ,
  DeliveryListAutoRefusalRestrictedByFreightForwarder ,
}
/*  Not a pure module */
