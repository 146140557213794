

import * as Ky from "ky";
import * as Qs from "qs";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as DateFns from "date-fns";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__Utils from "../ColiswebApi__Utils.bs.js";
import * as ColiswebApi__V6_Utils from "./ColiswebApi__V6_Utils.bs.js";
import * as Decoders__UnitMeasure from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Decoders__UnitMeasure.bs.js";
import * as Toolkit__Primitives_Array from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";
import * as Toolkit__Primitives_Result from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Result.bs.js";

var prefixBaseUrl = ColiswebApi__Env.v6ApiUrl + "/provider/external";

var kyInstance = Ky.default.create({
      prefixUrl: prefixBaseUrl,
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      credentials: "include",
      mode: "cors"
    });

function period_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.$$Date.t_encode, v.end)
                  ],
                  [
                    "endProposal",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.$$Date.t_encode, v.endProposal)
                  ]
                ]));
}

function period_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Spice.optionFromJson(Toolkit__Decoders.$$Date.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      var endProposal = Spice.optionFromJson(Toolkit__Decoders.$$Date.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "endProposal"), null));
      if (endProposal.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  start: start._0,
                  end: end_._0,
                  endProposal: endProposal._0
                }
              };
      }
      var e = endProposal._0;
      return {
              TAG: "Error",
              _0: {
                path: ".endProposal" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e$1.path),
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function activation_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ProviderRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "ruleTitle",
                    false,
                    Spice.stringToJson(v.ruleTitle)
                  ],
                  [
                    "periods",
                    false,
                    Spice.arrayToJson(period_encode, v.periods)
                  ]
                ]));
}

function activation_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ruleId = Curry._1(Identifiers.ProviderRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
  if (ruleId.TAG === "Ok") {
    var ruleTitle = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ruleTitle"), null));
    if (ruleTitle.TAG === "Ok") {
      var periods = Spice.arrayFromJson(period_decode, Belt_Option.getWithDefault(Js_dict.get(v, "periods"), null));
      if (periods.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  ruleId: ruleId._0,
                  ruleTitle: ruleTitle._0,
                  periods: periods._0
                }
              };
      }
      var e = periods._0;
      return {
              TAG: "Error",
              _0: {
                path: ".periods" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = ruleTitle._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleTitle" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = ruleId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ruleId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function providerIds_encode(v) {
  return Spice.arrayToJson(Identifiers.ProviderId.t_encode, v);
}

function providerIds_decode(v) {
  return Spice.arrayFromJson(Identifiers.ProviderId.t_decode, v);
}

function encoder(_v) {
  return Spice.stringToJson("");
}

function decoder(json) {
  var match = json.code;
  var value;
  if (match !== undefined) {
    switch (match) {
      case "ACCESS_REFUSED" :
          value = "accessRefused";
          break;
      case "COMPILE_ERROR" :
          value = {
            NAME: "compileError",
            VAL: json.compileError
          };
          break;
      case "OVERLAPPING_ACTIVATIONS" :
          value = {
            NAME: "overlappingActivations",
            VAL: Curry._2(Toolkit__Primitives_Array.$$Array.map, json.overlapped, (function (a) {
                    return Curry._1(Toolkit__Primitives_Result.Result.getExn, activation_decode(a));
                  }))
          };
          break;
      case "RULE_HAS_ACTIVATIONS" :
          var v = json.providerIds;
          value = {
            NAME: "ruleHasActivations",
            VAL: Curry._1(Toolkit__Primitives_Result.Result.getExn, Spice.arrayFromJson(Identifiers.ProviderId.t_decode, v))
          };
          break;
      default:
        value = "unknown";
    }
  } else {
    value = "unknown";
  }
  return {
          TAG: "Ok",
          _0: value
        };
}

var codec = [
  encoder,
  decoder
];

var t_encode = encoder;

var t_decode = decoder;

var $$Error = {
  period_encode: period_encode,
  period_decode: period_decode,
  activation_encode: activation_encode,
  activation_decode: activation_decode,
  providerIds_encode: providerIds_encode,
  providerIds_decode: providerIds_decode,
  encoder: encoder,
  decoder: decoder,
  codec: codec,
  t_encode: t_encode,
  t_decode: t_decode
};

var kyInstance$1 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/activations"
    });

function params_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "entityIds",
                    false,
                    Spice.arrayToJson(Identifiers.ProviderId.t_encode, v.entityIds)
                  ],
                  [
                    "date",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.Datetime.t_encode, v.date)
                  ]
                ]));
}

function params_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var entityIds = Spice.arrayFromJson(Identifiers.ProviderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "entityIds"), null));
  if (entityIds.TAG === "Ok") {
    var date = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
    if (date.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                entityIds: entityIds._0,
                date: date._0
              }
            };
    }
    var e = date._0;
    return {
            TAG: "Error",
            _0: {
              path: ".date" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = entityIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".entityIds" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function activation_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "providerId",
                    false,
                    Curry._1(Identifiers.ProviderId.t_encode, v.providerId)
                  ],
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ProviderRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "ruleTitle",
                    false,
                    Spice.stringToJson(v.ruleTitle)
                  ],
                  [
                    "period",
                    false,
                    ColiswebApi__V6_Utils.Period.t_encode(v.period)
                  ]
                ]));
}

function activation_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var providerId = Curry._1(Identifiers.ProviderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "providerId"), null));
  if (providerId.TAG === "Ok") {
    var ruleId = Curry._1(Identifiers.ProviderRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
    if (ruleId.TAG === "Ok") {
      var ruleTitle = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ruleTitle"), null));
      if (ruleTitle.TAG === "Ok") {
        var period = ColiswebApi__V6_Utils.Period.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "period"), null));
        if (period.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    providerId: providerId._0,
                    ruleId: ruleId._0,
                    ruleTitle: ruleTitle._0,
                    period: period._0
                  }
                };
        }
        var e = period._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".period" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = ruleTitle._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ruleTitle" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = ruleId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = providerId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".providerId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode(v) {
  return Spice.arrayToJson(activation_encode$1, v);
}

function response_decode(v) {
  return Spice.arrayFromJson(activation_decode$1, v);
}

function config(param) {
  var date = param[1];
  var entities = param[0];
  return {
          kyInstance: kyInstance$1,
          path: "",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(params_encode({
                      entityIds: entities,
                      date: date
                    }))
          },
          key: Curry._2(Toolkit__Primitives_Array.$$Array.concat, [
                "AdminGetClientStorePriceActivationsFetcher",
                Belt_Option.mapWithDefault(date, "", (function (date) {
                        return DateFns.format(date, "yyyy-MM-dd");
                      }))
              ], Curry._2(Toolkit__Primitives_Array.$$Array.map, entities, Identifiers.ProviderId.toString))
        };
}

var GetAll = {
  params_encode: params_encode,
  params_decode: params_decode,
  activation_encode: activation_encode$1,
  activation_decode: activation_decode$1,
  response_encode: response_encode,
  response_decode: response_decode,
  config: config
};

var kyInstance$2 = kyInstance$1.extend({
      prefixUrl: prefixBaseUrl + "/activations/rules"
    });

function activation_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "providerId",
                    false,
                    Curry._1(Identifiers.ProviderId.t_encode, v.providerId)
                  ],
                  [
                    "period",
                    false,
                    Spice.optionToJson(ColiswebApi__V6_Utils.Period.t_encode, v.period)
                  ],
                  [
                    "applyPossibleModifications",
                    false,
                    Spice.boolToJson(v.applyPossibleModifications)
                  ]
                ]));
}

function activation_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var providerId = Curry._1(Identifiers.ProviderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "providerId"), null));
  if (providerId.TAG === "Ok") {
    var period = Spice.optionFromJson(ColiswebApi__V6_Utils.Period.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "period"), null));
    if (period.TAG === "Ok") {
      var applyPossibleModifications = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "applyPossibleModifications"), null));
      if (applyPossibleModifications.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  providerId: providerId._0,
                  period: period._0,
                  applyPossibleModifications: applyPossibleModifications._0
                }
              };
      }
      var e = applyPossibleModifications._0;
      return {
              TAG: "Error",
              _0: {
                path: ".applyPossibleModifications" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = period._0;
    return {
            TAG: "Error",
            _0: {
              path: ".period" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = providerId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".providerId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function body_encode(v) {
  return Spice.arrayToJson(activation_encode$2, v);
}

function body_decode(v) {
  return Spice.arrayFromJson(activation_decode$2, v);
}

var response_encode$1 = Spice.unitToJson;

var response_decode$1 = Spice.unitFromJson;

function config$1(param) {
  return {
          kyInstance: kyInstance$2,
          path: param[0],
          requestOptions: {
            method: "POST",
            json: Spice.arrayToJson(activation_encode$2, param[1])
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, t_decode), (function (decodedError) {
                              if (decodedError.TAG === "Ok") {
                                return {
                                        TAG: "Custom",
                                        _0: decodedError._0
                                      };
                              } else {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Update = {
  activation_encode: activation_encode$2,
  activation_decode: activation_decode$2,
  body_encode: body_encode,
  body_decode: body_decode,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  config: config$1
};

var Rules = {
  kyInstance: kyInstance$2,
  Update: Update
};

var Activations = {
  kyInstance: kyInstance$1,
  GetAll: GetAll,
  Rules: Rules
};

var kyInstance$3 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/rules"
    });

function argument_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "definition",
                    false,
                    Spice.stringToJson(v.definition)
                  ]
                ]));
}

function argument_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
  if (title.TAG === "Ok") {
    var definition = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "definition"), null));
    if (definition.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                title: title._0,
                definition: definition._0
              }
            };
    }
    var e = definition._0;
    return {
            TAG: "Error",
            _0: {
              path: ".definition" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: "Error",
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$2(v) {
  return Curry._1(Identifiers.ProviderRuleId.t_encode, v);
}

function response_decode$2(v) {
  return Curry._1(Identifiers.ProviderRuleId.t_decode, v);
}

function config$2(body) {
  return {
          kyInstance: kyInstance$3,
          path: "",
          requestOptions: {
            method: "GET",
            json: argument_encode(body)
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, t_decode), (function (decodedError) {
                              if (decodedError.TAG === "Ok") {
                                return {
                                        TAG: "Custom",
                                        _0: decodedError._0
                                      };
                              } else {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Create = {
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  config: config$2
};

function argument_encode$1(v) {
  return Curry._1(Identifiers.ProviderRuleId.t_encode, v);
}

function argument_decode$1(v) {
  return Curry._1(Identifiers.ProviderRuleId.t_decode, v);
}

var response_encode$3 = Spice.unitToJson;

var response_decode$3 = Spice.unitFromJson;

function config$3(ruleId) {
  return {
          kyInstance: kyInstance$3,
          path: ruleId,
          requestOptions: {
            method: "DELETE"
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, t_decode), (function (decodedError) {
                              if (decodedError.TAG === "Ok") {
                                return {
                                        TAG: "Custom",
                                        _0: decodedError._0
                                      };
                              } else {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Delete = {
  argument_encode: argument_encode$1,
  argument_decode: argument_decode$1,
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  config: config$3
};

function body_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "definition",
                    false,
                    Spice.stringToJson(v.definition)
                  ]
                ]));
}

function body_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
  if (title.TAG === "Ok") {
    var definition = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "definition"), null));
    if (definition.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                title: title._0,
                definition: definition._0
              }
            };
    }
    var e = definition._0;
    return {
            TAG: "Error",
            _0: {
              path: ".definition" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: "Error",
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function argument_encode$2(v) {
  return [
          Curry._1(Identifiers.ProviderRuleId.t_encode, v[0]),
          body_encode$1(v[1])
        ];
}

function argument_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.ProviderRuleId.t_decode, v0);
  var match$1 = body_decode$1(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$4 = Spice.unitToJson;

var response_decode$4 = Spice.unitFromJson;

function config$4(param) {
  return {
          kyInstance: kyInstance$3,
          path: param[0],
          requestOptions: {
            method: "PUT",
            json: body_encode$1(param[1])
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, t_decode), (function (decodedError) {
                              if (decodedError.TAG === "Ok") {
                                return {
                                        TAG: "Custom",
                                        _0: decodedError._0
                                      };
                              } else {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Update$1 = {
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  argument_encode: argument_encode$2,
  argument_decode: argument_decode$2,
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  config: config$4
};

var argument_encode$3 = Spice.unitToJson;

var argument_decode$3 = Spice.unitFromJson;

function rule_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ProviderRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "isActive",
                    false,
                    Spice.boolToJson(v.isActive)
                  ],
                  [
                    "lastModification",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.lastModification)
                  ]
                ]));
}

function rule_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
  if (title.TAG === "Ok") {
    var ruleId = Curry._1(Identifiers.ProviderRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
    if (ruleId.TAG === "Ok") {
      var isActive = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isActive"), null));
      if (isActive.TAG === "Ok") {
        var lastModification = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "lastModification"), null));
        if (lastModification.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    title: title._0,
                    ruleId: ruleId._0,
                    isActive: isActive._0,
                    lastModification: lastModification._0
                  }
                };
        }
        var e = lastModification._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".lastModification" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = isActive._0;
      return {
              TAG: "Error",
              _0: {
                path: ".isActive" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = ruleId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = title._0;
  return {
          TAG: "Error",
          _0: {
            path: ".title" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$5(v) {
  return Spice.arrayToJson(rule_encode, v);
}

function response_decode$5(v) {
  return Spice.arrayFromJson(rule_decode, v);
}

function config$5(param) {
  return {
          kyInstance: kyInstance$3,
          path: "",
          requestOptions: {
            method: "GET"
          },
          key: ["GetAllRules"]
        };
}

var GetAll$1 = {
  argument_encode: argument_encode$3,
  argument_decode: argument_decode$3,
  rule_encode: rule_encode,
  rule_decode: rule_decode,
  response_encode: response_encode$5,
  response_decode: response_decode$5,
  config: config$5
};

function activation_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "providerId",
                    false,
                    Curry._1(Identifiers.ProviderId.t_encode, v.providerId)
                  ],
                  [
                    "providerName",
                    false,
                    Spice.stringToJson(v.providerName)
                  ],
                  [
                    "period",
                    false,
                    ColiswebApi__V6_Utils.Period.t_encode(v.period)
                  ]
                ]));
}

function activation_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var providerId = Curry._1(Identifiers.ProviderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "providerId"), null));
  if (providerId.TAG === "Ok") {
    var providerName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "providerName"), null));
    if (providerName.TAG === "Ok") {
      var period = ColiswebApi__V6_Utils.Period.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "period"), null));
      if (period.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  providerId: providerId._0,
                  providerName: providerName._0,
                  period: period._0
                }
              };
      }
      var e = period._0;
      return {
              TAG: "Error",
              _0: {
                path: ".period" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = providerName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".providerName" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = providerId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".providerId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "definition",
                    false,
                    Spice.stringToJson(v.definition)
                  ],
                  [
                    "activations",
                    false,
                    Spice.arrayToJson(activation_encode$3, v.activations)
                  ],
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ProviderRuleId.t_encode, v.ruleId)
                  ]
                ]));
}

function response_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
  if (title.TAG === "Ok") {
    var definition = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "definition"), null));
    if (definition.TAG === "Ok") {
      var activations = Spice.arrayFromJson(activation_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "activations"), null));
      if (activations.TAG === "Ok") {
        var ruleId = Curry._1(Identifiers.ProviderRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
        if (ruleId.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    title: title._0,
                    definition: definition._0,
                    activations: activations._0,
                    ruleId: ruleId._0
                  }
                };
        }
        var e = ruleId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".ruleId" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = activations._0;
      return {
              TAG: "Error",
              _0: {
                path: ".activations" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = definition._0;
    return {
            TAG: "Error",
            _0: {
              path: ".definition" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = title._0;
  return {
          TAG: "Error",
          _0: {
            path: ".title" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function config$6(ruleId) {
  return {
          kyInstance: kyInstance$3,
          path: ruleId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "AdminPriceGridsDetailsFetcher",
            ruleId
          ]
        };
}

var GetDetails = {
  response_encode: response_encode$6,
  response_decode: response_decode$6,
  activation_encode: activation_encode$3,
  activation_decode: activation_decode$3,
  config: config$6
};

var Rules$1 = {
  kyInstance: kyInstance$3,
  Create: Create,
  Delete: Delete,
  Update: Update$1,
  GetAll: GetAll$1,
  GetDetails: GetDetails
};

function argument_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "definition",
                    false,
                    Spice.stringToJson(v.definition)
                  ]]));
}

function argument_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var definition = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "definition"), null));
  if (definition.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              definition: definition._0
            }
          };
  }
  var e = definition._0;
  return {
          TAG: "Error",
          _0: {
            path: ".definition" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "compile",
                    false,
                    Spice.boolToJson(v.compile)
                  ],
                  [
                    "error",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.error)
                  ]
                ]));
}

function response_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var compile = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "compile"), null));
  if (compile.TAG === "Ok") {
    var error = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "error"), null));
    if (error.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                compile: compile._0,
                error: error._0
              }
            };
    }
    var e = error._0;
    return {
            TAG: "Error",
            _0: {
              path: ".error" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = compile._0;
  return {
          TAG: "Error",
          _0: {
            path: ".compile" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$7(body) {
  return {
          kyInstance: kyInstance,
          path: "testRule",
          requestOptions: {
            method: "POST",
            json: argument_encode$4(body)
          }
        };
}

var TestPriceDefinition = {
  argument_encode: argument_encode$4,
  argument_decode: argument_decode$4,
  response_encode: response_encode$7,
  response_decode: response_decode$7,
  config: config$7
};

var kyInstance$4 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/carriers"
    });

function user_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.UserUuid.t_encode, v.uuid)
                  ],
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.stringToJson(v.phoneNumber)
                  ]
                ]));
}

function user_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var uuid = Curry._1(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
  if (uuid.TAG === "Ok") {
    var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
    if (username.TAG === "Ok") {
      var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var phoneNumber = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
        if (phoneNumber.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    uuid: uuid._0,
                    username: username._0,
                    email: email._0,
                    phoneNumber: phoneNumber._0
                  }
                };
        }
        var e = phoneNumber._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phoneNumber" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = username._0;
    return {
            TAG: "Error",
            _0: {
              path: ".username" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = uuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".uuid" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function notifications_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "sms",
                    false,
                    Spice.boolToJson(v.sms)
                  ],
                  [
                    "email",
                    false,
                    Spice.boolToJson(v.email)
                  ]
                ]));
}

function notifications_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var sms = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "sms"), null));
  if (sms.TAG === "Ok") {
    var email = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
    if (email.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                sms: sms._0,
                email: email._0
              }
            };
    }
    var e = email._0;
    return {
            TAG: "Error",
            _0: {
              path: ".email" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = sms._0;
  return {
          TAG: "Error",
          _0: {
            path: ".sms" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "employeeId",
                    false,
                    Curry._1(Identifiers.EmployeeId.t_encode, v.employeeId)
                  ],
                  [
                    "transporterName",
                    false,
                    Spice.stringToJson(v.transporterName)
                  ],
                  [
                    "user",
                    false,
                    user_encode(v.user)
                  ],
                  [
                    "provider",
                    false,
                    Curry._1(Identifiers.ProviderId.t_encode, v.provider)
                  ],
                  [
                    "notifications",
                    false,
                    notifications_encode(v.notifications)
                  ],
                  [
                    "carrierNumber",
                    false,
                    Spice.intToJson(v.carrierNumber)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "isDeleted",
                    false,
                    Spice.boolToJson(v.isDeleted)
                  ]
                ]));
}

function response_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var employeeId = Curry._1(Identifiers.EmployeeId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "employeeId"), null));
  if (employeeId.TAG === "Ok") {
    var transporterName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "transporterName"), null));
    if (transporterName.TAG === "Ok") {
      var user = user_decode(Belt_Option.getWithDefault(Js_dict.get(v, "user"), null));
      if (user.TAG === "Ok") {
        var provider = Curry._1(Identifiers.ProviderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "provider"), null));
        if (provider.TAG === "Ok") {
          var notifications = notifications_decode(Belt_Option.getWithDefault(Js_dict.get(v, "notifications"), null));
          if (notifications.TAG === "Ok") {
            var carrierNumber = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "carrierNumber"), null));
            if (carrierNumber.TAG === "Ok") {
              var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
              if (createdAt.TAG === "Ok") {
                var isDeleted = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isDeleted"), null));
                if (isDeleted.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            employeeId: employeeId._0,
                            transporterName: transporterName._0,
                            user: user._0,
                            provider: provider._0,
                            notifications: notifications._0,
                            carrierNumber: carrierNumber._0,
                            createdAt: createdAt._0,
                            isDeleted: isDeleted._0
                          }
                        };
                }
                var e = isDeleted._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".isDeleted" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = createdAt._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".createdAt" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = carrierNumber._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".carrierNumber" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = notifications._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".notifications" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = provider._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".provider" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = user._0;
      return {
              TAG: "Error",
              _0: {
                path: ".user" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = transporterName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".transporterName" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = employeeId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".employeeId" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function error_encode(v) {
  return "CARRIER_NOT_FOUND";
}

function error_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("CARRIER_NOT_FOUND" === v) {
          return {
                  TAG: "Ok",
                  _0: "CARRIER_NOT_FOUND"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config$8(carrierId) {
  return {
          kyInstance: kyInstance$4,
          path: carrierId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "ProviderGetCarrier",
            carrierId
          ],
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode, param);
            })
        };
}

var Get = {
  user_encode: user_encode,
  user_decode: user_decode,
  notifications_encode: notifications_encode,
  notifications_decode: notifications_decode,
  response_encode: response_encode$8,
  response_decode: response_decode$8,
  error_encode: error_encode,
  error_decode: error_decode,
  config: config$8
};

function sort_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "authenticatedAt",
                    false,
                    ColiswebApi__Utils.SortOrder.t_encode(v.authenticatedAt)
                  ]]));
}

function sort_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var authenticatedAt = ColiswebApi__Utils.SortOrder.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "authenticatedAt"), null));
  if (authenticatedAt.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              authenticatedAt: authenticatedAt._0
            }
          };
  }
  var e = authenticatedAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".authenticatedAt" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function pagination_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function pagination_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagination",
                    false,
                    pagination_encode(v.pagination)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode(v.sort)
                  ]
                ]));
}

function body_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagination = pagination_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
  if (pagination.TAG === "Ok") {
    var sort = sort_decode(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagination: pagination._0,
                sort: sort._0
              }
            };
    }
    var e = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagination._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagination" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function devicePermissions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "foregroundLocationEnabled",
                    false,
                    Spice.boolToJson(v.foregroundLocationEnabled)
                  ],
                  [
                    "backgroundLocationEnabled",
                    false,
                    Spice.boolToJson(v.backgroundLocationEnabled)
                  ],
                  [
                    "physicalActivityRecognitionEnabled",
                    false,
                    Spice.boolToJson(v.physicalActivityRecognitionEnabled)
                  ]
                ]));
}

function devicePermissions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var foregroundLocationEnabled = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "foregroundLocationEnabled"), null));
  if (foregroundLocationEnabled.TAG === "Ok") {
    var backgroundLocationEnabled = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "backgroundLocationEnabled"), null));
    if (backgroundLocationEnabled.TAG === "Ok") {
      var physicalActivityRecognitionEnabled = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "physicalActivityRecognitionEnabled"), null));
      if (physicalActivityRecognitionEnabled.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  foregroundLocationEnabled: foregroundLocationEnabled._0,
                  backgroundLocationEnabled: backgroundLocationEnabled._0,
                  physicalActivityRecognitionEnabled: physicalActivityRecognitionEnabled._0
                }
              };
      }
      var e = physicalActivityRecognitionEnabled._0;
      return {
              TAG: "Error",
              _0: {
                path: ".physicalActivityRecognitionEnabled" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = backgroundLocationEnabled._0;
    return {
            TAG: "Error",
            _0: {
              path: ".backgroundLocationEnabled" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = foregroundLocationEnabled._0;
  return {
          TAG: "Error",
          _0: {
            path: ".foregroundLocationEnabled" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function deviceInformations_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "firstConnectionAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.firstConnectionAt)
                  ],
                  [
                    "lastConnectionAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.lastConnectionAt)
                  ],
                  [
                    "os",
                    false,
                    Spice.stringToJson(v.os)
                  ],
                  [
                    "osVersion",
                    false,
                    Spice.stringToJson(v.osVersion)
                  ],
                  [
                    "appVersion",
                    false,
                    Spice.stringToJson(v.appVersion)
                  ],
                  [
                    "deviceId",
                    false,
                    Spice.stringToJson(v.deviceId)
                  ],
                  [
                    "deviceBrand",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.deviceBrand)
                  ],
                  [
                    "permissions",
                    false,
                    devicePermissions_encode(v.permissions)
                  ]
                ]));
}

function deviceInformations_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var firstConnectionAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "firstConnectionAt"), null));
  if (firstConnectionAt.TAG === "Ok") {
    var lastConnectionAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "lastConnectionAt"), null));
    if (lastConnectionAt.TAG === "Ok") {
      var os = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "os"), null));
      if (os.TAG === "Ok") {
        var osVersion = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "osVersion"), null));
        if (osVersion.TAG === "Ok") {
          var appVersion = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "appVersion"), null));
          if (appVersion.TAG === "Ok") {
            var deviceId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deviceId"), null));
            if (deviceId.TAG === "Ok") {
              var deviceBrand = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "deviceBrand"), null));
              if (deviceBrand.TAG === "Ok") {
                var permissions = devicePermissions_decode(Belt_Option.getWithDefault(Js_dict.get(v, "permissions"), null));
                if (permissions.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            firstConnectionAt: firstConnectionAt._0,
                            lastConnectionAt: lastConnectionAt._0,
                            os: os._0,
                            osVersion: osVersion._0,
                            appVersion: appVersion._0,
                            deviceId: deviceId._0,
                            deviceBrand: deviceBrand._0,
                            permissions: permissions._0
                          }
                        };
                }
                var e = permissions._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".permissions" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = deviceBrand._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".deviceBrand" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = deviceId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".deviceId" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = appVersion._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".appVersion" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = osVersion._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".osVersion" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = os._0;
      return {
              TAG: "Error",
              _0: {
                path: ".os" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = lastConnectionAt._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lastConnectionAt" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = firstConnectionAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".firstConnectionAt" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function response_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "mobileInformation",
                    false,
                    Spice.arrayToJson(deviceInformations_encode, v.mobileInformation)
                  ]
                ]));
}

function response_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var mobileInformation = Spice.arrayFromJson(deviceInformations_decode, Belt_Option.getWithDefault(Js_dict.get(v, "mobileInformation"), null));
    if (mobileInformation.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagesCount: pagesCount._0,
                mobileInformation: mobileInformation._0
              }
            };
    }
    var e = mobileInformation._0;
    return {
            TAG: "Error",
            _0: {
              path: ".mobileInformation" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$9(param) {
  var body = param[1];
  var providerCarrierId = param[0];
  return {
          kyInstance: kyInstance$4,
          path: Curry._1(Identifiers.ProviderCarrierId.toString, providerCarrierId) + "/mobileInformation/history",
          requestOptions: {
            method: "POST",
            json: body_encode$2(body)
          },
          key: [
            "GetCarrierMobileInformationHistory",
            Curry._1(Identifiers.ProviderCarrierId.toString, providerCarrierId),
            JSON.stringify(body_encode$2(body))
          ]
        };
}

var GetMobileInformationHistory = {
  sort_encode: sort_encode,
  sort_decode: sort_decode,
  pagination_encode: pagination_encode,
  pagination_decode: pagination_decode,
  body_encode: body_encode$2,
  body_decode: body_decode$2,
  devicePermissions_encode: devicePermissions_encode,
  devicePermissions_decode: devicePermissions_decode,
  deviceInformations_encode: deviceInformations_encode,
  deviceInformations_decode: deviceInformations_decode,
  response_encode: response_encode$9,
  response_decode: response_decode$9,
  config: config$9
};

function requestParams_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "carrierIds",
                    false,
                    Spice.arrayToJson(Identifiers.ProviderCarrierId.t_encode, v.carrierIds)
                  ]]));
}

function requestParams_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var carrierIds = Spice.arrayFromJson(Identifiers.ProviderCarrierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carrierIds"), null));
  if (carrierIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              carrierIds: carrierIds._0
            }
          };
  }
  var e = carrierIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".carrierIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function provider_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ProviderId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function provider_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ProviderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function carrier_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ProviderCarrierId.t_encode, v.id)
                  ],
                  [
                    "carrierNumber",
                    false,
                    Spice.intToJson(v.carrierNumber)
                  ],
                  [
                    "provider",
                    false,
                    provider_encode(v.provider)
                  ],
                  [
                    "isDeleted",
                    false,
                    Spice.boolToJson(v.isDeleted)
                  ]
                ]));
}

function carrier_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ProviderCarrierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var carrierNumber = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "carrierNumber"), null));
    if (carrierNumber.TAG === "Ok") {
      var provider = provider_decode(Belt_Option.getWithDefault(Js_dict.get(v, "provider"), null));
      if (provider.TAG === "Ok") {
        var isDeleted = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isDeleted"), null));
        if (isDeleted.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    carrierNumber: carrierNumber._0,
                    provider: provider._0,
                    isDeleted: isDeleted._0
                  }
                };
        }
        var e = isDeleted._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".isDeleted" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = provider._0;
      return {
              TAG: "Error",
              _0: {
                path: ".provider" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = carrierNumber._0;
    return {
            TAG: "Error",
            _0: {
              path: ".carrierNumber" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "carriers",
                    false,
                    Spice.arrayToJson(carrier_encode, v.carriers)
                  ]]));
}

function response_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var carriers = Spice.arrayFromJson(carrier_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carriers"), null));
  if (carriers.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              carriers: carriers._0
            }
          };
  }
  var e = carriers._0;
  return {
          TAG: "Error",
          _0: {
            path: ".carriers" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$10(carrierIds) {
  return {
          kyInstance: kyInstance$4,
          path: "",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(requestParams_encode({
                      carrierIds: carrierIds
                    }), {
                  arrayFormat: "comma"
                })
          },
          key: Curry._2(Toolkit__Primitives_Array.$$Array.concat, ["GetAllByCarrierIds"], Curry._2(Toolkit__Primitives_Array.$$Array.map, carrierIds, Identifiers.ProviderCarrierId.toString))
        };
}

var GetAllByCarrierIds = {
  requestParams_encode: requestParams_encode,
  requestParams_decode: requestParams_decode,
  provider_encode: provider_encode,
  provider_decode: provider_decode,
  carrier_encode: carrier_encode,
  carrier_decode: carrier_decode,
  response_encode: response_encode$10,
  response_decode: response_decode$10,
  config: config$10
};

var Carriers = {
  kyInstance: kyInstance$4,
  Get: Get,
  GetMobileInformationHistory: GetMobileInformationHistory,
  GetAllByCarrierIds: GetAllByCarrierIds
};

var kyInstance$5 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/providers"
    });

function body_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "inactiveOnly",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.boolToJson, v.inactiveOnly)
                  ],
                  [
                    "nameFilter",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.nameFilter)
                  ],
                  [
                    "nameOrder",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.nameOrder)
                  ],
                  [
                    "page",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.pageSize)
                  ]
                ]));
}

function body_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var inactiveOnly = Toolkit__Decoders.$$Option.t_decode(Spice.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "inactiveOnly"), null));
  if (inactiveOnly.TAG === "Ok") {
    var nameFilter = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "nameFilter"), null));
    if (nameFilter.TAG === "Ok") {
      var nameOrder = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "nameOrder"), null));
      if (nameOrder.TAG === "Ok") {
        var page = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
        if (page.TAG === "Ok") {
          var pageSize = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
          if (pageSize.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      inactiveOnly: inactiveOnly._0,
                      nameFilter: nameFilter._0,
                      nameOrder: nameOrder._0,
                      page: page._0,
                      pageSize: pageSize._0
                    }
                  };
          }
          var e = pageSize._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".pageSize" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = page._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".page" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = nameOrder._0;
      return {
              TAG: "Error",
              _0: {
                path: ".nameOrder" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = nameFilter._0;
    return {
            TAG: "Error",
            _0: {
              path: ".nameFilter" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = inactiveOnly._0;
  return {
          TAG: "Error",
          _0: {
            path: ".inactiveOnly" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

var argument_encode$5 = body_encode$3;

var argument_decode$5 = body_decode$3;

function rule_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ProviderRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "ruleTitle",
                    false,
                    Spice.stringToJson(v.ruleTitle)
                  ]
                ]));
}

function rule_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ruleId = Curry._1(Identifiers.ProviderRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
  if (ruleId.TAG === "Ok") {
    var ruleTitle = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ruleTitle"), null));
    if (ruleTitle.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                ruleId: ruleId._0,
                ruleTitle: ruleTitle._0
              }
            };
    }
    var e = ruleTitle._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleTitle" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = ruleId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ruleId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function provider_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ProviderId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ],
                  [
                    "rule",
                    false,
                    Spice.optionToJson(rule_encode$1, v.rule)
                  ]
                ]));
}

function provider_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ProviderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
      if (updatedAt.TAG === "Ok") {
        var rule = Spice.optionFromJson(rule_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "rule"), null));
        if (rule.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    name: name._0,
                    updatedAt: updatedAt._0,
                    rule: rule._0
                  }
                };
        }
        var e = rule._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".rule" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = updatedAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".updatedAt" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "providers",
                    false,
                    Spice.arrayToJson(provider_encode$1, v.providers)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "providersCount",
                    false,
                    Spice.intToJson(v.providersCount)
                  ]
                ]));
}

function response_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var providers = Spice.arrayFromJson(provider_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "providers"), null));
  if (providers.TAG === "Ok") {
    var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
    if (pagesCount.TAG === "Ok") {
      var providersCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "providersCount"), null));
      if (providersCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  providers: providers._0,
                  pagesCount: pagesCount._0,
                  providersCount: providersCount._0
                }
              };
      }
      var e = providersCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".providersCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = pagesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pagesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = providers._0;
  return {
          TAG: "Error",
          _0: {
            path: ".providers" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function config$11(body) {
  return {
          kyInstance: kyInstance$5,
          path: "",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(body_encode$3(body))
          },
          key: [
            "AdminGetPaginatedTransportersWithRuleFetcher",
            JSON.stringify(body_encode$3(body))
          ]
        };
}

var GetPaginatedList = {
  body_encode: body_encode$3,
  body_decode: body_decode$3,
  argument_encode: argument_encode$5,
  argument_decode: argument_decode$5,
  rule_encode: rule_encode$1,
  rule_decode: rule_decode$1,
  provider_encode: provider_encode$1,
  provider_decode: provider_decode$1,
  response_encode: response_encode$11,
  response_decode: response_decode$11,
  config: config$11
};

function filters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "providerIds",
                    false,
                    Toolkit__Decoders.StringArray.t_encode(Identifiers.TransporterId.t_encode, v.providerIds)
                  ]]));
}

function filters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var providerIds = Toolkit__Decoders.StringArray.t_decode(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "providerIds"), null));
  if (providerIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              providerIds: providerIds._0
            }
          };
  }
  var e = providerIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".providerIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function provider_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ProviderId.t_encode, v.id)
                  ]
                ]));
}

function provider_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var id = Curry._1(Identifiers.ProviderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
    if (id.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                name: name._0,
                id: id._0
              }
            };
    }
    var e = id._0;
    return {
            TAG: "Error",
            _0: {
              path: ".id" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$12(v) {
  return Spice.arrayToJson(provider_encode$2, v);
}

function response_decode$12(v) {
  return Spice.arrayFromJson(provider_decode$2, v);
}

function config$12(filters) {
  return {
          kyInstance: kyInstance$5,
          path: "all",
          requestOptions: {
            method: "GET",
            searchParams: Belt_Option.map(filters, (function (filters) {
                    return Qs.stringify(filters_encode(filters));
                  }))
          },
          key: [
            "transporterList",
            Belt_Option.mapWithDefault(filters, "", (function (filters) {
                    return JSON.stringify(filters_encode(filters));
                  }))
          ]
        };
}

var GetAll$2 = {
  filters_encode: filters_encode,
  filters_decode: filters_decode,
  provider_encode: provider_encode$2,
  provider_decode: provider_decode$2,
  response_encode: response_encode$12,
  response_decode: response_decode$12,
  config: config$12
};

function response_encode$13(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ProviderId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "canBeAssignedAutomatically",
                    false,
                    Spice.boolToJson(v.canBeAssignedAutomatically)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ]
                ]));
}

function response_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ProviderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var canBeAssignedAutomatically = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canBeAssignedAutomatically"), null));
      if (canBeAssignedAutomatically.TAG === "Ok") {
        var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
        if (createdAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    name: name._0,
                    canBeAssignedAutomatically: canBeAssignedAutomatically._0,
                    createdAt: createdAt._0
                  }
                };
        }
        var e = createdAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".createdAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = canBeAssignedAutomatically._0;
      return {
              TAG: "Error",
              _0: {
                path: ".canBeAssignedAutomatically" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function config$13(providerId) {
  return {
          kyInstance: kyInstance$5,
          path: Curry._1(Identifiers.ProviderId.toString, providerId),
          requestOptions: {
            method: "GET"
          },
          key: [
            "GetProviderDetails",
            Curry._1(Identifiers.ProviderId.toString, providerId)
          ]
        };
}

var GetDetails$1 = {
  response_encode: response_encode$13,
  response_decode: response_decode$13,
  config: config$13
};

function notifications_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Spice.boolToJson(v.email)
                  ],
                  [
                    "sms",
                    false,
                    Spice.boolToJson(v.sms)
                  ]
                ]));
}

function notifications_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    var sms = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "sms"), null));
    if (sms.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                email: email._0,
                sms: sms._0
              }
            };
    }
    var e = sms._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sms" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function contact_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ContactIdInt.t_encode, v.id)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phoneNumber)
                  ],
                  [
                    "notifications",
                    false,
                    notifications_encode$1(v.notifications)
                  ]
                ]));
}

function contact_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ContactIdInt.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
    if (email.TAG === "Ok") {
      var phoneNumber = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
      if (phoneNumber.TAG === "Ok") {
        var notifications = notifications_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "notifications"), null));
        if (notifications.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    email: email._0,
                    phoneNumber: phoneNumber._0,
                    notifications: notifications._0
                  }
                };
        }
        var e = notifications._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".notifications" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = phoneNumber._0;
      return {
              TAG: "Error",
              _0: {
                path: ".phoneNumber" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = email._0;
    return {
            TAG: "Error",
            _0: {
              path: ".email" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$14(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "contacts",
                    false,
                    Spice.arrayToJson(contact_encode, v.contacts)
                  ]]));
}

function response_decode$14(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var contacts = Spice.arrayFromJson(contact_decode, Belt_Option.getWithDefault(Js_dict.get(v, "contacts"), null));
  if (contacts.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              contacts: contacts._0
            }
          };
  }
  var e = contacts._0;
  return {
          TAG: "Error",
          _0: {
            path: ".contacts" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$14(providerId) {
  return {
          kyInstance: kyInstance$5,
          path: Curry._1(Identifiers.ProviderId.toString, providerId) + "/contacts",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Provider",
            "GetProviderContacts",
            Curry._1(Identifiers.ProviderId.toString, providerId)
          ]
        };
}

var GetList = {
  notifications_encode: notifications_encode$1,
  notifications_decode: notifications_decode$1,
  contact_encode: contact_encode,
  contact_decode: contact_decode,
  response_encode: response_encode$14,
  response_decode: response_decode$14,
  config: config$14
};

function body_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Spice.boolToJson(v.email)
                  ],
                  [
                    "sms",
                    false,
                    Spice.boolToJson(v.sms)
                  ]
                ]));
}

function body_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    var sms = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "sms"), null));
    if (sms.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                email: email._0,
                sms: sms._0
              }
            };
    }
    var e = sms._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sms" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$15 = Spice.unitToJson;

var response_decode$15 = Spice.unitFromJson;

function config$15(param) {
  return {
          kyInstance: kyInstance$5,
          path: Curry._1(Identifiers.ProviderId.toString, param[0]) + "/contacts/" + Curry._1(Identifiers.ContactIdInt.toString, param[1]) + "/notifications",
          requestOptions: {
            method: "PUT",
            json: body_encode$4(param[2])
          }
        };
}

var UpdateNotificationsParameters = {
  body_encode: body_encode$4,
  body_decode: body_decode$4,
  response_encode: response_encode$15,
  response_decode: response_decode$15,
  config: config$15
};

var Contacts = {
  GetList: GetList,
  UpdateNotificationsParameters: UpdateNotificationsParameters
};

function account_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ProviderAccountId.t_encode, v.id)
                  ],
                  [
                    "firstName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lastName)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ]
                ]));
}

function account_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ProviderAccountId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var firstName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
    if (firstName.TAG === "Ok") {
      var lastName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
      if (lastName.TAG === "Ok") {
        var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
        if (email.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    firstName: firstName._0,
                    lastName: lastName._0,
                    email: email._0
                  }
                };
        }
        var e = email._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".email" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = lastName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lastName" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = firstName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".firstName" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function user_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phoneNumber)
                  ],
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.UserUuid.t_encode, v.uuid)
                  ]
                ]));
}

function user_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    var phoneNumber = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
    if (phoneNumber.TAG === "Ok") {
      var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
      if (username.TAG === "Ok") {
        var uuid = Curry._1(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
        if (uuid.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    email: email._0,
                    phoneNumber: phoneNumber._0,
                    username: username._0,
                    uuid: uuid._0
                  }
                };
        }
        var e = uuid._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".uuid" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = username._0;
      return {
              TAG: "Error",
              _0: {
                path: ".username" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = phoneNumber._0;
    return {
            TAG: "Error",
            _0: {
              path: ".phoneNumber" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$16(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "employeeId",
                    false,
                    Curry._1(Identifiers.ProviderCarrierId.t_encode, v.employeeId)
                  ],
                  [
                    "account",
                    false,
                    Spice.optionToJson(account_encode, v.account)
                  ],
                  [
                    "user",
                    false,
                    Spice.optionToJson(user_encode$1, v.user)
                  ],
                  [
                    "carrierNumber",
                    false,
                    Spice.intToJson(v.carrierNumber)
                  ]
                ]));
}

function response_decode$16(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var employeeId = Curry._1(Identifiers.ProviderCarrierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "employeeId"), null));
  if (employeeId.TAG === "Ok") {
    var account = Spice.optionFromJson(account_decode, Belt_Option.getWithDefault(Js_dict.get(v, "account"), null));
    if (account.TAG === "Ok") {
      var user = Spice.optionFromJson(user_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "user"), null));
      if (user.TAG === "Ok") {
        var carrierNumber = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "carrierNumber"), null));
        if (carrierNumber.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    employeeId: employeeId._0,
                    account: account._0,
                    user: user._0,
                    carrierNumber: carrierNumber._0
                  }
                };
        }
        var e = carrierNumber._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".carrierNumber" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = user._0;
      return {
              TAG: "Error",
              _0: {
                path: ".user" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = account._0;
    return {
            TAG: "Error",
            _0: {
              path: ".account" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = employeeId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".employeeId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function config$16(param) {
  var carrierId = param[1];
  var providerId = param[0];
  return {
          kyInstance: kyInstance$5,
          path: Curry._1(Identifiers.ProviderId.toString, providerId) + "/carriers/" + Curry._1(Identifiers.ProviderCarrierId.toString, carrierId),
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Provider",
            "CarriersGetDetails",
            Curry._1(Identifiers.ProviderId.toString, providerId),
            Curry._1(Identifiers.ProviderCarrierId.toString, carrierId)
          ]
        };
}

var GetDetail = {
  account_encode: account_encode,
  account_decode: account_decode,
  user_encode: user_encode$1,
  user_decode: user_decode$1,
  response_encode: response_encode$16,
  response_decode: response_decode$16,
  config: config$16
};

function notifications_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "sms",
                    false,
                    Spice.boolToJson(v.sms)
                  ]]));
}

function notifications_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var sms = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "sms"), null));
  if (sms.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              sms: sms._0
            }
          };
  }
  var e = sms._0;
  return {
          TAG: "Error",
          _0: {
            path: ".sms" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function carrier_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "userUuid",
                    false,
                    Curry._1(Identifiers.UserUuid.t_encode, v.userUuid)
                  ],
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ProviderCarrierId.t_encode, v.id)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phoneNumber)
                  ],
                  [
                    "login",
                    false,
                    Spice.stringToJson(v.login)
                  ],
                  [
                    "carrierNumber",
                    false,
                    Spice.intToJson(v.carrierNumber)
                  ],
                  [
                    "notifications",
                    false,
                    notifications_encode$2(v.notifications)
                  ]
                ]));
}

function carrier_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var userUuid = Curry._1(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "userUuid"), null));
  if (userUuid.TAG === "Ok") {
    var id = Curry._1(Identifiers.ProviderCarrierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
    if (id.TAG === "Ok") {
      var phoneNumber = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
      if (phoneNumber.TAG === "Ok") {
        var login = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "login"), null));
        if (login.TAG === "Ok") {
          var carrierNumber = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "carrierNumber"), null));
          if (carrierNumber.TAG === "Ok") {
            var notifications = notifications_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "notifications"), null));
            if (notifications.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        userUuid: userUuid._0,
                        id: id._0,
                        phoneNumber: phoneNumber._0,
                        login: login._0,
                        carrierNumber: carrierNumber._0,
                        notifications: notifications._0
                      }
                    };
            }
            var e = notifications._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".notifications" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = carrierNumber._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".carrierNumber" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = login._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".login" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = phoneNumber._0;
      return {
              TAG: "Error",
              _0: {
                path: ".phoneNumber" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = id._0;
    return {
            TAG: "Error",
            _0: {
              path: ".id" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = userUuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".userUuid" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function response_encode$17(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "carriers",
                    false,
                    Spice.arrayToJson(carrier_encode$1, v.carriers)
                  ]]));
}

function response_decode$17(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var carriers = Spice.arrayFromJson(carrier_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "carriers"), null));
  if (carriers.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              carriers: carriers._0
            }
          };
  }
  var e = carriers._0;
  return {
          TAG: "Error",
          _0: {
            path: ".carriers" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$17(providerId) {
  return {
          kyInstance: kyInstance$5,
          path: Curry._1(Identifiers.ProviderId.toString, providerId) + "/carriers",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Provider",
            "GetProviderCarriersList",
            Curry._1(Identifiers.ProviderId.toString, providerId)
          ]
        };
}

var GetList$1 = {
  notifications_encode: notifications_encode$2,
  notifications_decode: notifications_decode$2,
  carrier_encode: carrier_encode$1,
  carrier_decode: carrier_decode$1,
  response_encode: response_encode$17,
  response_decode: response_decode$17,
  config: config$17
};

function body_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "sms",
                    false,
                    Spice.boolToJson(v.sms)
                  ]]));
}

function body_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var sms = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "sms"), null));
  if (sms.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              sms: sms._0
            }
          };
  }
  var e = sms._0;
  return {
          TAG: "Error",
          _0: {
            path: ".sms" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$18 = Spice.unitToJson;

var response_decode$18 = Spice.unitFromJson;

function config$18(param) {
  return {
          kyInstance: kyInstance$5,
          path: Curry._1(Identifiers.ProviderId.toString, param[0]) + "/carriers/" + Curry._1(Identifiers.ProviderCarrierId.toString, param[1]) + "/notifications",
          requestOptions: {
            method: "PUT",
            json: body_encode$5(param[2])
          }
        };
}

var UpdateNotificationsParameters$1 = {
  body_encode: body_encode$5,
  body_decode: body_decode$5,
  response_encode: response_encode$18,
  response_decode: response_decode$18,
  config: config$18
};

function planning_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "monday",
                    false,
                    Spice.boolToJson(v.monday)
                  ],
                  [
                    "tuesday",
                    false,
                    Spice.boolToJson(v.tuesday)
                  ],
                  [
                    "wednesday",
                    false,
                    Spice.boolToJson(v.wednesday)
                  ],
                  [
                    "thursday",
                    false,
                    Spice.boolToJson(v.thursday)
                  ],
                  [
                    "friday",
                    false,
                    Spice.boolToJson(v.friday)
                  ],
                  [
                    "saturday",
                    false,
                    Spice.boolToJson(v.saturday)
                  ],
                  [
                    "sunday",
                    false,
                    Spice.boolToJson(v.sunday)
                  ]
                ]));
}

function planning_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var monday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "monday"), null));
  if (monday.TAG === "Ok") {
    var tuesday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tuesday"), null));
    if (tuesday.TAG === "Ok") {
      var wednesday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "wednesday"), null));
      if (wednesday.TAG === "Ok") {
        var thursday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "thursday"), null));
        if (thursday.TAG === "Ok") {
          var friday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "friday"), null));
          if (friday.TAG === "Ok") {
            var saturday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "saturday"), null));
            if (saturday.TAG === "Ok") {
              var sunday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "sunday"), null));
              if (sunday.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          monday: monday._0,
                          tuesday: tuesday._0,
                          wednesday: wednesday._0,
                          thursday: thursday._0,
                          friday: friday._0,
                          saturday: saturday._0,
                          sunday: sunday._0
                        }
                      };
              }
              var e = sunday._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".sunday" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = saturday._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".saturday" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = friday._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".friday" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = thursday._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".thursday" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = wednesday._0;
      return {
              TAG: "Error",
              _0: {
                path: ".wednesday" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = tuesday._0;
    return {
            TAG: "Error",
            _0: {
              path: ".tuesday" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = monday._0;
  return {
          TAG: "Error",
          _0: {
            path: ".monday" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function carrier_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "employeeId",
                    false,
                    Curry._1(Identifiers.EmployeeId.t_encode, v.employeeId)
                  ],
                  [
                    "planning",
                    false,
                    planning_encode(v.planning)
                  ]
                ]));
}

function carrier_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var employeeId = Curry._1(Identifiers.EmployeeId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "employeeId"), null));
  if (employeeId.TAG === "Ok") {
    var planning = planning_decode(Belt_Option.getWithDefault(Js_dict.get(v, "planning"), null));
    if (planning.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                employeeId: employeeId._0,
                planning: planning._0
              }
            };
    }
    var e = planning._0;
    return {
            TAG: "Error",
            _0: {
              path: ".planning" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = employeeId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".employeeId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$19(v) {
  return Spice.arrayToJson(carrier_encode$2, v);
}

function response_decode$19(v) {
  return Spice.arrayFromJson(carrier_decode$2, v);
}

function config$19(providerId) {
  return {
          kyInstance: kyInstance$5,
          path: Curry._1(Identifiers.ProviderId.toString, providerId) + "/carriers/plannings",
          requestOptions: {
            method: "GET"
          },
          key: [
            "GetCarriersPlanning",
            Curry._1(Identifiers.ProviderId.toString, providerId)
          ]
        };
}

var GetPlannings = {
  planning_encode: planning_encode,
  planning_decode: planning_decode,
  carrier_encode: carrier_encode$2,
  carrier_decode: carrier_decode$2,
  response_encode: response_encode$19,
  response_decode: response_decode$19,
  config: config$19
};

var Carriers$1 = {
  GetDetail: GetDetail,
  GetList: GetList$1,
  UpdateNotificationsParameters: UpdateNotificationsParameters$1,
  GetPlannings: GetPlannings
};

function body_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.end)
                  ]
                ]));
}

function body_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end: end._0
              }
            };
    }
    var e = end._0;
    return {
            TAG: "Error",
            _0: {
              path: ".end" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$20(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "unavailabilities",
                    false,
                    Spice.arrayToJson(Toolkit__Decoders.Datetime.t_encode, v.unavailabilities)
                  ]]));
}

function response_decode$20(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var unavailabilities = Spice.arrayFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "unavailabilities"), null));
  if (unavailabilities.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              unavailabilities: unavailabilities._0
            }
          };
  }
  var e = unavailabilities._0;
  return {
          TAG: "Error",
          _0: {
            path: ".unavailabilities" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$20(param) {
  var body = param[1];
  var providerId = param[0];
  return {
          kyInstance: kyInstance$5,
          path: Curry._1(Identifiers.ProviderId.toString, providerId) + "/unavailability",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(body_encode$6(body))
          },
          key: [
            "GetUnavailability",
            Curry._1(Identifiers.ProviderId.toString, providerId),
            JSON.stringify(body_encode$6(body))
          ]
        };
}

var Get$1 = {
  body_encode: body_encode$6,
  body_decode: body_decode$6,
  response_encode: response_encode$20,
  response_decode: response_decode$20,
  config: config$20
};

function params_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "date",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.date)
                  ]]));
}

function params_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              date: date._0
            }
          };
  }
  var e = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var body_encode$7 = Spice.unitToJson;

var body_decode$7 = Spice.unitFromJson;

var response_encode$21 = Spice.unitToJson;

var response_decode$21 = Spice.unitFromJson;

function config$21(param) {
  return {
          kyInstance: kyInstance$5,
          path: Curry._1(Identifiers.ProviderId.toString, param[0]) + "/unavailability",
          requestOptions: {
            method: "POST",
            searchParams: Qs.stringify(params_encode$1({
                      date: param[1]
                    }))
          }
        };
}

var Create$1 = {
  params_encode: params_encode$1,
  params_decode: params_decode$1,
  body_encode: body_encode$7,
  body_decode: body_decode$7,
  response_encode: response_encode$21,
  response_decode: response_decode$21,
  config: config$21
};

function params_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "date",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.date)
                  ]]));
}

function params_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              date: date._0
            }
          };
  }
  var e = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$22 = Spice.unitToJson;

var response_decode$22 = Spice.unitFromJson;

function config$22(param) {
  return {
          kyInstance: kyInstance$5,
          path: Curry._1(Identifiers.ProviderId.toString, param[0]) + "/unavailability",
          requestOptions: {
            method: "DELETE",
            searchParams: Qs.stringify(params_encode$2({
                      date: param[1]
                    }))
          }
        };
}

var Delete$1 = {
  params_encode: params_encode$2,
  params_decode: params_decode$2,
  response_encode: response_encode$22,
  response_decode: response_decode$22,
  config: config$22
};

var Unavailability = {
  Get: Get$1,
  Create: Create$1,
  Delete: Delete$1
};

function body_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "MONDAY",
                    false,
                    Spice.boolToJson(v.MONDAY)
                  ],
                  [
                    "TUESDAY",
                    false,
                    Spice.boolToJson(v.TUESDAY)
                  ],
                  [
                    "WEDNESDAY",
                    false,
                    Spice.boolToJson(v.WEDNESDAY)
                  ],
                  [
                    "THURSDAY",
                    false,
                    Spice.boolToJson(v.THURSDAY)
                  ],
                  [
                    "FRIDAY",
                    false,
                    Spice.boolToJson(v.FRIDAY)
                  ],
                  [
                    "SATURDAY",
                    false,
                    Spice.boolToJson(v.SATURDAY)
                  ],
                  [
                    "SUNDAY",
                    false,
                    Spice.boolToJson(v.SUNDAY)
                  ]
                ]));
}

function body_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var monday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "MONDAY"), null));
  if (monday.TAG === "Ok") {
    var tuesday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "TUESDAY"), null));
    if (tuesday.TAG === "Ok") {
      var wednesday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "WEDNESDAY"), null));
      if (wednesday.TAG === "Ok") {
        var thursday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "THURSDAY"), null));
        if (thursday.TAG === "Ok") {
          var friday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "FRIDAY"), null));
          if (friday.TAG === "Ok") {
            var saturday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "SATURDAY"), null));
            if (saturday.TAG === "Ok") {
              var sunday = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "SUNDAY"), null));
              if (sunday.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          MONDAY: monday._0,
                          TUESDAY: tuesday._0,
                          WEDNESDAY: wednesday._0,
                          THURSDAY: thursday._0,
                          FRIDAY: friday._0,
                          SATURDAY: saturday._0,
                          SUNDAY: sunday._0
                        }
                      };
              }
              var e = sunday._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: "." + ("SUNDAY" + e.path),
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = saturday._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: "." + ("SATURDAY" + e$1.path),
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = friday._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "." + ("FRIDAY" + e$2.path),
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = thursday._0;
        return {
                TAG: "Error",
                _0: {
                  path: "." + ("THURSDAY" + e$3.path),
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = wednesday._0;
      return {
              TAG: "Error",
              _0: {
                path: "." + ("WEDNESDAY" + e$4.path),
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = tuesday._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("TUESDAY" + e$5.path),
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = monday._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("MONDAY" + e$6.path),
            message: e$6.message,
            value: e$6.value
          }
        };
}

var response_encode$23 = Spice.unitToJson;

var response_decode$23 = Spice.unitFromJson;

function config$23(param) {
  return {
          kyInstance: kyInstance$5,
          path: Curry._1(Identifiers.ProviderId.toString, param[0]) + "/employees/" + Curry._1(Identifiers.EmployeeId.toString, param[1]) + "/planning",
          requestOptions: {
            method: "PUT",
            json: body_encode$8(param[2])
          }
        };
}

var Update$2 = {
  body_encode: body_encode$8,
  body_decode: body_decode$8,
  response_encode: response_encode$23,
  response_decode: response_decode$23,
  config: config$23
};

var Planning = {
  Update: Update$2
};

var Employees = {
  Planning: Planning
};

var Providers = {
  kyInstance: kyInstance$5,
  GetPaginatedList: GetPaginatedList,
  GetAll: GetAll$2,
  GetDetails: GetDetails$1,
  Contacts: Contacts,
  Carriers: Carriers$1,
  Unavailability: Unavailability,
  Employees: Employees
};

var kyInstance$6 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/hubs"
    });

function reports_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "folder",
                    false,
                    Spice.stringToJson(v.folder)
                  ],
                  [
                    "spreadsheetId",
                    false,
                    Spice.stringToJson(v.spreadsheetId)
                  ]
                ]));
}

function reports_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var folder = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "folder"), null));
  if (folder.TAG === "Ok") {
    var spreadsheetId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "spreadsheetId"), null));
    if (spreadsheetId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                folder: folder._0,
                spreadsheetId: spreadsheetId._0
              }
            };
    }
    var e = spreadsheetId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".spreadsheetId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = folder._0;
  return {
          TAG: "Error",
          _0: {
            path: ".folder" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function argument_encode$6(v) {
  return Curry._1(Identifiers.HubId.t_encode, v);
}

function argument_decode$6(v) {
  return Curry._1(Identifiers.HubId.t_decode, v);
}

function address_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "coordinates",
                    false,
                    Toolkit__Decoders.Coordinates.t_encode(v.coordinates)
                  ],
                  [
                    "radius",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.radius)
                  ]
                ]));
}

function address_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
    if (line2.TAG === "Ok") {
      var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
      if (city.TAG === "Ok") {
        var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
        if (postalCode.TAG === "Ok") {
          var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
          if (country.TAG === "Ok") {
            var coordinates = Toolkit__Decoders.Coordinates.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "coordinates"), null));
            if (coordinates.TAG === "Ok") {
              var radius = Curry._1(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "radius"), null));
              if (radius.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          line1: line1._0,
                          line2: line2._0,
                          city: city._0,
                          postalCode: postalCode._0,
                          country: country._0,
                          coordinates: coordinates._0,
                          radius: radius._0
                        }
                      };
              }
              var e = radius._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".radius" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = coordinates._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".coordinates" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = country._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".country" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = postalCode._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".postalCode" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = city._0;
      return {
              TAG: "Error",
              _0: {
                path: ".city" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = line2._0;
    return {
            TAG: "Error",
            _0: {
              path: ".line2" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function loadingParameters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "waitingTime",
                    false,
                    Spice.stringToJson(v.waitingTime)
                  ],
                  [
                    "consolidationFactor",
                    false,
                    Spice.floatToJson(v.consolidationFactor)
                  ],
                  [
                    "pickUpCapacity",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.pickUpCapacity)
                  ]
                ]));
}

function loadingParameters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var waitingTime = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "waitingTime"), null));
  if (waitingTime.TAG === "Ok") {
    var consolidationFactor = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "consolidationFactor"), null));
    if (consolidationFactor.TAG === "Ok") {
      var pickUpCapacity = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "pickUpCapacity"), null));
      if (pickUpCapacity.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  waitingTime: waitingTime._0,
                  consolidationFactor: consolidationFactor._0,
                  pickUpCapacity: pickUpCapacity._0
                }
              };
      }
      var e = pickUpCapacity._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pickUpCapacity" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = consolidationFactor._0;
    return {
            TAG: "Error",
            _0: {
              path: ".consolidationFactor" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = waitingTime._0;
  return {
          TAG: "Error",
          _0: {
            path: ".waitingTime" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function opening_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "dayOfWeek",
                    false,
                    ColiswebApi__V6_Utils.DayOfWeek.t_encode(v.dayOfWeek)
                  ],
                  [
                    "startTime",
                    false,
                    Spice.stringToJson(v.startTime)
                  ],
                  [
                    "endTime",
                    false,
                    Spice.stringToJson(v.endTime)
                  ]
                ]));
}

function opening_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var dayOfWeek = ColiswebApi__V6_Utils.DayOfWeek.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dayOfWeek"), null));
  if (dayOfWeek.TAG === "Ok") {
    var startTime = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "startTime"), null));
    if (startTime.TAG === "Ok") {
      var endTime = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "endTime"), null));
      if (endTime.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  dayOfWeek: dayOfWeek._0,
                  startTime: startTime._0,
                  endTime: endTime._0
                }
              };
      }
      var e = endTime._0;
      return {
              TAG: "Error",
              _0: {
                path: ".endTime" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = startTime._0;
    return {
            TAG: "Error",
            _0: {
              path: ".startTime" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = dayOfWeek._0;
  return {
          TAG: "Error",
          _0: {
            path: ".dayOfWeek" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exceptionalClosing_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end)
                  ]
                ]));
}

function exceptionalClosing_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var _end = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (_end.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end: _end._0
              }
            };
    }
    var e = _end._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function ftp_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "host",
                    false,
                    Spice.stringToJson(v.host)
                  ],
                  [
                    "login",
                    false,
                    Spice.stringToJson(v.login)
                  ],
                  [
                    "password",
                    false,
                    Spice.stringToJson(v.password)
                  ],
                  [
                    "folder",
                    false,
                    Spice.stringToJson(v.folder)
                  ],
                  [
                    "secure",
                    false,
                    Spice.boolToJson(v.secure)
                  ]
                ]));
}

function ftp_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var host = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "host"), null));
  if (host.TAG === "Ok") {
    var login = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "login"), null));
    if (login.TAG === "Ok") {
      var password = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
      if (password.TAG === "Ok") {
        var folder = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "folder"), null));
        if (folder.TAG === "Ok") {
          var secure = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "secure"), null));
          if (secure.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      host: host._0,
                      login: login._0,
                      password: password._0,
                      folder: folder._0,
                      secure: secure._0
                    }
                  };
          }
          var e = secure._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".secure" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = folder._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".folder" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = password._0;
      return {
              TAG: "Error",
              _0: {
                path: ".password" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = login._0;
    return {
            TAG: "Error",
            _0: {
              path: ".login" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = host._0;
  return {
          TAG: "Error",
          _0: {
            path: ".host" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function teliway_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "siretNumber",
                    false,
                    Spice.stringToJson(v.siretNumber)
                  ],
                  [
                    "ftp",
                    false,
                    ftp_encode(v.ftp)
                  ],
                  [
                    "reports",
                    false,
                    Spice.optionToJson(reports_encode, v.reports)
                  ]
                ]));
}

function teliway_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var siretNumber = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "siretNumber"), null));
  if (siretNumber.TAG === "Ok") {
    var ftp = ftp_decode(Belt_Option.getWithDefault(Js_dict.get(v, "ftp"), null));
    if (ftp.TAG === "Ok") {
      var reports = Spice.optionFromJson(reports_decode, Belt_Option.getWithDefault(Js_dict.get(v, "reports"), null));
      if (reports.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  siretNumber: siretNumber._0,
                  ftp: ftp._0,
                  reports: reports._0
                }
              };
      }
      var e = reports._0;
      return {
              TAG: "Error",
              _0: {
                path: ".reports" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = ftp._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ftp" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = siretNumber._0;
  return {
          TAG: "Error",
          _0: {
            path: ".siretNumber" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function hub_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "emails",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.emails)
                  ],
                  [
                    "slugId",
                    false,
                    Curry._1(Identifiers.HubSlugId.t_encode, v.slugId)
                  ],
                  [
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "analyticsCode",
                    false,
                    Spice.stringToJson(v.analyticsCode)
                  ],
                  [
                    "address",
                    false,
                    address_encode(v.address)
                  ],
                  [
                    "loadingParameters",
                    false,
                    loadingParameters_encode(v.loadingParameters)
                  ],
                  [
                    "openings",
                    false,
                    Spice.arrayToJson(opening_encode, v.openings)
                  ],
                  [
                    "withdrawals",
                    false,
                    Spice.arrayToJson(opening_encode, v.withdrawals)
                  ],
                  [
                    "exceptionalClosings",
                    false,
                    Spice.arrayToJson(exceptionalClosing_encode, v.exceptionalClosings)
                  ],
                  [
                    "teliway",
                    false,
                    Spice.optionToJson(teliway_encode, v.teliway)
                  ],
                  [
                    "coliswebTracking",
                    false,
                    Spice.boolToJson(v.coliswebTracking)
                  ],
                  [
                    "deletedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.deletedAt)
                  ]
                ]));
}

function hub_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var emails = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "emails"), (function (param) {
                return Spice.arrayFromJson(Spice.stringFromJson, param);
              })), {
          TAG: "Ok",
          _0: []
        });
    if (emails.TAG === "Ok") {
      var slugId = Curry._1(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "slugId"), null));
      if (slugId.TAG === "Ok") {
        var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
        if (hubId.TAG === "Ok") {
          var analyticsCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "analyticsCode"), null));
          if (analyticsCode.TAG === "Ok") {
            var address = address_decode(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
            if (address.TAG === "Ok") {
              var loadingParameters = loadingParameters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "loadingParameters"), null));
              if (loadingParameters.TAG === "Ok") {
                var openings = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "openings"), (function (param) {
                            return Spice.arrayFromJson(opening_decode, param);
                          })), {
                      TAG: "Ok",
                      _0: []
                    });
                if (openings.TAG === "Ok") {
                  var withdrawals = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "withdrawals"), (function (param) {
                              return Spice.arrayFromJson(opening_decode, param);
                            })), {
                        TAG: "Ok",
                        _0: []
                      });
                  if (withdrawals.TAG === "Ok") {
                    var exceptionalClosings = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "exceptionalClosings"), (function (param) {
                                return Spice.arrayFromJson(exceptionalClosing_decode, param);
                              })), {
                          TAG: "Ok",
                          _0: []
                        });
                    if (exceptionalClosings.TAG === "Ok") {
                      var teliway = Spice.optionFromJson(teliway_decode, Belt_Option.getWithDefault(Js_dict.get(v, "teliway"), null));
                      if (teliway.TAG === "Ok") {
                        var coliswebTracking = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "coliswebTracking"), null));
                        if (coliswebTracking.TAG === "Ok") {
                          var deletedAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deletedAt"), null));
                          if (deletedAt.TAG === "Ok") {
                            return {
                                    TAG: "Ok",
                                    _0: {
                                      name: name._0,
                                      emails: emails._0,
                                      slugId: slugId._0,
                                      hubId: hubId._0,
                                      analyticsCode: analyticsCode._0,
                                      address: address._0,
                                      loadingParameters: loadingParameters._0,
                                      openings: openings._0,
                                      withdrawals: withdrawals._0,
                                      exceptionalClosings: exceptionalClosings._0,
                                      teliway: teliway._0,
                                      coliswebTracking: coliswebTracking._0,
                                      deletedAt: deletedAt._0
                                    }
                                  };
                          }
                          var e = deletedAt._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".deletedAt" + e.path,
                                    message: e.message,
                                    value: e.value
                                  }
                                };
                        }
                        var e$1 = coliswebTracking._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".coliswebTracking" + e$1.path,
                                  message: e$1.message,
                                  value: e$1.value
                                }
                              };
                      }
                      var e$2 = teliway._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".teliway" + e$2.path,
                                message: e$2.message,
                                value: e$2.value
                              }
                            };
                    }
                    var e$3 = exceptionalClosings._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".exceptionalClosings" + e$3.path,
                              message: e$3.message,
                              value: e$3.value
                            }
                          };
                  }
                  var e$4 = withdrawals._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".withdrawals" + e$4.path,
                            message: e$4.message,
                            value: e$4.value
                          }
                        };
                }
                var e$5 = openings._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".openings" + e$5.path,
                          message: e$5.message,
                          value: e$5.value
                        }
                      };
              }
              var e$6 = loadingParameters._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".loadingParameters" + e$6.path,
                        message: e$6.message,
                        value: e$6.value
                      }
                    };
            }
            var e$7 = address._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".address" + e$7.path,
                      message: e$7.message,
                      value: e$7.value
                    }
                  };
          }
          var e$8 = analyticsCode._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".analyticsCode" + e$8.path,
                    message: e$8.message,
                    value: e$8.value
                  }
                };
        }
        var e$9 = hubId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".hubId" + e$9.path,
                  message: e$9.message,
                  value: e$9.value
                }
              };
      }
      var e$10 = slugId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".slugId" + e$10.path,
                message: e$10.message,
                value: e$10.value
              }
            };
    }
    var e$11 = emails._0;
    return {
            TAG: "Error",
            _0: {
              path: ".emails" + e$11.path,
              message: e$11.message,
              value: e$11.value
            }
          };
  }
  var e$12 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$12.path,
            message: e$12.message,
            value: e$12.value
          }
        };
}

var response_encode$24 = hub_encode;

var response_decode$24 = hub_decode;

function config$24(hubId) {
  return {
          kyInstance: kyInstance$6,
          path: hubId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "AdminGetHub",
            hubId
          ]
        };
}

var GetDetails$2 = {
  reports_encode: reports_encode,
  reports_decode: reports_decode,
  argument_encode: argument_encode$6,
  argument_decode: argument_decode$6,
  address_encode: address_encode,
  address_decode: address_decode,
  loadingParameters_encode: loadingParameters_encode,
  loadingParameters_decode: loadingParameters_decode,
  opening_encode: opening_encode,
  opening_decode: opening_decode,
  exceptionalClosing_encode: exceptionalClosing_encode,
  exceptionalClosing_decode: exceptionalClosing_decode,
  ftp_encode: ftp_encode,
  ftp_decode: ftp_decode,
  teliway_encode: teliway_encode,
  teliway_decode: teliway_decode,
  hub_encode: hub_encode,
  hub_decode: hub_decode,
  response_encode: response_encode$24,
  response_decode: response_decode$24,
  config: config$24
};

function hub_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "slugId",
                    false,
                    Spice.stringToJson(v.slugId)
                  ],
                  [
                    "address",
                    false,
                    address_encode(v.address)
                  ],
                  [
                    "loadingParameters",
                    false,
                    loadingParameters_encode(v.loadingParameters)
                  ],
                  [
                    "analyticsCode",
                    false,
                    Spice.stringToJson(v.analyticsCode)
                  ]
                ]));
}

function hub_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var slugId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "slugId"), null));
    if (slugId.TAG === "Ok") {
      var address = address_decode(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
      if (address.TAG === "Ok") {
        var loadingParameters = loadingParameters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "loadingParameters"), null));
        if (loadingParameters.TAG === "Ok") {
          var analyticsCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "analyticsCode"), null));
          if (analyticsCode.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      name: name._0,
                      slugId: slugId._0,
                      address: address._0,
                      loadingParameters: loadingParameters._0,
                      analyticsCode: analyticsCode._0
                    }
                  };
          }
          var e = analyticsCode._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".analyticsCode" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = loadingParameters._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".loadingParameters" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = address._0;
      return {
              TAG: "Error",
              _0: {
                path: ".address" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = slugId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".slugId" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

var argument_encode$7 = hub_encode$1;

var argument_decode$7 = hub_decode$1;

function response_encode$25(v) {
  return Curry._1(Identifiers.HubId.t_encode, v);
}

function response_decode$25(v) {
  return Curry._1(Identifiers.HubId.t_decode, v);
}

function errorPayload_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "message",
                    false,
                    Spice.stringToJson(v.message)
                  ]]));
}

function errorPayload_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var message = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "message"), null));
  if (message.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              message: message._0
            }
          };
  }
  var e = message._0;
  return {
          TAG: "Error",
          _0: {
            path: ".message" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$25(body) {
  return {
          kyInstance: kyInstance$6,
          path: "",
          requestOptions: {
            method: "POST",
            json: hub_encode$1(body)
          },
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, errorPayload_decode), (function (decodedError) {
                              if (decodedError.TAG !== "Ok") {
                                return {
                                        TAG: "DecodeError",
                                        _0: decodedError._0
                                      };
                              }
                              var message = decodedError._0.message;
                              if (message.includes("hub_slug_id") && message.includes("already exists")) {
                                return {
                                        TAG: "Custom",
                                        _0: "UniqueSlugId"
                                      };
                              } else {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            })
        };
}

var Create$2 = {
  hub_encode: hub_encode$1,
  hub_decode: hub_decode$1,
  argument_encode: argument_encode$7,
  argument_decode: argument_decode$7,
  response_encode: response_encode$25,
  response_decode: response_decode$25,
  errorPayload_encode: errorPayload_encode,
  errorPayload_decode: errorPayload_decode,
  config: config$25
};

function hub_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "emails",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.emails)
                  ],
                  [
                    "address",
                    false,
                    address_encode(v.address)
                  ],
                  [
                    "loadingParameters",
                    false,
                    loadingParameters_encode(v.loadingParameters)
                  ],
                  [
                    "analyticsCode",
                    false,
                    Spice.stringToJson(v.analyticsCode)
                  ]
                ]));
}

function hub_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var emails = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "emails"), null));
    if (emails.TAG === "Ok") {
      var address = address_decode(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
      if (address.TAG === "Ok") {
        var loadingParameters = loadingParameters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "loadingParameters"), null));
        if (loadingParameters.TAG === "Ok") {
          var analyticsCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "analyticsCode"), null));
          if (analyticsCode.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      name: name._0,
                      emails: emails._0,
                      address: address._0,
                      loadingParameters: loadingParameters._0,
                      analyticsCode: analyticsCode._0
                    }
                  };
          }
          var e = analyticsCode._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".analyticsCode" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = loadingParameters._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".loadingParameters" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = address._0;
      return {
              TAG: "Error",
              _0: {
                path: ".address" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = emails._0;
    return {
            TAG: "Error",
            _0: {
              path: ".emails" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function argument_encode$8(v) {
  return [
          Curry._1(Identifiers.HubId.t_encode, v[0]),
          hub_encode$2(v[1])
        ];
}

function argument_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.HubId.t_decode, v0);
  var match$1 = hub_decode$2(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$26 = Spice.unitToJson;

var response_decode$26 = Spice.unitFromJson;

function config$26(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0],
          requestOptions: {
            method: "PUT",
            json: hub_encode$2(param[1])
          }
        };
}

var Update$3 = {
  hub_encode: hub_encode$2,
  hub_decode: hub_decode$2,
  argument_encode: argument_encode$8,
  argument_decode: argument_decode$8,
  response_encode: response_encode$26,
  response_decode: response_decode$26,
  config: config$26
};

function argument_encode$9(v) {
  return Curry._1(Identifiers.HubId.t_encode, v);
}

function argument_decode$9(v) {
  return Curry._1(Identifiers.HubId.t_decode, v);
}

var response_encode$27 = Spice.unitToJson;

var response_decode$27 = Spice.unitFromJson;

function config$27(hubId) {
  return {
          kyInstance: kyInstance$6,
          path: hubId,
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$2 = {
  argument_encode: argument_encode$9,
  argument_decode: argument_decode$9,
  response_encode: response_encode$27,
  response_decode: response_decode$27,
  config: config$27
};

function queryParams_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "withDeleted",
                    false,
                    Spice.boolToJson(v.withDeleted)
                  ]]));
}

function queryParams_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var withDeleted = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "withDeleted"), null));
  if (withDeleted.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              withDeleted: withDeleted._0
            }
          };
  }
  var e = withDeleted._0;
  return {
          TAG: "Error",
          _0: {
            path: ".withDeleted" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function hub_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "slugId",
                    false,
                    Curry._1(Identifiers.HubSlugId.t_encode, v.slugId)
                  ],
                  [
                    "address",
                    false,
                    address_encode(v.address)
                  ],
                  [
                    "analyticsCode",
                    false,
                    Spice.stringToJson(v.analyticsCode)
                  ],
                  [
                    "deletedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.deletedAt)
                  ]
                ]));
}

function hub_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var slugId = Curry._1(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "slugId"), null));
      if (slugId.TAG === "Ok") {
        var address = address_decode(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
        if (address.TAG === "Ok") {
          var analyticsCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "analyticsCode"), null));
          if (analyticsCode.TAG === "Ok") {
            var deletedAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deletedAt"), null));
            if (deletedAt.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        name: name._0,
                        hubId: hubId._0,
                        slugId: slugId._0,
                        address: address._0,
                        analyticsCode: analyticsCode._0,
                        deletedAt: deletedAt._0
                      }
                    };
            }
            var e = deletedAt._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".deletedAt" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = analyticsCode._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".analyticsCode" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = address._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".address" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = slugId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".slugId" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function response_encode$28(v) {
  return Spice.arrayToJson(hub_encode$3, v);
}

function response_decode$28(v) {
  return Spice.arrayFromJson(hub_decode$3, v);
}

function config$28(queryParams) {
  return {
          kyInstance: kyInstance$6,
          path: "",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(queryParams_encode(queryParams))
          },
          key: [
            "AdminGetAllHubs",
            JSON.stringify(queryParams_encode(queryParams))
          ]
        };
}

var GetAll$3 = {
  queryParams_encode: queryParams_encode,
  queryParams_decode: queryParams_decode,
  hub_encode: hub_encode$3,
  hub_decode: hub_decode$3,
  response_encode: response_encode$28,
  response_decode: response_decode$28,
  config: config$28
};

function argument_encode$10(v) {
  return Curry._1(Identifiers.HubId.t_encode, v);
}

function argument_decode$10(v) {
  return Curry._1(Identifiers.HubId.t_decode, v);
}

function response_encode$29(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "slugId",
                    false,
                    Curry._1(Identifiers.HubSlugId.t_encode, v.slugId)
                  ],
                  [
                    "deletedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.deletedAt)
                  ]
                ]));
}

function response_decode$29(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var slugId = Curry._1(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "slugId"), null));
      if (slugId.TAG === "Ok") {
        var deletedAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deletedAt"), null));
        if (deletedAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    name: name._0,
                    hubId: hubId._0,
                    slugId: slugId._0,
                    deletedAt: deletedAt._0
                  }
                };
        }
        var e = deletedAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".deletedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = slugId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".slugId" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function config$29(hubId) {
  return {
          kyInstance: kyInstance$6,
          path: hubId + "/name",
          requestOptions: {
            method: "GET"
          },
          key: [
            "AdminGetHub",
            hubId
          ]
        };
}

var GetHubName = {
  argument_encode: argument_encode$10,
  argument_decode: argument_decode$10,
  response_encode: response_encode$29,
  response_decode: response_decode$29,
  config: config$29
};

function argument_encode$11(v) {
  return Curry._1(Identifiers.HubSlugId.t_encode, v);
}

function argument_decode$11(v) {
  return Curry._1(Identifiers.HubSlugId.t_decode, v);
}

function response_encode$30(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "slugId",
                    false,
                    Curry._1(Identifiers.HubSlugId.t_encode, v.slugId)
                  ],
                  [
                    "deletedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.deletedAt)
                  ]
                ]));
}

function response_decode$30(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var slugId = Curry._1(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "slugId"), null));
      if (slugId.TAG === "Ok") {
        var deletedAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deletedAt"), null));
        if (deletedAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    name: name._0,
                    hubId: hubId._0,
                    slugId: slugId._0,
                    deletedAt: deletedAt._0
                  }
                };
        }
        var e = deletedAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".deletedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = slugId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".slugId" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function config$30(hubSlugId) {
  return {
          kyInstance: kyInstance$6,
          path: "slug/" + hubSlugId + "/name",
          requestOptions: {
            method: "GET"
          },
          key: [
            "AdminGetHubNameBySlugId",
            hubSlugId
          ]
        };
}

var GetBySlugId = {
  argument_encode: argument_encode$11,
  argument_decode: argument_decode$11,
  response_encode: response_encode$30,
  response_decode: response_decode$30,
  config: config$30
};

function user_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.UserUuid.t_encode, v.uuid)
                  ],
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phoneNumber)
                  ],
                  [
                    "role",
                    false,
                    Spice.stringToJson(v.role)
                  ]
                ]));
}

function user_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var uuid = Curry._1(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
  if (uuid.TAG === "Ok") {
    var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
    if (username.TAG === "Ok") {
      var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var phoneNumber = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
        if (phoneNumber.TAG === "Ok") {
          var role = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
          if (role.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      uuid: uuid._0,
                      username: username._0,
                      email: email._0,
                      phoneNumber: phoneNumber._0,
                      role: role._0
                    }
                  };
          }
          var e = role._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".role" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = phoneNumber._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phoneNumber" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = username._0;
    return {
            TAG: "Error",
            _0: {
              path: ".username" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = uuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".uuid" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function response_encode$31(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode$2, v.users)
                  ]]));
}

function response_decode$31(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$31(hubId) {
  return {
          kyInstance: kyInstance$6,
          path: hubId + "/users",
          requestOptions: {
            method: "GET"
          },
          key: ["HubsGetUsers"]
        };
}

var GetAll$4 = {
  user_encode: user_encode$2,
  user_decode: user_decode$2,
  response_encode: response_encode$31,
  response_decode: response_decode$31,
  config: config$31
};

function body_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "password",
                    false,
                    Spice.stringToJson(v.password)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phoneNumber)
                  ]
                ]));
}

function body_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
  if (username.TAG === "Ok") {
    var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
    if (email.TAG === "Ok") {
      var password = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
      if (password.TAG === "Ok") {
        var phoneNumber = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
        if (phoneNumber.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    username: username._0,
                    email: email._0,
                    password: password._0,
                    phoneNumber: phoneNumber._0
                  }
                };
        }
        var e = phoneNumber._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phoneNumber" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = password._0;
      return {
              TAG: "Error",
              _0: {
                path: ".password" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = email._0;
    return {
            TAG: "Error",
            _0: {
              path: ".email" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = username._0;
  return {
          TAG: "Error",
          _0: {
            path: ".username" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var response_encode$32 = Spice.unitToJson;

var response_decode$32 = Spice.unitFromJson;

function error_encode$1(v) {
  if (v === "USER_CONFLICT") {
    return "USER_CONFLICT";
  } else {
    return "EMAIL_CONFLICT";
  }
}

function error_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("USER_CONFLICT" === v) {
          return {
                  TAG: "Ok",
                  _0: "USER_CONFLICT"
                };
        } else if ("EMAIL_CONFLICT" === v) {
          return {
                  TAG: "Ok",
                  _0: "EMAIL_CONFLICT"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config$32(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0] + "/users",
          requestOptions: {
            method: "POST",
            json: body_encode$9(param[1])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$1, param);
            })
        };
}

var Create$3 = {
  body_encode: body_encode$9,
  body_decode: body_decode$9,
  response_encode: response_encode$32,
  response_decode: response_decode$32,
  error_encode: error_encode$1,
  error_decode: error_decode$1,
  config: config$32
};

var Users = {
  GetAll: GetAll$4,
  Create: Create$3
};

function queryParams_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "withDeleted",
                    false,
                    Spice.boolToJson(v.withDeleted)
                  ]]));
}

function queryParams_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var withDeleted = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "withDeleted"), null));
  if (withDeleted.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              withDeleted: withDeleted._0
            }
          };
  }
  var e = withDeleted._0;
  return {
          TAG: "Error",
          _0: {
            path: ".withDeleted" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function hub_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "slugId",
                    false,
                    Curry._1(Identifiers.HubSlugId.t_encode, v.slugId)
                  ],
                  [
                    "deletedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.deletedAt)
                  ]
                ]));
}

function hub_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var slugId = Curry._1(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "slugId"), null));
      if (slugId.TAG === "Ok") {
        var deletedAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deletedAt"), null));
        if (deletedAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    name: name._0,
                    hubId: hubId._0,
                    slugId: slugId._0,
                    deletedAt: deletedAt._0
                  }
                };
        }
        var e = deletedAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".deletedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = slugId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".slugId" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$33(v) {
  return Spice.arrayToJson(hub_encode$4, v);
}

function response_decode$33(v) {
  return Spice.arrayFromJson(hub_decode$4, v);
}

function config$33(queryParams) {
  return {
          kyInstance: kyInstance$6,
          path: "names",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(queryParams_encode$1(queryParams))
          },
          key: [
            "AdminGetAllHubsNames",
            JSON.stringify(queryParams_encode$1(queryParams))
          ]
        };
}

var GetAllHubsNames = {
  queryParams_encode: queryParams_encode$1,
  queryParams_decode: queryParams_decode$1,
  hub_encode: hub_encode$4,
  hub_decode: hub_decode$4,
  response_encode: response_encode$33,
  response_decode: response_decode$33,
  config: config$33
};

function openings_encode(v) {
  return Spice.arrayToJson(opening_encode, v);
}

function openings_decode(v) {
  return Spice.arrayFromJson(opening_decode, v);
}

function argument_encode$12(v) {
  return [
          Curry._1(Identifiers.HubId.t_encode, v[0]),
          Spice.arrayToJson(opening_encode, v[1])
        ];
}

function argument_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.HubId.t_decode, v0);
  var match$1 = Spice.arrayFromJson(opening_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$34 = Spice.unitToJson;

var response_decode$34 = Spice.unitFromJson;

function config$34(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0] + "/openings",
          requestOptions: {
            method: "PUT",
            json: Spice.arrayToJson(opening_encode, param[1])
          }
        };
}

var Update$4 = {
  openings_encode: openings_encode,
  openings_decode: openings_decode,
  argument_encode: argument_encode$12,
  argument_decode: argument_decode$12,
  response_encode: response_encode$34,
  response_decode: response_decode$34,
  config: config$34
};

var Openings = {
  Update: Update$4
};

function openings_encode$1(v) {
  return Spice.arrayToJson(opening_encode, v);
}

function openings_decode$1(v) {
  return Spice.arrayFromJson(opening_decode, v);
}

function argument_encode$13(v) {
  return [
          Curry._1(Identifiers.HubId.t_encode, v[0]),
          Spice.arrayToJson(opening_encode, v[1])
        ];
}

function argument_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.HubId.t_decode, v0);
  var match$1 = Spice.arrayFromJson(opening_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$35 = Spice.unitToJson;

var response_decode$35 = Spice.unitFromJson;

function config$35(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0] + "/withdrawals",
          requestOptions: {
            method: "PUT",
            json: Spice.arrayToJson(opening_encode, param[1])
          }
        };
}

var Update$5 = {
  openings_encode: openings_encode$1,
  openings_decode: openings_decode$1,
  argument_encode: argument_encode$13,
  argument_decode: argument_decode$13,
  response_encode: response_encode$35,
  response_decode: response_decode$35,
  config: config$35
};

function argument_encode$14(v) {
  return Curry._1(Identifiers.HubId.t_encode, v);
}

function argument_decode$14(v) {
  return Curry._1(Identifiers.HubId.t_decode, v);
}

function openings_encode$2(v) {
  return Spice.arrayToJson(opening_encode, v);
}

function openings_decode$2(v) {
  return Spice.arrayFromJson(opening_decode, v);
}

var response_encode$36 = Spice.unitToJson;

var response_decode$36 = Spice.unitFromJson;

function config$36(hubId) {
  return {
          kyInstance: kyInstance$6,
          path: hubId + "/withdrawals/disable",
          requestOptions: {
            method: "POST"
          }
        };
}

var Disable = {
  argument_encode: argument_encode$14,
  argument_decode: argument_decode$14,
  openings_encode: openings_encode$2,
  openings_decode: openings_decode$2,
  response_encode: response_encode$36,
  response_decode: response_decode$36,
  config: config$36
};

var Withdrawals = {
  Update: Update$5,
  Disable: Disable
};

function exceptionalClosings_encode(v) {
  return Spice.arrayToJson(exceptionalClosing_encode, v);
}

function exceptionalClosings_decode(v) {
  return Spice.arrayFromJson(exceptionalClosing_decode, v);
}

function argument_encode$15(v) {
  return [
          Curry._1(Identifiers.HubId.t_encode, v[0]),
          Spice.arrayToJson(exceptionalClosing_encode, v[1])
        ];
}

function argument_decode$15(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.HubId.t_decode, v0);
  var match$1 = Spice.arrayFromJson(exceptionalClosing_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$37 = Spice.unitToJson;

var response_decode$37 = Spice.unitFromJson;

function config$37(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0] + "/exceptionalClosings",
          requestOptions: {
            method: "PUT",
            json: Spice.arrayToJson(exceptionalClosing_encode, param[1])
          }
        };
}

var Update$6 = {
  exceptionalClosings_encode: exceptionalClosings_encode,
  exceptionalClosings_decode: exceptionalClosings_decode,
  argument_encode: argument_encode$15,
  argument_decode: argument_decode$15,
  response_encode: response_encode$37,
  response_decode: response_decode$37,
  config: config$37
};

var ExceptionalClosings = {
  Update: Update$6
};

function body_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "teliway",
                    false,
                    Spice.optionToJson(teliway_encode, v.teliway)
                  ],
                  [
                    "coliswebTracking",
                    false,
                    Spice.boolToJson(v.coliswebTracking)
                  ]
                ]));
}

function body_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var teliway = Spice.optionFromJson(teliway_decode, Belt_Option.getWithDefault(Js_dict.get(v, "teliway"), null));
  if (teliway.TAG === "Ok") {
    var coliswebTracking = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "coliswebTracking"), null));
    if (coliswebTracking.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                teliway: teliway._0,
                coliswebTracking: coliswebTracking._0
              }
            };
    }
    var e = coliswebTracking._0;
    return {
            TAG: "Error",
            _0: {
              path: ".coliswebTracking" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = teliway._0;
  return {
          TAG: "Error",
          _0: {
            path: ".teliway" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function argument_encode$16(v) {
  return [
          Curry._1(Identifiers.HubId.t_encode, v[0]),
          body_encode$10(v[1])
        ];
}

function argument_decode$16(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.HubId.t_decode, v0);
  var match$1 = body_decode$10(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$38 = Spice.unitToJson;

var response_decode$38 = Spice.unitFromJson;

function config$38(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0] + "/trackingConfig",
          requestOptions: {
            method: "PUT",
            json: body_encode$10(param[1])
          }
        };
}

var Update$7 = {
  body_encode: body_encode$10,
  body_decode: body_decode$10,
  argument_encode: argument_encode$16,
  argument_decode: argument_decode$16,
  response_encode: response_encode$38,
  response_decode: response_decode$38,
  config: config$38
};

var TrackingConfig = {
  Update: Update$7
};

var Hubs = {
  kyInstance: kyInstance$6,
  GetDetails: GetDetails$2,
  Create: Create$2,
  Update: Update$3,
  Delete: Delete$2,
  GetAll: GetAll$3,
  GetHubName: GetHubName,
  GetBySlugId: GetBySlugId,
  Users: Users,
  GetAllHubsNames: GetAllHubsNames,
  Openings: Openings,
  Withdrawals: Withdrawals,
  ExceptionalClosings: ExceptionalClosings,
  TrackingConfig: TrackingConfig
};

var kyInstance$7 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/transporters"
    });

function response_encode$39(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ProviderId.t_encode, v.id)
                  ],
                  [
                    "freightForwarderId",
                    false,
                    Curry._1(Identifiers.FreightForwarderId.t_encode, v.freightForwarderId)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "crmId",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.crmId)
                  ],
                  [
                    "supportPhoneNumber",
                    false,
                    Spice.stringToJson(v.supportPhoneNumber)
                  ],
                  [
                    "canBeAssignedAutomatically",
                    false,
                    Spice.boolToJson(v.canBeAssignedAutomatically)
                  ],
                  [
                    "nationalIdentifier",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.nationalIdentifier)
                  ]
                ]));
}

function response_decode$39(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ProviderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var freightForwarderId = Curry._1(Identifiers.FreightForwarderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "freightForwarderId"), null));
    if (freightForwarderId.TAG === "Ok") {
      var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
      if (name.TAG === "Ok") {
        var crmId = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "crmId"), null));
        if (crmId.TAG === "Ok") {
          var supportPhoneNumber = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "supportPhoneNumber"), null));
          if (supportPhoneNumber.TAG === "Ok") {
            var canBeAssignedAutomatically = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canBeAssignedAutomatically"), null));
            if (canBeAssignedAutomatically.TAG === "Ok") {
              var nationalIdentifier = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "nationalIdentifier"), null));
              if (nationalIdentifier.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          id: id._0,
                          freightForwarderId: freightForwarderId._0,
                          name: name._0,
                          crmId: crmId._0,
                          supportPhoneNumber: supportPhoneNumber._0,
                          canBeAssignedAutomatically: canBeAssignedAutomatically._0,
                          nationalIdentifier: nationalIdentifier._0
                        }
                      };
              }
              var e = nationalIdentifier._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".nationalIdentifier" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = canBeAssignedAutomatically._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".canBeAssignedAutomatically" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = supportPhoneNumber._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".supportPhoneNumber" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = crmId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".crmId" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = name._0;
      return {
              TAG: "Error",
              _0: {
                path: ".name" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = freightForwarderId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".freightForwarderId" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function config$39(transporterId) {
  return {
          kyInstance: kyInstance$7,
          path: transporterId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "TransporterAdminDetails",
            transporterId
          ]
        };
}

var Get$2 = {
  response_encode: response_encode$39,
  response_decode: response_decode$39,
  config: config$39
};

function response_encode$40(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "token",
                    false,
                    Spice.stringToJson(v.token)
                  ]]));
}

function response_decode$40(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var token = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "token"), null));
  if (token.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              token: token._0
            }
          };
  }
  var e = token._0;
  return {
          TAG: "Error",
          _0: {
            path: ".token" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$40(transporterId) {
  return {
          kyInstance: kyInstance$7,
          path: transporterId + "/token",
          requestOptions: {
            method: "GET"
          },
          key: [
            "TransporterGetToken",
            transporterId
          ]
        };
}

var GetToken = {
  response_encode: response_encode$40,
  response_decode: response_decode$40,
  config: config$40
};

function user_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "password",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.password)
                  ]
                ]));
}

function user_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
  if (username.TAG === "Ok") {
    var password = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
    if (password.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                username: username._0,
                password: password._0
              }
            };
    }
    var e = password._0;
    return {
            TAG: "Error",
            _0: {
              path: ".password" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = username._0;
  return {
          TAG: "Error",
          _0: {
            path: ".username" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function contact_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.stringToJson(v.phoneNumber)
                  ]
                ]));
}

function contact_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    var phoneNumber = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
    if (phoneNumber.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                email: email._0,
                phoneNumber: phoneNumber._0
              }
            };
    }
    var e = phoneNumber._0;
    return {
            TAG: "Error",
            _0: {
              path: ".phoneNumber" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "crmId",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.crmId)
                  ],
                  [
                    "supportPhoneNumber",
                    false,
                    Spice.stringToJson(v.supportPhoneNumber)
                  ],
                  [
                    "canBeAssignedAutomatically",
                    false,
                    Spice.boolToJson(v.canBeAssignedAutomatically)
                  ],
                  [
                    "nationalIdentifier",
                    false,
                    Spice.stringToJson(v.nationalIdentifier)
                  ],
                  [
                    "user",
                    false,
                    user_encode$3(v.user)
                  ],
                  [
                    "contacts",
                    false,
                    Spice.arrayToJson(contact_encode$1, v.contacts)
                  ]
                ]));
}

function body_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var crmId = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "crmId"), null));
    if (crmId.TAG === "Ok") {
      var supportPhoneNumber = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "supportPhoneNumber"), null));
      if (supportPhoneNumber.TAG === "Ok") {
        var canBeAssignedAutomatically = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canBeAssignedAutomatically"), null));
        if (canBeAssignedAutomatically.TAG === "Ok") {
          var nationalIdentifier = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "nationalIdentifier"), null));
          if (nationalIdentifier.TAG === "Ok") {
            var user = user_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "user"), null));
            if (user.TAG === "Ok") {
              var contacts = Spice.arrayFromJson(contact_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "contacts"), null));
              if (contacts.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          name: name._0,
                          crmId: crmId._0,
                          supportPhoneNumber: supportPhoneNumber._0,
                          canBeAssignedAutomatically: canBeAssignedAutomatically._0,
                          nationalIdentifier: nationalIdentifier._0,
                          user: user._0,
                          contacts: contacts._0
                        }
                      };
              }
              var e = contacts._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".contacts" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = user._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".user" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = nationalIdentifier._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".nationalIdentifier" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = canBeAssignedAutomatically._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".canBeAssignedAutomatically" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = supportPhoneNumber._0;
      return {
              TAG: "Error",
              _0: {
                path: ".supportPhoneNumber" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = crmId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".crmId" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function response_encode$41(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "transporterId",
                    false,
                    Curry._1(Identifiers.ProviderId.t_encode, v.transporterId)
                  ],
                  [
                    "contactIds",
                    false,
                    Spice.arrayToJson(Identifiers.ContactIdInt.t_encode, v.contactIds)
                  ]
                ]));
}

function response_decode$41(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transporterId = Curry._1(Identifiers.ProviderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transporterId"), null));
  if (transporterId.TAG === "Ok") {
    var contactIds = Spice.arrayFromJson(Identifiers.ContactIdInt.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "contactIds"), null));
    if (contactIds.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                transporterId: transporterId._0,
                contactIds: contactIds._0
              }
            };
    }
    var e = contactIds._0;
    return {
            TAG: "Error",
            _0: {
              path: ".contactIds" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = transporterId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transporterId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function error_encode$2(v) {
  return "USER_CONFLICT";
}

function error_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("USER_CONFLICT" === v) {
          return {
                  TAG: "Ok",
                  _0: "USER_CONFLICT"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config$41(body) {
  return {
          kyInstance: kyInstance$7,
          path: "",
          requestOptions: {
            method: "POST",
            json: body_encode$11(body)
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$2, param);
            })
        };
}

var Create$4 = {
  user_encode: user_encode$3,
  user_decode: user_decode$3,
  contact_encode: contact_encode$1,
  contact_decode: contact_decode$1,
  body_encode: body_encode$11,
  body_decode: body_decode$11,
  response_encode: response_encode$41,
  response_decode: response_decode$41,
  error_encode: error_encode$2,
  error_decode: error_decode$2,
  config: config$41
};

function body_encode$12(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "crmId",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.crmId)
                  ],
                  [
                    "supportPhoneNumber",
                    false,
                    Spice.stringToJson(v.supportPhoneNumber)
                  ],
                  [
                    "canBeAssignedAutomatically",
                    false,
                    Spice.boolToJson(v.canBeAssignedAutomatically)
                  ],
                  [
                    "nationalIdentifier",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.nationalIdentifier)
                  ]
                ]));
}

function body_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var crmId = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "crmId"), null));
    if (crmId.TAG === "Ok") {
      var supportPhoneNumber = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "supportPhoneNumber"), null));
      if (supportPhoneNumber.TAG === "Ok") {
        var canBeAssignedAutomatically = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canBeAssignedAutomatically"), null));
        if (canBeAssignedAutomatically.TAG === "Ok") {
          var nationalIdentifier = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "nationalIdentifier"), null));
          if (nationalIdentifier.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      name: name._0,
                      crmId: crmId._0,
                      supportPhoneNumber: supportPhoneNumber._0,
                      canBeAssignedAutomatically: canBeAssignedAutomatically._0,
                      nationalIdentifier: nationalIdentifier._0
                    }
                  };
          }
          var e = nationalIdentifier._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".nationalIdentifier" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = canBeAssignedAutomatically._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".canBeAssignedAutomatically" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = supportPhoneNumber._0;
      return {
              TAG: "Error",
              _0: {
                path: ".supportPhoneNumber" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = crmId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".crmId" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

var response_encode$42 = Spice.unitToJson;

var response_decode$42 = Spice.unitFromJson;

function error_encode$3(v) {
  return "USER_CONFLICT";
}

function error_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("USER_CONFLICT" === v) {
          return {
                  TAG: "Ok",
                  _0: "USER_CONFLICT"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config$42(param) {
  return {
          kyInstance: kyInstance$7,
          path: param[0],
          requestOptions: {
            method: "PUT",
            json: body_encode$12(param[1])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$3, param);
            })
        };
}

var Update$8 = {
  body_encode: body_encode$12,
  body_decode: body_decode$12,
  response_encode: response_encode$42,
  response_decode: response_decode$42,
  error_encode: error_encode$3,
  error_decode: error_decode$3,
  config: config$42
};

var response_encode$43 = Spice.unitToJson;

var response_decode$43 = Spice.unitFromJson;

function error_encode$4(v) {
  return "CANNOT_DELETE_CARRIER";
}

function error_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("CANNOT_DELETE_CARRIER" === v) {
          return {
                  TAG: "Ok",
                  _0: "CANNOT_DELETE_CARRIER"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config$43(transporterId) {
  return {
          kyInstance: kyInstance$7,
          path: transporterId,
          requestOptions: {
            method: "DELETE"
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$4, param);
            })
        };
}

var Delete$3 = {
  response_encode: response_encode$43,
  response_decode: response_decode$43,
  error_encode: error_encode$4,
  error_decode: error_decode$4,
  config: config$43
};

function argument_encode$17(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]]));
}

function argument_decode$17(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              name: name._0
            }
          };
  }
  var e = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode$44(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "available",
                    false,
                    Spice.boolToJson(v.available)
                  ]]));
}

function response_decode$44(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var available = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "available"), null));
  if (available.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              available: available._0
            }
          };
  }
  var e = available._0;
  return {
          TAG: "Error",
          _0: {
            path: ".available" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$44(body) {
  return {
          kyInstance: kyInstance$7,
          path: "available",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(argument_encode$17(body))
          }
        };
}

var Available = {
  argument_encode: argument_encode$17,
  argument_decode: argument_decode$17,
  response_encode: response_encode$44,
  response_decode: response_decode$44,
  config: config$44
};

var AsAdmin = {
  Create: Create$4,
  Update: Update$8,
  Delete: Delete$3,
  Available: Available
};

function response_encode$45(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "carrierCanAcceptDeliveries",
                    false,
                    Spice.boolToJson(v.carrierCanAcceptDeliveries)
                  ],
                  [
                    "showPriceToCarriers",
                    false,
                    Spice.boolToJson(v.showPriceToCarriers)
                  ]
                ]));
}

function response_decode$45(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var carrierCanAcceptDeliveries = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "carrierCanAcceptDeliveries"), null));
  if (carrierCanAcceptDeliveries.TAG === "Ok") {
    var showPriceToCarriers = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "showPriceToCarriers"), null));
    if (showPriceToCarriers.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                carrierCanAcceptDeliveries: carrierCanAcceptDeliveries._0,
                showPriceToCarriers: showPriceToCarriers._0
              }
            };
    }
    var e = showPriceToCarriers._0;
    return {
            TAG: "Error",
            _0: {
              path: ".showPriceToCarriers" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = carrierCanAcceptDeliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".carrierCanAcceptDeliveries" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$45(transporterId) {
  return {
          kyInstance: kyInstance$7,
          path: transporterId + "/settings",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ProviderTransporterSettings",
            transporterId
          ]
        };
}

var Get$3 = {
  response_encode: response_encode$45,
  response_decode: response_decode$45,
  config: config$45
};

function body_encode$13(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "carrierCanAcceptDeliveries",
                    false,
                    Spice.boolToJson(v.carrierCanAcceptDeliveries)
                  ],
                  [
                    "showPriceToCarriers",
                    false,
                    Spice.boolToJson(v.showPriceToCarriers)
                  ]
                ]));
}

function body_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var carrierCanAcceptDeliveries = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "carrierCanAcceptDeliveries"), null));
  if (carrierCanAcceptDeliveries.TAG === "Ok") {
    var showPriceToCarriers = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "showPriceToCarriers"), null));
    if (showPriceToCarriers.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                carrierCanAcceptDeliveries: carrierCanAcceptDeliveries._0,
                showPriceToCarriers: showPriceToCarriers._0
              }
            };
    }
    var e = showPriceToCarriers._0;
    return {
            TAG: "Error",
            _0: {
              path: ".showPriceToCarriers" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = carrierCanAcceptDeliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".carrierCanAcceptDeliveries" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$46 = Spice.unitToJson;

var response_decode$46 = Spice.unitFromJson;

function config$46(param) {
  return {
          kyInstance: kyInstance$7,
          path: param[0] + "/settings",
          requestOptions: {
            method: "PUT",
            json: body_encode$13(param[1])
          }
        };
}

var Update$9 = {
  body_encode: body_encode$13,
  body_decode: body_decode$13,
  response_encode: response_encode$46,
  response_decode: response_decode$46,
  config: config$46
};

var Settings = {
  Get: Get$3,
  Update: Update$9
};

function response_encode$47(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "carrierId",
                    false,
                    Curry._1(Identifiers.ProviderCarrierId.t_encode, v.carrierId)
                  ]]));
}

function response_decode$47(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var carrierId = Curry._1(Identifiers.ProviderCarrierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carrierId"), null));
  if (carrierId.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              carrierId: carrierId._0
            }
          };
  }
  var e = carrierId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".carrierId" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function body_encode$14(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "login",
                    false,
                    Spice.stringToJson(v.login)
                  ],
                  [
                    "password",
                    false,
                    Spice.stringToJson(v.password)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.stringToJson(v.phoneNumber)
                  ]
                ]));
}

function body_decode$14(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var login = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "login"), null));
  if (login.TAG === "Ok") {
    var password = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
    if (password.TAG === "Ok") {
      var phoneNumber = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
      if (phoneNumber.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  login: login._0,
                  password: password._0,
                  phoneNumber: phoneNumber._0
                }
              };
      }
      var e = phoneNumber._0;
      return {
              TAG: "Error",
              _0: {
                path: ".phoneNumber" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = password._0;
    return {
            TAG: "Error",
            _0: {
              path: ".password" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = login._0;
  return {
          TAG: "Error",
          _0: {
            path: ".login" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function error_encode$5(v) {
  return "USER_CONFLICT";
}

function error_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("USER_CONFLICT" === v) {
          return {
                  TAG: "Ok",
                  _0: "USER_CONFLICT"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config$47(param) {
  return {
          kyInstance: kyInstance$7,
          path: param[0] + "/carriers",
          requestOptions: {
            method: "POST",
            json: body_encode$14(param[1])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$5, param);
            })
        };
}

var Create$5 = {
  response_encode: response_encode$47,
  response_decode: response_decode$47,
  body_encode: body_encode$14,
  body_decode: body_decode$14,
  error_encode: error_encode$5,
  error_decode: error_decode$5,
  config: config$47
};

var response_encode$48 = Spice.unitToJson;

var response_decode$48 = Spice.unitFromJson;

function error_encode$6(v) {
  return "CANNOT_DELETE_CARRIER";
}

function error_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("CANNOT_DELETE_CARRIER" === v) {
          return {
                  TAG: "Ok",
                  _0: "CANNOT_DELETE_CARRIER"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config$48(param) {
  return {
          kyInstance: kyInstance$7,
          path: param[0] + "/carriers/" + param[1],
          requestOptions: {
            method: "DELETE"
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$6, param);
            })
        };
}

var Delete$4 = {
  response_encode: response_encode$48,
  response_decode: response_decode$48,
  error_encode: error_encode$6,
  error_decode: error_decode$6,
  config: config$48
};

var Carriers$2 = {
  Create: Create$5,
  Delete: Delete$4
};

function user_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.UserUuid.t_encode, v.uuid)
                  ],
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phoneNumber)
                  ],
                  [
                    "role",
                    false,
                    Spice.stringToJson(v.role)
                  ]
                ]));
}

function user_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var uuid = Curry._1(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
  if (uuid.TAG === "Ok") {
    var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
    if (username.TAG === "Ok") {
      var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var phoneNumber = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
        if (phoneNumber.TAG === "Ok") {
          var role = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
          if (role.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      uuid: uuid._0,
                      username: username._0,
                      email: email._0,
                      phoneNumber: phoneNumber._0,
                      role: role._0
                    }
                  };
          }
          var e = role._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".role" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = phoneNumber._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phoneNumber" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = username._0;
    return {
            TAG: "Error",
            _0: {
              path: ".username" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = uuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".uuid" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function response_encode$49(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode$4, v.users)
                  ]]));
}

function response_decode$49(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode$4, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$49(transporterId) {
  return {
          kyInstance: kyInstance$7,
          path: transporterId + "/users",
          requestOptions: {
            method: "GET"
          },
          key: ["TransporterGetUsers"]
        };
}

var GetAll$5 = {
  user_encode: user_encode$4,
  user_decode: user_decode$4,
  response_encode: response_encode$49,
  response_decode: response_decode$49,
  config: config$49
};

var Users$1 = {
  GetAll: GetAll$5
};

var Transporters = {
  kyInstance: kyInstance$7,
  Get: Get$2,
  GetToken: GetToken,
  AsAdmin: AsAdmin,
  Settings: Settings,
  Carriers: Carriers$2,
  Users: Users$1
};

export {
  $$Error ,
  Activations ,
  Rules$1 as Rules,
  TestPriceDefinition ,
  Carriers ,
  Providers ,
  Hubs ,
  Transporters ,
}
/* kyInstance Not a pure module */
