

import * as Ky from "ky";
import * as Qs from "qs";
import * as Axios from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/Axios.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Toolkit__Request.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__Utils from "../ColiswebApi__Utils.bs.js";
import * as Decoders__UnitMeasure from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Decoders__UnitMeasure.bs.js";
import * as ColiswebApi__V5_Shared from "../v5/ColiswebApi__V5_Shared.bs.js";
import * as Toolkit__Primitives_Array from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";

var baseUrl = ColiswebApi__Env.v6ApiUrl + "/parcel/external";

var isWebview = Belt_Option.getWithDefault(window.isWebview, false);

var kyInstance = Ky.default.create({
      prefixUrl: baseUrl,
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      headers: {
        Webview: isWebview
      },
      credentials: "include",
      mode: "cors"
    });

var defaultHeaders = {
  Webview: isWebview
};

var arg = Caml_option.some(defaultHeaders);

var arg$1 = ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000;

function config(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  var partial_arg = true;
  return function (param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
    var tmp = {};
    if (param !== undefined) {
      tmp.url = param;
    }
    if (param$1 !== undefined) {
      tmp._method = param$1;
    }
    if (param$2 !== undefined) {
      tmp.baseURL = param$2;
    }
    if (param$3 !== undefined) {
      tmp.transformRequest = Caml_option.valFromOption(param$3);
    }
    if (param$4 !== undefined) {
      tmp.transformResponse = Caml_option.valFromOption(param$4);
    }
    if (arg !== undefined) {
      tmp.headers = Caml_option.valFromOption(arg);
    }
    if (param$5 !== undefined) {
      tmp.params = Caml_option.valFromOption(param$5);
    }
    if (param$6 !== undefined) {
      tmp.paramsSerializer = Caml_option.valFromOption(param$6);
    }
    if (param$7 !== undefined) {
      tmp.data = Caml_option.valFromOption(param$7);
    }
    if (arg$1 !== undefined) {
      tmp.timeout = arg$1;
    }
    if (partial_arg !== undefined) {
      tmp.withCredentials = partial_arg;
    }
    if (param$8 !== undefined) {
      tmp.adapter = Caml_option.valFromOption(param$8);
    }
    if (param$9 !== undefined) {
      tmp.auth = Caml_option.valFromOption(param$9);
    }
    if (param$10 !== undefined) {
      tmp.responseType = param$10;
    }
    if (param$11 !== undefined) {
      tmp.xsrfCookieName = param$11;
    }
    if (param$12 !== undefined) {
      tmp.xsrfHeaderName = param$12;
    }
    if (param$13 !== undefined) {
      tmp.onUploadProgress = Caml_option.valFromOption(param$13);
    }
    if (param$14 !== undefined) {
      tmp.onDownloadProgress = Caml_option.valFromOption(param$14);
    }
    if (param$15 !== undefined) {
      tmp.maxContentLength = param$15;
    }
    if (param$16 !== undefined) {
      tmp.validateStatus = Caml_option.valFromOption(param$16);
    }
    if (param$17 !== undefined) {
      tmp.maxRedirects = param$17;
    }
    if (param$18 !== undefined) {
      tmp.socketPath = param$18;
    }
    if (param$19 !== undefined) {
      tmp.proxy = Caml_option.valFromOption(param$19);
    }
    if (param$20 !== undefined) {
      tmp.cancelToken = Caml_option.valFromOption(param$20);
    }
    return tmp;
  };
}

function t_encode(v) {
  if (v === "missing") {
    return "missing";
  } else {
    return "received";
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("missing" === v) {
          return {
                  TAG: "Ok",
                  _0: "missing"
                };
        } else if ("received" === v) {
          return {
                  TAG: "Ok",
                  _0: "received"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString = t_encode;

var Status = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: toString
};

function t_encode$1(v) {
  switch (v) {
    case "missing" :
        return "missing";
    case "missingLabel" :
        return "missingLabel";
    case "unknownBarcode" :
        return "unknownBarcode";
    
  }
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("missing" === v) {
          return {
                  TAG: "Ok",
                  _0: "missing"
                };
        } else if ("missingLabel" === v) {
          return {
                  TAG: "Ok",
                  _0: "missingLabel"
                };
        } else if ("unknownBarcode" === v) {
          return {
                  TAG: "Ok",
                  _0: "unknownBarcode"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var missing = {
  id: "_9e8c0c12",
  defaultMessage: "UM manquante"
};

var missingLabel = {
  id: "_33b07209",
  defaultMessage: "Code-barres manquant"
};

var unknownBarcode = {
  id: "_a26b21cf",
  defaultMessage: "Code-barres non reconnu"
};

function humanize(intl, $$enum) {
  var message;
  switch ($$enum) {
    case "missing" :
        message = missing;
        break;
    case "missingLabel" :
        message = missingLabel;
        break;
    case "unknownBarcode" :
        message = unknownBarcode;
        break;
    
  }
  return intl.formatMessage(message);
}

var toString$1 = t_encode$1;

var UnscannedCause = {
  t_encode: t_encode$1,
  t_decode: t_decode$1,
  missing: missing,
  missingLabel: missingLabel,
  unknownBarcode: unknownBarcode,
  humanize: humanize,
  toString: toString$1
};

function t_encode$2(v) {
  switch (v) {
    case "damagedButDeliverable" :
        return "damagedButDeliverable";
    case "notDamaged" :
        return "notDamaged";
    case "undeliverable" :
        return "undeliverable";
    
  }
}

function t_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("damagedButDeliverable" === v) {
          return {
                  TAG: "Ok",
                  _0: "damagedButDeliverable"
                };
        } else if ("notDamaged" === v) {
          return {
                  TAG: "Ok",
                  _0: "notDamaged"
                };
        } else if ("undeliverable" === v) {
          return {
                  TAG: "Ok",
                  _0: "undeliverable"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$2 = t_encode$2;

var DamageStatus = {
  t_encode: t_encode$2,
  t_decode: t_decode$2,
  toString: toString$2
};

function encoder(_v) {
  return Spice.stringToJson("not implemented");
}

function decoder(json) {
  var name = json.name;
  var role = json.role;
  switch (role) {
    case "admin" :
        return {
                TAG: "Ok",
                _0: {
                  TAG: "Admin",
                  _0: {
                    name: name
                  }
                }
              };
    case "carrier" :
        return {
                TAG: "Ok",
                _0: {
                  TAG: "Carrier",
                  _0: {
                    transporterName: json.transporterName,
                    carrierNumber: json.carrierNumber,
                    transporterId: json.transporterId,
                    carrierId: json.carrierId
                  }
                }
              };
    case "hub" :
        return {
                TAG: "Ok",
                _0: {
                  TAG: "Hub",
                  _0: {
                    name: name,
                    hubId: json.hubId
                  }
                }
              };
    default:
      return {
              TAG: "Error",
              _0: {
                path: "role",
                message: "Invalid role",
                value: role
              }
            };
  }
}

var codec = [
  encoder,
  decoder
];

var t_encode$3 = encoder;

var t_decode$3 = decoder;

var UserAction = {
  encoder: encoder,
  decoder: decoder,
  codec: codec,
  t_encode: t_encode$3,
  t_decode: t_decode$3
};

function t_encode$4(v) {
  if (v === "deliverable") {
    return "deliverable";
  } else {
    return "undeliverable";
  }
}

function t_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("deliverable" === v) {
          return {
                  TAG: "Ok",
                  _0: "deliverable"
                };
        } else if ("undeliverable" === v) {
          return {
                  TAG: "Ok",
                  _0: "undeliverable"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$3 = t_encode$4;

var DamageLevel = {
  t_encode: t_encode$4,
  t_decode: t_decode$4,
  toString: toString$3
};

function t_encode$5(v) {
  switch (v) {
    case "shuttle" :
        return "shuttle";
    case "warehouse" :
        return "warehouse";
    case "deliveryReturn" :
        return "deliveryReturn";
    
  }
}

function t_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("shuttle" === v) {
          return {
                  TAG: "Ok",
                  _0: "shuttle"
                };
        } else if ("warehouse" === v) {
          return {
                  TAG: "Ok",
                  _0: "warehouse"
                };
        } else if ("deliveryReturn" === v) {
          return {
                  TAG: "Ok",
                  _0: "deliveryReturn"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$4 = t_encode$5;

var all = [
  "shuttle",
  "warehouse",
  "deliveryReturn"
];

var shuttle = {
  id: "_6921c8e9",
  defaultMessage: "Colis réceptionné en avarie"
};

var warehouse = {
  id: "_c5315cfb",
  defaultMessage: "Avarie créée en entrepôt"
};

var deliveryReturn = {
  id: "_71f5531b",
  defaultMessage: "Déclaration d'avarie suite à un retour de livraison"
};

function humanize$1(intl, t) {
  var message;
  switch (t) {
    case "shuttle" :
        message = shuttle;
        break;
    case "warehouse" :
        message = warehouse;
        break;
    case "deliveryReturn" :
        message = deliveryReturn;
        break;
    
  }
  return intl.formatMessage(message);
}

var Responsibility = {
  t_encode: t_encode$5,
  t_decode: t_decode$5,
  toString: toString$4,
  all: all,
  shuttle: shuttle,
  warehouse: warehouse,
  deliveryReturn: deliveryReturn,
  humanize: humanize$1
};

function t_encode$6(v) {
  switch (v) {
    case "toDeliver" :
        return "toDeliver";
    case "toReturn" :
        return "toReturn";
    case "toThrow" :
        return "toThrow";
    case "toDecide" :
        return "toDecide";
    
  }
}

function t_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("toDeliver" === v) {
          return {
                  TAG: "Ok",
                  _0: "toDeliver"
                };
        } else if ("toReturn" === v) {
          return {
                  TAG: "Ok",
                  _0: "toReturn"
                };
        } else if ("toThrow" === v) {
          return {
                  TAG: "Ok",
                  _0: "toThrow"
                };
        } else if ("toDecide" === v) {
          return {
                  TAG: "Ok",
                  _0: "toDecide"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$5 = t_encode$6;

var allDirectives = [
  "toDeliver",
  "toReturn",
  "toThrow",
  "toDecide"
];

var toDeliver = {
  id: "_9ba896f7",
  defaultMessage: "À livrer"
};

var toReturn = {
  id: "_86dd2709",
  defaultMessage: "À renvoyer"
};

var toThrow = {
  id: "_e10d3a07",
  defaultMessage: "À jeter"
};

var toDecide = {
  id: "_ae44529e",
  defaultMessage: "À statuer"
};

function humanize$2(intl, t) {
  var message;
  switch (t) {
    case "toDeliver" :
        message = toDeliver;
        break;
    case "toReturn" :
        message = toReturn;
        break;
    case "toThrow" :
        message = toThrow;
        break;
    case "toDecide" :
        message = toDecide;
        break;
    
  }
  return intl.formatMessage(message);
}

var Directive = {
  t_encode: t_encode$6,
  t_decode: t_decode$6,
  toString: toString$5,
  allDirectives: allDirectives,
  toDeliver: toDeliver,
  toReturn: toReturn,
  toThrow: toThrow,
  toDecide: toDecide,
  humanize: humanize$2
};

function t_encode$7(v) {
  switch (v) {
    case "atHub" :
        return "atHub";
    case "atOwnerPlace" :
        return "atOwnerPlace";
    case "inLightweightedVehicle" :
        return "inLightweightedVehicle";
    case "delivered" :
        return "delivered";
    case "thrown" :
        return "thrown";
    case "missing" :
        return "missing";
    case "lost" :
        return "lost";
    
  }
}

function t_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("atHub" === v) {
          return {
                  TAG: "Ok",
                  _0: "atHub"
                };
        } else if ("atOwnerPlace" === v) {
          return {
                  TAG: "Ok",
                  _0: "atOwnerPlace"
                };
        } else if ("inLightweightedVehicle" === v) {
          return {
                  TAG: "Ok",
                  _0: "inLightweightedVehicle"
                };
        } else if ("delivered" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivered"
                };
        } else if ("thrown" === v) {
          return {
                  TAG: "Ok",
                  _0: "thrown"
                };
        } else if ("missing" === v) {
          return {
                  TAG: "Ok",
                  _0: "missing"
                };
        } else if ("lost" === v) {
          return {
                  TAG: "Ok",
                  _0: "lost"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var atHub = {
  id: "_30bd2813",
  defaultMessage: "Dans le hub"
};

var atOwnerPlace = {
  id: "_3d301314",
  defaultMessage: "Dans la traction - Chez le donneur d'ordre"
};

var inLightweightedVehicle = {
  id: "_5757c0d2",
  defaultMessage: "Dans un véhicule léger"
};

var delivered = {
  id: "_8f9d883c",
  defaultMessage: "Livrée"
};

var thrown = {
  id: "_c9a7a7b2",
  defaultMessage: "Jetée"
};

var missing$1 = {
  id: "_cbf06f88",
  defaultMessage: "Manquante"
};

var lost = {
  id: "_298a4697",
  defaultMessage: "Perdue"
};

function humanize$3(intl, t) {
  var message;
  switch (t) {
    case "atHub" :
        message = atHub;
        break;
    case "atOwnerPlace" :
        message = atOwnerPlace;
        break;
    case "inLightweightedVehicle" :
        message = inLightweightedVehicle;
        break;
    case "delivered" :
        message = delivered;
        break;
    case "thrown" :
        message = thrown;
        break;
    case "missing" :
        message = missing$1;
        break;
    case "lost" :
        message = lost;
        break;
    
  }
  return intl.formatMessage(message);
}

var toString$6 = t_encode$7;

var DeliveryUnitLocation = {
  t_encode: t_encode$7,
  t_decode: t_decode$7,
  atHub: atHub,
  atOwnerPlace: atOwnerPlace,
  inLightweightedVehicle: inLightweightedVehicle,
  delivered: delivered,
  thrown: thrown,
  missing: missing$1,
  lost: lost,
  humanize: humanize$3,
  toString: toString$6
};

function deliveryUnitLocation_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "location",
                    false,
                    t_encode$7(v.location)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "carrierUserId",
                    false,
                    Spice.optionToJson(Identifiers.UserId.t_encode, v.carrierUserId)
                  ]
                ]));
}

function deliveryUnitLocation_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var $$location = t_decode$7(Belt_Option.getWithDefault(Js_dict.get(v, "location"), null));
  if ($$location.TAG === "Ok") {
    var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var carrierUserId = Spice.optionFromJson(Identifiers.UserId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carrierUserId"), null));
      if (carrierUserId.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  location: $$location._0,
                  hubId: hubId._0,
                  carrierUserId: carrierUserId._0
                }
              };
      }
      var e = carrierUserId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".carrierUserId" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = $$location._0;
  return {
          TAG: "Error",
          _0: {
            path: ".location" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function t_encode$8(v) {
  switch (v) {
    case "shuttle" :
        return "shuttle";
    case "routePreparation" :
        return "routePreparation";
    case "inventory" :
        return "inventory";
    case "loading" :
        return "loading";
    case "carrierReturn" :
        return "carrierReturn";
    case "damage" :
        return "damage";
    case "thrown" :
        return "thrown";
    case "carrierPickup" :
        return "carrierPickup";
    case "carrierShipping" :
        return "carrierShipping";
    case "returnToOwner" :
        return "returnToOwner";
    case "routeControl" :
        return "routeControl";
    case "wrongRouteControl" :
        return "wrongRouteControl";
    case "receivedByOwner" :
        return "receivedByOwner";
    case "lost" :
        return "lost";
    case "technicalCleaning" :
        return "technicalCleaning";
    
  }
}

function t_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("shuttle" === v) {
          return {
                  TAG: "Ok",
                  _0: "shuttle"
                };
        } else if ("routePreparation" === v) {
          return {
                  TAG: "Ok",
                  _0: "routePreparation"
                };
        } else if ("inventory" === v) {
          return {
                  TAG: "Ok",
                  _0: "inventory"
                };
        } else if ("loading" === v) {
          return {
                  TAG: "Ok",
                  _0: "loading"
                };
        } else if ("carrierReturn" === v) {
          return {
                  TAG: "Ok",
                  _0: "carrierReturn"
                };
        } else if ("damage" === v) {
          return {
                  TAG: "Ok",
                  _0: "damage"
                };
        } else if ("thrown" === v) {
          return {
                  TAG: "Ok",
                  _0: "thrown"
                };
        } else if ("carrierPickup" === v) {
          return {
                  TAG: "Ok",
                  _0: "carrierPickup"
                };
        } else if ("carrierShipping" === v) {
          return {
                  TAG: "Ok",
                  _0: "carrierShipping"
                };
        } else if ("returnToOwner" === v) {
          return {
                  TAG: "Ok",
                  _0: "returnToOwner"
                };
        } else if ("routeControl" === v) {
          return {
                  TAG: "Ok",
                  _0: "routeControl"
                };
        } else if ("wrongRouteControl" === v) {
          return {
                  TAG: "Ok",
                  _0: "wrongRouteControl"
                };
        } else if ("receivedByOwner" === v) {
          return {
                  TAG: "Ok",
                  _0: "receivedByOwner"
                };
        } else if ("lost" === v) {
          return {
                  TAG: "Ok",
                  _0: "lost"
                };
        } else if ("technicalCleaning" === v) {
          return {
                  TAG: "Ok",
                  _0: "technicalCleaning"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$7 = t_encode$8;

var arrayEnum = [
  "shuttle",
  "routePreparation",
  "inventory",
  "loading",
  "carrierReturn",
  "damage",
  "thrown",
  "carrierPickup",
  "carrierShipping",
  "returnToOwner",
  "routeControl",
  "wrongRouteControl",
  "receivedByOwner",
  "lost"
];

var allScanContexts = [
  "shuttle",
  "routePreparation",
  "inventory",
  "loading",
  "carrierReturn",
  "returnToOwner",
  "damage",
  "thrown",
  "carrierPickup",
  "carrierShipping",
  "receivedByOwner",
  "lost",
  "technicalCleaning"
];

var shuttle$1 = {
  id: "_b2abb3bf",
  defaultMessage: "Réception de traction"
};

var routePreparation = {
  id: "_922c36a2",
  defaultMessage: "Préparation tournée"
};

var inventory = {
  id: "_66c1fb35",
  defaultMessage: "Inventaire"
};

var carrierReturn = {
  id: "_46dd3a41",
  defaultMessage: "Retour livreur"
};

var loading = {
  id: "_a40405bf",
  defaultMessage: "Chargement livreur"
};

var returnToOwner = {
  id: "_dd25c79d",
  defaultMessage: "Renvoi donneur d'ordre"
};

var damage = {
  id: "_9d333917",
  defaultMessage: "Avarie"
};

var thrown$1 = {
  id: "_5d06b0e7",
  defaultMessage: "Jetée à la benne"
};

var carrierPickup = {
  id: "_148baf83",
  defaultMessage: "Retrait livreur"
};

var carrierShipping = {
  id: "_56c37071",
  defaultMessage: "Livraison livreur"
};

var routeControl = {
  id: "_d9c0e7b1",
  defaultMessage: "Contrôle tournée"
};

var wrongRouteControl = {
  id: "_bc86dc04",
  defaultMessage: "Mauvais Contrôle tournée"
};

var receivedByOwner = {
  id: "_56bd511b",
  defaultMessage: "Reçu par le donneur d'ordre"
};

var lost$1 = {
  id: "_eb60c5f0",
  defaultMessage: "Déclarée perdue"
};

var technicalCleaning = {
  id: "_c2588991",
  defaultMessage: "Nettoyage technique"
};

function humanize$4(intl, t) {
  var message;
  switch (t) {
    case "shuttle" :
        message = shuttle$1;
        break;
    case "routePreparation" :
        message = routePreparation;
        break;
    case "inventory" :
        message = inventory;
        break;
    case "loading" :
        message = loading;
        break;
    case "carrierReturn" :
        message = carrierReturn;
        break;
    case "damage" :
        message = damage;
        break;
    case "thrown" :
        message = thrown$1;
        break;
    case "carrierPickup" :
        message = carrierPickup;
        break;
    case "carrierShipping" :
        message = carrierShipping;
        break;
    case "returnToOwner" :
        message = returnToOwner;
        break;
    case "routeControl" :
        message = routeControl;
        break;
    case "wrongRouteControl" :
        message = wrongRouteControl;
        break;
    case "receivedByOwner" :
        message = receivedByOwner;
        break;
    case "lost" :
        message = lost$1;
        break;
    case "technicalCleaning" :
        message = technicalCleaning;
        break;
    
  }
  return intl.formatMessage(message);
}

var ScanContext = {
  t_encode: t_encode$8,
  t_decode: t_decode$8,
  toString: toString$7,
  arrayEnum: arrayEnum,
  allScanContexts: allScanContexts,
  shuttle: shuttle$1,
  routePreparation: routePreparation,
  inventory: inventory,
  carrierReturn: carrierReturn,
  loading: loading,
  returnToOwner: returnToOwner,
  damage: damage,
  thrown: thrown$1,
  carrierPickup: carrierPickup,
  carrierShipping: carrierShipping,
  routeControl: routeControl,
  wrongRouteControl: wrongRouteControl,
  receivedByOwner: receivedByOwner,
  lost: lost$1,
  technicalCleaning: technicalCleaning,
  humanize: humanize$4
};

function t_encode$9(v) {
  if (v === "returned") {
    return "returned";
  } else {
    return "thrown";
  }
}

function t_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("returned" === v) {
          return {
                  TAG: "Ok",
                  _0: "returned"
                };
        } else if ("thrown" === v) {
          return {
                  TAG: "Ok",
                  _0: "thrown"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var arrayEnum$1 = [
  "returned",
  "thrown"
];

var toString$8 = t_encode$9;

var AwaitingContext = {
  t_encode: t_encode$9,
  t_decode: t_decode$9,
  arrayEnum: arrayEnum$1,
  toString: toString$8
};

function t_encode$10(v) {
  if (v === "freeScan") {
    return "freeScan";
  } else {
    return "wrongHub";
  }
}

function t_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("freeScan" === v) {
          return {
                  TAG: "Ok",
                  _0: "freeScan"
                };
        } else if ("wrongHub" === v) {
          return {
                  TAG: "Ok",
                  _0: "wrongHub"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$9 = t_encode$10;

var Anomaly = {
  t_encode: t_encode$10,
  t_decode: t_decode$10,
  toString: toString$9
};

function orderInfo_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.id)
                  ],
                  [
                    "deliveryUnitCount",
                    false,
                    Spice.intToJson(v.deliveryUnitCount)
                  ],
                  [
                    "clientId",
                    false,
                    Spice.optionToJson(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "warehouseCode",
                    false,
                    Spice.optionToJson(Identifiers.WarehouseCode.t_encode, v.warehouseCode)
                  ],
                  [
                    "parcelDirective",
                    false,
                    t_encode$6(v.parcelDirective)
                  ]
                ]));
}

function orderInfo_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var deliveryUnitCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryUnitCount"), null));
    if (deliveryUnitCount.TAG === "Ok") {
      var clientId = Spice.optionFromJson(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
      if (clientId.TAG === "Ok") {
        var warehouseCode = Spice.optionFromJson(Identifiers.WarehouseCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "warehouseCode"), null));
        if (warehouseCode.TAG === "Ok") {
          var parcelDirective = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "parcelDirective"), null));
          if (parcelDirective.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      id: id._0,
                      deliveryUnitCount: deliveryUnitCount._0,
                      clientId: clientId._0,
                      warehouseCode: warehouseCode._0,
                      parcelDirective: parcelDirective._0
                    }
                  };
          }
          var e = parcelDirective._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".parcelDirective" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = warehouseCode._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".warehouseCode" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = clientId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".clientId" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = deliveryUnitCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryUnitCount" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function anomaly_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "reason",
                    false,
                    t_encode$10(v.reason)
                  ]]));
}

function anomaly_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var reason = t_decode$10(Belt_Option.getWithDefault(Js_dict.get(v, "reason"), null));
  if (reason.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              reason: reason._0
            }
          };
  }
  var e = reason._0;
  return {
          TAG: "Error",
          _0: {
            path: ".reason" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "order",
                    false,
                    Spice.optionToJson(orderInfo_encode, v.order)
                  ],
                  [
                    "anomaly",
                    false,
                    Spice.optionToJson(anomaly_encode, v.anomaly)
                  ]
                ]));
}

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var order = Spice.optionFromJson(orderInfo_decode, Belt_Option.getWithDefault(Js_dict.get(v, "order"), null));
  if (order.TAG === "Ok") {
    var anomaly = Spice.optionFromJson(anomaly_decode, Belt_Option.getWithDefault(Js_dict.get(v, "anomaly"), null));
    if (anomaly.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                order: order._0,
                anomaly: anomaly._0
              }
            };
    }
    var e = anomaly._0;
    return {
            TAG: "Error",
            _0: {
              path: ".anomaly" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = order._0;
  return {
          TAG: "Error",
          _0: {
            path: ".order" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "date",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.date)
                  ],
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "context",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(t_encode$8, v.context)
                  ],
                  [
                    "unscannedCause",
                    false,
                    Spice.optionToJson(t_encode$1, v.unscannedCause)
                  ]
                ]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
    if (barcode.TAG === "Ok") {
      var context = Toolkit__Decoders.$$Option.t_decode(t_decode$8, Belt_Option.getWithDefault(Js_dict.get(v, "context"), null));
      if (context.TAG === "Ok") {
        var unscannedCause = Spice.optionFromJson(t_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "unscannedCause"), null));
        if (unscannedCause.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    date: date._0,
                    barcode: barcode._0,
                    context: context._0,
                    unscannedCause: unscannedCause._0
                  }
                };
        }
        var e = unscannedCause._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".unscannedCause" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = context._0;
      return {
              TAG: "Error",
              _0: {
                path: ".context" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = barcode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".barcode" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function config$1(param) {
  return {
          kyInstance: kyInstance,
          path: "hubs/" + param[0] + "/units/scan",
          requestOptions: {
            method: "POST",
            json: body_encode(param[1])
          },
          mapCustomErrors: (function (error) {
              var match = error.response;
              return $$Promise.resolved(match !== undefined && match.status === 404 ? ({
                              TAG: "Custom",
                              _0: "unknownHub"
                            }) : ({
                              TAG: "Unknown",
                              _0: error
                            }));
            })
        };
}

var Scan = {
  Anomaly: Anomaly,
  orderInfo_encode: orderInfo_encode,
  orderInfo_decode: orderInfo_decode,
  anomaly_encode: anomaly_encode,
  anomaly_decode: anomaly_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  body_encode: body_encode,
  body_decode: body_decode,
  config: config$1
};

function order_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.id)
                  ],
                  [
                    "deliveryUnitCount",
                    false,
                    Spice.intToJson(v.deliveryUnitCount)
                  ]
                ]));
}

function order_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var deliveryUnitCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryUnitCount"), null));
    if (deliveryUnitCount.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                deliveryUnitCount: deliveryUnitCount._0
              }
            };
    }
    var e = deliveryUnitCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryUnitCount" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "order",
                    false,
                    Spice.optionToJson(order_encode, v.order)
                  ]]));
}

function response_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var order = Spice.optionFromJson(order_decode, Belt_Option.getWithDefault(Js_dict.get(v, "order"), null));
  if (order.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              order: order._0
            }
          };
  }
  var e = order._0;
  return {
          TAG: "Error",
          _0: {
            path: ".order" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function body_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "context",
                    false,
                    t_encode$8(v.context)
                  ]
                ]));
}

function body_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var context = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "context"), null));
    if (context.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                barcode: barcode._0,
                context: context._0
              }
            };
    }
    var e = context._0;
    return {
            TAG: "Error",
            _0: {
              path: ".context" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$2(body) {
  return {
          kyInstance: kyInstance,
          path: "units/scan",
          requestOptions: {
            method: "POST",
            json: body_encode$1(body)
          }
        };
}

var AdminScan = {
  order_encode: order_encode,
  order_decode: order_decode,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  config: config$2
};

function scanEvent_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "context",
                    false,
                    t_encode$8(v.context)
                  ]
                ]));
}

function scanEvent_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var context = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "context"), null));
    if (context.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                barcode: barcode._0,
                context: context._0
              }
            };
    }
    var e = context._0;
    return {
            TAG: "Error",
            _0: {
              path: ".context" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "scans",
                    false,
                    Spice.arrayToJson(scanEvent_encode, v.scans)
                  ]]));
}

function body_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var scans = Spice.arrayFromJson(scanEvent_decode, Belt_Option.getWithDefault(Js_dict.get(v, "scans"), null));
  if (scans.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              scans: scans._0
            }
          };
  }
  var e = scans._0;
  return {
          TAG: "Error",
          _0: {
            path: ".scans" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$2 = Spice.unitToJson;

var response_decode$2 = Spice.unitFromJson;

function config$3(body) {
  return {
          kyInstance: kyInstance,
          path: "units/scans/bulk",
          requestOptions: {
            method: "POST",
            json: body_encode$2(body)
          }
        };
}

var AdminBulkScan = {
  scanEvent_encode: scanEvent_encode,
  scanEvent_decode: scanEvent_decode,
  body_encode: body_encode$2,
  body_decode: body_decode$2,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  config: config$3
};

var response_encode$3 = Spice.unitToJson;

var response_decode$3 = Spice.unitFromJson;

var body_encode$3 = body_encode$1;

var body_decode$3 = body_decode$1;

function config$4(body) {
  return {
          kyInstance: kyInstance,
          path: "units/scan",
          requestOptions: {
            method: "POST",
            json: body_encode$1(body)
          }
        };
}

var AdminOrphanScan = {
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  body_encode: body_encode$3,
  body_decode: body_decode$3,
  config: config$4
};

function response_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "deliveryUnitScansCount",
                    false,
                    Spice.intToJson(v.deliveryUnitScansCount)
                  ],
                  [
                    "orphanScansCount",
                    false,
                    Spice.intToJson(v.orphanScansCount)
                  ],
                  [
                    "ordersCount",
                    false,
                    Spice.intToJson(v.ordersCount)
                  ]
                ]));
}

function response_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryUnitScansCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryUnitScansCount"), null));
  if (deliveryUnitScansCount.TAG === "Ok") {
    var orphanScansCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "orphanScansCount"), null));
    if (orphanScansCount.TAG === "Ok") {
      var ordersCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ordersCount"), null));
      if (ordersCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  deliveryUnitScansCount: deliveryUnitScansCount._0,
                  orphanScansCount: orphanScansCount._0,
                  ordersCount: ordersCount._0
                }
              };
      }
      var e = ordersCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ordersCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = orphanScansCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".orphanScansCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = deliveryUnitScansCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryUnitScansCount" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec(param) {
  var warehouseCodeFilter = param[4];
  var clientIdFilter = param[3];
  var config$5 = Belt_Option.mapWithDefault(param[2], config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (function (param) {
          return config(undefined, undefined, undefined, undefined, undefined, {
                        start: Toolkit__Decoders.Datetime.t_encode(param.start),
                        end: Toolkit__Decoders.Datetime.t_encode(param.end),
                        clientId: Belt_Option.map(clientIdFilter, (function (prim) {
                                return prim;
                              })),
                        warehouseCode: Belt_Option.map(warehouseCodeFilter, (function (prim) {
                                return prim;
                              }))
                      }, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        }));
  return Axios.WithResult.get(undefined, config$5, response_decode$4, undefined, baseUrl + "/hubs/" + param[0] + "/context/" + t_encode$8(param[1]) + "/counts");
}

var Config = {
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  exec: exec
};

var $$Request = Toolkit__Request.Make({
      exec: exec
    });

var ContextCount = {
  Config: Config,
  $$Request: $$Request
};

function response_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "barcodes",
                    false,
                    Spice.arrayToJson(Identifiers.Barcode.t_encode, v.barcodes)
                  ]]));
}

function response_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcodes = Spice.arrayFromJson(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcodes"), null));
  if (barcodes.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              barcodes: barcodes._0
            }
          };
  }
  var e = barcodes._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcodes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function body_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "barcodes",
                    false,
                    Spice.arrayToJson(Identifiers.Barcode.t_encode, v.barcodes)
                  ]]));
}

function body_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcodes = Spice.arrayFromJson(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcodes"), null));
  if (barcodes.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              barcodes: barcodes._0
            }
          };
  }
  var e = barcodes._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcodes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$5(param) {
  var barcodes = param[2];
  var context = param[1];
  var hubId = param[0];
  return {
          kyInstance: kyInstance,
          path: "hubs/" + hubId + "/context/" + t_encode$8(context) + "/scanned",
          requestOptions: {
            method: "POST",
            json: body_encode$4({
                  barcodes: barcodes
                })
          },
          key: [
            "HubContextCountForBarcodes",
            hubId,
            t_encode$8(context),
            Curry._2(Toolkit__Primitives_Array.$$Array.map, barcodes, Identifiers.Barcode.toString).join("-")
          ]
        };
}

var HubContextCountForBarcodes = {
  response_encode: response_encode$5,
  response_decode: response_decode$5,
  body_encode: body_encode$4,
  body_decode: body_decode$4,
  config: config$5
};

function response_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveryUnitCount",
                    false,
                    Spice.intToJson(v.deliveryUnitCount)
                  ]]));
}

function response_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryUnitCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryUnitCount"), null));
  if (deliveryUnitCount.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveryUnitCount: deliveryUnitCount._0
            }
          };
  }
  var e = deliveryUnitCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryUnitCount" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function searchParams_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "awaitingContext",
                    false,
                    t_encode$9(v.awaitingContext)
                  ]]));
}

function searchParams_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var awaitingContext = t_decode$9(Belt_Option.getWithDefault(Js_dict.get(v, "awaitingContext"), null));
  if (awaitingContext.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              awaitingContext: awaitingContext._0
            }
          };
  }
  var e = awaitingContext._0;
  return {
          TAG: "Error",
          _0: {
            path: ".awaitingContext" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$6(param) {
  var awaitingContext = param[1];
  var hubId = param[0];
  return {
          kyInstance: kyInstance,
          path: "hubs/" + hubId + "/units/count",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(searchParams_encode({
                      awaitingContext: awaitingContext
                    }))
          },
          key: [
            "v6ParcelAwaitingContextCount",
            hubId,
            t_encode$9(awaitingContext)
          ]
        };
}

var AwaitingContextCount = {
  response_encode: response_encode$6,
  response_decode: response_decode$6,
  searchParams_encode: searchParams_encode,
  searchParams_decode: searchParams_decode,
  config: config$6
};

function deliveryUnit_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "primaryBarcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.primaryBarcode)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "lastScanDate",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.lastScanDate)
                  ]
                ]));
}

function deliveryUnit_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var primaryBarcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "primaryBarcode"), null));
  if (primaryBarcode.TAG === "Ok") {
    var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
    if (description.TAG === "Ok") {
      var lastScanDate = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastScanDate"), null));
      if (lastScanDate.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  primaryBarcode: primaryBarcode._0,
                  description: description._0,
                  lastScanDate: lastScanDate._0
                }
              };
      }
      var e = lastScanDate._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lastScanDate" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = description._0;
    return {
            TAG: "Error",
            _0: {
              path: ".description" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = primaryBarcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".primaryBarcode" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function order_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "deliveryUnits",
                    false,
                    Spice.arrayToJson(deliveryUnit_encode, v.deliveryUnits)
                  ]
                ]));
}

function order_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      var deliveryUnits = Spice.arrayFromJson(deliveryUnit_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryUnits"), null));
      if (deliveryUnits.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  orderId: orderId._0,
                  clientId: clientId._0,
                  deliveryUnits: deliveryUnits._0
                }
              };
      }
      var e = deliveryUnits._0;
      return {
              TAG: "Error",
              _0: {
                path: ".deliveryUnits" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orders",
                    false,
                    Spice.arrayToJson(order_encode$1, v.orders)
                  ]]));
}

function response_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orders = Spice.arrayFromJson(order_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "orders"), null));
  if (orders.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orders: orders._0
            }
          };
  }
  var e = orders._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orders" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function searchParams_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "awaitingContext",
                    false,
                    t_encode$9(v.awaitingContext)
                  ]]));
}

function searchParams_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var awaitingContext = t_decode$9(Belt_Option.getWithDefault(Js_dict.get(v, "awaitingContext"), null));
  if (awaitingContext.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              awaitingContext: awaitingContext._0
            }
          };
  }
  var e = awaitingContext._0;
  return {
          TAG: "Error",
          _0: {
            path: ".awaitingContext" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$7(param) {
  var awaitingContext = param[1];
  var hubId = param[0];
  return {
          kyInstance: kyInstance,
          path: "hubs/" + hubId + "/units/list",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(searchParams_encode$1({
                      awaitingContext: awaitingContext
                    }))
          },
          key: [
            "v6ParcelAwaitingContextList",
            hubId,
            t_encode$9(awaitingContext)
          ]
        };
}

var AwaitingContextList = {
  deliveryUnit_encode: deliveryUnit_encode,
  deliveryUnit_decode: deliveryUnit_decode,
  order_encode: order_encode$1,
  order_decode: order_decode$1,
  response_encode: response_encode$7,
  response_decode: response_decode$7,
  searchParams_encode: searchParams_encode$1,
  searchParams_decode: searchParams_decode$1,
  config: config$7
};

function body_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orderIds",
                    false,
                    Spice.arrayToJson(Identifiers.OrderId.t_encode, v.orderIds)
                  ]]));
}

function body_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderIds = Spice.arrayFromJson(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderIds"), null));
  if (orderIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orderIds: orderIds._0
            }
          };
  }
  var e = orderIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function argument_encode(v) {
  return [
          Curry._1(Identifiers.HubId.t_encode, v[0]),
          body_encode$5(v[1])
        ];
}

function argument_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.HubId.t_decode, v0);
  var match$1 = body_decode$5(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "unitsCount",
                    false,
                    Spice.intToJson(v.unitsCount)
                  ]]));
}

function response_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var unitsCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "unitsCount"), null));
  if (unitsCount.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              unitsCount: unitsCount._0
            }
          };
  }
  var e = unitsCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".unitsCount" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$8(param) {
  var body = param[1];
  var hubId = param[0];
  return {
          kyInstance: kyInstance,
          path: "hubs/" + hubId + "/units/expected/count",
          requestOptions: {
            method: "POST",
            json: body_encode$5(body)
          },
          key: [
            "ExpectedOrdersUnitCount",
            hubId,
            JSON.stringify(body_encode$5(body))
          ]
        };
}

var ExpectedOrdersUnitCount = {
  body_encode: body_encode$5,
  body_decode: body_decode$5,
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  response_encode: response_encode$8,
  response_decode: response_decode$8,
  config: config$8
};

function filters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.DateTimeSlot.t_encode, v.receivedBetween)
                  ],
                  [
                    "hubIds",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.HubId.t_encode, param);
                          }), v.hubIds)
                  ],
                  [
                    "status",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode, param);
                          }), v.status)
                  ],
                  [
                    "isTracked",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.$$Boolean.t_encode, v.isTracked)
                  ],
                  [
                    "damageStatus",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode$2, param);
                          }), v.damageStatus)
                  ]
                ]));
}

function filters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Toolkit__Decoders.$$Option.t_decode(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.DateTimeSlot.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
    if (receivedBetween.TAG === "Ok") {
      var hubIds = Toolkit__Decoders.$$Option.t_decode((function (param) {
              return Spice.arrayFromJson(Identifiers.HubId.t_decode, param);
            }), Belt_Option.getWithDefault(Js_dict.get(v, "hubIds"), null));
      if (hubIds.TAG === "Ok") {
        var status = Toolkit__Decoders.$$Option.t_decode((function (param) {
                return Spice.arrayFromJson(t_decode, param);
              }), Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
        if (status.TAG === "Ok") {
          var isTracked = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.$$Boolean.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "isTracked"), null));
          if (isTracked.TAG === "Ok") {
            var damageStatus = Toolkit__Decoders.$$Option.t_decode((function (param) {
                    return Spice.arrayFromJson(t_decode$2, param);
                  }), Belt_Option.getWithDefault(Js_dict.get(v, "damageStatus"), null));
            if (damageStatus.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        barcode: barcode._0,
                        receivedBetween: receivedBetween._0,
                        hubIds: hubIds._0,
                        status: status._0,
                        isTracked: isTracked._0,
                        damageStatus: damageStatus._0
                      }
                    };
            }
            var e = damageStatus._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".damageStatus" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = isTracked._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".isTracked" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = status._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".status" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = hubIds._0;
      return {
              TAG: "Error",
              _0: {
                path: ".hubIds" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = receivedBetween._0;
    return {
            TAG: "Error",
            _0: {
              path: ".receivedBetween" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function sort_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.receivedBetween)
                  ]]));
}

function sort_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
  if (receivedBetween.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              receivedBetween: receivedBetween._0
            }
          };
  }
  var e = receivedBetween._0;
  return {
          TAG: "Error",
          _0: {
            path: ".receivedBetween" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function pagination_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function pagination_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode(v.pagination)
                  ]
                ]));
}

function body_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function lastScanInfo_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "date",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.date)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "context",
                    false,
                    t_encode$8(v.context)
                  ],
                  [
                    "user",
                    false,
                    Spice.optionToJson(t_encode$3, v.user)
                  ]
                ]));
}

function lastScanInfo_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var context = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "context"), null));
      if (context.TAG === "Ok") {
        var user = Spice.optionFromJson(t_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "user"), null));
        if (user.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    date: date._0,
                    hubId: hubId._0,
                    context: context._0,
                    user: user._0
                  }
                };
        }
        var e = user._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".user" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = context._0;
      return {
              TAG: "Error",
              _0: {
                path: ".context" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function parcel_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lastHubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.lastHubId)
                  ],
                  [
                    "lastScanDate",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.lastScanDate)
                  ],
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "matchingSecondaryBarcodes",
                    false,
                    Spice.optionToJson((function (param) {
                            return Spice.arrayToJson(Identifiers.Barcode.t_encode, param);
                          }), v.matchingSecondaryBarcodes)
                  ],
                  [
                    "lastScanInfo",
                    false,
                    Spice.optionToJson(lastScanInfo_encode, v.lastScanInfo)
                  ],
                  [
                    "status",
                    false,
                    t_encode(v.status)
                  ],
                  [
                    "orderIsTracked",
                    false,
                    Spice.boolToJson(v.orderIsTracked)
                  ],
                  [
                    "damageStatus",
                    false,
                    t_encode$2(v.damageStatus)
                  ]
                ]));
}

function parcel_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lastHubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastHubId"), null));
  if (lastHubId.TAG === "Ok") {
    var lastScanDate = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastScanDate"), null));
    if (lastScanDate.TAG === "Ok") {
      var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
      if (barcode.TAG === "Ok") {
        var matchingSecondaryBarcodes = Spice.optionFromJson((function (param) {
                return Spice.arrayFromJson(Identifiers.Barcode.t_decode, param);
              }), Belt_Option.getWithDefault(Js_dict.get(v, "matchingSecondaryBarcodes"), null));
        if (matchingSecondaryBarcodes.TAG === "Ok") {
          var lastScanInfo = Spice.optionFromJson(lastScanInfo_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastScanInfo"), null));
          if (lastScanInfo.TAG === "Ok") {
            var status = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
            if (status.TAG === "Ok") {
              var orderIsTracked = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "orderIsTracked"), null));
              if (orderIsTracked.TAG === "Ok") {
                var damageStatus = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "damageStatus"), null));
                if (damageStatus.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            lastHubId: lastHubId._0,
                            lastScanDate: lastScanDate._0,
                            barcode: barcode._0,
                            matchingSecondaryBarcodes: matchingSecondaryBarcodes._0,
                            lastScanInfo: lastScanInfo._0,
                            status: status._0,
                            orderIsTracked: orderIsTracked._0,
                            damageStatus: damageStatus._0
                          }
                        };
                }
                var e = damageStatus._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".damageStatus" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = orderIsTracked._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".orderIsTracked" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = status._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".status" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = lastScanInfo._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".lastScanInfo" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = matchingSecondaryBarcodes._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".matchingSecondaryBarcodes" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = barcode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".barcode" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = lastScanDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lastScanDate" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = lastHubId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lastHubId" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function response_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "units",
                    false,
                    Spice.arrayToJson(parcel_encode, v.units)
                  ]
                ]));
}

function response_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var units = Spice.arrayFromJson(parcel_decode, Belt_Option.getWithDefault(Js_dict.get(v, "units"), null));
    if (units.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagesCount: pagesCount._0,
                units: units._0
              }
            };
    }
    var e = units._0;
    return {
            TAG: "Error",
            _0: {
              path: ".units" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$9(body) {
  return {
          kyInstance: kyInstance,
          path: "units/list",
          requestOptions: {
            method: "POST",
            json: body_encode$6(body)
          },
          key: [
            "UnitList",
            JSON.stringify(body_encode$6(body))
          ]
        };
}

var List = {
  filters_encode: filters_encode,
  filters_decode: filters_decode,
  sort_encode: sort_encode,
  sort_decode: sort_decode,
  pagination_encode: pagination_encode,
  pagination_decode: pagination_decode,
  body_encode: body_encode$6,
  body_decode: body_decode$6,
  lastScanInfo_encode: lastScanInfo_encode,
  lastScanInfo_decode: lastScanInfo_decode,
  parcel_encode: parcel_encode,
  parcel_decode: parcel_decode,
  response_encode: response_encode$9,
  response_decode: response_decode$9,
  config: config$9
};

function filters_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.DateTimeSlot.t_encode, v.receivedBetween)
                  ],
                  [
                    "hubIds",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.HubId.t_encode, param);
                          }), v.hubIds)
                  ]
                ]));
}

function filters_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Toolkit__Decoders.$$Option.t_decode(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.DateTimeSlot.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
    if (receivedBetween.TAG === "Ok") {
      var hubIds = Toolkit__Decoders.$$Option.t_decode((function (param) {
              return Spice.arrayFromJson(Identifiers.HubId.t_decode, param);
            }), Belt_Option.getWithDefault(Js_dict.get(v, "hubIds"), null));
      if (hubIds.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  barcode: barcode._0,
                  receivedBetween: receivedBetween._0,
                  hubIds: hubIds._0
                }
              };
      }
      var e = hubIds._0;
      return {
              TAG: "Error",
              _0: {
                path: ".hubIds" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = receivedBetween._0;
    return {
            TAG: "Error",
            _0: {
              path: ".receivedBetween" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function sort_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.receivedBetween)
                  ]]));
}

function sort_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
  if (receivedBetween.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              receivedBetween: receivedBetween._0
            }
          };
  }
  var e = receivedBetween._0;
  return {
          TAG: "Error",
          _0: {
            path: ".receivedBetween" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function pagination_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function pagination_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode$1(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode$1(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode$1(v.pagination)
                  ]
                ]));
}

function body_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function lastScanInfo_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "date",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.date)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "context",
                    false,
                    t_encode$8(v.context)
                  ],
                  [
                    "user",
                    false,
                    Spice.optionToJson(t_encode$3, v.user)
                  ]
                ]));
}

function lastScanInfo_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var context = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "context"), null));
      if (context.TAG === "Ok") {
        var user = Spice.optionFromJson(t_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "user"), null));
        if (user.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    date: date._0,
                    hubId: hubId._0,
                    context: context._0,
                    user: user._0
                  }
                };
        }
        var e = user._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".user" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = context._0;
      return {
              TAG: "Error",
              _0: {
                path: ".context" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function unit__encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lastHubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.lastHubId)
                  ],
                  [
                    "lastScanDate",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.lastScanDate)
                  ],
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "lastScanInfo",
                    false,
                    lastScanInfo_encode$1(v.lastScanInfo)
                  ]
                ]));
}

function unit__decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lastHubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastHubId"), null));
  if (lastHubId.TAG === "Ok") {
    var lastScanDate = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastScanDate"), null));
    if (lastScanDate.TAG === "Ok") {
      var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
      if (barcode.TAG === "Ok") {
        var lastScanInfo = lastScanInfo_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "lastScanInfo"), null));
        if (lastScanInfo.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    lastHubId: lastHubId._0,
                    lastScanDate: lastScanDate._0,
                    barcode: barcode._0,
                    lastScanInfo: lastScanInfo._0
                  }
                };
        }
        var e = lastScanInfo._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".lastScanInfo" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = barcode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".barcode" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = lastScanDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lastScanDate" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = lastHubId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lastHubId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "units",
                    false,
                    Spice.arrayToJson(unit__encode, v.units)
                  ]
                ]));
}

function response_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var units = Spice.arrayFromJson(unit__decode, Belt_Option.getWithDefault(Js_dict.get(v, "units"), null));
    if (units.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagesCount: pagesCount._0,
                units: units._0
              }
            };
    }
    var e = units._0;
    return {
            TAG: "Error",
            _0: {
              path: ".units" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$1(body) {
  return Axios.WithResult.post(undefined, body_encode$7(body), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$10, undefined, baseUrl + "/scans/orphans/list");
}

var Config$1 = {
  filters_encode: filters_encode$1,
  filters_decode: filters_decode$1,
  sort_encode: sort_encode$1,
  sort_decode: sort_decode$1,
  pagination_encode: pagination_encode$1,
  pagination_decode: pagination_decode$1,
  body_encode: body_encode$7,
  body_decode: body_decode$7,
  lastScanInfo_encode: lastScanInfo_encode$1,
  lastScanInfo_decode: lastScanInfo_decode$1,
  unit__encode: unit__encode,
  unit__decode: unit__decode,
  response_encode: response_encode$10,
  response_decode: response_decode$10,
  exec: exec$1
};

var $$Request$1 = Toolkit__Request.Make({
      exec: exec$1
    });

var OrphansList = {
  Config: Config$1,
  $$Request: $$Request$1
};

function event_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Spice.optionToJson(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "user",
                    false,
                    Spice.optionToJson(t_encode$3, v.user)
                  ],
                  [
                    "date",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.date)
                  ],
                  [
                    "mobileEventDate",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.mobileEventDate)
                  ],
                  [
                    "context",
                    false,
                    t_encode$8(v.context)
                  ],
                  [
                    "unscannedCause",
                    false,
                    Spice.optionToJson(t_encode$1, v.unscannedCause)
                  ]
                ]));
}

function event_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Spice.optionFromJson(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var user = Spice.optionFromJson(t_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "user"), null));
      if (user.TAG === "Ok") {
        var date = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
        if (date.TAG === "Ok") {
          var mobileEventDate = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "mobileEventDate"), null));
          if (mobileEventDate.TAG === "Ok") {
            var context = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "context"), null));
            if (context.TAG === "Ok") {
              var unscannedCause = Spice.optionFromJson(t_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "unscannedCause"), null));
              if (unscannedCause.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          barcode: barcode._0,
                          hubId: hubId._0,
                          user: user._0,
                          date: date._0,
                          mobileEventDate: mobileEventDate._0,
                          context: context._0,
                          unscannedCause: unscannedCause._0
                        }
                      };
              }
              var e = unscannedCause._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".unscannedCause" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = context._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".context" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = mobileEventDate._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".mobileEventDate" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = date._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".date" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = user._0;
      return {
              TAG: "Error",
              _0: {
                path: ".user" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function damage_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "damageLevel",
                    false,
                    t_encode$4(v.damageLevel)
                  ],
                  [
                    "responsibility",
                    false,
                    t_encode$5(v.responsibility)
                  ],
                  [
                    "comments",
                    false,
                    Spice.stringToJson(v.comments)
                  ],
                  [
                    "reportingDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.reportingDate)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ]
                ]));
}

function damage_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var damageLevel = t_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "damageLevel"), null));
    if (damageLevel.TAG === "Ok") {
      var responsibility = t_decode$5(Belt_Option.getWithDefault(Js_dict.get(v, "responsibility"), null));
      if (responsibility.TAG === "Ok") {
        var comments = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "comments"), null));
        if (comments.TAG === "Ok") {
          var reportingDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "reportingDate"), null));
          if (reportingDate.TAG === "Ok") {
            var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
            if (hubId.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        barcode: barcode._0,
                        damageLevel: damageLevel._0,
                        responsibility: responsibility._0,
                        comments: comments._0,
                        reportingDate: reportingDate._0,
                        hubId: hubId._0
                      }
                    };
            }
            var e = hubId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".hubId" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = reportingDate._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".reportingDate" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = comments._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".comments" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = responsibility._0;
      return {
              TAG: "Error",
              _0: {
                path: ".responsibility" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = damageLevel._0;
    return {
            TAG: "Error",
            _0: {
              path: ".damageLevel" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function response_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "events",
                    false,
                    Spice.arrayToJson(event_encode, v.events)
                  ],
                  [
                    "orderId",
                    false,
                    Spice.optionToJson(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "secondaryBarcodes",
                    false,
                    Spice.arrayToJson(Identifiers.Barcode.t_encode, v.secondaryBarcodes)
                  ],
                  [
                    "damages",
                    false,
                    Spice.arrayToJson(damage_encode, v.damages)
                  ],
                  [
                    "damageStatus",
                    false,
                    t_encode$2(v.damageStatus)
                  ],
                  [
                    "directive",
                    false,
                    t_encode$6(v.directive)
                  ],
                  [
                    "deliveryUnitLocation",
                    false,
                    deliveryUnitLocation_encode(v.deliveryUnitLocation)
                  ]
                ]));
}

function response_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var events = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "events"), (function (param) {
              return Spice.arrayFromJson(event_decode, param);
            })), {
        TAG: "Ok",
        _0: []
      });
  if (events.TAG === "Ok") {
    var orderId = Spice.optionFromJson(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
    if (orderId.TAG === "Ok") {
      var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
      if (barcode.TAG === "Ok") {
        var secondaryBarcodes = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "secondaryBarcodes"), (function (param) {
                    return Spice.arrayFromJson(Identifiers.Barcode.t_decode, param);
                  })), {
              TAG: "Ok",
              _0: []
            });
        if (secondaryBarcodes.TAG === "Ok") {
          var damages = Spice.arrayFromJson(damage_decode, Belt_Option.getWithDefault(Js_dict.get(v, "damages"), null));
          if (damages.TAG === "Ok") {
            var damageStatus = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "damageStatus"), null));
            if (damageStatus.TAG === "Ok") {
              var directive = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "directive"), t_decode$6), {
                    TAG: "Ok",
                    _0: "toDeliver"
                  });
              if (directive.TAG === "Ok") {
                var deliveryUnitLocation = deliveryUnitLocation_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryUnitLocation"), null));
                if (deliveryUnitLocation.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            events: events._0,
                            orderId: orderId._0,
                            barcode: barcode._0,
                            secondaryBarcodes: secondaryBarcodes._0,
                            damages: damages._0,
                            damageStatus: damageStatus._0,
                            directive: directive._0,
                            deliveryUnitLocation: deliveryUnitLocation._0
                          }
                        };
                }
                var e = deliveryUnitLocation._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".deliveryUnitLocation" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = directive._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".directive" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = damageStatus._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".damageStatus" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = damages._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".damages" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = secondaryBarcodes._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".secondaryBarcodes" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = barcode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".barcode" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = orderId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".orderId" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = events._0;
  return {
          TAG: "Error",
          _0: {
            path: ".events" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function config$10(barcode) {
  return {
          kyInstance: kyInstance,
          path: "units/" + Identifiers.Barcode.toString(barcode),
          requestOptions: {
            method: "GET"
          },
          key: [
            "GetParcel",
            barcode
          ]
        };
}

var EventDetail = {
  event_encode: event_encode,
  event_decode: event_decode,
  damage_encode: damage_encode,
  damage_decode: damage_decode,
  response_encode: response_encode$11,
  response_decode: response_decode$11,
  config: config$10
};

function response_encode$12(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "primaryBarcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.primaryBarcode)
                  ]
                ]));
}

function response_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var primaryBarcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "primaryBarcode"), null));
    if (primaryBarcode.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                orderId: orderId._0,
                primaryBarcode: primaryBarcode._0
              }
            };
    }
    var e = primaryBarcode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".primaryBarcode" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function error_encode(v) {
  return "BARCODE_NOT_FOUND";
}

function error_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("BARCODE_NOT_FOUND" === v) {
          return {
                  TAG: "Ok",
                  _0: "BARCODE_NOT_FOUND"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function errorBody_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "code",
                    false,
                    "BARCODE_NOT_FOUND"
                  ]]));
}

function errorBody_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = error_decode(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$2(barcode) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$12, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var err = errorBody_decode(match.data);
                if (err.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: err._0.code
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            err._0
                          ]
                        };
                }
              }), baseUrl + "/units/" + Identifiers.Barcode.toString(barcode) + "/order");
}

var Config$2 = {
  response_encode: response_encode$12,
  response_decode: response_decode$12,
  error_encode: error_encode,
  error_decode: error_decode,
  errorBody_encode: errorBody_encode,
  errorBody_decode: errorBody_decode,
  exec: exec$2
};

var $$Request$2 = Toolkit__Request.Make({
      exec: exec$2
    });

var Order = {
  Config: Config$2,
  $$Request: $$Request$2
};

function body_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "expectedHubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.expectedHubId)
                  ]]));
}

function body_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var expectedHubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "expectedHubId"), null));
  if (expectedHubId.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              expectedHubId: expectedHubId._0
            }
          };
  }
  var e = expectedHubId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".expectedHubId" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function encoder$1(_v) {
  return Spice.stringToJson("not implemented");
}

function decoder$1(json) {
  var role = json.role;
  switch (role) {
    case "admin" :
        return {
                TAG: "Ok",
                _0: "Admin"
              };
    case "carrier" :
        return {
                TAG: "Ok",
                _0: {
                  TAG: "Carrier",
                  _0: {
                    transporterName: json.transporterName
                  }
                }
              };
    case "hub" :
        return {
                TAG: "Ok",
                _0: Belt_Option.mapWithDefault(json.name, "OtherHub", (function (name) {
                        return {
                                TAG: "Hub",
                                _0: name
                              };
                      }))
              };
    default:
      return {
              TAG: "Error",
              _0: {
                path: "role",
                message: "Invalid role",
                value: role
              }
            };
  }
}

var codec$1 = [
  encoder$1,
  decoder$1
];

var t_encode$11 = encoder$1;

var t_decode$11 = decoder$1;

var UserAction$1 = {
  encoder: encoder$1,
  decoder: decoder$1,
  codec: codec$1,
  t_encode: t_encode$11,
  t_decode: t_decode$11
};

function event_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "user",
                    false,
                    Spice.optionToJson(t_encode$11, v.user)
                  ],
                  [
                    "date",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.date)
                  ],
                  [
                    "mobileEventDate",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.mobileEventDate)
                  ],
                  [
                    "context",
                    false,
                    t_encode$8(v.context)
                  ],
                  [
                    "unscannedCause",
                    false,
                    Spice.optionToJson(t_encode$1, v.unscannedCause)
                  ]
                ]));
}

function event_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var user = Spice.optionFromJson(t_decode$11, Belt_Option.getWithDefault(Js_dict.get(v, "user"), null));
      if (user.TAG === "Ok") {
        var date = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
        if (date.TAG === "Ok") {
          var mobileEventDate = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "mobileEventDate"), null));
          if (mobileEventDate.TAG === "Ok") {
            var context = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "context"), null));
            if (context.TAG === "Ok") {
              var unscannedCause = Spice.optionFromJson(t_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "unscannedCause"), null));
              if (unscannedCause.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          barcode: barcode._0,
                          hubId: hubId._0,
                          user: user._0,
                          date: date._0,
                          mobileEventDate: mobileEventDate._0,
                          context: context._0,
                          unscannedCause: unscannedCause._0
                        }
                      };
              }
              var e = unscannedCause._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".unscannedCause" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = context._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".context" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = mobileEventDate._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".mobileEventDate" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = date._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".date" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = user._0;
      return {
              TAG: "Error",
              _0: {
                path: ".user" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function damageEvent_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "damageLevel",
                    false,
                    t_encode$4(v.damageLevel)
                  ],
                  [
                    "responsibility",
                    false,
                    t_encode$5(v.responsibility)
                  ],
                  [
                    "comments",
                    false,
                    Spice.stringToJson(v.comments)
                  ],
                  [
                    "reportingDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.reportingDate)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ]
                ]));
}

function damageEvent_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var damageLevel = t_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "damageLevel"), null));
    if (damageLevel.TAG === "Ok") {
      var responsibility = t_decode$5(Belt_Option.getWithDefault(Js_dict.get(v, "responsibility"), null));
      if (responsibility.TAG === "Ok") {
        var comments = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "comments"), null));
        if (comments.TAG === "Ok") {
          var reportingDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "reportingDate"), null));
          if (reportingDate.TAG === "Ok") {
            var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
            if (hubId.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        barcode: barcode._0,
                        damageLevel: damageLevel._0,
                        responsibility: responsibility._0,
                        comments: comments._0,
                        reportingDate: reportingDate._0,
                        hubId: hubId._0
                      }
                    };
            }
            var e = hubId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".hubId" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = reportingDate._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".reportingDate" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = comments._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".comments" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = responsibility._0;
      return {
              TAG: "Error",
              _0: {
                path: ".responsibility" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = damageLevel._0;
    return {
            TAG: "Error",
            _0: {
              path: ".damageLevel" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function response_encode$13(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "secondaryBarcodes",
                    false,
                    Spice.arrayToJson(Identifiers.Barcode.t_encode, v.secondaryBarcodes)
                  ],
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "damageStatus",
                    false,
                    t_encode$2(v.damageStatus)
                  ],
                  [
                    "directive",
                    false,
                    t_encode$6(v.directive)
                  ],
                  [
                    "events",
                    false,
                    Spice.arrayToJson(event_encode$1, v.events)
                  ],
                  [
                    "damages",
                    false,
                    Spice.arrayToJson(damageEvent_encode, v.damages)
                  ]
                ]));
}

function response_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var secondaryBarcodes = Spice.arrayFromJson(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "secondaryBarcodes"), null));
    if (secondaryBarcodes.TAG === "Ok") {
      var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
      if (orderId.TAG === "Ok") {
        var damageStatus = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "damageStatus"), null));
        if (damageStatus.TAG === "Ok") {
          var directive = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "directive"), null));
          if (directive.TAG === "Ok") {
            var events = Spice.arrayFromJson(event_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "events"), null));
            if (events.TAG === "Ok") {
              var damages = Spice.arrayFromJson(damageEvent_decode, Belt_Option.getWithDefault(Js_dict.get(v, "damages"), null));
              if (damages.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          barcode: barcode._0,
                          secondaryBarcodes: secondaryBarcodes._0,
                          orderId: orderId._0,
                          damageStatus: damageStatus._0,
                          directive: directive._0,
                          events: events._0,
                          damages: damages._0
                        }
                      };
              }
              var e = damages._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".damages" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = events._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".events" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = directive._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".directive" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = damageStatus._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".damageStatus" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = orderId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".orderId" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = secondaryBarcodes._0;
    return {
            TAG: "Error",
            _0: {
              path: ".secondaryBarcodes" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function exec$3(param) {
  return Axios.WithResult.post(undefined, body_encode$8(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$13, undefined, baseUrl + "/hubs/" + param[0] + "/units/" + Identifiers.Barcode.toString(param[1]));
}

var Config$3 = {
  body_encode: body_encode$8,
  body_decode: body_decode$8,
  UserAction: UserAction$1,
  event_encode: event_encode$1,
  event_decode: event_decode$1,
  damageEvent_encode: damageEvent_encode,
  damageEvent_decode: damageEvent_decode,
  response_encode: response_encode$13,
  response_decode: response_decode$13,
  exec: exec$3
};

var $$Request$3 = Toolkit__Request.Make({
      exec: exec$3
    });

var HubParcelDetail = {
  Config: Config$3,
  $$Request: $$Request$3
};

function order_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "ref1",
                    false,
                    Spice.stringToJson(v.ref1)
                  ],
                  [
                    "parcelCount",
                    false,
                    Spice.intToJson(v.parcelCount)
                  ],
                  [
                    "parcelScanned",
                    false,
                    Spice.intToJson(v.parcelScanned)
                  ]
                ]));
}

function order_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var ref1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
    if (ref1.TAG === "Ok") {
      var parcelCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "parcelCount"), null));
      if (parcelCount.TAG === "Ok") {
        var parcelScanned = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "parcelScanned"), null));
        if (parcelScanned.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    orderId: orderId._0,
                    ref1: ref1._0,
                    parcelCount: parcelCount._0,
                    parcelScanned: parcelScanned._0
                  }
                };
        }
        var e = parcelScanned._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".parcelScanned" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = parcelCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".parcelCount" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = ref1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ref1" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function delivery_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "deliveryId",
                    false,
                    Spice.optionToJson(Identifiers.RouteId.t_encode, v.deliveryId)
                  ],
                  [
                    "ref1",
                    false,
                    Curry._1(Identifiers.DeliveryReference.t_encode, v.ref1)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "address",
                    false,
                    Spice.stringToJson(v.address)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "plannedSlotStart",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.plannedSlotStart)
                  ],
                  [
                    "plannedSlotEnd",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.plannedSlotEnd)
                  ],
                  [
                    "customerCanUpdateSlot",
                    false,
                    Spice.boolToJson(v.customerCanUpdateSlot)
                  ],
                  [
                    "status",
                    false,
                    ColiswebApi__V5_Shared.StatusProvider.t_encode(v.status)
                  ]
                ]));
}

function delivery_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryId = Spice.optionFromJson(Identifiers.RouteId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
  if (deliveryId.TAG === "Ok") {
    var ref1 = Curry._1(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
    if (ref1.TAG === "Ok") {
      var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
      if (name.TAG === "Ok") {
        var address = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
        if (address.TAG === "Ok") {
          var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
          if (city.TAG === "Ok") {
            var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
            if (postalCode.TAG === "Ok") {
              var plannedSlotStart = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "plannedSlotStart"), null));
              if (plannedSlotStart.TAG === "Ok") {
                var plannedSlotEnd = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "plannedSlotEnd"), null));
                if (plannedSlotEnd.TAG === "Ok") {
                  var customerCanUpdateSlot = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "customerCanUpdateSlot"), null));
                  if (customerCanUpdateSlot.TAG === "Ok") {
                    var status = ColiswebApi__V5_Shared.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
                    if (status.TAG === "Ok") {
                      return {
                              TAG: "Ok",
                              _0: {
                                deliveryId: deliveryId._0,
                                ref1: ref1._0,
                                name: name._0,
                                address: address._0,
                                city: city._0,
                                postalCode: postalCode._0,
                                plannedSlotStart: plannedSlotStart._0,
                                plannedSlotEnd: plannedSlotEnd._0,
                                customerCanUpdateSlot: customerCanUpdateSlot._0,
                                status: status._0
                              }
                            };
                    }
                    var e = status._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".status" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = customerCanUpdateSlot._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".customerCanUpdateSlot" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = plannedSlotEnd._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".plannedSlotEnd" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = plannedSlotStart._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".plannedSlotStart" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = postalCode._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".postalCode" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = city._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".city" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = address._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".address" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = name._0;
      return {
              TAG: "Error",
              _0: {
                path: ".name" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = ref1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ref1" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = deliveryId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryId" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function route_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "projectName",
                    false,
                    Spice.stringToJson(v.projectName)
                  ],
                  [
                    "routeCreationDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.routeCreationDate)
                  ],
                  [
                    "routeIndexInPlan",
                    false,
                    Spice.intToJson(v.routeIndexInPlan)
                  ],
                  [
                    "planSize",
                    false,
                    Spice.intToJson(v.planSize)
                  ],
                  [
                    "deliveryPickupTimes",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.deliveryPickupTimes)
                  ],
                  [
                    "remainingPickupTimes",
                    false,
                    Spice.arrayToJson(Toolkit__Decoders.Datetime.t_encode, v.remainingPickupTimes)
                  ],
                  [
                    "dropoffTime",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.dropoffTime)
                  ],
                  [
                    "loadOrder",
                    false,
                    Spice.intToJson(v.loadOrder)
                  ],
                  [
                    "loadCount",
                    false,
                    Spice.intToJson(v.loadCount)
                  ]
                ]));
}

function route_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var projectName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "projectName"), null));
  if (projectName.TAG === "Ok") {
    var routeCreationDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "routeCreationDate"), null));
    if (routeCreationDate.TAG === "Ok") {
      var routeIndexInPlan = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "routeIndexInPlan"), null));
      if (routeIndexInPlan.TAG === "Ok") {
        var planSize = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "planSize"), null));
        if (planSize.TAG === "Ok") {
          var deliveryPickupTimes = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryPickupTimes"), null));
          if (deliveryPickupTimes.TAG === "Ok") {
            var remainingPickupTimes = Spice.arrayFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "remainingPickupTimes"), null));
            if (remainingPickupTimes.TAG === "Ok") {
              var dropoffTime = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dropoffTime"), null));
              if (dropoffTime.TAG === "Ok") {
                var loadOrder = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "loadOrder"), null));
                if (loadOrder.TAG === "Ok") {
                  var loadCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "loadCount"), null));
                  if (loadCount.TAG === "Ok") {
                    return {
                            TAG: "Ok",
                            _0: {
                              projectName: projectName._0,
                              routeCreationDate: routeCreationDate._0,
                              routeIndexInPlan: routeIndexInPlan._0,
                              planSize: planSize._0,
                              deliveryPickupTimes: deliveryPickupTimes._0,
                              remainingPickupTimes: remainingPickupTimes._0,
                              dropoffTime: dropoffTime._0,
                              loadOrder: loadOrder._0,
                              loadCount: loadCount._0
                            }
                          };
                  }
                  var e = loadCount._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".loadCount" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = loadOrder._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".loadOrder" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = dropoffTime._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".dropoffTime" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = remainingPickupTimes._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".remainingPickupTimes" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = deliveryPickupTimes._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".deliveryPickupTimes" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = planSize._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".planSize" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = routeIndexInPlan._0;
      return {
              TAG: "Error",
              _0: {
                path: ".routeIndexInPlan" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = routeCreationDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".routeCreationDate" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = projectName._0;
  return {
          TAG: "Error",
          _0: {
            path: ".projectName" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

function response_encode$14(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "order",
                    false,
                    Spice.optionToJson(order_encode$2, v.order)
                  ],
                  [
                    "delivery",
                    false,
                    Spice.optionToJson(delivery_encode, v.delivery)
                  ],
                  [
                    "route",
                    false,
                    Spice.optionToJson(route_encode, v.route)
                  ]
                ]));
}

function response_decode$14(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var order = Spice.optionFromJson(order_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "order"), null));
    if (order.TAG === "Ok") {
      var delivery = Spice.optionFromJson(delivery_decode, Belt_Option.getWithDefault(Js_dict.get(v, "delivery"), null));
      if (delivery.TAG === "Ok") {
        var route = Spice.optionFromJson(route_decode, Belt_Option.getWithDefault(Js_dict.get(v, "route"), null));
        if (route.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    barcode: barcode._0,
                    order: order._0,
                    delivery: delivery._0,
                    route: route._0
                  }
                };
        }
        var e = route._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".route" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = delivery._0;
      return {
              TAG: "Error",
              _0: {
                path: ".delivery" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = order._0;
    return {
            TAG: "Error",
            _0: {
              path: ".order" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function exec$4(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$14, (function (err) {
                var responseBody = Belt_Option.map(err.response, (function (r) {
                        return r.data;
                      }));
                var errorCode = Belt_Option.map(responseBody, (function (body) {
                        return body.code;
                      }));
                if (errorCode === "DELIVERY_UNIT_NOT_FOUND") {
                  return {
                          NAME: "custom",
                          VAL: "unitNotFound"
                        };
                } else {
                  return {
                          NAME: "default",
                          VAL: err
                        };
                }
              }), baseUrl + "/hubs/" + param[0] + "/units/" + Identifiers.Barcode.toString(param[1]) + "/routePreparation");
}

var Config$4 = {
  order_encode: order_encode$2,
  order_decode: order_decode$2,
  delivery_encode: delivery_encode,
  delivery_decode: delivery_decode,
  route_encode: route_encode,
  route_decode: route_decode,
  response_encode: response_encode$14,
  response_decode: response_decode$14,
  exec: exec$4
};

var $$Request$4 = Toolkit__Request.Make({
      exec: exec$4
    });

var RoutePreparationUnitDetails = {
  Config: Config$4,
  $$Request: $$Request$4
};

function t_encode$12(v) {
  if (v === "BARCODE_NOT_FOUND") {
    return "BARCODE_NOT_FOUND";
  } else {
    return "NOT_ORPHAN_SCAN";
  }
}

function t_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("BARCODE_NOT_FOUND" === v) {
          return {
                  TAG: "Ok",
                  _0: "BARCODE_NOT_FOUND"
                };
        } else if ("NOT_ORPHAN_SCAN" === v) {
          return {
                  TAG: "Ok",
                  _0: "NOT_ORPHAN_SCAN"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var ErrorCode = {
  t_encode: t_encode$12,
  t_decode: t_decode$12
};

function event_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "date",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.date)
                  ],
                  [
                    "context",
                    false,
                    t_encode$8(v.context)
                  ],
                  [
                    "user",
                    false,
                    Spice.optionToJson(t_encode$3, v.user)
                  ]
                ]));
}

function event_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
  if (hubId.TAG === "Ok") {
    var date = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
    if (date.TAG === "Ok") {
      var context = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "context"), null));
      if (context.TAG === "Ok") {
        var user = Spice.optionFromJson(t_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "user"), null));
        if (user.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    hubId: hubId._0,
                    date: date._0,
                    context: context._0,
                    user: user._0
                  }
                };
        }
        var e = user._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".user" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = context._0;
      return {
              TAG: "Error",
              _0: {
                path: ".context" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = date._0;
    return {
            TAG: "Error",
            _0: {
              path: ".date" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = hubId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".hubId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$15(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "events",
                    false,
                    Spice.arrayToJson(event_encode$2, v.events)
                  ]]));
}

function response_decode$15(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var events = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "events"), (function (param) {
              return Spice.arrayFromJson(event_decode$2, param);
            })), {
        TAG: "Ok",
        _0: []
      });
  if (events.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              events: events._0
            }
          };
  }
  var e = events._0;
  return {
          TAG: "Error",
          _0: {
            path: ".events" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function errorBody_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "code",
                    false,
                    t_encode$12(v.code)
                  ]]));
}

function errorBody_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = t_decode$12(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var error_encode$1 = t_encode$12;

var error_decode$1 = t_decode$12;

function exec$5(barcode) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$15, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var err = errorBody_decode$1(match.data);
                if (err.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: err._0.code
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            err._0
                          ]
                        };
                }
              }), baseUrl + "/scans/orphans/" + Identifiers.Barcode.toString(barcode));
}

var Config$5 = {
  event_encode: event_encode$2,
  event_decode: event_decode$2,
  response_encode: response_encode$15,
  response_decode: response_decode$15,
  errorBody_encode: errorBody_encode$1,
  errorBody_decode: errorBody_decode$1,
  error_encode: error_encode$1,
  error_decode: error_decode$1,
  exec: exec$5
};

var $$Request$5 = Toolkit__Request.Make({
      exec: exec$5
    });

var OrphanEventDetail = {
  ErrorCode: ErrorCode,
  Config: Config$5,
  $$Request: $$Request$5
};

function body_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "directive",
                    false,
                    t_encode$6(v.directive)
                  ]]));
}

function body_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var directive = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "directive"), null));
  if (directive.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              directive: directive._0
            }
          };
  }
  var e = directive._0;
  return {
          TAG: "Error",
          _0: {
            path: ".directive" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$16 = Spice.unitToJson;

var response_decode$16 = Spice.unitFromJson;

function exec$6(param) {
  return Axios.WithResult.put(undefined, body_encode$9(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$16, undefined, baseUrl + "/units/" + Identifiers.Barcode.toString(param[0]) + "/directives");
}

var Config$6 = {
  body_encode: body_encode$9,
  body_decode: body_decode$9,
  response_encode: response_encode$16,
  response_decode: response_decode$16,
  exec: exec$6
};

var $$Request$6 = Toolkit__Request.Make({
      exec: exec$6
    });

var UpdateDirective = {
  Config: Config$6,
  $$Request: $$Request$6
};

function directiveHistory_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "userId",
                    false,
                    Spice.optionToJson(Identifiers.UserId.t_encode, v.userId)
                  ],
                  [
                    "directive",
                    false,
                    t_encode$6(v.directive)
                  ]
                ]));
}

function directiveHistory_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
  if (createdAt.TAG === "Ok") {
    var userId = Spice.optionFromJson(Identifiers.UserId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "userId"), null));
    if (userId.TAG === "Ok") {
      var directive = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "directive"), null));
      if (directive.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  createdAt: createdAt._0,
                  userId: userId._0,
                  directive: directive._0
                }
              };
      }
      var e = directive._0;
      return {
              TAG: "Error",
              _0: {
                path: ".directive" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = userId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".userId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = createdAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".createdAt" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$17(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "directivesHistory",
                    false,
                    Spice.arrayToJson(directiveHistory_encode, v.directivesHistory)
                  ]]));
}

function response_decode$17(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var directivesHistory = Spice.arrayFromJson(directiveHistory_decode, Belt_Option.getWithDefault(Js_dict.get(v, "directivesHistory"), null));
  if (directivesHistory.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              directivesHistory: directivesHistory._0
            }
          };
  }
  var e = directivesHistory._0;
  return {
          TAG: "Error",
          _0: {
            path: ".directivesHistory" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$7(barcode) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$17, undefined, baseUrl + "/units/" + Identifiers.Barcode.toString(barcode) + "/directives/history");
}

var Config$7 = {
  directiveHistory_encode: directiveHistory_encode,
  directiveHistory_decode: directiveHistory_decode,
  response_encode: response_encode$17,
  response_decode: response_decode$17,
  exec: exec$7
};

var $$Request$7 = Toolkit__Request.Make({
      exec: exec$7
    });

var DirectivesHistory = {
  Config: Config$7,
  $$Request: $$Request$7
};

function response_encode$18(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "damagePicturesId",
                    false,
                    Curry._1(Identifiers.DamagePicturesId.t_encode, v.damagePicturesId)
                  ]]));
}

function response_decode$18(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var damagePicturesId = Curry._1(Identifiers.DamagePicturesId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "damagePicturesId"), null));
  if (damagePicturesId.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              damagePicturesId: damagePicturesId._0
            }
          };
  }
  var e = damagePicturesId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".damagePicturesId" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$8(param) {
  var body = param[2];
  var formData = new FormData();
  formData.append("damagePicture1", body.damagePicture1, "file1.jpg");
  Belt_Option.forEach(body.damagePicture2, (function (damagePicture2) {
          formData.append("damagePicture2", damagePicture2, "file2.jpg");
        }));
  Belt_Option.forEach(body.labelPicture, (function (labelPicture) {
          formData.append("labelPicture", labelPicture, "file3.jpg");
        }));
  return Axios.WithResult.post(undefined, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Webview: isWebview
              },
              timeout: 90000,
              withCredentials: true
            }, response_decode$18, undefined, baseUrl + "/hubs/" + param[0] + "/units/" + Identifiers.Barcode.toString(param[1]) + "/damages/pictures");
}

var Config$8 = {
  response_encode: response_encode$18,
  response_decode: response_decode$18,
  exec: exec$8
};

var $$Request$8 = Toolkit__Request.Make({
      exec: exec$8
    });

var UploadPictures = {
  Config: Config$8,
  $$Request: $$Request$8
};

function body_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "ref",
                    false,
                    Curry._1(Identifiers.OrderReference.t_encode, v.ref)
                  ],
                  [
                    "damageLevel",
                    false,
                    t_encode$4(v.damageLevel)
                  ],
                  [
                    "comments",
                    false,
                    Spice.stringToJson(v.comments)
                  ],
                  [
                    "responsibility",
                    false,
                    t_encode$5(v.responsibility)
                  ],
                  [
                    "department",
                    false,
                    Spice.stringToJson(v.department)
                  ],
                  [
                    "hubName",
                    false,
                    Spice.stringToJson(v.hubName)
                  ],
                  [
                    "damagePicturesId",
                    false,
                    Curry._1(Identifiers.DamagePicturesId.t_encode, v.damagePicturesId)
                  ]
                ]));
}

function body_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var ref = Curry._1(Identifiers.OrderReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref"), null));
    if (ref.TAG === "Ok") {
      var damageLevel = t_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "damageLevel"), null));
      if (damageLevel.TAG === "Ok") {
        var comments = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "comments"), null));
        if (comments.TAG === "Ok") {
          var responsibility = t_decode$5(Belt_Option.getWithDefault(Js_dict.get(v, "responsibility"), null));
          if (responsibility.TAG === "Ok") {
            var department = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "department"), null));
            if (department.TAG === "Ok") {
              var hubName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "hubName"), null));
              if (hubName.TAG === "Ok") {
                var damagePicturesId = Curry._1(Identifiers.DamagePicturesId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "damagePicturesId"), null));
                if (damagePicturesId.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            clientId: clientId._0,
                            ref: ref._0,
                            damageLevel: damageLevel._0,
                            comments: comments._0,
                            responsibility: responsibility._0,
                            department: department._0,
                            hubName: hubName._0,
                            damagePicturesId: damagePicturesId._0
                          }
                        };
                }
                var e = damagePicturesId._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".damagePicturesId" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = hubName._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".hubName" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = department._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".department" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = responsibility._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".responsibility" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = comments._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".comments" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = damageLevel._0;
      return {
              TAG: "Error",
              _0: {
                path: ".damageLevel" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = ref._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ref" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

var response_encode$19 = Spice.unitToJson;

var response_decode$19 = Spice.unitFromJson;

function exec$9(param) {
  return Axios.WithResult.post(undefined, body_encode$10(param[2]), {
              headers: defaultHeaders,
              timeout: 90000,
              withCredentials: true
            }, response_decode$19, undefined, baseUrl + "/hubs/" + param[0] + "/units/" + Identifiers.Barcode.toString(param[1]) + "/damages/declare");
}

var Config$9 = {
  body_encode: body_encode$10,
  body_decode: body_decode$10,
  response_encode: response_encode$19,
  response_decode: response_decode$19,
  exec: exec$9
};

var $$Request$9 = Toolkit__Request.Make({
      exec: exec$9
    });

var DeclareDamage = {
  Config: Config$9,
  $$Request: $$Request$9
};

var Damages = {
  UploadPictures: UploadPictures,
  DeclareDamage: DeclareDamage
};

var Unit = {
  Scan: Scan,
  AdminScan: AdminScan,
  AdminBulkScan: AdminBulkScan,
  AdminOrphanScan: AdminOrphanScan,
  ContextCount: ContextCount,
  HubContextCountForBarcodes: HubContextCountForBarcodes,
  AwaitingContextCount: AwaitingContextCount,
  AwaitingContextList: AwaitingContextList,
  ExpectedOrdersUnitCount: ExpectedOrdersUnitCount,
  List: List,
  OrphansList: OrphansList,
  EventDetail: EventDetail,
  Order: Order,
  HubParcelDetail: HubParcelDetail,
  RoutePreparationUnitDetails: RoutePreparationUnitDetails,
  OrphanEventDetail: OrphanEventDetail,
  UpdateDirective: UpdateDirective,
  DirectivesHistory: DirectivesHistory,
  Damages: Damages
};

function body_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orderIds",
                    false,
                    Spice.arrayToJson(Identifiers.OrderId.t_encode, v.orderIds)
                  ]]));
}

function body_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderIds = Spice.arrayFromJson(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderIds"), null));
  if (orderIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orderIds: orderIds._0
            }
          };
  }
  var e = orderIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function order_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "hasParcelsToReturn",
                    false,
                    Spice.boolToJson(v.hasParcelsToReturn)
                  ],
                  [
                    "hasParcelsToThrow",
                    false,
                    Spice.boolToJson(v.hasParcelsToThrow)
                  ],
                  [
                    "hasParcelsToDecide",
                    false,
                    Spice.boolToJson(v.hasParcelsToDecide)
                  ]
                ]));
}

function order_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var hasParcelsToReturn = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "hasParcelsToReturn"), null));
    if (hasParcelsToReturn.TAG === "Ok") {
      var hasParcelsToThrow = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "hasParcelsToThrow"), null));
      if (hasParcelsToThrow.TAG === "Ok") {
        var hasParcelsToDecide = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "hasParcelsToDecide"), null));
        if (hasParcelsToDecide.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    orderId: orderId._0,
                    hasParcelsToReturn: hasParcelsToReturn._0,
                    hasParcelsToThrow: hasParcelsToThrow._0,
                    hasParcelsToDecide: hasParcelsToDecide._0
                  }
                };
        }
        var e = hasParcelsToDecide._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".hasParcelsToDecide" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = hasParcelsToThrow._0;
      return {
              TAG: "Error",
              _0: {
                path: ".hasParcelsToThrow" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = hasParcelsToReturn._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hasParcelsToReturn" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$20(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orders",
                    false,
                    Spice.arrayToJson(order_encode$3, v.orders)
                  ]]));
}

function response_decode$20(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orders = Spice.arrayFromJson(order_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "orders"), null));
  if (orders.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orders: orders._0
            }
          };
  }
  var e = orders._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orders" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$10(body) {
  return Axios.WithResult.post(undefined, body_encode$11(body), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$20, undefined, baseUrl + "/orders/directives");
}

function config$11(body) {
  return {
          kyInstance: kyInstance,
          path: "orders/directives",
          requestOptions: {
            method: "POST",
            json: body_encode$11(body)
          },
          key: [
            "OrdersDirectives",
            JSON.stringify(body_encode$11(body))
          ]
        };
}

var OrdersDirectives = {
  body_encode: body_encode$11,
  body_decode: body_decode$11,
  order_encode: order_encode$3,
  order_decode: order_decode$3,
  response_encode: response_encode$20,
  response_decode: response_decode$20,
  exec: exec$10,
  config: config$11
};

function lastScanInfo_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "date",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.date)
                  ],
                  [
                    "context",
                    false,
                    t_encode$8(v.context)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "unscannedCause",
                    false,
                    Spice.optionToJson(t_encode$1, v.unscannedCause)
                  ],
                  [
                    "user",
                    false,
                    Spice.optionToJson(t_encode$3, v.user)
                  ]
                ]));
}

function lastScanInfo_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    var context = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "context"), null));
    if (context.TAG === "Ok") {
      var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
      if (hubId.TAG === "Ok") {
        var unscannedCause = Spice.optionFromJson(t_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "unscannedCause"), null));
        if (unscannedCause.TAG === "Ok") {
          var user = Spice.optionFromJson(t_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "user"), null));
          if (user.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      date: date._0,
                      context: context._0,
                      hubId: hubId._0,
                      unscannedCause: unscannedCause._0,
                      user: user._0
                    }
                  };
          }
          var e = user._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".user" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = unscannedCause._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".unscannedCause" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = hubId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".hubId" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = context._0;
    return {
            TAG: "Error",
            _0: {
              path: ".context" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function firstScanInfo_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "date",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.date)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ]
                ]));
}

function firstScanInfo_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                date: date._0,
                hubId: hubId._0
              }
            };
    }
    var e = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function scansInfo_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lastScanInfo",
                    false,
                    lastScanInfo_encode$2(v.lastScanInfo)
                  ],
                  [
                    "firstScanInfo",
                    false,
                    firstScanInfo_encode(v.firstScanInfo)
                  ]
                ]));
}

function scansInfo_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lastScanInfo = lastScanInfo_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "lastScanInfo"), null));
  if (lastScanInfo.TAG === "Ok") {
    var firstScanInfo = firstScanInfo_decode(Belt_Option.getWithDefault(Js_dict.get(v, "firstScanInfo"), null));
    if (firstScanInfo.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                lastScanInfo: lastScanInfo._0,
                firstScanInfo: firstScanInfo._0
              }
            };
    }
    var e = firstScanInfo._0;
    return {
            TAG: "Error",
            _0: {
              path: ".firstScanInfo" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = lastScanInfo._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lastScanInfo" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function skill_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ]]));
}

function skill_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
  if (tag.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              tag: tag._0
            }
          };
  }
  var e = tag._0;
  return {
          TAG: "Error",
          _0: {
            path: ".tag" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function deliveryUnitLocationForClient_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "location",
                    false,
                    t_encode$7(v.location)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ]
                ]));
}

function deliveryUnitLocationForClient_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var $$location = t_decode$7(Belt_Option.getWithDefault(Js_dict.get(v, "location"), null));
  if ($$location.TAG === "Ok") {
    var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                location: $$location._0,
                hubId: hubId._0
              }
            };
    }
    var e = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = $$location._0;
  return {
          TAG: "Error",
          _0: {
            path: ".location" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function parcel_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "secondaryBarcodes",
                    false,
                    Spice.arrayToJson(Identifiers.Barcode.t_encode, v.secondaryBarcodes)
                  ],
                  [
                    "scansInfo",
                    false,
                    Spice.optionToJson(scansInfo_encode, v.scansInfo)
                  ],
                  [
                    "lastScanDate",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.lastScanDate)
                  ],
                  [
                    "lastHubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.lastHubId)
                  ],
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "status",
                    false,
                    t_encode(v.status)
                  ],
                  [
                    "length",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.length)
                  ],
                  [
                    "height",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.height)
                  ],
                  [
                    "width",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.width)
                  ],
                  [
                    "weight",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.weight)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(skill_encode, v.skills)
                  ],
                  [
                    "productTypology",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.productTypology)
                  ],
                  [
                    "packageType",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.packageType)
                  ],
                  [
                    "volumeM3",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Volume.M3.t_encode, v.volumeM3)
                  ],
                  [
                    "damageStatus",
                    false,
                    t_encode$2(v.damageStatus)
                  ],
                  [
                    "directive",
                    false,
                    t_encode$6(v.directive)
                  ],
                  [
                    "deliveryUnitLocation",
                    false,
                    deliveryUnitLocationForClient_encode(v.deliveryUnitLocation)
                  ]
                ]));
}

function parcel_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var secondaryBarcodes = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "secondaryBarcodes"), (function (param) {
                return Spice.arrayFromJson(Identifiers.Barcode.t_decode, param);
              })), {
          TAG: "Ok",
          _0: []
        });
    if (secondaryBarcodes.TAG === "Ok") {
      var scansInfo = Spice.optionFromJson(scansInfo_decode, Belt_Option.getWithDefault(Js_dict.get(v, "scansInfo"), null));
      if (scansInfo.TAG === "Ok") {
        var lastScanDate = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastScanDate"), null));
        if (lastScanDate.TAG === "Ok") {
          var lastHubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastHubId"), null));
          if (lastHubId.TAG === "Ok") {
            var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
            if (orderId.TAG === "Ok") {
              var status = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
              if (status.TAG === "Ok") {
                var length = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "length"), null));
                if (length.TAG === "Ok") {
                  var height = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "height"), null));
                  if (height.TAG === "Ok") {
                    var width = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "width"), null));
                    if (width.TAG === "Ok") {
                      var weight = Spice.optionFromJson(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "weight"), null));
                      if (weight.TAG === "Ok") {
                        var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
                        if (description.TAG === "Ok") {
                          var skills = Spice.arrayFromJson(skill_decode, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                          if (skills.TAG === "Ok") {
                            var productTypology = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "productTypology"), null));
                            if (productTypology.TAG === "Ok") {
                              var packageType = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "packageType"), null));
                              if (packageType.TAG === "Ok") {
                                var volumeM3 = Spice.optionFromJson(Decoders__UnitMeasure.Volume.M3.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "volumeM3"), null));
                                if (volumeM3.TAG === "Ok") {
                                  var damageStatus = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "damageStatus"), null));
                                  if (damageStatus.TAG === "Ok") {
                                    var directive = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "directive"), t_decode$6), {
                                          TAG: "Ok",
                                          _0: "toDeliver"
                                        });
                                    if (directive.TAG === "Ok") {
                                      var deliveryUnitLocation = deliveryUnitLocationForClient_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryUnitLocation"), null));
                                      if (deliveryUnitLocation.TAG === "Ok") {
                                        return {
                                                TAG: "Ok",
                                                _0: {
                                                  barcode: barcode._0,
                                                  secondaryBarcodes: secondaryBarcodes._0,
                                                  scansInfo: scansInfo._0,
                                                  lastScanDate: lastScanDate._0,
                                                  lastHubId: lastHubId._0,
                                                  orderId: orderId._0,
                                                  status: status._0,
                                                  length: length._0,
                                                  height: height._0,
                                                  width: width._0,
                                                  weight: weight._0,
                                                  description: description._0,
                                                  skills: skills._0,
                                                  productTypology: productTypology._0,
                                                  packageType: packageType._0,
                                                  volumeM3: volumeM3._0,
                                                  damageStatus: damageStatus._0,
                                                  directive: directive._0,
                                                  deliveryUnitLocation: deliveryUnitLocation._0
                                                }
                                              };
                                      }
                                      var e = deliveryUnitLocation._0;
                                      return {
                                              TAG: "Error",
                                              _0: {
                                                path: ".deliveryUnitLocation" + e.path,
                                                message: e.message,
                                                value: e.value
                                              }
                                            };
                                    }
                                    var e$1 = directive._0;
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              path: ".directive" + e$1.path,
                                              message: e$1.message,
                                              value: e$1.value
                                            }
                                          };
                                  }
                                  var e$2 = damageStatus._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".damageStatus" + e$2.path,
                                            message: e$2.message,
                                            value: e$2.value
                                          }
                                        };
                                }
                                var e$3 = volumeM3._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".volumeM3" + e$3.path,
                                          message: e$3.message,
                                          value: e$3.value
                                        }
                                      };
                              }
                              var e$4 = packageType._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".packageType" + e$4.path,
                                        message: e$4.message,
                                        value: e$4.value
                                      }
                                    };
                            }
                            var e$5 = productTypology._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".productTypology" + e$5.path,
                                      message: e$5.message,
                                      value: e$5.value
                                    }
                                  };
                          }
                          var e$6 = skills._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".skills" + e$6.path,
                                    message: e$6.message,
                                    value: e$6.value
                                  }
                                };
                        }
                        var e$7 = description._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".description" + e$7.path,
                                  message: e$7.message,
                                  value: e$7.value
                                }
                              };
                      }
                      var e$8 = weight._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".weight" + e$8.path,
                                message: e$8.message,
                                value: e$8.value
                              }
                            };
                    }
                    var e$9 = width._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".width" + e$9.path,
                              message: e$9.message,
                              value: e$9.value
                            }
                          };
                  }
                  var e$10 = height._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".height" + e$10.path,
                            message: e$10.message,
                            value: e$10.value
                          }
                        };
                }
                var e$11 = length._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".length" + e$11.path,
                          message: e$11.message,
                          value: e$11.value
                        }
                      };
              }
              var e$12 = status._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".status" + e$12.path,
                        message: e$12.message,
                        value: e$12.value
                      }
                    };
            }
            var e$13 = orderId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".orderId" + e$13.path,
                      message: e$13.message,
                      value: e$13.value
                    }
                  };
          }
          var e$14 = lastHubId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".lastHubId" + e$14.path,
                    message: e$14.message,
                    value: e$14.value
                  }
                };
        }
        var e$15 = lastScanDate._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".lastScanDate" + e$15.path,
                  message: e$15.message,
                  value: e$15.value
                }
              };
      }
      var e$16 = scansInfo._0;
      return {
              TAG: "Error",
              _0: {
                path: ".scansInfo" + e$16.path,
                message: e$16.message,
                value: e$16.value
              }
            };
    }
    var e$17 = secondaryBarcodes._0;
    return {
            TAG: "Error",
            _0: {
              path: ".secondaryBarcodes" + e$17.path,
              message: e$17.message,
              value: e$17.value
            }
          };
  }
  var e$18 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$18.path,
            message: e$18.message,
            value: e$18.value
          }
        };
}

function response_encode$21(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "units",
                    false,
                    Spice.arrayToJson(parcel_encode$1, v.units)
                  ]]));
}

function response_decode$21(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var units = Spice.arrayFromJson(parcel_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "units"), null));
  if (units.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              units: units._0
            }
          };
  }
  var e = units._0;
  return {
          TAG: "Error",
          _0: {
            path: ".units" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$12(param) {
  var orderId = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance,
          path: "clients/" + clientId + "/orders/" + orderId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "OrderParcelList",
            clientId,
            orderId
          ],
          mapCustomErrors: (function (error) {
              var match = error.response;
              return $$Promise.resolved(match !== undefined && match.status === 404 ? ({
                              TAG: "Custom",
                              _0: "noParcels"
                            }) : ({
                              TAG: "Unknown",
                              _0: error
                            }));
            })
        };
}

var ParcelList = {
  lastScanInfo_encode: lastScanInfo_encode$2,
  lastScanInfo_decode: lastScanInfo_decode$2,
  firstScanInfo_encode: firstScanInfo_encode,
  firstScanInfo_decode: firstScanInfo_decode,
  scansInfo_encode: scansInfo_encode,
  scansInfo_decode: scansInfo_decode,
  skill_encode: skill_encode,
  skill_decode: skill_decode,
  deliveryUnitLocationForClient_encode: deliveryUnitLocationForClient_encode,
  deliveryUnitLocationForClient_decode: deliveryUnitLocationForClient_decode,
  parcel_encode: parcel_encode$1,
  parcel_decode: parcel_decode$1,
  response_encode: response_encode$21,
  response_decode: response_decode$21,
  config: config$12
};

function directiveHistory_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "userId",
                    false,
                    Spice.optionToJson(Identifiers.UserId.t_encode, v.userId)
                  ],
                  [
                    "directive",
                    false,
                    t_encode$6(v.directive)
                  ]
                ]));
}

function directiveHistory_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
  if (createdAt.TAG === "Ok") {
    var userId = Spice.optionFromJson(Identifiers.UserId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "userId"), null));
    if (userId.TAG === "Ok") {
      var directive = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "directive"), null));
      if (directive.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  createdAt: createdAt._0,
                  userId: userId._0,
                  directive: directive._0
                }
              };
      }
      var e = directive._0;
      return {
              TAG: "Error",
              _0: {
                path: ".directive" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = userId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".userId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = createdAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".createdAt" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function barcodeWithHistory_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var history = Spice.arrayFromJson(directiveHistory_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "history"), null));
    if (history.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                barcode: barcode._0,
                history: history._0
              }
            };
    }
    var e = history._0;
    return {
            TAG: "Error",
            _0: {
              path: ".history" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function barcodeWithHistory_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "history",
                    false,
                    Spice.arrayToJson(directiveHistory_encode$1, v.history)
                  ]
                ]));
}

function response_encode$22(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "barcodesHistory",
                    false,
                    Spice.arrayToJson(barcodeWithHistory_encode, v.barcodesHistory)
                  ]
                ]));
}

function response_decode$22(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var barcodesHistory = Spice.arrayFromJson(barcodeWithHistory_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcodesHistory"), null));
    if (barcodesHistory.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                orderId: orderId._0,
                barcodesHistory: barcodesHistory._0
              }
            };
    }
    var e = barcodesHistory._0;
    return {
            TAG: "Error",
            _0: {
              path: ".barcodesHistory" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$13(param) {
  var orderId = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance,
          path: "clients/" + clientId + "/orders/" + orderId + "/directives/history",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ClientOrderDirectivesHistory",
            clientId,
            orderId
          ]
        };
}

var ClientOrderDirectivesHistory = {
  response_encode: response_encode$22,
  response_decode: response_decode$22,
  barcodeWithHistory_encode: barcodeWithHistory_encode,
  barcodeWithHistory_decode: barcodeWithHistory_decode,
  directiveHistory_encode: directiveHistory_encode$1,
  directiveHistory_decode: directiveHistory_decode$1,
  config: config$13
};

var Order$1 = {
  ParcelList: ParcelList,
  ClientOrderDirectivesHistory: ClientOrderDirectivesHistory
};

var Client = {
  Order: Order$1
};

var orderBaseUrl = baseUrl + "/orders";

function lastScanInfo_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "date",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.date)
                  ],
                  [
                    "context",
                    false,
                    t_encode$8(v.context)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "unscannedCause",
                    false,
                    Spice.optionToJson(t_encode$1, v.unscannedCause)
                  ],
                  [
                    "user",
                    false,
                    Spice.optionToJson(t_encode$3, v.user)
                  ]
                ]));
}

function lastScanInfo_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    var context = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "context"), null));
    if (context.TAG === "Ok") {
      var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
      if (hubId.TAG === "Ok") {
        var unscannedCause = Spice.optionFromJson(t_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "unscannedCause"), null));
        if (unscannedCause.TAG === "Ok") {
          var user = Spice.optionFromJson(t_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "user"), null));
          if (user.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      date: date._0,
                      context: context._0,
                      hubId: hubId._0,
                      unscannedCause: unscannedCause._0,
                      user: user._0
                    }
                  };
          }
          var e = user._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".user" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = unscannedCause._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".unscannedCause" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = hubId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".hubId" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = context._0;
    return {
            TAG: "Error",
            _0: {
              path: ".context" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function firstScanInfo_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "date",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.date)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "userId",
                    false,
                    Spice.optionToJson(Identifiers.UserId.t_encode, v.userId)
                  ]
                ]));
}

function firstScanInfo_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var userId = Spice.optionFromJson(Identifiers.UserId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "userId"), null));
      if (userId.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  date: date._0,
                  hubId: hubId._0,
                  userId: userId._0
                }
              };
      }
      var e = userId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".userId" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function scansInfo_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lastScanInfo",
                    false,
                    lastScanInfo_encode$3(v.lastScanInfo)
                  ],
                  [
                    "firstScanInfo",
                    false,
                    firstScanInfo_encode$1(v.firstScanInfo)
                  ]
                ]));
}

function scansInfo_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lastScanInfo = lastScanInfo_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "lastScanInfo"), null));
  if (lastScanInfo.TAG === "Ok") {
    var firstScanInfo = firstScanInfo_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "firstScanInfo"), null));
    if (firstScanInfo.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                lastScanInfo: lastScanInfo._0,
                firstScanInfo: firstScanInfo._0
              }
            };
    }
    var e = firstScanInfo._0;
    return {
            TAG: "Error",
            _0: {
              path: ".firstScanInfo" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = lastScanInfo._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lastScanInfo" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function skill_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ]]));
}

function skill_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
  if (tag.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              tag: tag._0
            }
          };
  }
  var e = tag._0;
  return {
          TAG: "Error",
          _0: {
            path: ".tag" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function parcel_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "secondaryBarcodes",
                    false,
                    Spice.arrayToJson(Identifiers.Barcode.t_encode, v.secondaryBarcodes)
                  ],
                  [
                    "scansInfo",
                    false,
                    Spice.optionToJson(scansInfo_encode$1, v.scansInfo)
                  ],
                  [
                    "lastScanDate",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.lastScanDate)
                  ],
                  [
                    "lastHubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.lastHubId)
                  ],
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "status",
                    false,
                    t_encode(v.status)
                  ],
                  [
                    "orderIsTracked",
                    false,
                    Spice.boolToJson(v.orderIsTracked)
                  ],
                  [
                    "length",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.length)
                  ],
                  [
                    "height",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.height)
                  ],
                  [
                    "width",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.width)
                  ],
                  [
                    "weight",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.weight)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(skill_encode$1, v.skills)
                  ],
                  [
                    "productTypology",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.productTypology)
                  ],
                  [
                    "packageType",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.packageType)
                  ],
                  [
                    "volumeM3",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Volume.M3.t_encode, v.volumeM3)
                  ],
                  [
                    "damageStatus",
                    false,
                    t_encode$2(v.damageStatus)
                  ],
                  [
                    "directive",
                    false,
                    t_encode$6(v.directive)
                  ],
                  [
                    "deliveryUnitLocation",
                    false,
                    deliveryUnitLocation_encode(v.deliveryUnitLocation)
                  ]
                ]));
}

function parcel_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var secondaryBarcodes = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "secondaryBarcodes"), (function (param) {
                return Spice.arrayFromJson(Identifiers.Barcode.t_decode, param);
              })), {
          TAG: "Ok",
          _0: []
        });
    if (secondaryBarcodes.TAG === "Ok") {
      var scansInfo = Spice.optionFromJson(scansInfo_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "scansInfo"), null));
      if (scansInfo.TAG === "Ok") {
        var lastScanDate = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastScanDate"), null));
        if (lastScanDate.TAG === "Ok") {
          var lastHubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastHubId"), null));
          if (lastHubId.TAG === "Ok") {
            var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
            if (orderId.TAG === "Ok") {
              var status = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
              if (status.TAG === "Ok") {
                var orderIsTracked = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "orderIsTracked"), null));
                if (orderIsTracked.TAG === "Ok") {
                  var length = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "length"), null));
                  if (length.TAG === "Ok") {
                    var height = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "height"), null));
                    if (height.TAG === "Ok") {
                      var width = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "width"), null));
                      if (width.TAG === "Ok") {
                        var weight = Spice.optionFromJson(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "weight"), null));
                        if (weight.TAG === "Ok") {
                          var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
                          if (description.TAG === "Ok") {
                            var skills = Spice.arrayFromJson(skill_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                            if (skills.TAG === "Ok") {
                              var productTypology = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "productTypology"), null));
                              if (productTypology.TAG === "Ok") {
                                var packageType = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "packageType"), null));
                                if (packageType.TAG === "Ok") {
                                  var volumeM3 = Spice.optionFromJson(Decoders__UnitMeasure.Volume.M3.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "volumeM3"), null));
                                  if (volumeM3.TAG === "Ok") {
                                    var damageStatus = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "damageStatus"), null));
                                    if (damageStatus.TAG === "Ok") {
                                      var directive = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "directive"), t_decode$6), {
                                            TAG: "Ok",
                                            _0: "toDeliver"
                                          });
                                      if (directive.TAG === "Ok") {
                                        var deliveryUnitLocation = deliveryUnitLocation_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryUnitLocation"), null));
                                        if (deliveryUnitLocation.TAG === "Ok") {
                                          return {
                                                  TAG: "Ok",
                                                  _0: {
                                                    barcode: barcode._0,
                                                    secondaryBarcodes: secondaryBarcodes._0,
                                                    scansInfo: scansInfo._0,
                                                    lastScanDate: lastScanDate._0,
                                                    lastHubId: lastHubId._0,
                                                    orderId: orderId._0,
                                                    status: status._0,
                                                    orderIsTracked: orderIsTracked._0,
                                                    length: length._0,
                                                    height: height._0,
                                                    width: width._0,
                                                    weight: weight._0,
                                                    description: description._0,
                                                    skills: skills._0,
                                                    productTypology: productTypology._0,
                                                    packageType: packageType._0,
                                                    volumeM3: volumeM3._0,
                                                    damageStatus: damageStatus._0,
                                                    directive: directive._0,
                                                    deliveryUnitLocation: deliveryUnitLocation._0
                                                  }
                                                };
                                        }
                                        var e = deliveryUnitLocation._0;
                                        return {
                                                TAG: "Error",
                                                _0: {
                                                  path: ".deliveryUnitLocation" + e.path,
                                                  message: e.message,
                                                  value: e.value
                                                }
                                              };
                                      }
                                      var e$1 = directive._0;
                                      return {
                                              TAG: "Error",
                                              _0: {
                                                path: ".directive" + e$1.path,
                                                message: e$1.message,
                                                value: e$1.value
                                              }
                                            };
                                    }
                                    var e$2 = damageStatus._0;
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              path: ".damageStatus" + e$2.path,
                                              message: e$2.message,
                                              value: e$2.value
                                            }
                                          };
                                  }
                                  var e$3 = volumeM3._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".volumeM3" + e$3.path,
                                            message: e$3.message,
                                            value: e$3.value
                                          }
                                        };
                                }
                                var e$4 = packageType._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".packageType" + e$4.path,
                                          message: e$4.message,
                                          value: e$4.value
                                        }
                                      };
                              }
                              var e$5 = productTypology._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".productTypology" + e$5.path,
                                        message: e$5.message,
                                        value: e$5.value
                                      }
                                    };
                            }
                            var e$6 = skills._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".skills" + e$6.path,
                                      message: e$6.message,
                                      value: e$6.value
                                    }
                                  };
                          }
                          var e$7 = description._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".description" + e$7.path,
                                    message: e$7.message,
                                    value: e$7.value
                                  }
                                };
                        }
                        var e$8 = weight._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".weight" + e$8.path,
                                  message: e$8.message,
                                  value: e$8.value
                                }
                              };
                      }
                      var e$9 = width._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".width" + e$9.path,
                                message: e$9.message,
                                value: e$9.value
                              }
                            };
                    }
                    var e$10 = height._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".height" + e$10.path,
                              message: e$10.message,
                              value: e$10.value
                            }
                          };
                  }
                  var e$11 = length._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".length" + e$11.path,
                            message: e$11.message,
                            value: e$11.value
                          }
                        };
                }
                var e$12 = orderIsTracked._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".orderIsTracked" + e$12.path,
                          message: e$12.message,
                          value: e$12.value
                        }
                      };
              }
              var e$13 = status._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".status" + e$13.path,
                        message: e$13.message,
                        value: e$13.value
                      }
                    };
            }
            var e$14 = orderId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".orderId" + e$14.path,
                      message: e$14.message,
                      value: e$14.value
                    }
                  };
          }
          var e$15 = lastHubId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".lastHubId" + e$15.path,
                    message: e$15.message,
                    value: e$15.value
                  }
                };
        }
        var e$16 = lastScanDate._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".lastScanDate" + e$16.path,
                  message: e$16.message,
                  value: e$16.value
                }
              };
      }
      var e$17 = scansInfo._0;
      return {
              TAG: "Error",
              _0: {
                path: ".scansInfo" + e$17.path,
                message: e$17.message,
                value: e$17.value
              }
            };
    }
    var e$18 = secondaryBarcodes._0;
    return {
            TAG: "Error",
            _0: {
              path: ".secondaryBarcodes" + e$18.path,
              message: e$18.message,
              value: e$18.value
            }
          };
  }
  var e$19 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$19.path,
            message: e$19.message,
            value: e$19.value
          }
        };
}

function response_encode$23(v) {
  return Spice.arrayToJson(parcel_encode$2, v);
}

function response_decode$23(v) {
  return Spice.arrayFromJson(parcel_decode$2, v);
}

function config$14(orderId) {
  return {
          kyInstance: kyInstance,
          path: "orders/" + orderId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "ParcelList",
            orderId
          ],
          mapCustomErrors: (function (error) {
              var match = error.response;
              return $$Promise.resolved(match !== undefined && match.status === 404 ? ({
                              TAG: "Custom",
                              _0: "noParcels"
                            }) : ({
                              TAG: "Unknown",
                              _0: error
                            }));
            })
        };
}

var ParcelList$1 = {
  lastScanInfo_encode: lastScanInfo_encode$3,
  lastScanInfo_decode: lastScanInfo_decode$3,
  firstScanInfo_encode: firstScanInfo_encode$1,
  firstScanInfo_decode: firstScanInfo_decode$1,
  scansInfo_encode: scansInfo_encode$1,
  scansInfo_decode: scansInfo_decode$1,
  skill_encode: skill_encode$1,
  skill_decode: skill_decode$1,
  parcel_encode: parcel_encode$2,
  parcel_decode: parcel_decode$2,
  response_encode: response_encode$23,
  response_decode: response_decode$23,
  config: config$14
};

function barcodeWithHistory_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var history = Spice.arrayFromJson(directiveHistory_decode, Belt_Option.getWithDefault(Js_dict.get(v, "history"), null));
    if (history.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                barcode: barcode._0,
                history: history._0
              }
            };
    }
    var e = history._0;
    return {
            TAG: "Error",
            _0: {
              path: ".history" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function barcodeWithHistory_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "history",
                    false,
                    Spice.arrayToJson(directiveHistory_encode, v.history)
                  ]
                ]));
}

function response_encode$24(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "barcodesHistory",
                    false,
                    Spice.arrayToJson(barcodeWithHistory_encode$1, v.barcodesHistory)
                  ]
                ]));
}

function response_decode$24(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var barcodesHistory = Spice.arrayFromJson(barcodeWithHistory_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "barcodesHistory"), null));
    if (barcodesHistory.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                orderId: orderId._0,
                barcodesHistory: barcodesHistory._0
              }
            };
    }
    var e = barcodesHistory._0;
    return {
            TAG: "Error",
            _0: {
              path: ".barcodesHistory" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$15(orderId) {
  return {
          kyInstance: kyInstance,
          path: "orders/" + orderId + "/directives/history",
          requestOptions: {
            method: "GET"
          },
          key: [
            "AdminOrderDirectivesHistory",
            orderId
          ]
        };
}

var AdminOrderDirectivesHistory = {
  response_encode: response_encode$24,
  response_decode: response_decode$24,
  barcodeWithHistory_encode: barcodeWithHistory_encode$1,
  barcodeWithHistory_decode: barcodeWithHistory_decode$1,
  config: config$15
};

var Order$2 = {
  orderBaseUrl: orderBaseUrl,
  ParcelList: ParcelList$1,
  AdminOrderDirectivesHistory: AdminOrderDirectivesHistory
};

var baseUrl$1 = baseUrl + "/hubs";

function sort_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.barcode)
                  ],
                  [
                    "directive",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.directive)
                  ],
                  [
                    "clientId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.clientId)
                  ],
                  [
                    "plannedDeliveryDate",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.plannedDeliveryDate)
                  ],
                  [
                    "lastScanContext",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.lastScanContext)
                  ],
                  [
                    "lastScanDate",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.lastScanDate)
                  ],
                  [
                    "atHubSince",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.atHubSince)
                  ]
                ]));
}

function sort_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var directive = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "directive"), null));
    if (directive.TAG === "Ok") {
      var clientId = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
      if (clientId.TAG === "Ok") {
        var plannedDeliveryDate = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "plannedDeliveryDate"), null));
        if (plannedDeliveryDate.TAG === "Ok") {
          var lastScanContext = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastScanContext"), null));
          if (lastScanContext.TAG === "Ok") {
            var lastScanDate = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastScanDate"), null));
            if (lastScanDate.TAG === "Ok") {
              var atHubSince = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "atHubSince"), null));
              if (atHubSince.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          barcode: barcode._0,
                          directive: directive._0,
                          clientId: clientId._0,
                          plannedDeliveryDate: plannedDeliveryDate._0,
                          lastScanContext: lastScanContext._0,
                          lastScanDate: lastScanDate._0,
                          atHubSince: atHubSince._0
                        }
                      };
              }
              var e = atHubSince._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".atHubSince" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = lastScanDate._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".lastScanDate" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = lastScanContext._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".lastScanContext" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = plannedDeliveryDate._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".plannedDeliveryDate" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = clientId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".clientId" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = directive._0;
    return {
            TAG: "Error",
            _0: {
              path: ".directive" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function filters_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.barcode)
                  ],
                  [
                    "directive",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode$6, param);
                          }), v.directive)
                  ],
                  [
                    "clientId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.ClientId.t_encode, param);
                          }), v.clientId)
                  ],
                  [
                    "lastScanContext",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode$8, param);
                          }), v.lastScanContext)
                  ],
                  [
                    "lastScanDate",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.DateTimeSlot.t_encode, v.lastScanDate)
                  ]
                ]));
}

function filters_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var directive = Toolkit__Decoders.$$Option.t_decode((function (param) {
            return Spice.arrayFromJson(t_decode$6, param);
          }), Belt_Option.getWithDefault(Js_dict.get(v, "directive"), null));
    if (directive.TAG === "Ok") {
      var clientId = Toolkit__Decoders.$$Option.t_decode((function (param) {
              return Spice.arrayFromJson(Identifiers.ClientId.t_decode, param);
            }), Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
      if (clientId.TAG === "Ok") {
        var lastScanContext = Toolkit__Decoders.$$Option.t_decode((function (param) {
                return Spice.arrayFromJson(t_decode$8, param);
              }), Belt_Option.getWithDefault(Js_dict.get(v, "lastScanContext"), null));
        if (lastScanContext.TAG === "Ok") {
          var lastScanDate = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.DateTimeSlot.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastScanDate"), null));
          if (lastScanDate.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      barcode: barcode._0,
                      directive: directive._0,
                      clientId: clientId._0,
                      lastScanContext: lastScanContext._0,
                      lastScanDate: lastScanDate._0
                    }
                  };
          }
          var e = lastScanDate._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".lastScanDate" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = lastScanContext._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".lastScanContext" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = clientId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".clientId" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = directive._0;
    return {
            TAG: "Error",
            _0: {
              path: ".directive" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function pagination_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function pagination_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$12(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode$2(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode$2(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode$2(v.pagination)
                  ]
                ]));
}

function body_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function counters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "totalUnitsCount",
                    false,
                    Spice.intToJson(v.totalUnitsCount)
                  ],
                  [
                    "totalOrdersCount",
                    false,
                    Spice.intToJson(v.totalOrdersCount)
                  ]
                ]));
}

function counters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var totalUnitsCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "totalUnitsCount"), null));
  if (totalUnitsCount.TAG === "Ok") {
    var totalOrdersCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "totalOrdersCount"), null));
    if (totalOrdersCount.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                totalUnitsCount: totalUnitsCount._0,
                totalOrdersCount: totalOrdersCount._0
              }
            };
    }
    var e = totalOrdersCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".totalOrdersCount" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = totalUnitsCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".totalUnitsCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function parcelUnit_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "lastScanInfo",
                    false,
                    lastScanInfo_encode(v.lastScanInfo)
                  ],
                  [
                    "directive",
                    false,
                    t_encode$6(v.directive)
                  ],
                  [
                    "orderId",
                    false,
                    Spice.optionToJson(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "plannedDeliveryDate",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.$$Date.t_encode, v.plannedDeliveryDate)
                  ],
                  [
                    "atHubSince",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.atHubSince)
                  ]
                ]));
}

function parcelUnit_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      var lastScanInfo = lastScanInfo_decode(Belt_Option.getWithDefault(Js_dict.get(v, "lastScanInfo"), null));
      if (lastScanInfo.TAG === "Ok") {
        var directive = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "directive"), null));
        if (directive.TAG === "Ok") {
          var orderId = Spice.optionFromJson(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
          if (orderId.TAG === "Ok") {
            var plannedDeliveryDate = Spice.optionFromJson(Toolkit__Decoders.$$Date.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "plannedDeliveryDate"), null));
            if (plannedDeliveryDate.TAG === "Ok") {
              var atHubSince = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "atHubSince"), null));
              if (atHubSince.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          barcode: barcode._0,
                          clientId: clientId._0,
                          lastScanInfo: lastScanInfo._0,
                          directive: directive._0,
                          orderId: orderId._0,
                          plannedDeliveryDate: plannedDeliveryDate._0,
                          atHubSince: atHubSince._0
                        }
                      };
              }
              var e = atHubSince._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".atHubSince" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = plannedDeliveryDate._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".plannedDeliveryDate" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = orderId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".orderId" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = directive._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".directive" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = lastScanInfo._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lastScanInfo" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function response_encode$25(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "counters",
                    false,
                    counters_encode(v.counters)
                  ],
                  [
                    "units",
                    false,
                    Spice.arrayToJson(parcelUnit_encode, v.units)
                  ]
                ]));
}

function response_decode$25(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var counters = counters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "counters"), null));
    if (counters.TAG === "Ok") {
      var units = Spice.arrayFromJson(parcelUnit_decode, Belt_Option.getWithDefault(Js_dict.get(v, "units"), null));
      if (units.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  pagesCount: pagesCount._0,
                  counters: counters._0,
                  units: units._0
                }
              };
      }
      var e = units._0;
      return {
              TAG: "Error",
              _0: {
                path: ".units" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = counters._0;
    return {
            TAG: "Error",
            _0: {
              path: ".counters" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$11(param) {
  return Axios.WithResult.post(undefined, body_encode$12(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$25, undefined, baseUrl$1 + "/" + param[0] + "/inventory");
}

var Config$10 = {
  sort_encode: sort_encode$2,
  sort_decode: sort_decode$2,
  filters_encode: filters_encode$2,
  filters_decode: filters_decode$2,
  pagination_encode: pagination_encode$2,
  pagination_decode: pagination_decode$2,
  body_encode: body_encode$12,
  body_decode: body_decode$12,
  counters_encode: counters_encode,
  counters_decode: counters_decode,
  parcelUnit_encode: parcelUnit_encode,
  parcelUnit_decode: parcelUnit_decode,
  response_encode: response_encode$25,
  response_decode: response_decode$25,
  exec: exec$11
};

var $$Request$10 = Toolkit__Request.Make({
      exec: exec$11
    });

var GetInventoryUnits = {
  Config: Config$10,
  $$Request: $$Request$10
};

function hubCounters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "toDeliver",
                    false,
                    Spice.intToJson(v.toDeliver)
                  ],
                  [
                    "toReturn",
                    false,
                    Spice.intToJson(v.toReturn)
                  ],
                  [
                    "toThrow",
                    false,
                    Spice.intToJson(v.toThrow)
                  ],
                  [
                    "toDecide",
                    false,
                    Spice.intToJson(v.toDecide)
                  ]
                ]));
}

function hubCounters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var toDeliver = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "toDeliver"), null));
  if (toDeliver.TAG === "Ok") {
    var toReturn = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "toReturn"), null));
    if (toReturn.TAG === "Ok") {
      var toThrow = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "toThrow"), null));
      if (toThrow.TAG === "Ok") {
        var toDecide = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "toDecide"), null));
        if (toDecide.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    toDeliver: toDeliver._0,
                    toReturn: toReturn._0,
                    toThrow: toThrow._0,
                    toDecide: toDecide._0
                  }
                };
        }
        var e = toDecide._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".toDecide" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = toThrow._0;
      return {
              TAG: "Error",
              _0: {
                path: ".toThrow" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = toReturn._0;
    return {
            TAG: "Error",
            _0: {
              path: ".toReturn" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = toDeliver._0;
  return {
          TAG: "Error",
          _0: {
            path: ".toDeliver" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function hub_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "hubSlugId",
                    false,
                    Curry._1(Identifiers.HubSlugId.t_encode, v.hubSlugId)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "counters",
                    false,
                    hubCounters_encode(v.counters)
                  ]
                ]));
}

function hub_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
  if (hubId.TAG === "Ok") {
    var hubSlugId = Curry._1(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubSlugId"), null));
    if (hubSlugId.TAG === "Ok") {
      var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
      if (name.TAG === "Ok") {
        var counters = hubCounters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "counters"), null));
        if (counters.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    hubId: hubId._0,
                    hubSlugId: hubSlugId._0,
                    name: name._0,
                    counters: counters._0
                  }
                };
        }
        var e = counters._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".counters" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = name._0;
      return {
              TAG: "Error",
              _0: {
                path: ".name" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = hubSlugId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubSlugId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = hubId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".hubId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$26(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "hubs",
                    false,
                    Spice.arrayToJson(hub_encode, v.hubs)
                  ],
                  [
                    "totalCounters",
                    false,
                    hubCounters_encode(v.totalCounters)
                  ]
                ]));
}

function response_decode$26(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var hubs = Spice.arrayFromJson(hub_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubs"), null));
  if (hubs.TAG === "Ok") {
    var totalCounters = hubCounters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "totalCounters"), null));
    if (totalCounters.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                hubs: hubs._0,
                totalCounters: totalCounters._0
              }
            };
    }
    var e = totalCounters._0;
    return {
            TAG: "Error",
            _0: {
              path: ".totalCounters" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = hubs._0;
  return {
          TAG: "Error",
          _0: {
            path: ".hubs" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$12(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$26, undefined, baseUrl$1 + "/inventory/counts");
}

var Config$11 = {
  hubCounters_encode: hubCounters_encode,
  hubCounters_decode: hubCounters_decode,
  hub_encode: hub_encode,
  hub_decode: hub_decode,
  response_encode: response_encode$26,
  response_decode: response_decode$26,
  exec: exec$12
};

var $$Request$11 = Toolkit__Request.Make({
      exec: exec$12
    });

var GetInventoryCounts = {
  Config: Config$11,
  $$Request: $$Request$11
};

function sort_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "damageDate",
                    false,
                    ColiswebApi__Utils.SortOrder.t_encode(v.damageDate)
                  ]]));
}

function sort_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var damageDate = ColiswebApi__Utils.SortOrder.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "damageDate"), null));
  if (damageDate.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              damageDate: damageDate._0
            }
          };
  }
  var e = damageDate._0;
  return {
          TAG: "Error",
          _0: {
            path: ".damageDate" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function filters_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    true,
                    Spice.optionToJson((function (param) {
                            return Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, param);
                          }), v.barcode)
                  ],
                  [
                    "damageDate",
                    true,
                    Spice.optionToJson((function (param) {
                            return Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.DateTimeSlot.t_encode, param);
                          }), v.damageDate)
                  ],
                  [
                    "responsibility",
                    true,
                    Spice.optionToJson((function (param) {
                            return Toolkit__Decoders.$$Option.t_encode((function (param) {
                                          return Spice.arrayToJson(t_encode$5, param);
                                        }), param);
                          }), v.responsibility)
                  ],
                  [
                    "damageLevel",
                    true,
                    Spice.optionToJson((function (param) {
                            return Toolkit__Decoders.$$Option.t_encode((function (param) {
                                          return Spice.arrayToJson(t_encode$4, param);
                                        }), param);
                          }), v.damageLevel)
                  ],
                  [
                    "client",
                    true,
                    Spice.optionToJson((function (param) {
                            return Toolkit__Decoders.$$Option.t_encode((function (param) {
                                          return Spice.arrayToJson(Identifiers.ClientId.t_encode, param);
                                        }), param);
                          }), v.client)
                  ]
                ]));
}

function filters_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Spice.optionFromJson((function (param) {
          return Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, param);
        }), Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var damageDate = Spice.optionFromJson((function (param) {
            return Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.DateTimeSlot.t_decode, param);
          }), Belt_Option.getWithDefault(Js_dict.get(v, "damageDate"), null));
    if (damageDate.TAG === "Ok") {
      var responsibility = Spice.optionFromJson((function (param) {
              return Toolkit__Decoders.$$Option.t_decode((function (param) {
                            return Spice.arrayFromJson(t_decode$5, param);
                          }), param);
            }), Belt_Option.getWithDefault(Js_dict.get(v, "responsibility"), null));
      if (responsibility.TAG === "Ok") {
        var damageLevel = Spice.optionFromJson((function (param) {
                return Toolkit__Decoders.$$Option.t_decode((function (param) {
                              return Spice.arrayFromJson(t_decode$4, param);
                            }), param);
              }), Belt_Option.getWithDefault(Js_dict.get(v, "damageLevel"), null));
        if (damageLevel.TAG === "Ok") {
          var client = Spice.optionFromJson((function (param) {
                  return Toolkit__Decoders.$$Option.t_decode((function (param) {
                                return Spice.arrayFromJson(Identifiers.ClientId.t_decode, param);
                              }), param);
                }), Belt_Option.getWithDefault(Js_dict.get(v, "client"), null));
          if (client.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      barcode: barcode._0,
                      damageDate: damageDate._0,
                      responsibility: responsibility._0,
                      damageLevel: damageLevel._0,
                      client: client._0
                    }
                  };
          }
          var e = client._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".client" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = damageLevel._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".damageLevel" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = responsibility._0;
      return {
              TAG: "Error",
              _0: {
                path: ".responsibility" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = damageDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".damageDate" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function pagination_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function pagination_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$13(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode$3(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode$3(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode$3(v.pagination)
                  ]
                ]));
}

function body_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function order_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.id)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function order_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                clientId: clientId._0
              }
            };
    }
    var e = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function damage_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "order",
                    false,
                    Spice.optionToJson(order_encode$4, v.order)
                  ],
                  [
                    "damageDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.damageDate)
                  ],
                  [
                    "responsibility",
                    false,
                    t_encode$5(v.responsibility)
                  ],
                  [
                    "damageLevel",
                    false,
                    t_encode$4(v.damageLevel)
                  ],
                  [
                    "comments",
                    false,
                    Spice.stringToJson(v.comments)
                  ]
                ]));
}

function damage_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var order = Spice.optionFromJson(order_decode$4, Belt_Option.getWithDefault(Js_dict.get(v, "order"), null));
    if (order.TAG === "Ok") {
      var damageDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "damageDate"), null));
      if (damageDate.TAG === "Ok") {
        var responsibility = t_decode$5(Belt_Option.getWithDefault(Js_dict.get(v, "responsibility"), null));
        if (responsibility.TAG === "Ok") {
          var damageLevel = t_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "damageLevel"), null));
          if (damageLevel.TAG === "Ok") {
            var comments = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "comments"), null));
            if (comments.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        barcode: barcode._0,
                        order: order._0,
                        damageDate: damageDate._0,
                        responsibility: responsibility._0,
                        damageLevel: damageLevel._0,
                        comments: comments._0
                      }
                    };
            }
            var e = comments._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".comments" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = damageLevel._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".damageLevel" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = responsibility._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".responsibility" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = damageDate._0;
      return {
              TAG: "Error",
              _0: {
                path: ".damageDate" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = order._0;
    return {
            TAG: "Error",
            _0: {
              path: ".order" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function checksum_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orders",
                    false,
                    Spice.stringToJson(v.orders)
                  ]]));
}

function checksum_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orders = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "orders"), null));
  if (orders.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orders: orders._0
            }
          };
  }
  var e = orders._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orders" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode$27(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "damages",
                    false,
                    Spice.arrayToJson(damage_encode$1, v.damages)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "checksum",
                    false,
                    checksum_encode(v.checksum)
                  ]
                ]));
}

function response_decode$27(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var damages = Spice.arrayFromJson(damage_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "damages"), null));
  if (damages.TAG === "Ok") {
    var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
    if (pagesCount.TAG === "Ok") {
      var checksum = checksum_decode(Belt_Option.getWithDefault(Js_dict.get(v, "checksum"), null));
      if (checksum.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  damages: damages._0,
                  pagesCount: pagesCount._0,
                  checksum: checksum._0
                }
              };
      }
      var e = checksum._0;
      return {
              TAG: "Error",
              _0: {
                path: ".checksum" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = pagesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pagesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = damages._0;
  return {
          TAG: "Error",
          _0: {
            path: ".damages" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$13(param) {
  return Axios.WithResult.post(undefined, body_encode$13(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$27, undefined, baseUrl$1 + "/" + param[0] + "/damages/history");
}

var Config$12 = {
  sort_encode: sort_encode$3,
  sort_decode: sort_decode$3,
  filters_encode: filters_encode$3,
  filters_decode: filters_decode$3,
  pagination_encode: pagination_encode$3,
  pagination_decode: pagination_decode$3,
  body_encode: body_encode$13,
  body_decode: body_decode$13,
  order_encode: order_encode$4,
  order_decode: order_decode$4,
  damage_encode: damage_encode$1,
  damage_decode: damage_decode$1,
  checksum_encode: checksum_encode,
  checksum_decode: checksum_decode,
  response_encode: response_encode$27,
  response_decode: response_decode$27,
  exec: exec$13
};

var $$Request$12 = Toolkit__Request.Make({
      exec: exec$13
    });

var DamagesListPaginated = {
  Config: Config$12,
  $$Request: $$Request$12
};

var Damages$1 = {
  DamagesListPaginated: DamagesListPaginated
};

var Hubs = {
  baseUrl: baseUrl$1,
  GetInventoryUnits: GetInventoryUnits,
  GetInventoryCounts: GetInventoryCounts,
  Damages: Damages$1
};

var kyInstance$1 = kyInstance.extend({
      prefixUrl: baseUrl + "/damages"
    });

var kyInstance$2 = kyInstance$1.extend({
      prefixUrl: baseUrl + "/damages/history"
    });

function filters_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "hubId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.HubId.t_encode, param);
                          }), v.hubId)
                  ],
                  [
                    "client",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.ClientId.t_encode, param);
                          }), v.client)
                  ],
                  [
                    "responsibility",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode$5, param);
                          }), v.responsibility)
                  ],
                  [
                    "damageLevel",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode$4, param);
                          }), v.damageLevel)
                  ],
                  [
                    "damageDate",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.DateTimeSlot.t_encode, v.damageDate)
                  ]
                ]));
}

function filters_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Toolkit__Decoders.$$Option.t_decode(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var hubId = Toolkit__Decoders.$$Option.t_decode((function (param) {
            return Spice.arrayFromJson(Identifiers.HubId.t_decode, param);
          }), Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var client = Toolkit__Decoders.$$Option.t_decode((function (param) {
              return Spice.arrayFromJson(Identifiers.ClientId.t_decode, param);
            }), Belt_Option.getWithDefault(Js_dict.get(v, "client"), null));
      if (client.TAG === "Ok") {
        var responsibility = Toolkit__Decoders.$$Option.t_decode((function (param) {
                return Spice.arrayFromJson(t_decode$5, param);
              }), Belt_Option.getWithDefault(Js_dict.get(v, "responsibility"), null));
        if (responsibility.TAG === "Ok") {
          var damageLevel = Toolkit__Decoders.$$Option.t_decode((function (param) {
                  return Spice.arrayFromJson(t_decode$4, param);
                }), Belt_Option.getWithDefault(Js_dict.get(v, "damageLevel"), null));
          if (damageLevel.TAG === "Ok") {
            var damageDate = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.DateTimeSlot.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "damageDate"), null));
            if (damageDate.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        barcode: barcode._0,
                        hubId: hubId._0,
                        client: client._0,
                        responsibility: responsibility._0,
                        damageLevel: damageLevel._0,
                        damageDate: damageDate._0
                      }
                    };
            }
            var e = damageDate._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".damageDate" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = damageLevel._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".damageLevel" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = responsibility._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".responsibility" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = client._0;
      return {
              TAG: "Error",
              _0: {
                path: ".client" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function sort_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "damageDate",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.damageDate)
                  ]]));
}

function sort_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var damageDate = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "damageDate"), null));
  if (damageDate.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              damageDate: damageDate._0
            }
          };
  }
  var e = damageDate._0;
  return {
          TAG: "Error",
          _0: {
            path: ".damageDate" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function pagination_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function pagination_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$14(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode$4(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode$4(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode$4(v.pagination)
                  ]
                ]));
}

function body_decode$14(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function order_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.id)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function order_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                clientId: clientId._0
              }
            };
    }
    var e = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function damage_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "hubId",
                    false,
                    Curry._1(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "order",
                    false,
                    Spice.optionToJson(order_encode$5, v.order)
                  ],
                  [
                    "damageDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.damageDate)
                  ],
                  [
                    "responsibility",
                    false,
                    t_encode$5(v.responsibility)
                  ],
                  [
                    "damageLevel",
                    false,
                    t_encode$4(v.damageLevel)
                  ],
                  [
                    "comments",
                    false,
                    Spice.stringToJson(v.comments)
                  ]
                ]));
}

function damage_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    var hubId = Curry._1(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
    if (hubId.TAG === "Ok") {
      var order = Spice.optionFromJson(order_decode$5, Belt_Option.getWithDefault(Js_dict.get(v, "order"), null));
      if (order.TAG === "Ok") {
        var damageDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "damageDate"), null));
        if (damageDate.TAG === "Ok") {
          var responsibility = t_decode$5(Belt_Option.getWithDefault(Js_dict.get(v, "responsibility"), null));
          if (responsibility.TAG === "Ok") {
            var damageLevel = t_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "damageLevel"), null));
            if (damageLevel.TAG === "Ok") {
              var comments = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "comments"), null));
              if (comments.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          barcode: barcode._0,
                          hubId: hubId._0,
                          order: order._0,
                          damageDate: damageDate._0,
                          responsibility: responsibility._0,
                          damageLevel: damageLevel._0,
                          comments: comments._0
                        }
                      };
              }
              var e = comments._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".comments" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = damageLevel._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".damageLevel" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = responsibility._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".responsibility" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = damageDate._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".damageDate" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = order._0;
      return {
              TAG: "Error",
              _0: {
                path: ".order" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = hubId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubId" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function response_encode$28(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "damages",
                    false,
                    Spice.arrayToJson(damage_encode$2, v.damages)
                  ]
                ]));
}

function response_decode$28(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var damages = Spice.arrayFromJson(damage_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "damages"), null));
    if (damages.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagesCount: pagesCount._0,
                damages: damages._0
              }
            };
    }
    var e = damages._0;
    return {
            TAG: "Error",
            _0: {
              path: ".damages" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$16(body) {
  return {
          kyInstance: kyInstance$2,
          path: "",
          requestOptions: {
            method: "POST",
            json: body_encode$14(body)
          },
          key: [
            "damagesHistory",
            JSON.stringify(body_encode$14(body))
          ]
        };
}

var Get = {
  filters_encode: filters_encode$4,
  filters_decode: filters_decode$4,
  sort_encode: sort_encode$4,
  sort_decode: sort_decode$4,
  pagination_encode: pagination_encode$4,
  pagination_decode: pagination_decode$4,
  body_encode: body_encode$14,
  body_decode: body_decode$14,
  order_encode: order_encode$5,
  order_decode: order_decode$5,
  damage_encode: damage_encode$2,
  damage_decode: damage_decode$2,
  response_encode: response_encode$28,
  response_decode: response_decode$28,
  config: config$16
};

var $$History = {
  kyInstance: kyInstance$2,
  Get: Get
};

var Damages$2 = {
  kyInstance: kyInstance$1,
  $$History: $$History
};

var Decoders;

export {
  Decoders ,
  baseUrl ,
  Status ,
  UnscannedCause ,
  DamageStatus ,
  UserAction ,
  DamageLevel ,
  Responsibility ,
  Directive ,
  DeliveryUnitLocation ,
  deliveryUnitLocation_encode ,
  deliveryUnitLocation_decode ,
  ScanContext ,
  AwaitingContext ,
  Unit ,
  OrdersDirectives ,
  Client ,
  Order$2 as Order,
  Hubs ,
  Damages$2 as Damages,
}
/* isWebview Not a pure module */
