

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as ReactIntl from "react-intl";
import * as Fa from "react-icons/fa";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Tooltip from "../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Tooltip.bs.js";
import * as Decoders__UnitMeasure from "../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Decoders__UnitMeasure.bs.js";

function ApiComponents__VehicleFormatPopover(props) {
  var vehicleFormat = props.vehicleFormat;
  return JsxRuntime.jsx(Toolkit__Ui_Tooltip.make, {
              triggerContent: JsxRuntime.jsx(Fa.FaInfoCircle, {
                    size: 16,
                    className: "text-info-500"
                  }),
              tooltipContent: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("p", {
                            children: vehicleFormat.name,
                            className: "text-neutral-600 mb-2 mr-6"
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("strong", {
                                            children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_f4839ef4",
                                                  defaultMessage: "Longueur"
                                                }),
                                            className: "font-semibold  bg-neutral-100 block px-1 py-0.5"
                                          }),
                                      JsxRuntime.jsx("p", {
                                            children: Curry._3(Decoders__UnitMeasure.Dimension.WithUnit.display, vehicleFormat.length, undefined, undefined),
                                            className: "font-mono px-1"
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("strong", {
                                            children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_a75a4f63",
                                                  defaultMessage: "Hauteur"
                                                }),
                                            className: "font-semibold  bg-neutral-100 block px-1 py-0.5"
                                          }),
                                      JsxRuntime.jsx("p", {
                                            children: Curry._3(Decoders__UnitMeasure.Dimension.WithUnit.display, vehicleFormat.height, undefined, undefined),
                                            className: "font-mono px-1"
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("strong", {
                                            children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_fb3027d9",
                                                  defaultMessage: "Largeur"
                                                }),
                                            className: "font-semibold  bg-neutral-100 block py-0.5"
                                          }),
                                      JsxRuntime.jsx("p", {
                                            children: Curry._3(Decoders__UnitMeasure.Dimension.WithUnit.display, vehicleFormat.width, undefined, undefined),
                                            className: "font-mono"
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("strong", {
                                            children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_bd7a9717",
                                                  defaultMessage: "Volume"
                                                }),
                                            className: "font-semibold  bg-neutral-100 block py-0.5"
                                          }),
                                      JsxRuntime.jsx("p", {
                                            children: Curry._3(Decoders__UnitMeasure.Volume.WithUnit.display, vehicleFormat.volume, undefined, undefined),
                                            className: "font-mono"
                                          })
                                    ]
                                  })
                            ],
                            className: "text-neutral-700 grid grid-cols-3 gap-y-2"
                          })
                    ]
                  }),
              side: "bottom",
              contentClassName: "!px-2"
            });
}

var make = ApiComponents__VehicleFormatPopover;

export {
  make ,
}
/* react-intl Not a pure module */
