

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as UnleashApi from "./UnleashApi.bs.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Identifiers from "./Identifiers.bs.js";
import * as Toolkit__Fetcher from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/fetcher/Toolkit__Fetcher.bs.js";
import * as Toolkit__Decoders from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__V5_Hubs from "./v5/ColiswebApi__V5_Hubs.bs.js";
import * as ColiswebApi__V5_Admin from "./v5/ColiswebApi__V5_Admin.bs.js";
import * as ColiswebApi__V5_Store from "./v5/ColiswebApi__V5_Store.bs.js";
import * as ColiswebApi__V5_Utils from "./v5/ColiswebApi__V5_Utils.bs.js";
import * as ColiswebApi__V5_Client from "./v5/ColiswebApi__V5_Client.bs.js";
import * as ColiswebApi__V5_Orders from "./v5/ColiswebApi__V5_Orders.bs.js";
import * as ColiswebApi__V6_Parcel from "./v6/ColiswebApi__V6_Parcel.bs.js";
import * as Toolkit__Primitives_Array from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";
import * as ColiswebApi__V5_Transporter from "./v5/ColiswebApi__V5_Transporter.bs.js";

function key(storeId) {
  return [
          "accessRestrictedByStoreId",
          storeId
        ];
}

var AccessRestrictedByStoreId = Toolkit__Fetcher.Make({
      $$Request: UnleashApi.AccessRestrictedByStoreId.$$Request,
      key: key
    });

function key$1(clientId) {
  return [
          "AccessRestrictedByClientId",
          clientId
        ];
}

var AccessRestrictedByClientId = Toolkit__Fetcher.Make({
      $$Request: UnleashApi.AccessRestrictedByClientId.$$Request,
      key: key$1
    });

function key$2(transporterId) {
  return [
          "AccessRestrictedByTransporterId",
          transporterId
        ];
}

var AccessRestrictedByTransporterId = Toolkit__Fetcher.Make({
      $$Request: UnleashApi.AccessRestrictedByTransporterId.$$Request,
      key: key$2
    });

function key$3(param) {
  return ["FetchActivatedStores"];
}

var FetchActivatedStores = Toolkit__Fetcher.Make({
      $$Request: UnleashApi.FetchAllowedStores.$$Request,
      key: key$3
    });

function key$4(param) {
  return ["FetchActivatedClients"];
}

var FetchActivatedClients = Toolkit__Fetcher.Make({
      $$Request: UnleashApi.FetchAllowedClients.$$Request,
      key: key$4
    });

function key$5(username) {
  return [
          "RoutePlanEditRestrictedByUsername",
          username
        ];
}

var RoutePlanEditRestrictedByUsername = Toolkit__Fetcher.Make({
      $$Request: UnleashApi.RoutePlanEditRestrictedByUsername.$$Request,
      key: key$5
    });

function key$6(username) {
  return [
          "PreselectionJobRetryRestrictedByUsername",
          username
        ];
}

var OrderJobRetryEditRestrictedByUsername = Toolkit__Fetcher.Make({
      $$Request: UnleashApi.PreselectionJobRetryRestrictedByUsername.$$Request,
      key: key$6
    });

var Unleash = {
  AccessRestrictedByStoreId: AccessRestrictedByStoreId,
  AccessRestrictedByClientId: AccessRestrictedByClientId,
  AccessRestrictedByTransporterId: AccessRestrictedByTransporterId,
  FetchActivatedStores: FetchActivatedStores,
  FetchActivatedClients: FetchActivatedClients,
  RoutePlanEditRestrictedByUsername: RoutePlanEditRestrictedByUsername,
  OrderJobRetryEditRestrictedByUsername: OrderJobRetryEditRestrictedByUsername
};

function key$7(param) {
  return [
          "HubsGetCounters",
          param[0],
          JSON.stringify(Curry._1(ColiswebApi__V5_Hubs.GetDeliveriesCount.Config.params_encode, param[1]))
        ];
}

var GetCounters = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Hubs.GetDeliveriesCount.$$Request,
      key: key$7
    });

function key$8(param) {
  return [
          "GetSpotDeliveries",
          param[0],
          JSON.stringify(Curry._1(ColiswebApi__V5_Hubs.GetSpotDeliveries.Config.params_encode, param[1]))
        ];
}

var GetSpotDeliveries = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Hubs.GetSpotDeliveries.$$Request,
      key: key$8
    });

function key$9(param) {
  return [
          "HubsGetDeliveriesInformations",
          param[0],
          JSON.stringify(Curry._1(ColiswebApi__V5_Hubs.GetDeliveriesInformations.Config.params_encode, param[1]))
        ];
}

var GetDeliveriesInformations = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Hubs.GetDeliveriesInformations.$$Request,
      key: key$9
    });

function key$10(param) {
  return [
          "HubsGetDeliveriesPackets",
          param[0],
          Curry._2(Toolkit__Primitives_Array.$$Array.map, param[1], (function (v) {
                    return v;
                  })).join("-")
        ];
}

var GetDeliveriesPackets = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Hubs.GetDeliveriesPackets.$$Request,
      key: key$10
    });

var Hubs = {
  GetCounters: GetCounters,
  GetSpotDeliveries: GetSpotDeliveries,
  GetDeliveriesInformations: GetDeliveriesInformations,
  GetDeliveriesPackets: GetDeliveriesPackets
};

function key$11(clientId) {
  return [
          "client",
          clientId
        ];
}

var ClientFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.GetClient.$$Request,
      key: key$11
    });

function key$12(clientIds) {
  return [
          "ClientNamesFetcher",
          Curry._2(Toolkit__Primitives_Array.$$Array.map, clientIds, (function (prim) {
                    return prim;
                  })).join("-")
        ];
}

var ClientNamesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.GetClientNames.$$Request,
      key: key$12
    });

function key$13(param) {
  return [
          "client",
          param[0],
          "deliveries",
          JSON.stringify(param[1])
        ];
}

var ClientDeliveriesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.GetDeliveries.$$Request,
      key: key$13
    });

function key$14(param) {
  return [
          "ClientGetAllStores",
          param[0],
          param[1]
        ];
}

var ClientGetAllStores = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.SearchStore.$$Request,
      key: key$14
    });

function key$15(param) {
  return [
          "ClientGetStores",
          param[0],
          JSON.stringify(Curry._1(ColiswebApi__V5_Client.GetStores.Config.body_encode, param[1]))
        ];
}

var ClientGetStoresFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.GetStores.$$Request,
      key: key$15
    });

function key$16(param) {
  var body = param[1];
  return Curry._2(Toolkit__Primitives_Array.$$Array.concat, Curry._2(Toolkit__Primitives_Array.$$Array.concat, [
                  "GetDeliveriesCountByStores",
                  param[0]
                ], Curry._2(Toolkit__Primitives_Array.$$Array.map, body.storeIds, (function (prim) {
                        return prim;
                      }))), Curry._2(Toolkit__Primitives_Array.$$Array.map, Belt_Option.getWithDefault(body.deliveryStates, []), ColiswebApi__V5_Utils.StatusProvider.toString));
}

var GetDeliveriesCountByStoresFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.GetDeliveriesCountByStores.$$Request,
      key: key$16
    });

function key$17(param) {
  return [
          "client",
          param[0],
          "states",
          param[1]
        ];
}

var ClientDeliveryStatesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.GetStates.$$Request,
      key: key$17
    });

function key$18(clientId) {
  return [
          "storeEligibleOptions",
          clientId
        ];
}

var ClientEligibleOptionsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.DeliveryEligibility.GetEligibleOptions.$$Request,
      key: key$18
    });

function key$19(param) {
  return [
          "storeDelivery",
          param[0],
          param[1]
        ];
}

var ClientDeliveryFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.Delivery.GetDelivery.$$Request,
      key: key$19
    });

function key$20(param) {
  return [
          "storeDeliveryPictures",
          param[0],
          param[1]
        ];
}

var ClientDeliveryPicturesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.Delivery.GetPictures.$$Request,
      key: key$20
    });

function key$21(param) {
  return [
          "storeDeliveryComments",
          param[0],
          param[1]
        ];
}

var ClientDeliveryCommentsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.Delivery.GetComments.$$Request,
      key: key$21
    });

function key$22(param) {
  return [
          "storeDeliveryPackets",
          param[0],
          param[1]
        ];
}

var ClientDeliveryPacketsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.Delivery.GetPackets.$$Request,
      key: key$22
    });

function key$23(param) {
  return [
          "storeDeliveryChangesHistory",
          param[0],
          param[1]
        ];
}

var ClientDeliveryChangesHistoryFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.Delivery.GetChangesHistory.$$Request,
      key: key$23
    });

function key$24(param) {
  return [
          "StoreDeliveryGetEligibleTimeOptions",
          param[0],
          Belt_Option.getWithDefault(JSON.stringify(param[1]), "")
        ];
}

var ClientDeliveryGetEligibleTimeOptions = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.DeliveryEligibility.GetEligibleTimeSlots.$$Request,
      key: key$24
    });

function key$25(param) {
  return [
          "ClientDeliveryGetRescheduleTimeOptions",
          param[0],
          param[1],
          Belt_Option.getWithDefault(JSON.stringify(param[2]), "")
        ];
}

var ClientDeliveryGetRescheduleTimeOptions = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.Delivery.GetReschedulingCalendar.$$Request,
      key: key$25
    });

function key$26(param) {
  return [
          "StoreGetSlotOptionPriceComposition",
          param[0],
          param[1],
          param[2]
        ];
}

var GetSlotOptionPriceComposition = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.Delivery.GetSlotOptionPriceComposition.$$Request,
      key: key$26
    });

function key$27(param) {
  return [
          "GetStoreAddressByStoreCode",
          param[0],
          param[1]
        ];
}

var GetStoreAddressByStoreCode = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.GetStoreAddressByStoreCode.$$Request,
      key: key$27
    });

function key$28(param) {
  return [
          "GetOrderDeliveriesForClient",
          param[0],
          param[1]
        ];
}

var GetOrderDeliveriesForClient = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Client.GetOrderDeliveriesForClient.$$Request,
      key: key$28
    });

var Client = {
  ClientFetcher: ClientFetcher,
  ClientNamesFetcher: ClientNamesFetcher,
  ClientDeliveriesFetcher: ClientDeliveriesFetcher,
  ClientGetAllStores: ClientGetAllStores,
  ClientGetStoresFetcher: ClientGetStoresFetcher,
  GetDeliveriesCountByStoresFetcher: GetDeliveriesCountByStoresFetcher,
  ClientDeliveryStatesFetcher: ClientDeliveryStatesFetcher,
  ClientEligibleOptionsFetcher: ClientEligibleOptionsFetcher,
  ClientDeliveryFetcher: ClientDeliveryFetcher,
  ClientDeliveryPicturesFetcher: ClientDeliveryPicturesFetcher,
  ClientDeliveryCommentsFetcher: ClientDeliveryCommentsFetcher,
  ClientDeliveryPacketsFetcher: ClientDeliveryPacketsFetcher,
  ClientDeliveryChangesHistoryFetcher: ClientDeliveryChangesHistoryFetcher,
  ClientDeliveryGetEligibleTimeOptions: ClientDeliveryGetEligibleTimeOptions,
  ClientDeliveryGetRescheduleTimeOptions: ClientDeliveryGetRescheduleTimeOptions,
  GetSlotOptionPriceComposition: GetSlotOptionPriceComposition,
  GetStoreAddressByStoreCode: GetStoreAddressByStoreCode,
  GetOrderDeliveriesForClient: GetOrderDeliveriesForClient
};

function key$29(param) {
  return [
          "OrderDeliveryDatesFetcher",
          "hubId-" + param[0],
          Curry._2(Toolkit__Primitives_Array.$$Array.map, param[1], (function (prim) {
                    return prim;
                  })).join("-")
        ];
}

var OrderDeliveryDatesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Orders.GetOrderDeliveryDates.$$Request,
      key: key$29
    });

var Deliveries = {
  OrderDeliveryDatesFetcher: OrderDeliveryDatesFetcher
};

function key$30(param) {
  return [
          "store",
          param[0],
          param[1]
        ];
}

var StoreFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.GetStore.$$Request,
      key: key$30
    });

function key$31(param) {
  return [
          "client",
          param[0],
          "store",
          param[1],
          "deliveries",
          JSON.stringify(param[2])
        ];
}

var StoreDeliveriesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.GetDeliveries.$$Request,
      key: key$31
    });

function key$32(param) {
  return [
          "client",
          param[0],
          "store",
          param[1],
          "states",
          Belt_Option.mapWithDefault(param[2], "", ColiswebApi__V5_Store.GetStates.Config.StateFilter.toString)
        ];
}

var StoreDeliveryStatesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.GetStates.$$Request,
      key: key$32
    });

function key$33(param) {
  return [
          "storeEligibleOptions",
          param[0],
          param[1]
        ];
}

var StoreEligibleOptionsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.DeliveryEligibility.GetEligibleOptions.$$Request,
      key: key$33
    });

function key$34(param) {
  return [
          "storeDelivery",
          param[0],
          param[1],
          param[2]
        ];
}

var StoreDeliveryFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.Delivery.GetDelivery.$$Request,
      key: key$34
    });

function key$35(param) {
  return [
          "storeDeliveryPictures",
          param[0],
          param[1],
          param[2]
        ];
}

var StoreDeliveryPicturesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.Delivery.GetPictures.$$Request,
      key: key$35
    });

function key$36(param) {
  return [
          "storeDeliveryComments",
          param[0],
          param[1],
          param[2]
        ];
}

var StoreDeliveryCommentsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.Delivery.GetComments.$$Request,
      key: key$36
    });

function key$37(param) {
  return [
          "storeDeliveryPackets",
          param[0],
          param[1],
          param[2]
        ];
}

var StoreDeliveryPacketsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.Delivery.GetPackets.$$Request,
      key: key$37
    });

function key$38(param) {
  return [
          "storeDeliveryChangesHistory",
          param[0],
          param[1],
          param[2]
        ];
}

var StoreDeliveryChangesHistoryFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.Delivery.GetChangesHistory.$$Request,
      key: key$38
    });

function key$39(param) {
  return [
          "StoreDeliveryGetEligibleTimeOptions",
          param[0],
          param[1],
          Belt_Option.getWithDefault(JSON.stringify(param[2]), "")
        ];
}

var StoreDeliveryGetEligibleTimeOptions = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.DeliveryEligibility.GetEligibleTimeSlots.$$Request,
      key: key$39
    });

function key$40(param) {
  return [
          "StoreGetSlotOptionPriceComposition",
          param[0],
          param[1],
          param[2],
          param[3]
        ];
}

var GetSlotOptionPriceComposition$1 = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.Delivery.GetSlotOptionPriceComposition.$$Request,
      key: key$40
    });

function key$41(param) {
  return [
          "StoreDeliveryGetRescheduleTimeOptions",
          param[0],
          param[1],
          param[2],
          Belt_Option.getWithDefault(JSON.stringify(param[3]), "")
        ];
}

var StoreDeliveryGetRescheduleTimeOptions = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Store.Delivery.GetReschedulingCalendar.$$Request,
      key: key$41
    });

var Store = {
  StoreFetcher: StoreFetcher,
  StoreDeliveriesFetcher: StoreDeliveriesFetcher,
  StoreDeliveryStatesFetcher: StoreDeliveryStatesFetcher,
  StoreEligibleOptionsFetcher: StoreEligibleOptionsFetcher,
  StoreDeliveryFetcher: StoreDeliveryFetcher,
  StoreDeliveryPicturesFetcher: StoreDeliveryPicturesFetcher,
  StoreDeliveryCommentsFetcher: StoreDeliveryCommentsFetcher,
  StoreDeliveryPacketsFetcher: StoreDeliveryPacketsFetcher,
  StoreDeliveryChangesHistoryFetcher: StoreDeliveryChangesHistoryFetcher,
  StoreDeliveryGetEligibleTimeOptions: StoreDeliveryGetEligibleTimeOptions,
  GetSlotOptionPriceComposition: GetSlotOptionPriceComposition$1,
  StoreDeliveryGetRescheduleTimeOptions: StoreDeliveryGetRescheduleTimeOptions
};

function key$42(transporterId) {
  return [
          transporterId,
          "GetSettings"
        ];
}

var GetSettings = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Transporter.GetSettings.$$Request,
      key: key$42
    });

function key$43(param) {
  return [
          param[0],
          "TransporterDeliveriesCountsFetcher",
          JSON.stringify(param[1])
        ];
}

var TransporterDeliveriesCountsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Transporter.GetDeliveriesCounts.$$Request,
      key: key$43
    });

function key$44(param) {
  return [
          param[0],
          "TransporterDeliveriesFetcher",
          JSON.stringify(param[1])
        ];
}

var TransporterDeliveriesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Transporter.GetDeliveries.$$Request,
      key: key$44
    });

function key$45(param) {
  return [
          "courierDetails",
          param[0],
          param[1]
        ];
}

var CourierDetailsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Transporter.GetCourierDetails.$$Request,
      key: key$45
    });

function key$46(param) {
  return [
          param[0],
          param[1],
          "TransporterDeliveryFetcher"
        ];
}

var TransporterDeliveryFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Transporter.GetDelivery.$$Request,
      key: key$46
    });

function key$47(param) {
  return [
          "transporterDeliveryPictures",
          param[0],
          param[1]
        ];
}

var TransporterDeliveryPicturesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Transporter.GetPictures.$$Request,
      key: key$47
    });

function key$48(param) {
  return [
          "transporterDeliveryPackets",
          param[0],
          param[1]
        ];
}

var TransporterDeliveryPacketsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Transporter.GetPackets.$$Request,
      key: key$48
    });

function key$49(param) {
  return [
          "transporterDeliveryComments",
          param[0],
          param[1]
        ];
}

var TransporterDeliveryCommentsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Transporter.GetComments.$$Request,
      key: key$49
    });

function key$50(param) {
  return [
          "transporterGetChangesHistory",
          param[0],
          param[1]
        ];
}

var TransporterDeliveryGetChangesHistory = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Transporter.GetChangesHistory.$$Request,
      key: key$50
    });

var Transporter = {
  GetSettings: GetSettings,
  TransporterDeliveriesCountsFetcher: TransporterDeliveriesCountsFetcher,
  TransporterDeliveriesFetcher: TransporterDeliveriesFetcher,
  CourierDetailsFetcher: CourierDetailsFetcher,
  TransporterDeliveryFetcher: TransporterDeliveryFetcher,
  TransporterDeliveryPicturesFetcher: TransporterDeliveryPicturesFetcher,
  TransporterDeliveryPacketsFetcher: TransporterDeliveryPacketsFetcher,
  TransporterDeliveryCommentsFetcher: TransporterDeliveryCommentsFetcher,
  TransporterDeliveryGetChangesHistory: TransporterDeliveryGetChangesHistory
};

function key$51(routeId) {
  return [
          "GetRouteLatestLocations",
          routeId
        ];
}

var GetRouteLatestLocations = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetRouteLatestLocations.$$Request,
      key: key$51
    });

function key$52(params) {
  return Curry._1(Toolkit__Primitives_Array.$$Array.concatMany, [
              ["GetStoresListByStoreCode"],
              Curry._2(Toolkit__Primitives_Array.$$Array.map, params.references, (function (param) {
                      return param.clientId + "-" + param.storeCode;
                    }))
            ]);
}

var GetStoresListByStoreCode = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetStoresListByStoreCode.$$Request,
      key: key$52
    });

function key$53(routeId) {
  return [
          "AdminGetRoutePrice",
          routeId
        ];
}

var AdminGetRoutePrice = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetRoutePrice.$$Request,
      key: key$53
    });

function key$54(storeIds) {
  return Curry._2(Toolkit__Primitives_Array.$$Array.concat, ["AdminGetStoresInformationsFetcher"], Curry._2(Toolkit__Primitives_Array.$$Array.map, storeIds, (function (c) {
                    return c;
                  })));
}

var AdminGetStoresInformationsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetStoresInformations.$$Request,
      key: key$54
    });

function key$55(clientIds) {
  return Curry._2(Toolkit__Primitives_Array.$$Array.concat, ["AdminGetClientsInformationsFetcher"], Curry._2(Toolkit__Primitives_Array.$$Array.map, clientIds, (function (c) {
                    return c;
                  })));
}

var AdminGetClientsInformationsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetClientsInformation.$$Request,
      key: key$55
    });

function key$56(param) {
  return Curry._2(Toolkit__Primitives_Array.$$Array.concat, [
              "AdminGetStoresPaginatedFetcher",
              Belt_Option.getWithDefault(param[1], ""),
              String(param[3]),
              String(param[2])
            ], Curry._2(Toolkit__Primitives_Array.$$Array.map, param[0], (function (c) {
                    return c;
                  })));
}

var AdminSearchStoresPaginatedFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.SearchStoresPaginated.$$Request,
      key: key$56
    });

function key$57(transporterIds) {
  return Curry._2(Toolkit__Primitives_Array.$$Array.concat, ["AdminGetTransportersInformations"], Curry._2(Toolkit__Primitives_Array.$$Array.map, transporterIds, (function (c) {
                    return c;
                  })));
}

var AdminGetTransportersInformationsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetTransportersInformations.$$Request,
      key: key$57
    });

function key$58(clientId) {
  return [
          "AdminGetClientStoresFetcher",
          clientId
        ];
}

var AdminGetClientStoresFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetClientStores.$$Request,
      key: key$58
    });

function key$59(param) {
  return ["AdminGetAllStoresAddressesFetcher"];
}

var AdminGetAllStoresAddressesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetAllStoresAddresses.$$Request,
      key: key$59
    });

function key$60(deliveryId) {
  return [
          "AdminGetEligibleTransportersFetcher",
          deliveryId
        ];
}

var AdminGetEligibleTransportersFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetEligibleTransporters.$$Request,
      key: key$60
    });

function key$61(param) {
  return [
          "AdminGetEligibleTransporterDetailsFetcher",
          param[0],
          param[1]
        ];
}

var AdminGetEligibleTransporterDetailsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetEligibleTransporterDetails.$$Request,
      key: key$61
    });

function key$62(param) {
  return Curry._2(Toolkit__Primitives_Array.$$Array.concat, [
              "AdminGetEligibleTransportersDetailsFetcher",
              param[0]
            ], Curry._2(Toolkit__Primitives_Array.$$Array.map, param[1], (function (c) {
                    return c;
                  })));
}

var AdminGetEligibleTransportersDetailsFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetTransporterEligibilityDetails.$$Request,
      key: key$62
    });

function key$63(orderId) {
  return [
          "AdminGetOrderDeliveriesFetcher",
          orderId
        ];
}

var AdminGetOrderDeliveriesFetcher = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Orders.GetOrderDeliveries.$$Request,
      key: key$63
    });

function key$64(param) {
  return [
          "AdminGetStoreAddressByStoreCode",
          param[0],
          param[1]
        ];
}

var AdminGetStoreAddressByStoreCode = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V5_Admin.GetStoreAddressByApiCode.$$Request,
      key: key$64
    });

var Admin = {
  GetRouteLatestLocations: GetRouteLatestLocations,
  GetStoresListByStoreCode: GetStoresListByStoreCode,
  AdminGetRoutePrice: AdminGetRoutePrice,
  AdminGetStoresInformationsFetcher: AdminGetStoresInformationsFetcher,
  AdminGetClientsInformationsFetcher: AdminGetClientsInformationsFetcher,
  AdminSearchStoresPaginatedFetcher: AdminSearchStoresPaginatedFetcher,
  AdminGetTransportersInformationsFetcher: AdminGetTransportersInformationsFetcher,
  AdminGetClientStoresFetcher: AdminGetClientStoresFetcher,
  AdminGetAllStoresAddressesFetcher: AdminGetAllStoresAddressesFetcher,
  AdminGetEligibleTransportersFetcher: AdminGetEligibleTransportersFetcher,
  AdminGetEligibleTransporterDetailsFetcher: AdminGetEligibleTransporterDetailsFetcher,
  AdminGetEligibleTransportersDetailsFetcher: AdminGetEligibleTransportersDetailsFetcher,
  AdminGetOrderDeliveriesFetcher: AdminGetOrderDeliveriesFetcher,
  AdminGetStoreAddressByStoreCode: AdminGetStoreAddressByStoreCode
};

var V5 = {
  Hubs: Hubs,
  Client: Client,
  Deliveries: Deliveries,
  Store: Store,
  Transporter: Transporter,
  Admin: Admin
};

function key$65(param) {
  return ["GetHubsInventoryCounts"];
}

var GetHubsInventoryCounts = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V6_Parcel.Hubs.GetInventoryCounts.$$Request,
      key: key$65
    });

function key$66(param) {
  return [
          "GetHubsInventoryUnits",
          param[0],
          JSON.stringify(Curry._1(ColiswebApi__V6_Parcel.Hubs.GetInventoryUnits.Config.body_encode, param[1]))
        ];
}

var GetHubsInventoryUnits = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V6_Parcel.Hubs.GetInventoryUnits.$$Request,
      key: key$66
    });

function key$67(param) {
  return [
          "HubParcelDetail",
          param[0],
          Identifiers.Barcode.toString(param[1]),
          JSON.stringify(Curry._1(ColiswebApi__V6_Parcel.Unit.HubParcelDetail.Config.body_encode, param[2]))
        ];
}

var GetHubParcelDetail = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V6_Parcel.Unit.HubParcelDetail.$$Request,
      key: key$67
    });

function key$68(barcode) {
  return [
          "GetDirectivesHistory",
          Identifiers.Barcode.toString(barcode)
        ];
}

var GetDirectivesHistory = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V6_Parcel.Unit.DirectivesHistory.$$Request,
      key: key$68
    });

function key$69(param) {
  return [
          "GetOrphansParcels",
          JSON.stringify(Curry._1(ColiswebApi__V6_Parcel.Unit.OrphansList.Config.filters_encode, param.filters)),
          JSON.stringify(Curry._1(ColiswebApi__V6_Parcel.Unit.OrphansList.Config.sort_encode, param.sort)),
          JSON.stringify(Curry._1(ColiswebApi__V6_Parcel.Unit.OrphansList.Config.pagination_encode, param.pagination))
        ];
}

var GetOrphansParcels = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V6_Parcel.Unit.OrphansList.$$Request,
      key: key$69
    });

function key$70(param) {
  return [
          "GetContextParcelCount",
          param[0],
          ColiswebApi__V6_Parcel.ScanContext.toString(param[1]),
          Belt_Option.mapWithDefault(param[2], "", (function (dateFilters) {
                  return JSON.stringify(Toolkit__Decoders.Datetime.t_encode(dateFilters.start)) + JSON.stringify(Toolkit__Decoders.Datetime.t_encode(dateFilters.end));
                })),
          Belt_Option.mapWithDefault(param[3], "empty_clientId", (function (prim) {
                  return prim;
                })),
          Belt_Option.mapWithDefault(param[4], "empty_warehouseCode", (function (prim) {
                  return prim;
                }))
        ];
}

var GetContextParcelCount = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V6_Parcel.Unit.ContextCount.$$Request,
      key: key$70
    });

function key$71(barcode) {
  return [
          "GetOrphanParcelDetail",
          Identifiers.Barcode.toString(barcode)
        ];
}

var GetOrphanParcelDetail = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V6_Parcel.Unit.OrphanEventDetail.$$Request,
      key: key$71
    });

function key$72(param) {
  return [
          "GetHubDamagesHistory",
          param[0],
          JSON.stringify(Curry._1(ColiswebApi__V6_Parcel.Hubs.Damages.DamagesListPaginated.Config.body_encode, param[1]))
        ];
}

var GetHubDamagesHistory = Toolkit__Fetcher.Make({
      $$Request: ColiswebApi__V6_Parcel.Hubs.Damages.DamagesListPaginated.$$Request,
      key: key$72
    });

var Parcel = {
  GetHubsInventoryCounts: GetHubsInventoryCounts,
  GetHubsInventoryUnits: GetHubsInventoryUnits,
  GetHubParcelDetail: GetHubParcelDetail,
  GetDirectivesHistory: GetDirectivesHistory,
  GetOrphansParcels: GetOrphansParcels,
  GetContextParcelCount: GetContextParcelCount,
  GetOrphanParcelDetail: GetOrphanParcelDetail,
  GetHubDamagesHistory: GetHubDamagesHistory
};

var V6 = {
  Parcel: Parcel
};

export {
  Unleash ,
  V5 ,
  V6 ,
}
/* AccessRestrictedByStoreId Not a pure module */
