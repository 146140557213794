

import * as Ky from "ky";
import * as Qs from "qs";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_exn from "../../../../node_modules/rescript/lib/es6/js_exn.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as Js_array from "../../../../node_modules/rescript/lib/es6/js_array.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__Utils from "../ColiswebApi__Utils.bs.js";
import * as Decoders__UnitMeasure from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Decoders__UnitMeasure.bs.js";
import * as ColiswebApi__V6_Parcel from "./ColiswebApi__V6_Parcel.bs.js";
import * as Toolkit__Primitives_Result from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Result.bs.js";

var baseUrl = ColiswebApi__Env.v6ApiUrl + "/order/external";

var kyInstance = Ky.default.create({
      prefixUrl: baseUrl,
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      credentials: "include",
      mode: "cors"
    });

function t_encode(v) {
  switch (v) {
    case "allMissing" :
        return "allMissing";
    case "complete" :
        return "complete";
    case "incomplete" :
        return "incomplete";
    case "waitingForUnits" :
        return "waitingForUnits";
    
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("allMissing" === v) {
          return {
                  TAG: "Ok",
                  _0: "allMissing"
                };
        } else if ("complete" === v) {
          return {
                  TAG: "Ok",
                  _0: "complete"
                };
        } else if ("incomplete" === v) {
          return {
                  TAG: "Ok",
                  _0: "incomplete"
                };
        } else if ("waitingForUnits" === v) {
          return {
                  TAG: "Ok",
                  _0: "waitingForUnits"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString = t_encode;

var State = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: toString
};

function t_encode$1(v) {
  switch (v) {
    case "all_missing" :
        return "all_missing";
    case "complete" :
        return "complete";
    case "incomplete" :
        return "incomplete";
    case "waiting_for_units" :
        return "waiting_for_units";
    
  }
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("all_missing" === v) {
          return {
                  TAG: "Ok",
                  _0: "all_missing"
                };
        } else if ("complete" === v) {
          return {
                  TAG: "Ok",
                  _0: "complete"
                };
        } else if ("incomplete" === v) {
          return {
                  TAG: "Ok",
                  _0: "incomplete"
                };
        } else if ("waiting_for_units" === v) {
          return {
                  TAG: "Ok",
                  _0: "waiting_for_units"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function toV6(v5) {
  switch (v5) {
    case "all_missing" :
        return "allMissing";
    case "complete" :
        return "complete";
    case "incomplete" :
        return "incomplete";
    case "waiting_for_units" :
        return "waitingForUnits";
    
  }
}

var StateV5 = {
  t_encode: t_encode$1,
  t_decode: t_decode$1,
  toV6: toV6
};

function t_encode$2(v) {
  switch (v) {
    case "received" :
        return "received";
    case "canceled" :
        return "canceled";
    case "planned" :
        return "planned";
    
  }
}

function t_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("received" === v) {
          return {
                  TAG: "Ok",
                  _0: "received"
                };
        } else if ("canceled" === v) {
          return {
                  TAG: "Ok",
                  _0: "canceled"
                };
        } else if ("planned" === v) {
          return {
                  TAG: "Ok",
                  _0: "planned"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$1 = t_encode$2;

var GlobalState = {
  t_encode: t_encode$2,
  t_decode: t_decode$2,
  toString: toString$1
};

function t_encode$3(v) {
  if (v === "notDamaged") {
    return "notDamaged";
  } else {
    return "damaged";
  }
}

function t_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("notDamaged" === v) {
          return {
                  TAG: "Ok",
                  _0: "notDamaged"
                };
        } else if ("damaged" === v) {
          return {
                  TAG: "Ok",
                  _0: "damaged"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$2 = t_encode$3;

var DamageStatus = {
  t_encode: t_encode$3,
  t_decode: t_decode$3,
  toString: toString$2
};

function t_encode$4(v) {
  if (v === "areaNotFound") {
    return "areaNotFound";
  } else {
    return "noHubFound";
  }
}

function t_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("areaNotFound" === v) {
          return {
                  TAG: "Ok",
                  _0: "areaNotFound"
                };
        } else if ("noHubFound" === v) {
          return {
                  TAG: "Ok",
                  _0: "noHubFound"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$3 = t_encode$4;

var areaNotFound = {
  id: "_12c23797",
  defaultMessage: "Pas de zone géographique trouvé pour l'adresse"
};

var noHubFound = {
  id: "_24cb534e",
  defaultMessage: "Pas de configuration de hub pour le client et la zone géographique"
};

function humanize(intl, t) {
  var message;
  message = t === "areaNotFound" ? areaNotFound : noHubFound;
  return intl.formatMessage(message);
}

var NoHubCause = {
  t_encode: t_encode$4,
  t_decode: t_decode$4,
  toString: toString$3,
  areaNotFound: areaNotFound,
  noHubFound: noHubFound,
  humanize: humanize
};

function t_encode$5(v) {
  switch (v) {
    case "toIntegrate" :
        return "toIntegrate";
    case "error" :
        return "error";
    case "toIgnore" :
        return "toIgnore";
    case "ongoing" :
        return "ongoing";
    case "confirmed" :
        return "confirmed";
    case "invalid" :
        return "invalid";
    case "toReIntegrate" :
        return "toReIntegrate";
    
  }
}

function t_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("toIntegrate" === v) {
          return {
                  TAG: "Ok",
                  _0: "toIntegrate"
                };
        } else if ("error" === v) {
          return {
                  TAG: "Ok",
                  _0: "error"
                };
        } else if ("toIgnore" === v) {
          return {
                  TAG: "Ok",
                  _0: "toIgnore"
                };
        } else if ("ongoing" === v) {
          return {
                  TAG: "Ok",
                  _0: "ongoing"
                };
        } else if ("confirmed" === v) {
          return {
                  TAG: "Ok",
                  _0: "confirmed"
                };
        } else if ("invalid" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid"
                };
        } else if ("toReIntegrate" === v) {
          return {
                  TAG: "Ok",
                  _0: "toReIntegrate"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var IntegrationStatus = {
  t_encode: t_encode$5,
  t_decode: t_decode$5
};

function t_encode$6(v) {
  switch (v) {
    case "success" :
        return "success";
    case "error" :
        return "error";
    case "toReIntegrate" :
        return "toReIntegrate";
    
  }
}

function t_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("success" === v) {
          return {
                  TAG: "Ok",
                  _0: "success"
                };
        } else if ("error" === v) {
          return {
                  TAG: "Ok",
                  _0: "error"
                };
        } else if ("toReIntegrate" === v) {
          return {
                  TAG: "Ok",
                  _0: "toReIntegrate"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var IntegrationResult = {
  t_encode: t_encode$6,
  t_decode: t_decode$6
};

function encoder(v) {
  var data = {};
  if (v.NAME === "noHub") {
    data["noHubCause"] = t_encode$4(v.VAL);
  } else {
    data["hubId"] = v.VAL;
  }
  return data;
}

function decoder(json) {
  var match = json.noHubCause;
  var match$1 = json.hubId;
  if (match === undefined) {
    if (match$1 !== undefined) {
      return {
              TAG: "Ok",
              _0: {
                NAME: "hub",
                VAL: Caml_option.valFromOption(match$1)
              }
            };
    } else {
      return Spice.error(undefined, "No hub cause or hubId found", json);
    }
  }
  var noHubCause = t_decode$4(Caml_option.valFromOption(match));
  if (noHubCause.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              NAME: "noHub",
              VAL: noHubCause._0
            }
          };
  } else {
    return noHubCause;
  }
}

var codec = [
  encoder,
  decoder
];

var t_encode$7 = encoder;

var t_decode$7 = decoder;

var ExpectedHub = {
  encoder: encoder,
  decoder: decoder,
  codec: codec,
  t_encode: t_encode$7,
  t_decode: t_decode$7
};

var Shared = {
  State: State,
  StateV5: StateV5,
  GlobalState: GlobalState,
  DamageStatus: DamageStatus,
  NoHubCause: NoHubCause,
  IntegrationStatus: IntegrationStatus,
  IntegrationResult: IntegrationResult,
  ExpectedHub: ExpectedHub
};

function t_encode$8(v) {
  switch (v) {
    case "all" :
        return "all";
    case "sameAsExcepted" :
        return "sameAsExcepted";
    case "differentAsExpected" :
        return "differentAsExpected";
    case "withoutExpectedHub" :
        return "withoutExpectedHub";
    
  }
}

function t_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("all" === v) {
          return {
                  TAG: "Ok",
                  _0: "all"
                };
        } else if ("sameAsExcepted" === v) {
          return {
                  TAG: "Ok",
                  _0: "sameAsExcepted"
                };
        } else if ("differentAsExpected" === v) {
          return {
                  TAG: "Ok",
                  _0: "differentAsExpected"
                };
        } else if ("withoutExpectedHub" === v) {
          return {
                  TAG: "Ok",
                  _0: "withoutExpectedHub"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var HubExpectationMode = {
  t_encode: t_encode$8,
  t_decode: t_decode$8
};

function filters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "reference",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.reference)
                  ],
                  [
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.DateTimeSlot.t_encode, v.receivedBetween)
                  ],
                  [
                    "hubIds",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.HubId.t_encode, param);
                          }), v.hubIds)
                  ],
                  [
                    "clientId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.ClientId.t_encode, param);
                          }), v.clientId)
                  ],
                  [
                    "state",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode, param);
                          }), v.state)
                  ],
                  [
                    "globalState",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode$2, param);
                          }), v.globalState)
                  ],
                  [
                    "isTracked",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.$$Boolean.t_encode, v.isTracked)
                  ],
                  [
                    "expectedHubIds",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.HubId.t_encode, param);
                          }), v.expectedHubIds)
                  ],
                  [
                    "hubExpectationMode",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(t_encode$8, v.hubExpectationMode)
                  ],
                  [
                    "damageStatus",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode$3, param);
                          }), v.damageStatus)
                  ]
                ]));
}

function filters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var reference = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "reference"), null));
  if (reference.TAG === "Ok") {
    var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.DateTimeSlot.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
    if (receivedBetween.TAG === "Ok") {
      var hubIds = Toolkit__Decoders.$$Option.t_decode((function (param) {
              return Spice.arrayFromJson(Identifiers.HubId.t_decode, param);
            }), Belt_Option.getWithDefault(Js_dict.get(v, "hubIds"), null));
      if (hubIds.TAG === "Ok") {
        var clientId = Toolkit__Decoders.$$Option.t_decode((function (param) {
                return Spice.arrayFromJson(Identifiers.ClientId.t_decode, param);
              }), Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
        if (clientId.TAG === "Ok") {
          var state = Toolkit__Decoders.$$Option.t_decode((function (param) {
                  return Spice.arrayFromJson(t_decode, param);
                }), Belt_Option.getWithDefault(Js_dict.get(v, "state"), null));
          if (state.TAG === "Ok") {
            var globalState = Toolkit__Decoders.$$Option.t_decode((function (param) {
                    return Spice.arrayFromJson(t_decode$2, param);
                  }), Belt_Option.getWithDefault(Js_dict.get(v, "globalState"), null));
            if (globalState.TAG === "Ok") {
              var isTracked = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.$$Boolean.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "isTracked"), null));
              if (isTracked.TAG === "Ok") {
                var expectedHubIds = Toolkit__Decoders.$$Option.t_decode((function (param) {
                        return Spice.arrayFromJson(Identifiers.HubId.t_decode, param);
                      }), Belt_Option.getWithDefault(Js_dict.get(v, "expectedHubIds"), null));
                if (expectedHubIds.TAG === "Ok") {
                  var hubExpectationMode = Toolkit__Decoders.$$Option.t_decode(t_decode$8, Belt_Option.getWithDefault(Js_dict.get(v, "hubExpectationMode"), null));
                  if (hubExpectationMode.TAG === "Ok") {
                    var damageStatus = Toolkit__Decoders.$$Option.t_decode((function (param) {
                            return Spice.arrayFromJson(t_decode$3, param);
                          }), Belt_Option.getWithDefault(Js_dict.get(v, "damageStatus"), null));
                    if (damageStatus.TAG === "Ok") {
                      return {
                              TAG: "Ok",
                              _0: {
                                reference: reference._0,
                                receivedBetween: receivedBetween._0,
                                hubIds: hubIds._0,
                                clientId: clientId._0,
                                state: state._0,
                                globalState: globalState._0,
                                isTracked: isTracked._0,
                                expectedHubIds: expectedHubIds._0,
                                hubExpectationMode: hubExpectationMode._0,
                                damageStatus: damageStatus._0
                              }
                            };
                    }
                    var e = damageStatus._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".damageStatus" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = hubExpectationMode._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".hubExpectationMode" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = expectedHubIds._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".expectedHubIds" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = isTracked._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".isTracked" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = globalState._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".globalState" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = state._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".state" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = clientId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".clientId" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = hubIds._0;
      return {
              TAG: "Error",
              _0: {
                path: ".hubIds" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = receivedBetween._0;
    return {
            TAG: "Error",
            _0: {
              path: ".receivedBetween" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = reference._0;
  return {
          TAG: "Error",
          _0: {
            path: ".reference" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function sort_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.receivedBetween)
                  ]]));
}

function sort_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
  if (receivedBetween.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              receivedBetween: receivedBetween._0
            }
          };
  }
  var e = receivedBetween._0;
  return {
          TAG: "Error",
          _0: {
            path: ".receivedBetween" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function pagination_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function pagination_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode(v.pagination)
                  ]
                ]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function order_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "primaryOrderReference",
                    false,
                    Curry._1(Identifiers.OrderReference.t_encode, v.primaryOrderReference)
                  ],
                  [
                    "receivedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.receivedAt)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "hubs",
                    false,
                    Spice.arrayToJson(Identifiers.HubId.t_encode, v.hubs)
                  ],
                  [
                    "state",
                    false,
                    t_encode(v.state)
                  ],
                  [
                    "globalState",
                    false,
                    t_encode$2(v.globalState)
                  ],
                  [
                    "isTracked",
                    false,
                    Spice.boolToJson(v.isTracked)
                  ],
                  [
                    "expectedHubId",
                    false,
                    encoder(v.expectedHubId)
                  ],
                  [
                    "damageStatus",
                    false,
                    t_encode$3(v.damageStatus)
                  ]
                ]));
}

function order_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var primaryOrderReference = Curry._1(Identifiers.OrderReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "primaryOrderReference"), null));
    if (primaryOrderReference.TAG === "Ok") {
      var receivedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "receivedAt"), null));
      if (receivedAt.TAG === "Ok") {
        var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
        if (clientId.TAG === "Ok") {
          var hubs = Spice.arrayFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubs"), null));
          if (hubs.TAG === "Ok") {
            var state = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "state"), null));
            if (state.TAG === "Ok") {
              var globalState = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "globalState"), null));
              if (globalState.TAG === "Ok") {
                var isTracked = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isTracked"), null));
                if (isTracked.TAG === "Ok") {
                  var expectedHubId = t_decode$7(Belt_Option.getWithDefault(Js_dict.get(v, "expectedHubId"), null));
                  if (expectedHubId.TAG === "Ok") {
                    var damageStatus = t_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "damageStatus"), null));
                    if (damageStatus.TAG === "Ok") {
                      return {
                              TAG: "Ok",
                              _0: {
                                orderId: orderId._0,
                                primaryOrderReference: primaryOrderReference._0,
                                receivedAt: receivedAt._0,
                                clientId: clientId._0,
                                hubs: hubs._0,
                                state: state._0,
                                globalState: globalState._0,
                                isTracked: isTracked._0,
                                expectedHubId: expectedHubId._0,
                                damageStatus: damageStatus._0
                              }
                            };
                    }
                    var e = damageStatus._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".damageStatus" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = expectedHubId._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".expectedHubId" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = isTracked._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".isTracked" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = globalState._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".globalState" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = state._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".state" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = hubs._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".hubs" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = clientId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".clientId" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = receivedAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".receivedAt" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = primaryOrderReference._0;
    return {
            TAG: "Error",
            _0: {
              path: ".primaryOrderReference" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function response_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "orders",
                    false,
                    Spice.arrayToJson(order_encode, v.orders)
                  ]
                ]));
}

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var orders = Spice.arrayFromJson(order_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orders"), null));
    if (orders.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagesCount: pagesCount._0,
                orders: orders._0
              }
            };
    }
    var e = orders._0;
    return {
            TAG: "Error",
            _0: {
              path: ".orders" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config(body) {
  return {
          kyInstance: kyInstance,
          path: "warehouse/orders/list",
          requestOptions: {
            method: "POST",
            json: body_encode(body)
          },
          key: [
            "ColiswebApi__V6_Order",
            "GetAllOrder",
            JSON.stringify(filters_encode(body.filters)),
            JSON.stringify(sort_encode(body.sort)),
            JSON.stringify(pagination_encode(body.pagination))
          ]
        };
}

var List = {
  HubExpectationMode: HubExpectationMode,
  filters_encode: filters_encode,
  filters_decode: filters_decode,
  sort_encode: sort_encode,
  sort_decode: sort_decode,
  pagination_encode: pagination_encode,
  pagination_decode: pagination_decode,
  body_encode: body_encode,
  body_decode: body_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  order_encode: order_encode,
  order_decode: order_decode,
  config: config
};

var kyInstance$1 = kyInstance.extend({
      prefixUrl: baseUrl + "/orders"
    });

var kyInstance$2 = kyInstance$1.extend({
      prefixUrl: baseUrl + "/orders/integrations"
    });

function t_encode$9(v) {
  if (v === "warehouse") {
    return "warehouse";
  } else {
    return "store";
  }
}

function t_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("warehouse" === v) {
          return {
                  TAG: "Ok",
                  _0: "warehouse"
                };
        } else if ("store" === v) {
          return {
                  TAG: "Ok",
                  _0: "store"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var PickupType = {
  t_encode: t_encode$9,
  t_decode: t_decode$9
};

function t_encode$10(v) {
  if (v === "withLoadBreak") {
    return "withLoadBreak";
  } else {
    return "withoutLoadBreak";
  }
}

function t_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("withLoadBreak" === v) {
          return {
                  TAG: "Ok",
                  _0: "withLoadBreak"
                };
        } else if ("withoutLoadBreak" === v) {
          return {
                  TAG: "Ok",
                  _0: "withoutLoadBreak"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var LoadBreak = {
  t_encode: t_encode$10,
  t_decode: t_decode$10
};

function t_encode$11(v) {
  if (v === "enabled") {
    return "enabled";
  } else {
    return "disabled";
  }
}

function t_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("enabled" === v) {
          return {
                  TAG: "Ok",
                  _0: "enabled"
                };
        } else if ("disabled" === v) {
          return {
                  TAG: "Ok",
                  _0: "disabled"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Tracking = {
  t_encode: t_encode$11,
  t_decode: t_decode$11
};

function sort_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "receivedBetween",
                    false,
                    ColiswebApi__Utils.SortOrder.t_encode(v.receivedBetween)
                  ]]));
}

function sort_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var receivedBetween = ColiswebApi__Utils.SortOrder.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
  if (receivedBetween.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              receivedBetween: receivedBetween._0
            }
          };
  }
  var e = receivedBetween._0;
  return {
          TAG: "Error",
          _0: {
            path: ".receivedBetween" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_encode$12(v) {
  switch (v) {
    case "all" :
        return "all";
    case "withoutProject" :
        return "withoutProject";
    case "withProject" :
        return "withProject";
    
  }
}

function t_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("all" === v) {
          return {
                  TAG: "Ok",
                  _0: "all"
                };
        } else if ("withoutProject" === v) {
          return {
                  TAG: "Ok",
                  _0: "withoutProject"
                };
        } else if ("withProject" === v) {
          return {
                  TAG: "Ok",
                  _0: "withProject"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var SelectProjectFilter = {
  t_encode: t_encode$12,
  t_decode: t_decode$12
};

function pagination_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function filters_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var integrationStatus = Spice.arrayFromJson(t_decode$5, Belt_Option.getWithDefault(Js_dict.get(v, "integrationStatus"), null));
  if (integrationStatus.TAG === "Ok") {
    var expectedHubIds = Toolkit__Decoders.$$Option.t_decode((function (param) {
            return Spice.arrayFromJson(Identifiers.HubId.t_decode, param);
          }), Belt_Option.getWithDefault(Js_dict.get(v, "expectedHubIds"), null));
    if (expectedHubIds.TAG === "Ok") {
      var hubIds = Toolkit__Decoders.$$Option.t_decode((function (param) {
              return Spice.arrayFromJson(Identifiers.HubId.t_decode, param);
            }), Belt_Option.getWithDefault(Js_dict.get(v, "hubIds"), null));
      if (hubIds.TAG === "Ok") {
        var referenceStartsWith = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "referenceStartsWith"), null));
        if (referenceStartsWith.TAG === "Ok") {
          var clientIds = Toolkit__Decoders.$$Option.t_decode((function (param) {
                  return Spice.arrayFromJson(Identifiers.ClientId.t_decode, param);
                }), Belt_Option.getWithDefault(Js_dict.get(v, "clientIds"), null));
          if (clientIds.TAG === "Ok") {
            var orderStatus = Toolkit__Decoders.$$Option.t_decode((function (param) {
                    return Spice.arrayFromJson(t_decode, param);
                  }), Belt_Option.getWithDefault(Js_dict.get(v, "orderStatus"), null));
            if (orderStatus.TAG === "Ok") {
              var receivedBetween = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.DateTimeSlot.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
              if (receivedBetween.TAG === "Ok") {
                var projectIds = Toolkit__Decoders.$$Option.t_decode((function (param) {
                        return Spice.arrayFromJson(Identifiers.RoutePlanId.t_decode, param);
                      }), Belt_Option.getWithDefault(Js_dict.get(v, "projectIds"), null));
                if (projectIds.TAG === "Ok") {
                  var selectProject = Toolkit__Decoders.$$Option.t_decode(t_decode$12, Belt_Option.getWithDefault(Js_dict.get(v, "selectProject"), null));
                  if (selectProject.TAG === "Ok") {
                    var loadBreak = Toolkit__Decoders.$$Option.t_decode(t_decode$10, Belt_Option.getWithDefault(Js_dict.get(v, "loadBreak"), null));
                    if (loadBreak.TAG === "Ok") {
                      var tracking = Toolkit__Decoders.$$Option.t_decode(t_decode$11, Belt_Option.getWithDefault(Js_dict.get(v, "tracking"), null));
                      if (tracking.TAG === "Ok") {
                        var pickupType = Toolkit__Decoders.$$Option.t_decode(t_decode$9, Belt_Option.getWithDefault(Js_dict.get(v, "pickupType"), null));
                        if (pickupType.TAG === "Ok") {
                          var storeCodes = Toolkit__Decoders.$$Option.t_decode((function (param) {
                                  return Spice.arrayFromJson(Identifiers.StoreCode.t_decode, param);
                                }), Belt_Option.getWithDefault(Js_dict.get(v, "storeCodes"), null));
                          if (storeCodes.TAG === "Ok") {
                            var warehouseCodes = Toolkit__Decoders.$$Option.t_decode((function (param) {
                                    return Spice.arrayFromJson(Identifiers.WarehouseCode.t_decode, param);
                                  }), Belt_Option.getWithDefault(Js_dict.get(v, "warehouseCodes"), null));
                            if (warehouseCodes.TAG === "Ok") {
                              return {
                                      TAG: "Ok",
                                      _0: {
                                        integrationStatus: integrationStatus._0,
                                        expectedHubIds: expectedHubIds._0,
                                        hubIds: hubIds._0,
                                        referenceStartsWith: referenceStartsWith._0,
                                        clientIds: clientIds._0,
                                        orderStatus: orderStatus._0,
                                        receivedBetween: receivedBetween._0,
                                        projectIds: projectIds._0,
                                        selectProject: selectProject._0,
                                        loadBreak: loadBreak._0,
                                        tracking: tracking._0,
                                        pickupType: pickupType._0,
                                        storeCodes: storeCodes._0,
                                        warehouseCodes: warehouseCodes._0
                                      }
                                    };
                            }
                            var e = warehouseCodes._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".warehouseCodes" + e.path,
                                      message: e.message,
                                      value: e.value
                                    }
                                  };
                          }
                          var e$1 = storeCodes._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".storeCodes" + e$1.path,
                                    message: e$1.message,
                                    value: e$1.value
                                  }
                                };
                        }
                        var e$2 = pickupType._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".pickupType" + e$2.path,
                                  message: e$2.message,
                                  value: e$2.value
                                }
                              };
                      }
                      var e$3 = tracking._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".tracking" + e$3.path,
                                message: e$3.message,
                                value: e$3.value
                              }
                            };
                    }
                    var e$4 = loadBreak._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".loadBreak" + e$4.path,
                              message: e$4.message,
                              value: e$4.value
                            }
                          };
                  }
                  var e$5 = selectProject._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".selectProject" + e$5.path,
                            message: e$5.message,
                            value: e$5.value
                          }
                        };
                }
                var e$6 = projectIds._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".projectIds" + e$6.path,
                          message: e$6.message,
                          value: e$6.value
                        }
                      };
              }
              var e$7 = receivedBetween._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".receivedBetween" + e$7.path,
                        message: e$7.message,
                        value: e$7.value
                      }
                    };
            }
            var e$8 = orderStatus._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".orderStatus" + e$8.path,
                      message: e$8.message,
                      value: e$8.value
                    }
                  };
          }
          var e$9 = clientIds._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".clientIds" + e$9.path,
                    message: e$9.message,
                    value: e$9.value
                  }
                };
        }
        var e$10 = referenceStartsWith._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".referenceStartsWith" + e$10.path,
                  message: e$10.message,
                  value: e$10.value
                }
              };
      }
      var e$11 = hubIds._0;
      return {
              TAG: "Error",
              _0: {
                path: ".hubIds" + e$11.path,
                message: e$11.message,
                value: e$11.value
              }
            };
    }
    var e$12 = expectedHubIds._0;
    return {
            TAG: "Error",
            _0: {
              path: ".expectedHubIds" + e$12.path,
              message: e$12.message,
              value: e$12.value
            }
          };
  }
  var e$13 = integrationStatus._0;
  return {
          TAG: "Error",
          _0: {
            path: ".integrationStatus" + e$13.path,
            message: e$13.message,
            value: e$13.value
          }
        };
}

function pagination_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function filters_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "integrationStatus",
                    false,
                    Spice.arrayToJson(t_encode$5, v.integrationStatus)
                  ],
                  [
                    "expectedHubIds",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.HubId.t_encode, param);
                          }), v.expectedHubIds)
                  ],
                  [
                    "hubIds",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.HubId.t_encode, param);
                          }), v.hubIds)
                  ],
                  [
                    "referenceStartsWith",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.referenceStartsWith)
                  ],
                  [
                    "clientIds",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.ClientId.t_encode, param);
                          }), v.clientIds)
                  ],
                  [
                    "orderStatus",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode, param);
                          }), v.orderStatus)
                  ],
                  [
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.DateTimeSlot.t_encode, v.receivedBetween)
                  ],
                  [
                    "projectIds",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.RoutePlanId.t_encode, param);
                          }), v.projectIds)
                  ],
                  [
                    "selectProject",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(t_encode$12, v.selectProject)
                  ],
                  [
                    "loadBreak",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(t_encode$10, v.loadBreak)
                  ],
                  [
                    "tracking",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(t_encode$11, v.tracking)
                  ],
                  [
                    "pickupType",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(t_encode$9, v.pickupType)
                  ],
                  [
                    "storeCodes",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.StoreCode.t_encode, param);
                          }), v.storeCodes)
                  ],
                  [
                    "warehouseCodes",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.WarehouseCode.t_encode, param);
                          }), v.warehouseCodes)
                  ]
                ]));
}

function params_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode$1(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode$1(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode$1(v.pagination)
                  ]
                ]));
}

function params_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var argument_encode = params_encode;

var argument_decode = params_decode;

function order_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var primaryOrderReference = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "primaryOrderReference"), null));
    if (primaryOrderReference.TAG === "Ok") {
      var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
      if (clientId.TAG === "Ok") {
        var expectedHubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "expectedHubId"), null));
        if (expectedHubId.TAG === "Ok") {
          var hubs = Spice.arrayFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubs"), null));
          if (hubs.TAG === "Ok") {
            var receivedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "receivedAt"), null));
            if (receivedAt.TAG === "Ok") {
              var projectId = Spice.optionFromJson(Identifiers.RoutePlanId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "projectId"), null));
              if (projectId.TAG === "Ok") {
                var integrationStatus = t_decode$5(Belt_Option.getWithDefault(Js_dict.get(v, "integrationStatus"), null));
                if (integrationStatus.TAG === "Ok") {
                  var orderStatus = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "orderStatus"), null));
                  if (orderStatus.TAG === "Ok") {
                    var storeCode = Spice.optionFromJson(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
                    if (storeCode.TAG === "Ok") {
                      var warehouseCode = Spice.optionFromJson(Identifiers.WarehouseCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "warehouseCode"), null));
                      if (warehouseCode.TAG === "Ok") {
                        var triesLeft = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "triesLeft"), null));
                        if (triesLeft.TAG === "Ok") {
                          var triggerAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "triggerAt"), null));
                          if (triggerAt.TAG === "Ok") {
                            return {
                                    TAG: "Ok",
                                    _0: {
                                      orderId: orderId._0,
                                      primaryOrderReference: primaryOrderReference._0,
                                      clientId: clientId._0,
                                      expectedHubId: expectedHubId._0,
                                      hubs: hubs._0,
                                      receivedAt: receivedAt._0,
                                      projectId: projectId._0,
                                      integrationStatus: integrationStatus._0,
                                      orderStatus: orderStatus._0,
                                      storeCode: storeCode._0,
                                      warehouseCode: warehouseCode._0,
                                      triesLeft: triesLeft._0,
                                      triggerAt: triggerAt._0
                                    }
                                  };
                          }
                          var e = triggerAt._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".triggerAt" + e.path,
                                    message: e.message,
                                    value: e.value
                                  }
                                };
                        }
                        var e$1 = triesLeft._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".triesLeft" + e$1.path,
                                  message: e$1.message,
                                  value: e$1.value
                                }
                              };
                      }
                      var e$2 = warehouseCode._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".warehouseCode" + e$2.path,
                                message: e$2.message,
                                value: e$2.value
                              }
                            };
                    }
                    var e$3 = storeCode._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".storeCode" + e$3.path,
                              message: e$3.message,
                              value: e$3.value
                            }
                          };
                  }
                  var e$4 = orderStatus._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".orderStatus" + e$4.path,
                            message: e$4.message,
                            value: e$4.value
                          }
                        };
                }
                var e$5 = integrationStatus._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".integrationStatus" + e$5.path,
                          message: e$5.message,
                          value: e$5.value
                        }
                      };
              }
              var e$6 = projectId._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".projectId" + e$6.path,
                        message: e$6.message,
                        value: e$6.value
                      }
                    };
            }
            var e$7 = receivedAt._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".receivedAt" + e$7.path,
                      message: e$7.message,
                      value: e$7.value
                    }
                  };
          }
          var e$8 = hubs._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".hubs" + e$8.path,
                    message: e$8.message,
                    value: e$8.value
                  }
                };
        }
        var e$9 = expectedHubId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".expectedHubId" + e$9.path,
                  message: e$9.message,
                  value: e$9.value
                }
              };
      }
      var e$10 = clientId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".clientId" + e$10.path,
                message: e$10.message,
                value: e$10.value
              }
            };
    }
    var e$11 = primaryOrderReference._0;
    return {
            TAG: "Error",
            _0: {
              path: ".primaryOrderReference" + e$11.path,
              message: e$11.message,
              value: e$11.value
            }
          };
  }
  var e$12 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$12.path,
            message: e$12.message,
            value: e$12.value
          }
        };
}

function counters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var toIntegrate = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "toIntegrate"), null));
  if (toIntegrate.TAG === "Ok") {
    var error = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "error"), null));
    if (error.TAG === "Ok") {
      var toIgnore = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "toIgnore"), null));
      if (toIgnore.TAG === "Ok") {
        var integrationRunning = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "integrationRunning"), null));
        if (integrationRunning.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    toIntegrate: toIntegrate._0,
                    error: error._0,
                    toIgnore: toIgnore._0,
                    integrationRunning: integrationRunning._0
                  }
                };
        }
        var e = integrationRunning._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".integrationRunning" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = toIgnore._0;
      return {
              TAG: "Error",
              _0: {
                path: ".toIgnore" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = error._0;
    return {
            TAG: "Error",
            _0: {
              path: ".error" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = toIntegrate._0;
  return {
          TAG: "Error",
          _0: {
            path: ".toIntegrate" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function order_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "primaryOrderReference",
                    false,
                    Spice.stringToJson(v.primaryOrderReference)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "expectedHubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.expectedHubId)
                  ],
                  [
                    "hubs",
                    false,
                    Spice.arrayToJson(Identifiers.HubId.t_encode, v.hubs)
                  ],
                  [
                    "receivedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.receivedAt)
                  ],
                  [
                    "projectId",
                    false,
                    Spice.optionToJson(Identifiers.RoutePlanId.t_encode, v.projectId)
                  ],
                  [
                    "integrationStatus",
                    false,
                    t_encode$5(v.integrationStatus)
                  ],
                  [
                    "orderStatus",
                    false,
                    t_encode(v.orderStatus)
                  ],
                  [
                    "storeCode",
                    false,
                    Spice.optionToJson(Identifiers.StoreCode.t_encode, v.storeCode)
                  ],
                  [
                    "warehouseCode",
                    false,
                    Spice.optionToJson(Identifiers.WarehouseCode.t_encode, v.warehouseCode)
                  ],
                  [
                    "triesLeft",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.triesLeft)
                  ],
                  [
                    "triggerAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.triggerAt)
                  ]
                ]));
}

function counters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "toIntegrate",
                    false,
                    Spice.intToJson(v.toIntegrate)
                  ],
                  [
                    "error",
                    false,
                    Spice.intToJson(v.error)
                  ],
                  [
                    "toIgnore",
                    false,
                    Spice.intToJson(v.toIgnore)
                  ],
                  [
                    "integrationRunning",
                    false,
                    Spice.intToJson(v.integrationRunning)
                  ]
                ]));
}

function response_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "counters",
                    false,
                    counters_encode(v.counters)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "data",
                    false,
                    Spice.arrayToJson(order_encode$1, v.data)
                  ]
                ]));
}

function response_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var counters = counters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "counters"), null));
  if (counters.TAG === "Ok") {
    var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
    if (pagesCount.TAG === "Ok") {
      var data = Spice.arrayFromJson(order_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "data"), null));
      if (data.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  counters: counters._0,
                  pagesCount: pagesCount._0,
                  data: data._0
                }
              };
      }
      var e = data._0;
      return {
              TAG: "Error",
              _0: {
                path: ".data" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = pagesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pagesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = counters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".counters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function config$1(params) {
  return {
          kyInstance: kyInstance$2,
          path: "",
          requestOptions: {
            method: "POST",
            json: params_encode(params)
          },
          key: [
            "ColiswebApi__V6_Order",
            "GetAdminOrderListByIntegrations",
            JSON.stringify(filters_encode$1(params.filters)),
            JSON.stringify(sort_encode$1(params.sort)),
            JSON.stringify(pagination_encode$1(params.pagination))
          ]
        };
}

var GetAll = {
  PickupType: PickupType,
  LoadBreak: LoadBreak,
  Tracking: Tracking,
  sort_encode: sort_encode$1,
  sort_decode: sort_decode$1,
  SelectProjectFilter: SelectProjectFilter,
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  params_encode: params_encode,
  params_decode: params_decode,
  filters_encode: filters_encode$1,
  filters_decode: filters_decode$1,
  pagination_encode: pagination_encode$1,
  pagination_decode: pagination_decode$1,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  counters_encode: counters_encode,
  counters_decode: counters_decode,
  order_encode: order_encode$1,
  order_decode: order_decode$1,
  config: config$1
};

var Integrations = {
  kyInstance: kyInstance$2,
  GetAll: GetAll
};

var Orders = {
  kyInstance: kyInstance$1,
  Integrations: Integrations
};

function filters_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "reference",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.reference)
                  ],
                  [
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.DateTimeSlot.t_encode, v.receivedBetween)
                  ],
                  [
                    "hubIds",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.HubId.t_encode, param);
                          }), v.hubIds)
                  ],
                  [
                    "state",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode, param);
                          }), v.state)
                  ],
                  [
                    "globalState",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode$2, param);
                          }), v.globalState)
                  ],
                  [
                    "damageStatus",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode$3, param);
                          }), v.damageStatus)
                  ]
                ]));
}

function filters_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var reference = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "reference"), null));
  if (reference.TAG === "Ok") {
    var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.DateTimeSlot.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
    if (receivedBetween.TAG === "Ok") {
      var hubIds = Toolkit__Decoders.$$Option.t_decode((function (param) {
              return Spice.arrayFromJson(Identifiers.HubId.t_decode, param);
            }), Belt_Option.getWithDefault(Js_dict.get(v, "hubIds"), null));
      if (hubIds.TAG === "Ok") {
        var state = Toolkit__Decoders.$$Option.t_decode((function (param) {
                return Spice.arrayFromJson(t_decode, param);
              }), Belt_Option.getWithDefault(Js_dict.get(v, "state"), null));
        if (state.TAG === "Ok") {
          var globalState = Toolkit__Decoders.$$Option.t_decode((function (param) {
                  return Spice.arrayFromJson(t_decode$2, param);
                }), Belt_Option.getWithDefault(Js_dict.get(v, "globalState"), null));
          if (globalState.TAG === "Ok") {
            var damageStatus = Toolkit__Decoders.$$Option.t_decode((function (param) {
                    return Spice.arrayFromJson(t_decode$3, param);
                  }), Belt_Option.getWithDefault(Js_dict.get(v, "damageStatus"), null));
            if (damageStatus.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        reference: reference._0,
                        receivedBetween: receivedBetween._0,
                        hubIds: hubIds._0,
                        state: state._0,
                        globalState: globalState._0,
                        damageStatus: damageStatus._0
                      }
                    };
            }
            var e = damageStatus._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".damageStatus" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = globalState._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".globalState" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = state._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".state" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = hubIds._0;
      return {
              TAG: "Error",
              _0: {
                path: ".hubIds" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = receivedBetween._0;
    return {
            TAG: "Error",
            _0: {
              path: ".receivedBetween" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = reference._0;
  return {
          TAG: "Error",
          _0: {
            path: ".reference" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function sort_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.receivedBetween)
                  ]]));
}

function sort_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
  if (receivedBetween.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              receivedBetween: receivedBetween._0
            }
          };
  }
  var e = receivedBetween._0;
  return {
          TAG: "Error",
          _0: {
            path: ".receivedBetween" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function pagination_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function pagination_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode$2(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode$2(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode$2(v.pagination)
                  ]
                ]));
}

function body_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function order_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "primaryOrderReference",
                    false,
                    Curry._1(Identifiers.OrderReference.t_encode, v.primaryOrderReference)
                  ],
                  [
                    "receivedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.receivedAt)
                  ],
                  [
                    "hubs",
                    false,
                    Spice.arrayToJson(Identifiers.HubId.t_encode, v.hubs)
                  ],
                  [
                    "state",
                    false,
                    t_encode(v.state)
                  ],
                  [
                    "globalState",
                    false,
                    t_encode$2(v.globalState)
                  ],
                  [
                    "damageStatus",
                    false,
                    t_encode$3(v.damageStatus)
                  ]
                ]));
}

function order_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var primaryOrderReference = Curry._1(Identifiers.OrderReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "primaryOrderReference"), null));
    if (primaryOrderReference.TAG === "Ok") {
      var receivedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "receivedAt"), null));
      if (receivedAt.TAG === "Ok") {
        var hubs = Spice.arrayFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubs"), null));
        if (hubs.TAG === "Ok") {
          var state = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "state"), null));
          if (state.TAG === "Ok") {
            var globalState = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "globalState"), null));
            if (globalState.TAG === "Ok") {
              var damageStatus = t_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "damageStatus"), null));
              if (damageStatus.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          orderId: orderId._0,
                          primaryOrderReference: primaryOrderReference._0,
                          receivedAt: receivedAt._0,
                          hubs: hubs._0,
                          state: state._0,
                          globalState: globalState._0,
                          damageStatus: damageStatus._0
                        }
                      };
              }
              var e = damageStatus._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".damageStatus" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = globalState._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".globalState" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = state._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".state" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = hubs._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".hubs" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = receivedAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".receivedAt" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = primaryOrderReference._0;
    return {
            TAG: "Error",
            _0: {
              path: ".primaryOrderReference" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function response_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "data",
                    false,
                    Spice.arrayToJson(order_encode$2, v.data)
                  ]
                ]));
}

function response_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var data = Spice.arrayFromJson(order_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "data"), null));
    if (data.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagesCount: pagesCount._0,
                data: data._0
              }
            };
    }
    var e = data._0;
    return {
            TAG: "Error",
            _0: {
              path: ".data" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$2(param) {
  var body = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance,
          path: "clients/" + clientId + "/warehouse/orders/list",
          requestOptions: {
            method: "POST",
            json: body_encode$1(body)
          },
          key: [
            "ColiswebApi__V6_Order",
            "GetAllClientOrders",
            clientId,
            JSON.stringify(filters_encode$2(body.filters)),
            JSON.stringify(sort_encode$2(body.sort)),
            JSON.stringify(pagination_encode$2(body.pagination))
          ]
        };
}

var ListForClients = {
  filters_encode: filters_encode$2,
  filters_decode: filters_decode$2,
  sort_encode: sort_encode$2,
  sort_decode: sort_decode$2,
  pagination_encode: pagination_encode$2,
  pagination_decode: pagination_decode$2,
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  order_encode: order_encode$2,
  order_decode: order_decode$2,
  config: config$2
};

function t_encode$13(v) {
  switch (v) {
    case "blockIntegration" :
        return "blockIntegration";
    case "forceIntegration" :
        return "forceIntegration";
    case "updateAddress" :
        return "updateAddress";
    
  }
}

function t_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("blockIntegration" === v) {
          return {
                  TAG: "Ok",
                  _0: "blockIntegration"
                };
        } else if ("forceIntegration" === v) {
          return {
                  TAG: "Ok",
                  _0: "forceIntegration"
                };
        } else if ("updateAddress" === v) {
          return {
                  TAG: "Ok",
                  _0: "updateAddress"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var blockIntegration = {
  id: "_0f4ec588",
  defaultMessage: "Bloquer l'intégration"
};

var forceIntegration = {
  id: "_194bb9b5",
  defaultMessage: "Forcer l'intégration"
};

var updateAddress = {
  id: "_89b627ae",
  defaultMessage: "Modifier l'adresse"
};

var toString$4 = t_encode$13;

function humanize$1(intl, t) {
  var message;
  switch (t) {
    case "blockIntegration" :
        message = blockIntegration;
        break;
    case "forceIntegration" :
        message = forceIntegration;
        break;
    case "updateAddress" :
        message = updateAddress;
        break;
    
  }
  return intl.formatMessage(message);
}

var IntegrationAction = {
  t_encode: t_encode$13,
  t_decode: t_decode$13,
  blockIntegration: blockIntegration,
  forceIntegration: forceIntegration,
  updateAddress: updateAddress,
  toString: toString$4,
  humanize: humanize$1
};

var kyInstance$3 = kyInstance.extend({
      prefixUrl: baseUrl + "/warehouse"
    });

var kyInstance$4 = kyInstance$3.extend({
      prefixUrl: baseUrl + "/warehouse/orders"
    });

function t_encode$14(v) {
  if (v === "pickup") {
    return "pickup";
  } else {
    return "dropoff";
  }
}

function t_decode$14(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("pickup" === v) {
          return {
                  TAG: "Ok",
                  _0: "pickup"
                };
        } else if ("dropoff" === v) {
          return {
                  TAG: "Ok",
                  _0: "dropoff"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var PointType = {
  t_encode: t_encode$14,
  t_decode: t_decode$14
};

function handledEnum_encode(v) {
  switch (v) {
    case "ZONE_NOT_COVERED" :
        return "ZONE_NOT_COVERED";
    case "TOTAL_WEIGHT_TOO_HEAVY" :
        return "TOTAL_WEIGHT_TOO_HEAVY";
    case "PACKET_TOO_BIG" :
        return "PACKET_TOO_BIG";
    case "PACKAGE_TOO_BIG" :
        return "PACKAGE_TOO_BIG";
    case "VOLUME_TOO_BIG" :
        return "VOLUME_TOO_BIG";
    case "SLOT_OUT_OF_OPENING" :
        return "SLOT_OUT_OF_OPENING";
    case "TOO_HEAVY_SINGLE_PACKET" :
        return "TOO_HEAVY_SINGLE_PACKET";
    case "NO_TRANSPORT_CAPACITY" :
        return "NO_TRANSPORT_CAPACITY";
    case "DISTANCE_TOO_FAR" :
        return "DISTANCE_TOO_FAR";
    case "STORE_NOT_CONFIGURED" :
        return "STORE_NOT_CONFIGURED";
    case "UNKNOWN_STORE" :
        return "UNKNOWN_STORE";
    case "CONDITIONS_NOT_MATCHED" :
        return "CONDITIONS_NOT_MATCHED";
    case "SLOT_TOO_NARROW" :
        return "SLOT_TOO_NARROW";
    case "SLOT_IN_THE_PAST" :
        return "SLOT_IN_THE_PAST";
    case "SLOT_BEFORE_FIRST_AVAILABLE_SLOT" :
        return "SLOT_BEFORE_FIRST_AVAILABLE_SLOT";
    case "UNAUTHORIZED_REQUIRED_SKILLS" :
        return "UNAUTHORIZED_REQUIRED_SKILLS";
    case "CANNOT_CALCULATE_DISTANCE" :
        return "CANNOT_CALCULATE_DISTANCE";
    case "TECHNICAL_ERROR" :
        return "TECHNICAL_ERROR";
    case "NO_COMMON_SLOT_BETWEEN_CONFIG_AND_OPTIONS" :
        return "NO_COMMON_SLOT_BETWEEN_CONFIG_AND_OPTIONS";
    case "CONFIG_SLOTS_ARE_TOO_SHORT" :
        return "CONFIG_SLOTS_ARE_TOO_SHORT";
    
  }
}

function handledEnum_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("ZONE_NOT_COVERED" === v) {
          return {
                  TAG: "Ok",
                  _0: "ZONE_NOT_COVERED"
                };
        } else if ("TOTAL_WEIGHT_TOO_HEAVY" === v) {
          return {
                  TAG: "Ok",
                  _0: "TOTAL_WEIGHT_TOO_HEAVY"
                };
        } else if ("PACKET_TOO_BIG" === v) {
          return {
                  TAG: "Ok",
                  _0: "PACKET_TOO_BIG"
                };
        } else if ("PACKAGE_TOO_BIG" === v) {
          return {
                  TAG: "Ok",
                  _0: "PACKAGE_TOO_BIG"
                };
        } else if ("VOLUME_TOO_BIG" === v) {
          return {
                  TAG: "Ok",
                  _0: "VOLUME_TOO_BIG"
                };
        } else if ("SLOT_OUT_OF_OPENING" === v) {
          return {
                  TAG: "Ok",
                  _0: "SLOT_OUT_OF_OPENING"
                };
        } else if ("TOO_HEAVY_SINGLE_PACKET" === v) {
          return {
                  TAG: "Ok",
                  _0: "TOO_HEAVY_SINGLE_PACKET"
                };
        } else if ("NO_TRANSPORT_CAPACITY" === v) {
          return {
                  TAG: "Ok",
                  _0: "NO_TRANSPORT_CAPACITY"
                };
        } else if ("DISTANCE_TOO_FAR" === v) {
          return {
                  TAG: "Ok",
                  _0: "DISTANCE_TOO_FAR"
                };
        } else if ("STORE_NOT_CONFIGURED" === v) {
          return {
                  TAG: "Ok",
                  _0: "STORE_NOT_CONFIGURED"
                };
        } else if ("UNKNOWN_STORE" === v) {
          return {
                  TAG: "Ok",
                  _0: "UNKNOWN_STORE"
                };
        } else if ("CONDITIONS_NOT_MATCHED" === v) {
          return {
                  TAG: "Ok",
                  _0: "CONDITIONS_NOT_MATCHED"
                };
        } else if ("SLOT_TOO_NARROW" === v) {
          return {
                  TAG: "Ok",
                  _0: "SLOT_TOO_NARROW"
                };
        } else if ("SLOT_IN_THE_PAST" === v) {
          return {
                  TAG: "Ok",
                  _0: "SLOT_IN_THE_PAST"
                };
        } else if ("SLOT_BEFORE_FIRST_AVAILABLE_SLOT" === v) {
          return {
                  TAG: "Ok",
                  _0: "SLOT_BEFORE_FIRST_AVAILABLE_SLOT"
                };
        } else if ("UNAUTHORIZED_REQUIRED_SKILLS" === v) {
          return {
                  TAG: "Ok",
                  _0: "UNAUTHORIZED_REQUIRED_SKILLS"
                };
        } else if ("CANNOT_CALCULATE_DISTANCE" === v) {
          return {
                  TAG: "Ok",
                  _0: "CANNOT_CALCULATE_DISTANCE"
                };
        } else if ("TECHNICAL_ERROR" === v) {
          return {
                  TAG: "Ok",
                  _0: "TECHNICAL_ERROR"
                };
        } else if ("NO_COMMON_SLOT_BETWEEN_CONFIG_AND_OPTIONS" === v) {
          return {
                  TAG: "Ok",
                  _0: "NO_COMMON_SLOT_BETWEEN_CONFIG_AND_OPTIONS"
                };
        } else if ("CONFIG_SLOTS_ARE_TOO_SHORT" === v) {
          return {
                  TAG: "Ok",
                  _0: "CONFIG_SLOTS_ARE_TOO_SHORT"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function encoder$1(v) {
  if (v.TAG === "ErrorCause") {
    return handledEnum_encode(v._0);
  } else {
    return v._0;
  }
}

function decoder$1(json) {
  var str = Js_json.classify(json);
  if (typeof str !== "object") {
    return {
            TAG: "Error",
            _0: {
              path: "",
              message: "Expected error cause enum",
              value: json
            }
          };
  }
  if (str.TAG !== "JSONString") {
    return {
            TAG: "Error",
            _0: {
              path: "",
              message: "Expected error cause enum",
              value: json
            }
          };
  }
  var str$1 = str._0;
  var r = handledEnum_decode(str$1);
  if (r.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              TAG: "ErrorCause",
              _0: r._0
            }
          };
  } else {
    return {
            TAG: "Ok",
            _0: {
              TAG: "Unknown",
              _0: str$1
            }
          };
  }
}

var codec$1 = [
  encoder$1,
  decoder$1
];

var t_encode$15 = encoder$1;

var t_decode$15 = decoder$1;

var ErrorCause = {
  handledEnum_encode: handledEnum_encode,
  handledEnum_decode: handledEnum_decode,
  encoder: encoder$1,
  decoder: decoder$1,
  codec: codec$1,
  t_encode: t_encode$15,
  t_decode: t_decode$15
};

function store_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "storeCode",
                    false,
                    Curry._1(Identifiers.StoreCode.t_encode, v.storeCode)
                  ]]));
}

function address_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone1)
                  ],
                  [
                    "phone2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone2)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "line3",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line3)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "additionalInformation",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.additionalInformation)
                  ],
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ],
                  [
                    "lift",
                    false,
                    Spice.optionToJson(Spice.boolToJson, v.lift)
                  ]
                ]));
}

function warehouse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "warehouseCode",
                    false,
                    Spice.stringToJson(v.warehouseCode)
                  ]]));
}

function location_encode(v) {
  var variant = v.NAME;
  if (variant === "store") {
    return [
            "store",
            store_encode(v.VAL)
          ];
  } else if (variant === "address") {
    return [
            "address",
            address_encode(v.VAL)
          ];
  } else {
    return [
            "warehouse",
            warehouse_encode(v.VAL)
          ];
  }
}

function location_decode(v) {
  var json_arr = Js_json.classify(v);
  if (typeof json_arr !== "object") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  if (json_arr.TAG !== "JSONArray") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  var json_arr$1 = json_arr._0;
  if (json_arr$1.length === 0) {
    return Spice.error(undefined, "Expected polyvariant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, json_arr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match === "object" && match.TAG === "JSONString") {
    switch (match._0) {
      case "address" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v0 = address_decode(Belt_Array.getExn(json_arr$1, 1));
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "address",
                      VAL: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "store" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v0$1 = store_decode(Belt_Array.getExn(json_arr$1, 1));
          if (v0$1.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "store",
                      VAL: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      case "warehouse" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v0$2 = warehouse_decode(Belt_Array.getExn(json_arr$1, 1));
          if (v0$2.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "warehouse",
                      VAL: v0$2._0
                    }
                  };
          }
          var e$2 = v0$2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid polymorphic constructor", Belt_Array.getExn(json_arr$1, 0));
}

function integration_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "date",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.date)
                  ],
                  [
                    "triggerId",
                    false,
                    Spice.optionToJson(Identifiers.OrderJobTriggerId.t_encode, v.triggerId)
                  ],
                  [
                    "result",
                    false,
                    t_encode$6(v.result)
                  ],
                  [
                    "errorCause",
                    false,
                    Spice.optionToJson(t_encode$15, v.errorCause)
                  ]
                ]));
}

function integration_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    var triggerId = Spice.optionFromJson(Identifiers.OrderJobTriggerId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "triggerId"), null));
    if (triggerId.TAG === "Ok") {
      var result = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "result"), null));
      if (result.TAG === "Ok") {
        var errorCause = Spice.optionFromJson(t_decode$15, Belt_Option.getWithDefault(Js_dict.get(v, "errorCause"), null));
        if (errorCause.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    date: date._0,
                    triggerId: triggerId._0,
                    result: result._0,
                    errorCause: errorCause._0
                  }
                };
        }
        var e = errorCause._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".errorCause" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = result._0;
      return {
              TAG: "Error",
              _0: {
                path: ".result" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = triggerId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".triggerId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function address_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var phone1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
    if (phone1.TAG === "Ok") {
      var phone2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone2"), null));
      if (phone2.TAG === "Ok") {
        var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
        if (email.TAG === "Ok") {
          var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
          if (line1.TAG === "Ok") {
            var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
            if (line2.TAG === "Ok") {
              var line3 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line3"), null));
              if (line3.TAG === "Ok") {
                var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
                if (postalCode.TAG === "Ok") {
                  var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
                  if (city.TAG === "Ok") {
                    var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
                    if (country.TAG === "Ok") {
                      var additionalInformation = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "additionalInformation"), null));
                      if (additionalInformation.TAG === "Ok") {
                        var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
                        if (floor.TAG === "Ok") {
                          var lift = Spice.optionFromJson(Spice.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lift"), null));
                          if (lift.TAG === "Ok") {
                            return {
                                    TAG: "Ok",
                                    _0: {
                                      name: name._0,
                                      phone1: phone1._0,
                                      phone2: phone2._0,
                                      email: email._0,
                                      line1: line1._0,
                                      line2: line2._0,
                                      line3: line3._0,
                                      postalCode: postalCode._0,
                                      city: city._0,
                                      country: country._0,
                                      additionalInformation: additionalInformation._0,
                                      floor: floor._0,
                                      lift: lift._0
                                    }
                                  };
                          }
                          var e = lift._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".lift" + e.path,
                                    message: e.message,
                                    value: e.value
                                  }
                                };
                        }
                        var e$1 = floor._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".floor" + e$1.path,
                                  message: e$1.message,
                                  value: e$1.value
                                }
                              };
                      }
                      var e$2 = additionalInformation._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".additionalInformation" + e$2.path,
                                message: e$2.message,
                                value: e$2.value
                              }
                            };
                    }
                    var e$3 = country._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".country" + e$3.path,
                              message: e$3.message,
                              value: e$3.value
                            }
                          };
                  }
                  var e$4 = city._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".city" + e$4.path,
                            message: e$4.message,
                            value: e$4.value
                          }
                        };
                }
                var e$5 = postalCode._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".postalCode" + e$5.path,
                          message: e$5.message,
                          value: e$5.value
                        }
                      };
              }
              var e$6 = line3._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".line3" + e$6.path,
                        message: e$6.message,
                        value: e$6.value
                      }
                    };
            }
            var e$7 = line2._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".line2" + e$7.path,
                      message: e$7.message,
                      value: e$7.value
                    }
                  };
          }
          var e$8 = line1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".line1" + e$8.path,
                    message: e$8.message,
                    value: e$8.value
                  }
                };
        }
        var e$9 = email._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".email" + e$9.path,
                  message: e$9.message,
                  value: e$9.value
                }
              };
      }
      var e$10 = phone2._0;
      return {
              TAG: "Error",
              _0: {
                path: ".phone2" + e$10.path,
                message: e$10.message,
                value: e$10.value
              }
            };
    }
    var e$11 = phone1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".phone1" + e$11.path,
              message: e$11.message,
              value: e$11.value
            }
          };
  }
  var e$12 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$12.path,
            message: e$12.message,
            value: e$12.value
          }
        };
}

function warehouse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var warehouseCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "warehouseCode"), null));
  if (warehouseCode.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              warehouseCode: warehouseCode._0
            }
          };
  }
  var e = warehouseCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".warehouseCode" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function store_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var storeCode = Curry._1(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
  if (storeCode.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              storeCode: storeCode._0
            }
          };
  }
  var e = storeCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".storeCode" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function deliveryPoint_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pointType = t_decode$14(Belt_Option.getWithDefault(Js_dict.get(v, "pointType"), null));
  if (pointType.TAG === "Ok") {
    var id = Curry._1(Identifiers.OrderDeliveryPointId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
    if (id.TAG === "Ok") {
      var associatedBarcodes = Spice.arrayFromJson(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "associatedBarcodes"), null));
      if (associatedBarcodes.TAG === "Ok") {
        var $$location = location_decode(Belt_Option.getWithDefault(Js_dict.get(v, "location"), null));
        if ($$location.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    pointType: pointType._0,
                    id: id._0,
                    associatedBarcodes: associatedBarcodes._0,
                    location: $$location._0
                  }
                };
        }
        var e = $$location._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".location" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = associatedBarcodes._0;
      return {
              TAG: "Error",
              _0: {
                path: ".associatedBarcodes" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = id._0;
    return {
            TAG: "Error",
            _0: {
              path: ".id" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = pointType._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pointType" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function skill_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
  if (tag.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              tag: tag._0
            }
          };
  }
  var e = tag._0;
  return {
          TAG: "Error",
          _0: {
            path: ".tag" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function integrations_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var status = t_decode$5(Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
  if (status.TAG === "Ok") {
    var triesLeft = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "triesLeft"), null));
    if (triesLeft.TAG === "Ok") {
      var history = Spice.arrayFromJson(integration_decode, Belt_Option.getWithDefault(Js_dict.get(v, "history"), null));
      if (history.TAG === "Ok") {
        var projectId = Spice.optionFromJson(Identifiers.RoutePlanId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "projectId"), null));
        if (projectId.TAG === "Ok") {
          var triggerAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "triggerAt"), null));
          if (triggerAt.TAG === "Ok") {
            var deliveryEstimatedAt = Spice.optionFromJson(Toolkit__Decoders.$$Date.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryEstimatedAt"), null));
            if (deliveryEstimatedAt.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        status: status._0,
                        triesLeft: triesLeft._0,
                        history: history._0,
                        projectId: projectId._0,
                        triggerAt: triggerAt._0,
                        deliveryEstimatedAt: deliveryEstimatedAt._0
                      }
                    };
            }
            var e = deliveryEstimatedAt._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".deliveryEstimatedAt" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = triggerAt._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".triggerAt" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = projectId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".projectId" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = history._0;
      return {
              TAG: "Error",
              _0: {
                path: ".history" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = triesLeft._0;
    return {
            TAG: "Error",
            _0: {
              path: ".triesLeft" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = status._0;
  return {
          TAG: "Error",
          _0: {
            path: ".status" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function integrations_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "status",
                    false,
                    t_encode$5(v.status)
                  ],
                  [
                    "triesLeft",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.triesLeft)
                  ],
                  [
                    "history",
                    false,
                    Spice.arrayToJson(integration_encode, v.history)
                  ],
                  [
                    "projectId",
                    false,
                    Spice.optionToJson(Identifiers.RoutePlanId.t_encode, v.projectId)
                  ],
                  [
                    "triggerAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.triggerAt)
                  ],
                  [
                    "deliveryEstimatedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.$$Date.t_encode, v.deliveryEstimatedAt)
                  ]
                ]));
}

function skill_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ]]));
}

function deliveryPoint_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pointType",
                    false,
                    t_encode$14(v.pointType)
                  ],
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.OrderDeliveryPointId.t_encode, v.id)
                  ],
                  [
                    "associatedBarcodes",
                    false,
                    Spice.arrayToJson(Identifiers.Barcode.t_encode, v.associatedBarcodes)
                  ],
                  [
                    "location",
                    false,
                    location_encode(v.location)
                  ]
                ]));
}

function response_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "primaryOrderReference",
                    false,
                    Curry._1(Identifiers.OrderReference.t_encode, v.primaryOrderReference)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.ref2)
                  ],
                  [
                    "expectedHubId",
                    false,
                    encoder(v.expectedHubId)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "state",
                    false,
                    t_encode(v.state)
                  ],
                  [
                    "receivedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.receivedAt)
                  ],
                  [
                    "hubs",
                    false,
                    Spice.arrayToJson(Identifiers.HubId.t_encode, v.hubs)
                  ],
                  [
                    "isTracked",
                    false,
                    Spice.boolToJson(v.isTracked)
                  ],
                  [
                    "integrationDateLimit",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.integrationDateLimit)
                  ],
                  [
                    "globalState",
                    false,
                    t_encode$2(v.globalState)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(skill_encode, v.skills)
                  ],
                  [
                    "unknownSkills",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.unknownSkills)
                  ],
                  [
                    "ecommerceValidationDate",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.ecommerceValidationDate)
                  ],
                  [
                    "deliveryPoints",
                    false,
                    Spice.arrayToJson(deliveryPoint_encode, v.deliveryPoints)
                  ],
                  [
                    "integration",
                    false,
                    Spice.optionToJson(integrations_encode, v.integration)
                  ],
                  [
                    "actions",
                    false,
                    Spice.optionToJson((function (param) {
                            return Spice.arrayToJson(t_encode$13, param);
                          }), v.actions)
                  ]
                ]));
}

function response_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var primaryOrderReference = Curry._1(Identifiers.OrderReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "primaryOrderReference"), null));
    if (primaryOrderReference.TAG === "Ok") {
      var ref2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
      if (ref2.TAG === "Ok") {
        var expectedHubId = t_decode$7(Belt_Option.getWithDefault(Js_dict.get(v, "expectedHubId"), null));
        if (expectedHubId.TAG === "Ok") {
          var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
          if (clientId.TAG === "Ok") {
            var state = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "state"), null));
            if (state.TAG === "Ok") {
              var receivedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "receivedAt"), null));
              if (receivedAt.TAG === "Ok") {
                var hubs = Spice.arrayFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubs"), null));
                if (hubs.TAG === "Ok") {
                  var isTracked = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isTracked"), null));
                  if (isTracked.TAG === "Ok") {
                    var integrationDateLimit = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "integrationDateLimit"), null));
                    if (integrationDateLimit.TAG === "Ok") {
                      var globalState = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "globalState"), null));
                      if (globalState.TAG === "Ok") {
                        var skills = Spice.arrayFromJson(skill_decode, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                        if (skills.TAG === "Ok") {
                          var unknownSkills = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "unknownSkills"), null));
                          if (unknownSkills.TAG === "Ok") {
                            var ecommerceValidationDate = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ecommerceValidationDate"), null));
                            if (ecommerceValidationDate.TAG === "Ok") {
                              var deliveryPoints = Spice.arrayFromJson(deliveryPoint_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryPoints"), null));
                              if (deliveryPoints.TAG === "Ok") {
                                var integration = Spice.optionFromJson(integrations_decode, Belt_Option.getWithDefault(Js_dict.get(v, "integration"), null));
                                if (integration.TAG === "Ok") {
                                  var actions = Spice.optionFromJson((function (param) {
                                          return Spice.arrayFromJson(t_decode$13, param);
                                        }), Belt_Option.getWithDefault(Js_dict.get(v, "actions"), null));
                                  if (actions.TAG === "Ok") {
                                    return {
                                            TAG: "Ok",
                                            _0: {
                                              orderId: orderId._0,
                                              primaryOrderReference: primaryOrderReference._0,
                                              ref2: ref2._0,
                                              expectedHubId: expectedHubId._0,
                                              clientId: clientId._0,
                                              state: state._0,
                                              receivedAt: receivedAt._0,
                                              hubs: hubs._0,
                                              isTracked: isTracked._0,
                                              integrationDateLimit: integrationDateLimit._0,
                                              globalState: globalState._0,
                                              skills: skills._0,
                                              unknownSkills: unknownSkills._0,
                                              ecommerceValidationDate: ecommerceValidationDate._0,
                                              deliveryPoints: deliveryPoints._0,
                                              integration: integration._0,
                                              actions: actions._0
                                            }
                                          };
                                  }
                                  var e = actions._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".actions" + e.path,
                                            message: e.message,
                                            value: e.value
                                          }
                                        };
                                }
                                var e$1 = integration._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".integration" + e$1.path,
                                          message: e$1.message,
                                          value: e$1.value
                                        }
                                      };
                              }
                              var e$2 = deliveryPoints._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".deliveryPoints" + e$2.path,
                                        message: e$2.message,
                                        value: e$2.value
                                      }
                                    };
                            }
                            var e$3 = ecommerceValidationDate._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".ecommerceValidationDate" + e$3.path,
                                      message: e$3.message,
                                      value: e$3.value
                                    }
                                  };
                          }
                          var e$4 = unknownSkills._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".unknownSkills" + e$4.path,
                                    message: e$4.message,
                                    value: e$4.value
                                  }
                                };
                        }
                        var e$5 = skills._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".skills" + e$5.path,
                                  message: e$5.message,
                                  value: e$5.value
                                }
                              };
                      }
                      var e$6 = globalState._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".globalState" + e$6.path,
                                message: e$6.message,
                                value: e$6.value
                              }
                            };
                    }
                    var e$7 = integrationDateLimit._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".integrationDateLimit" + e$7.path,
                              message: e$7.message,
                              value: e$7.value
                            }
                          };
                  }
                  var e$8 = isTracked._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".isTracked" + e$8.path,
                            message: e$8.message,
                            value: e$8.value
                          }
                        };
                }
                var e$9 = hubs._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".hubs" + e$9.path,
                          message: e$9.message,
                          value: e$9.value
                        }
                      };
              }
              var e$10 = receivedAt._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".receivedAt" + e$10.path,
                        message: e$10.message,
                        value: e$10.value
                      }
                    };
            }
            var e$11 = state._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".state" + e$11.path,
                      message: e$11.message,
                      value: e$11.value
                    }
                  };
          }
          var e$12 = clientId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".clientId" + e$12.path,
                    message: e$12.message,
                    value: e$12.value
                  }
                };
        }
        var e$13 = expectedHubId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".expectedHubId" + e$13.path,
                  message: e$13.message,
                  value: e$13.value
                }
              };
      }
      var e$14 = ref2._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ref2" + e$14.path,
                message: e$14.message,
                value: e$14.value
              }
            };
    }
    var e$15 = primaryOrderReference._0;
    return {
            TAG: "Error",
            _0: {
              path: ".primaryOrderReference" + e$15.path,
              message: e$15.message,
              value: e$15.value
            }
          };
  }
  var e$16 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$16.path,
            message: e$16.message,
            value: e$16.value
          }
        };
}

function config$3(orderId) {
  return {
          kyInstance: kyInstance$4,
          path: orderId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Order",
            "GetAdminOrderDetail",
            orderId
          ]
        };
}

var AdminGet = {
  PointType: PointType,
  ErrorCause: ErrorCause,
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  skill_encode: skill_encode,
  skill_decode: skill_decode,
  deliveryPoint_encode: deliveryPoint_encode,
  deliveryPoint_decode: deliveryPoint_decode,
  location_encode: location_encode,
  location_decode: location_decode,
  store_encode: store_encode,
  store_decode: store_decode,
  warehouse_encode: warehouse_encode,
  warehouse_decode: warehouse_decode,
  address_encode: address_encode,
  address_decode: address_decode,
  integrations_encode: integrations_encode,
  integrations_decode: integrations_decode,
  integration_encode: integration_encode,
  integration_decode: integration_decode,
  config: config$3
};

var Orders$1 = {
  kyInstance: kyInstance$4,
  AdminGet: AdminGet
};

var Warehouse = {
  kyInstance: kyInstance$3,
  Orders: Orders$1
};

function t_encode$16(v) {
  if (v === "pickup") {
    return "pickup";
  } else {
    return "dropoff";
  }
}

function t_decode$16(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("pickup" === v) {
          return {
                  TAG: "Ok",
                  _0: "pickup"
                };
        } else if ("dropoff" === v) {
          return {
                  TAG: "Ok",
                  _0: "dropoff"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var PointType$1 = {
  t_encode: t_encode$16,
  t_decode: t_decode$16
};

function handledEnum_encode$1(v) {
  switch (v) {
    case "ZONE_NOT_COVERED" :
        return "ZONE_NOT_COVERED";
    case "TOTAL_WEIGHT_TOO_HEAVY" :
        return "TOTAL_WEIGHT_TOO_HEAVY";
    case "PACKET_TOO_BIG" :
        return "PACKET_TOO_BIG";
    case "PACKAGE_TOO_BIG" :
        return "PACKAGE_TOO_BIG";
    case "VOLUME_TOO_BIG" :
        return "VOLUME_TOO_BIG";
    case "SLOT_OUT_OF_OPENING" :
        return "SLOT_OUT_OF_OPENING";
    case "TOO_HEAVY_SINGLE_PACKET" :
        return "TOO_HEAVY_SINGLE_PACKET";
    case "NO_TRANSPORT_CAPACITY" :
        return "NO_TRANSPORT_CAPACITY";
    case "DISTANCE_TOO_FAR" :
        return "DISTANCE_TOO_FAR";
    case "STORE_NOT_CONFIGURED" :
        return "STORE_NOT_CONFIGURED";
    case "UNKNOWN_STORE" :
        return "UNKNOWN_STORE";
    case "CONDITIONS_NOT_MATCHED" :
        return "CONDITIONS_NOT_MATCHED";
    case "SLOT_TOO_NARROW" :
        return "SLOT_TOO_NARROW";
    case "SLOT_IN_THE_PAST" :
        return "SLOT_IN_THE_PAST";
    case "SLOT_BEFORE_FIRST_AVAILABLE_SLOT" :
        return "SLOT_BEFORE_FIRST_AVAILABLE_SLOT";
    case "UNAUTHORIZED_REQUIRED_SKILLS" :
        return "UNAUTHORIZED_REQUIRED_SKILLS";
    case "CANNOT_CALCULATE_DISTANCE" :
        return "CANNOT_CALCULATE_DISTANCE";
    case "TECHNICAL_ERROR" :
        return "TECHNICAL_ERROR";
    case "NO_COMMON_SLOT_BETWEEN_CONFIG_AND_OPTIONS" :
        return "NO_COMMON_SLOT_BETWEEN_CONFIG_AND_OPTIONS";
    case "CONFIG_SLOTS_ARE_TOO_SHORT" :
        return "CONFIG_SLOTS_ARE_TOO_SHORT";
    
  }
}

function handledEnum_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("ZONE_NOT_COVERED" === v) {
          return {
                  TAG: "Ok",
                  _0: "ZONE_NOT_COVERED"
                };
        } else if ("TOTAL_WEIGHT_TOO_HEAVY" === v) {
          return {
                  TAG: "Ok",
                  _0: "TOTAL_WEIGHT_TOO_HEAVY"
                };
        } else if ("PACKET_TOO_BIG" === v) {
          return {
                  TAG: "Ok",
                  _0: "PACKET_TOO_BIG"
                };
        } else if ("PACKAGE_TOO_BIG" === v) {
          return {
                  TAG: "Ok",
                  _0: "PACKAGE_TOO_BIG"
                };
        } else if ("VOLUME_TOO_BIG" === v) {
          return {
                  TAG: "Ok",
                  _0: "VOLUME_TOO_BIG"
                };
        } else if ("SLOT_OUT_OF_OPENING" === v) {
          return {
                  TAG: "Ok",
                  _0: "SLOT_OUT_OF_OPENING"
                };
        } else if ("TOO_HEAVY_SINGLE_PACKET" === v) {
          return {
                  TAG: "Ok",
                  _0: "TOO_HEAVY_SINGLE_PACKET"
                };
        } else if ("NO_TRANSPORT_CAPACITY" === v) {
          return {
                  TAG: "Ok",
                  _0: "NO_TRANSPORT_CAPACITY"
                };
        } else if ("DISTANCE_TOO_FAR" === v) {
          return {
                  TAG: "Ok",
                  _0: "DISTANCE_TOO_FAR"
                };
        } else if ("STORE_NOT_CONFIGURED" === v) {
          return {
                  TAG: "Ok",
                  _0: "STORE_NOT_CONFIGURED"
                };
        } else if ("UNKNOWN_STORE" === v) {
          return {
                  TAG: "Ok",
                  _0: "UNKNOWN_STORE"
                };
        } else if ("CONDITIONS_NOT_MATCHED" === v) {
          return {
                  TAG: "Ok",
                  _0: "CONDITIONS_NOT_MATCHED"
                };
        } else if ("SLOT_TOO_NARROW" === v) {
          return {
                  TAG: "Ok",
                  _0: "SLOT_TOO_NARROW"
                };
        } else if ("SLOT_IN_THE_PAST" === v) {
          return {
                  TAG: "Ok",
                  _0: "SLOT_IN_THE_PAST"
                };
        } else if ("SLOT_BEFORE_FIRST_AVAILABLE_SLOT" === v) {
          return {
                  TAG: "Ok",
                  _0: "SLOT_BEFORE_FIRST_AVAILABLE_SLOT"
                };
        } else if ("UNAUTHORIZED_REQUIRED_SKILLS" === v) {
          return {
                  TAG: "Ok",
                  _0: "UNAUTHORIZED_REQUIRED_SKILLS"
                };
        } else if ("CANNOT_CALCULATE_DISTANCE" === v) {
          return {
                  TAG: "Ok",
                  _0: "CANNOT_CALCULATE_DISTANCE"
                };
        } else if ("TECHNICAL_ERROR" === v) {
          return {
                  TAG: "Ok",
                  _0: "TECHNICAL_ERROR"
                };
        } else if ("NO_COMMON_SLOT_BETWEEN_CONFIG_AND_OPTIONS" === v) {
          return {
                  TAG: "Ok",
                  _0: "NO_COMMON_SLOT_BETWEEN_CONFIG_AND_OPTIONS"
                };
        } else if ("CONFIG_SLOTS_ARE_TOO_SHORT" === v) {
          return {
                  TAG: "Ok",
                  _0: "CONFIG_SLOTS_ARE_TOO_SHORT"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function encoder$2(v) {
  if (v.TAG === "ErrorCause") {
    return handledEnum_encode$1(v._0);
  } else {
    return v._0;
  }
}

function decoder$2(json) {
  var str = Js_json.classify(json);
  if (typeof str !== "object") {
    return {
            TAG: "Error",
            _0: {
              path: "",
              message: "Expected error cause enum",
              value: json
            }
          };
  }
  if (str.TAG !== "JSONString") {
    return {
            TAG: "Error",
            _0: {
              path: "",
              message: "Expected error cause enum",
              value: json
            }
          };
  }
  var str$1 = str._0;
  var r = handledEnum_decode$1(str$1);
  if (r.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              TAG: "ErrorCause",
              _0: r._0
            }
          };
  } else {
    return {
            TAG: "Ok",
            _0: {
              TAG: "Unknown",
              _0: str$1
            }
          };
  }
}

var codec$2 = [
  encoder$2,
  decoder$2
];

var t_encode$17 = encoder$2;

var t_decode$17 = decoder$2;

var ErrorCause$1 = {
  handledEnum_encode: handledEnum_encode$1,
  handledEnum_decode: handledEnum_decode$1,
  encoder: encoder$2,
  decoder: decoder$2,
  codec: codec$2,
  t_encode: t_encode$17,
  t_decode: t_decode$17
};

function location_encode$1(v) {
  var variant = v.NAME;
  if (variant === "store") {
    return [
            "store",
            store_encode$1(v.VAL)
          ];
  } else if (variant === "address") {
    return [
            "address",
            address_encode$1(v.VAL)
          ];
  } else {
    return [
            "warehouse",
            warehouse_encode$1(v.VAL)
          ];
  }
}

function location_decode$1(v) {
  var json_arr = Js_json.classify(v);
  if (typeof json_arr !== "object") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  if (json_arr.TAG !== "JSONArray") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  var json_arr$1 = json_arr._0;
  if (json_arr$1.length === 0) {
    return Spice.error(undefined, "Expected polyvariant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, json_arr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match === "object" && match.TAG === "JSONString") {
    switch (match._0) {
      case "address" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v0 = address_decode$1(Belt_Array.getExn(json_arr$1, 1));
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "address",
                      VAL: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "store" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v0$1 = store_decode$1(Belt_Array.getExn(json_arr$1, 1));
          if (v0$1.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "store",
                      VAL: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      case "warehouse" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v0$2 = warehouse_decode$1(Belt_Array.getExn(json_arr$1, 1));
          if (v0$2.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "warehouse",
                      VAL: v0$2._0
                    }
                  };
          }
          var e$2 = v0$2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid polymorphic constructor", Belt_Array.getExn(json_arr$1, 0));
}

function address_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone1)
                  ],
                  [
                    "phone2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone2)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "line3",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line3)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "additionalInformation",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.additionalInformation)
                  ],
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ],
                  [
                    "lift",
                    false,
                    Spice.optionToJson(Spice.boolToJson, v.lift)
                  ]
                ]));
}

function store_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "storeCode",
                    false,
                    Curry._1(Identifiers.StoreCode.t_encode, v.storeCode)
                  ]]));
}

function warehouse_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "warehouseCode",
                    false,
                    Spice.stringToJson(v.warehouseCode)
                  ]]));
}

function warehouse_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var warehouseCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "warehouseCode"), null));
  if (warehouseCode.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              warehouseCode: warehouseCode._0
            }
          };
  }
  var e = warehouseCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".warehouseCode" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function store_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var storeCode = Curry._1(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
  if (storeCode.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              storeCode: storeCode._0
            }
          };
  }
  var e = storeCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".storeCode" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function address_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var phone1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
    if (phone1.TAG === "Ok") {
      var phone2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone2"), null));
      if (phone2.TAG === "Ok") {
        var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
        if (email.TAG === "Ok") {
          var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
          if (line1.TAG === "Ok") {
            var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
            if (line2.TAG === "Ok") {
              var line3 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line3"), null));
              if (line3.TAG === "Ok") {
                var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
                if (postalCode.TAG === "Ok") {
                  var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
                  if (city.TAG === "Ok") {
                    var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
                    if (country.TAG === "Ok") {
                      var additionalInformation = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "additionalInformation"), null));
                      if (additionalInformation.TAG === "Ok") {
                        var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
                        if (floor.TAG === "Ok") {
                          var lift = Spice.optionFromJson(Spice.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lift"), null));
                          if (lift.TAG === "Ok") {
                            return {
                                    TAG: "Ok",
                                    _0: {
                                      name: name._0,
                                      phone1: phone1._0,
                                      phone2: phone2._0,
                                      email: email._0,
                                      line1: line1._0,
                                      line2: line2._0,
                                      line3: line3._0,
                                      postalCode: postalCode._0,
                                      city: city._0,
                                      country: country._0,
                                      additionalInformation: additionalInformation._0,
                                      floor: floor._0,
                                      lift: lift._0
                                    }
                                  };
                          }
                          var e = lift._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".lift" + e.path,
                                    message: e.message,
                                    value: e.value
                                  }
                                };
                        }
                        var e$1 = floor._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".floor" + e$1.path,
                                  message: e$1.message,
                                  value: e$1.value
                                }
                              };
                      }
                      var e$2 = additionalInformation._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".additionalInformation" + e$2.path,
                                message: e$2.message,
                                value: e$2.value
                              }
                            };
                    }
                    var e$3 = country._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".country" + e$3.path,
                              message: e$3.message,
                              value: e$3.value
                            }
                          };
                  }
                  var e$4 = city._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".city" + e$4.path,
                            message: e$4.message,
                            value: e$4.value
                          }
                        };
                }
                var e$5 = postalCode._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".postalCode" + e$5.path,
                          message: e$5.message,
                          value: e$5.value
                        }
                      };
              }
              var e$6 = line3._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".line3" + e$6.path,
                        message: e$6.message,
                        value: e$6.value
                      }
                    };
            }
            var e$7 = line2._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".line2" + e$7.path,
                      message: e$7.message,
                      value: e$7.value
                    }
                  };
          }
          var e$8 = line1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".line1" + e$8.path,
                    message: e$8.message,
                    value: e$8.value
                  }
                };
        }
        var e$9 = email._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".email" + e$9.path,
                  message: e$9.message,
                  value: e$9.value
                }
              };
      }
      var e$10 = phone2._0;
      return {
              TAG: "Error",
              _0: {
                path: ".phone2" + e$10.path,
                message: e$10.message,
                value: e$10.value
              }
            };
    }
    var e$11 = phone1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".phone1" + e$11.path,
              message: e$11.message,
              value: e$11.value
            }
          };
  }
  var e$12 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$12.path,
            message: e$12.message,
            value: e$12.value
          }
        };
}

function deliveryPoint_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pointType = t_decode$16(Belt_Option.getWithDefault(Js_dict.get(v, "pointType"), null));
  if (pointType.TAG === "Ok") {
    var id = Curry._1(Identifiers.OrderDeliveryPointId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
    if (id.TAG === "Ok") {
      var associatedBarcodes = Spice.arrayFromJson(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "associatedBarcodes"), null));
      if (associatedBarcodes.TAG === "Ok") {
        var $$location = location_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "location"), null));
        if ($$location.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    pointType: pointType._0,
                    id: id._0,
                    associatedBarcodes: associatedBarcodes._0,
                    location: $$location._0
                  }
                };
        }
        var e = $$location._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".location" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = associatedBarcodes._0;
      return {
              TAG: "Error",
              _0: {
                path: ".associatedBarcodes" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = id._0;
    return {
            TAG: "Error",
            _0: {
              path: ".id" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = pointType._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pointType" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function skill_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
  if (tag.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              tag: tag._0
            }
          };
  }
  var e = tag._0;
  return {
          TAG: "Error",
          _0: {
            path: ".tag" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function deliveryPoint_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pointType",
                    false,
                    t_encode$16(v.pointType)
                  ],
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.OrderDeliveryPointId.t_encode, v.id)
                  ],
                  [
                    "associatedBarcodes",
                    false,
                    Spice.arrayToJson(Identifiers.Barcode.t_encode, v.associatedBarcodes)
                  ],
                  [
                    "location",
                    false,
                    location_encode$1(v.location)
                  ]
                ]));
}

function skill_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ]]));
}

function response_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "ref1",
                    false,
                    Curry._1(Identifiers.OrderReference.t_encode, v.ref1)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.ref2)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "state",
                    false,
                    t_encode(v.state)
                  ],
                  [
                    "integrationDateLimit",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.integrationDateLimit)
                  ],
                  [
                    "receivedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.receivedAt)
                  ],
                  [
                    "hubs",
                    false,
                    Spice.arrayToJson(Identifiers.HubId.t_encode, v.hubs)
                  ],
                  [
                    "globalState",
                    false,
                    t_encode$2(v.globalState)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(skill_encode$1, v.skills)
                  ],
                  [
                    "unknownSkills",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.unknownSkills)
                  ],
                  [
                    "ecommerceValidationDate",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.ecommerceValidationDate)
                  ],
                  [
                    "deliveryPoints",
                    false,
                    Spice.arrayToJson(deliveryPoint_encode$1, v.deliveryPoints)
                  ]
                ]));
}

function response_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var ref1 = Curry._1(Identifiers.OrderReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
    if (ref1.TAG === "Ok") {
      var ref2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
      if (ref2.TAG === "Ok") {
        var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
        if (clientId.TAG === "Ok") {
          var state = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "state"), null));
          if (state.TAG === "Ok") {
            var integrationDateLimit = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "integrationDateLimit"), null));
            if (integrationDateLimit.TAG === "Ok") {
              var receivedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "receivedAt"), null));
              if (receivedAt.TAG === "Ok") {
                var hubs = Spice.arrayFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubs"), null));
                if (hubs.TAG === "Ok") {
                  var globalState = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "globalState"), null));
                  if (globalState.TAG === "Ok") {
                    var skills = Spice.arrayFromJson(skill_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                    if (skills.TAG === "Ok") {
                      var unknownSkills = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "unknownSkills"), null));
                      if (unknownSkills.TAG === "Ok") {
                        var ecommerceValidationDate = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ecommerceValidationDate"), null));
                        if (ecommerceValidationDate.TAG === "Ok") {
                          var deliveryPoints = Spice.arrayFromJson(deliveryPoint_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryPoints"), null));
                          if (deliveryPoints.TAG === "Ok") {
                            return {
                                    TAG: "Ok",
                                    _0: {
                                      orderId: orderId._0,
                                      ref1: ref1._0,
                                      ref2: ref2._0,
                                      clientId: clientId._0,
                                      state: state._0,
                                      integrationDateLimit: integrationDateLimit._0,
                                      receivedAt: receivedAt._0,
                                      hubs: hubs._0,
                                      globalState: globalState._0,
                                      skills: skills._0,
                                      unknownSkills: unknownSkills._0,
                                      ecommerceValidationDate: ecommerceValidationDate._0,
                                      deliveryPoints: deliveryPoints._0
                                    }
                                  };
                          }
                          var e = deliveryPoints._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".deliveryPoints" + e.path,
                                    message: e.message,
                                    value: e.value
                                  }
                                };
                        }
                        var e$1 = ecommerceValidationDate._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".ecommerceValidationDate" + e$1.path,
                                  message: e$1.message,
                                  value: e$1.value
                                }
                              };
                      }
                      var e$2 = unknownSkills._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".unknownSkills" + e$2.path,
                                message: e$2.message,
                                value: e$2.value
                              }
                            };
                    }
                    var e$3 = skills._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".skills" + e$3.path,
                              message: e$3.message,
                              value: e$3.value
                            }
                          };
                  }
                  var e$4 = globalState._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".globalState" + e$4.path,
                            message: e$4.message,
                            value: e$4.value
                          }
                        };
                }
                var e$5 = hubs._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".hubs" + e$5.path,
                          message: e$5.message,
                          value: e$5.value
                        }
                      };
              }
              var e$6 = receivedAt._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".receivedAt" + e$6.path,
                        message: e$6.message,
                        value: e$6.value
                      }
                    };
            }
            var e$7 = integrationDateLimit._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".integrationDateLimit" + e$7.path,
                      message: e$7.message,
                      value: e$7.value
                    }
                  };
          }
          var e$8 = state._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".state" + e$8.path,
                    message: e$8.message,
                    value: e$8.value
                  }
                };
        }
        var e$9 = clientId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".clientId" + e$9.path,
                  message: e$9.message,
                  value: e$9.value
                }
              };
      }
      var e$10 = ref2._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ref2" + e$10.path,
                message: e$10.message,
                value: e$10.value
              }
            };
    }
    var e$11 = ref1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ref1" + e$11.path,
              message: e$11.message,
              value: e$11.value
            }
          };
  }
  var e$12 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$12.path,
            message: e$12.message,
            value: e$12.value
          }
        };
}

function config$4(param) {
  var orderId = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance,
          path: "clients/" + clientId + "/warehouse/orders/" + orderId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "ColiswebApi__V6_Order",
            "GetClientOrderDetail",
            clientId,
            orderId
          ]
        };
}

var Get = {
  PointType: PointType$1,
  ErrorCause: ErrorCause$1,
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  skill_encode: skill_encode$1,
  skill_decode: skill_decode$1,
  deliveryPoint_encode: deliveryPoint_encode$1,
  deliveryPoint_decode: deliveryPoint_decode$1,
  location_encode: location_encode$1,
  location_decode: location_decode$1,
  store_encode: store_encode$1,
  store_decode: store_decode$1,
  warehouse_encode: warehouse_encode$1,
  warehouse_decode: warehouse_decode$1,
  address_encode: address_encode$1,
  address_decode: address_decode$1,
  config: config$4
};

var Orders$2 = {
  Get: Get
};

var Warehouse$1 = {
  Orders: Orders$2
};

function store_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    Spice.stringToJson(v.type_)
                  ],
                  [
                    "storeCode",
                    false,
                    Curry._1(Identifiers.StoreCode.t_encode, v.storeCode)
                  ]
                ]));
}

function store_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var storeCode = Curry._1(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
    if (storeCode.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                type_: type_._0,
                storeCode: storeCode._0
              }
            };
    }
    var e = storeCode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeCode" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$1.path),
            message: e$1.message,
            value: e$1.value
          }
        };
}

function address_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    Spice.stringToJson(v.type_)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ]
                ]));
}

function address_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
        if (city.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    type_: type_._0,
                    name: name._0,
                    postalCode: postalCode._0,
                    city: city._0
                  }
                };
        }
        var e = city._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".city" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$3.path),
            message: e$3.message,
            value: e$3.value
          }
        };
}

function encoder$3(v) {
  if (v.type === "Store") {
    return store_encode$2(v._0);
  } else {
    return address_encode$2(v._0);
  }
}

function decoder$3(json) {
  var match = json.type;
  if (match === undefined) {
    return Spice.error(undefined, "Unhandled location type", json);
  }
  switch (match) {
    case "address" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, address_decode$2(json), (function (c) {
                      return {
                              type: "Address",
                              _0: c
                            };
                    }));
    case "store" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, store_decode$2(json), (function (c) {
                      return {
                              type: "Store",
                              _0: c
                            };
                    }));
    default:
      return Spice.error(undefined, "Unhandled location type", json);
  }
}

var codec$3 = [
  encoder$3,
  decoder$3
];

var t_encode$18 = encoder$3;

var t_decode$18 = decoder$3;

var $$Location = {
  store_encode: store_encode$2,
  store_decode: store_decode$2,
  address_encode: address_encode$2,
  address_decode: address_decode$2,
  encoder: encoder$3,
  decoder: decoder$3,
  codec: codec$3,
  t_encode: t_encode$18,
  t_decode: t_decode$18
};

function filters_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "primaryOrderReference",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.OrderReference.t_encode, v.primaryOrderReference)
                  ],
                  [
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.DateTimeSlot.t_encode, v.receivedBetween)
                  ]
                ]));
}

function filters_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var primaryOrderReference = Toolkit__Decoders.$$Option.t_decode(Identifiers.OrderReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "primaryOrderReference"), null));
  if (primaryOrderReference.TAG === "Ok") {
    var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.DateTimeSlot.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
    if (receivedBetween.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                primaryOrderReference: primaryOrderReference._0,
                receivedBetween: receivedBetween._0
              }
            };
    }
    var e = receivedBetween._0;
    return {
            TAG: "Error",
            _0: {
              path: ".receivedBetween" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = primaryOrderReference._0;
  return {
          TAG: "Error",
          _0: {
            path: ".primaryOrderReference" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function sort_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.receivedBetween)
                  ]]));
}

function sort_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
  if (receivedBetween.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              receivedBetween: receivedBetween._0
            }
          };
  }
  var e = receivedBetween._0;
  return {
          TAG: "Error",
          _0: {
            path: ".receivedBetween" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function pagination_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Spice.intToJson(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Spice.intToJson(v.pageSize)
                  ]
                ]));
}

function pagination_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode$3(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode$3(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode$3(v.pagination)
                  ]
                ]));
}

function body_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function order_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "primaryOrderReference",
                    false,
                    Curry._1(Identifiers.OrderReference.t_encode, v.primaryOrderReference)
                  ],
                  [
                    "receivedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.receivedAt)
                  ],
                  [
                    "globalState",
                    false,
                    t_encode$2(v.globalState)
                  ],
                  [
                    "pickUp",
                    false,
                    encoder$3(v.pickUp)
                  ],
                  [
                    "dropOff",
                    false,
                    encoder$3(v.dropOff)
                  ]
                ]));
}

function order_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var primaryOrderReference = Curry._1(Identifiers.OrderReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "primaryOrderReference"), null));
    if (primaryOrderReference.TAG === "Ok") {
      var receivedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "receivedAt"), null));
      if (receivedAt.TAG === "Ok") {
        var globalState = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "globalState"), null));
        if (globalState.TAG === "Ok") {
          var pickUp = t_decode$18(Belt_Option.getWithDefault(Js_dict.get(v, "pickUp"), null));
          if (pickUp.TAG === "Ok") {
            var dropOff = t_decode$18(Belt_Option.getWithDefault(Js_dict.get(v, "dropOff"), null));
            if (dropOff.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        orderId: orderId._0,
                        primaryOrderReference: primaryOrderReference._0,
                        receivedAt: receivedAt._0,
                        globalState: globalState._0,
                        pickUp: pickUp._0,
                        dropOff: dropOff._0
                      }
                    };
            }
            var e = dropOff._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".dropOff" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = pickUp._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".pickUp" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = globalState._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".globalState" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = receivedAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".receivedAt" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = primaryOrderReference._0;
    return {
            TAG: "Error",
            _0: {
              path: ".primaryOrderReference" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function response_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "orders",
                    false,
                    Spice.arrayToJson(order_encode$3, v.orders)
                  ]
                ]));
}

function response_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var orders = Spice.arrayFromJson(order_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "orders"), null));
    if (orders.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagesCount: pagesCount._0,
                orders: orders._0
              }
            };
    }
    var e = orders._0;
    return {
            TAG: "Error",
            _0: {
              path: ".orders" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function skill_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ]]));
}

function skill_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
  if (tag.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              tag: tag._0
            }
          };
  }
  var e = tag._0;
  return {
          TAG: "Error",
          _0: {
            path: ".tag" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$5(param) {
  var body = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance,
          path: "clients/" + clientId + "/orders/list",
          requestOptions: {
            method: "POST",
            json: body_encode$2(body)
          },
          key: [
            "ColiswebApi__V6_Order",
            "ClientOrdersGetList",
            clientId,
            JSON.stringify(body_encode$2(body))
          ]
        };
}

var GetList = {
  $$Location: $$Location,
  filters_encode: filters_encode$3,
  filters_decode: filters_decode$3,
  sort_encode: sort_encode$3,
  sort_decode: sort_decode$3,
  pagination_encode: pagination_encode$3,
  pagination_decode: pagination_decode$3,
  body_encode: body_encode$2,
  body_decode: body_decode$2,
  response_encode: response_encode$5,
  response_decode: response_decode$5,
  order_encode: order_encode$3,
  order_decode: order_decode$3,
  skill_encode: skill_encode$2,
  skill_decode: skill_decode$2,
  config: config$5
};

var Orders$3 = {
  GetList: GetList
};

var Client = {
  Warehouse: Warehouse$1,
  Orders: Orders$3
};

function store_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    Spice.stringToJson(v.type_)
                  ],
                  [
                    "storeCode",
                    false,
                    Curry._1(Identifiers.StoreCode.t_encode, v.storeCode)
                  ]
                ]));
}

function store_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var storeCode = Curry._1(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
    if (storeCode.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                type_: type_._0,
                storeCode: storeCode._0
              }
            };
    }
    var e = storeCode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeCode" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$1.path),
            message: e$1.message,
            value: e$1.value
          }
        };
}

function address_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    Spice.stringToJson(v.type_)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ]
                ]));
}

function address_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
        if (city.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    type_: type_._0,
                    name: name._0,
                    postalCode: postalCode._0,
                    city: city._0
                  }
                };
        }
        var e = city._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".city" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$3.path),
            message: e$3.message,
            value: e$3.value
          }
        };
}

function encoder$4(v) {
  if (v.type === "Store") {
    return store_encode$3(v._0);
  } else {
    return address_encode$3(v._0);
  }
}

function decoder$4(json) {
  var match = json.type;
  if (match === undefined) {
    return Spice.error(undefined, "Unhandled location type", json);
  }
  switch (match) {
    case "address" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, address_decode$3(json), (function (c) {
                      return {
                              type: "Address",
                              _0: c
                            };
                    }));
    case "store" :
        return Curry._2(Toolkit__Primitives_Result.Result.map, store_decode$3(json), (function (c) {
                      return {
                              type: "Store",
                              _0: c
                            };
                    }));
    default:
      return Spice.error(undefined, "Unhandled location type", json);
  }
}

var codec$4 = [
  encoder$4,
  decoder$4
];

var t_encode$19 = encoder$4;

var t_decode$19 = decoder$4;

var $$Location$1 = {
  store_encode: store_encode$3,
  store_decode: store_decode$3,
  address_encode: address_encode$3,
  address_decode: address_decode$3,
  encoder: encoder$4,
  decoder: decoder$4,
  codec: codec$4,
  t_encode: t_encode$19,
  t_decode: t_decode$19
};

function filters_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "primaryOrderReference",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.OrderReference.t_encode, v.primaryOrderReference)
                  ],
                  [
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.DateTimeSlot.t_encode, v.receivedBetween)
                  ]
                ]));
}

function filters_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var primaryOrderReference = Toolkit__Decoders.$$Option.t_decode(Identifiers.OrderReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "primaryOrderReference"), null));
  if (primaryOrderReference.TAG === "Ok") {
    var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.DateTimeSlot.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
    if (receivedBetween.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                primaryOrderReference: primaryOrderReference._0,
                receivedBetween: receivedBetween._0
              }
            };
    }
    var e = receivedBetween._0;
    return {
            TAG: "Error",
            _0: {
              path: ".receivedBetween" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = primaryOrderReference._0;
  return {
          TAG: "Error",
          _0: {
            path: ".primaryOrderReference" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function sort_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "receivedBetween",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.receivedBetween)
                  ]]));
}

function sort_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var receivedBetween = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "receivedBetween"), null));
  if (receivedBetween.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              receivedBetween: receivedBetween._0
            }
          };
  }
  var e = receivedBetween._0;
  return {
          TAG: "Error",
          _0: {
            path: ".receivedBetween" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function pagination_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Spice.intToJson(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Spice.intToJson(v.pageSize)
                  ]
                ]));
}

function pagination_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode$4(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode$4(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode$4(v.pagination)
                  ]
                ]));
}

function body_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = pagination_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function order_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var primaryOrderReference = Curry._1(Identifiers.OrderReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "primaryOrderReference"), null));
    if (primaryOrderReference.TAG === "Ok") {
      var receivedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "receivedAt"), null));
      if (receivedAt.TAG === "Ok") {
        var globalState = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "globalState"), null));
        if (globalState.TAG === "Ok") {
          var pickUp = t_decode$19(Belt_Option.getWithDefault(Js_dict.get(v, "pickUp"), null));
          if (pickUp.TAG === "Ok") {
            var dropOff = t_decode$19(Belt_Option.getWithDefault(Js_dict.get(v, "dropOff"), null));
            if (dropOff.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        orderId: orderId._0,
                        primaryOrderReference: primaryOrderReference._0,
                        receivedAt: receivedAt._0,
                        globalState: globalState._0,
                        pickUp: pickUp._0,
                        dropOff: dropOff._0
                      }
                    };
            }
            var e = dropOff._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".dropOff" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = pickUp._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".pickUp" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = globalState._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".globalState" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = receivedAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".receivedAt" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = primaryOrderReference._0;
    return {
            TAG: "Error",
            _0: {
              path: ".primaryOrderReference" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function order_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "primaryOrderReference",
                    false,
                    Curry._1(Identifiers.OrderReference.t_encode, v.primaryOrderReference)
                  ],
                  [
                    "receivedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.receivedAt)
                  ],
                  [
                    "globalState",
                    false,
                    t_encode$2(v.globalState)
                  ],
                  [
                    "pickUp",
                    false,
                    encoder$4(v.pickUp)
                  ],
                  [
                    "dropOff",
                    false,
                    encoder$4(v.dropOff)
                  ]
                ]));
}

function response_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "orders",
                    false,
                    Spice.arrayToJson(order_encode$4, v.orders)
                  ]
                ]));
}

function response_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var orders = Spice.arrayFromJson(order_decode$4, Belt_Option.getWithDefault(Js_dict.get(v, "orders"), null));
    if (orders.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagesCount: pagesCount._0,
                orders: orders._0
              }
            };
    }
    var e = orders._0;
    return {
            TAG: "Error",
            _0: {
              path: ".orders" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function skill_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ]]));
}

function skill_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
  if (tag.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              tag: tag._0
            }
          };
  }
  var e = tag._0;
  return {
          TAG: "Error",
          _0: {
            path: ".tag" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$6(param) {
  var body = param[1];
  var storeId = param[0];
  return {
          kyInstance: kyInstance,
          path: "stores/" + storeId + "/orders/list",
          requestOptions: {
            method: "POST",
            json: body_encode$3(body)
          },
          key: [
            "ColiswebApi__V6_Order",
            "StoreOrdersGetList",
            storeId,
            JSON.stringify(body_encode$3(body))
          ]
        };
}

var GetList$1 = {
  $$Location: $$Location$1,
  filters_encode: filters_encode$4,
  filters_decode: filters_decode$4,
  sort_encode: sort_encode$4,
  sort_decode: sort_decode$4,
  pagination_encode: pagination_encode$4,
  pagination_decode: pagination_decode$4,
  body_encode: body_encode$3,
  body_decode: body_decode$3,
  response_encode: response_encode$6,
  response_decode: response_decode$6,
  order_encode: order_encode$4,
  order_decode: order_decode$4,
  skill_encode: skill_encode$3,
  skill_decode: skill_decode$3,
  config: config$6
};

var Orders$4 = {
  GetList: GetList$1
};

var Store = {
  Orders: Orders$4
};

var response_encode$7 = Spice.unitToJson;

var response_decode$7 = Spice.unitFromJson;

function config$7(param) {
  return {
          kyInstance: kyInstance,
          path: "orders/" + param[0] + "/deliveryPoints/" + param[1],
          requestOptions: {
            method: "PUT",
            json: address_encode(param[2])
          }
        };
}

var AdminUpdateAddress = {
  response_encode: response_encode$7,
  response_decode: response_decode$7,
  config: config$7
};

function t_encode$20(v) {
  switch (v) {
    case "created" :
        return "created";
    case "onGoing" :
        return "onGoing";
    case "succeeded" :
        return "succeeded";
    case "failed" :
        return "failed";
    
  }
}

function t_decode$20(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("created" === v) {
          return {
                  TAG: "Ok",
                  _0: "created"
                };
        } else if ("onGoing" === v) {
          return {
                  TAG: "Ok",
                  _0: "onGoing"
                };
        } else if ("succeeded" === v) {
          return {
                  TAG: "Ok",
                  _0: "succeeded"
                };
        } else if ("failed" === v) {
          return {
                  TAG: "Ok",
                  _0: "failed"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$5 = t_encode$20;

function fromString(str) {
  return Curry._3(Toolkit__Primitives_Result.Result.mapWithDefault, t_decode$20(str), undefined, (function (t) {
                return t;
              }));
}

var JobStatus = {
  t_encode: t_encode$20,
  t_decode: t_decode$20,
  toString: toString$5,
  fromString: fromString
};

function t_encode$21(v) {
  if (v === "createDeliveries") {
    return "createDeliveries";
  } else {
    return "sendOrderEdi";
  }
}

function t_decode$21(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("createDeliveries" === v) {
          return {
                  TAG: "Ok",
                  _0: "createDeliveries"
                };
        } else if ("sendOrderEdi" === v) {
          return {
                  TAG: "Ok",
                  _0: "sendOrderEdi"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$6 = t_encode$21;

var JobType = {
  t_encode: t_encode$21,
  t_decode: t_decode$21,
  toString: toString$6
};

function params_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var from = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "from"), null));
  if (from.TAG === "Ok") {
    var to = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "to"), null));
    if (to.TAG === "Ok") {
      var status = Toolkit__Decoders.$$Option.t_decode(t_decode$20, Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
      if (status.TAG === "Ok") {
        var projectId = Toolkit__Decoders.$$Option.t_decode(Identifiers.ProjectId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "projectId"), null));
        if (projectId.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    from: from._0,
                    to: to._0,
                    status: status._0,
                    projectId: projectId._0
                  }
                };
        }
        var e = projectId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".projectId" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = status._0;
      return {
              TAG: "Error",
              _0: {
                path: ".status" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = to._0;
    return {
            TAG: "Error",
            _0: {
              path: ".to" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = from._0;
  return {
          TAG: "Error",
          _0: {
            path: ".from" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function params_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "from",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.Datetime.t_encode, v.from)
                  ],
                  [
                    "to",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.Datetime.t_encode, v.to)
                  ],
                  [
                    "status",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(t_encode$20, v.status)
                  ],
                  [
                    "projectId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.ProjectId.t_encode, v.projectId)
                  ]
                ]));
}

var argument_encode$1 = params_encode$1;

var argument_decode$1 = params_decode$1;

function job_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "triggerId",
                    false,
                    Curry._1(Identifiers.OrderJobTriggerId.t_encode, v.triggerId)
                  ],
                  [
                    "at",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.at)
                  ],
                  [
                    "projectId",
                    false,
                    Curry._1(Identifiers.ProjectId.t_encode, v.projectId)
                  ],
                  [
                    "status",
                    false,
                    t_encode$20(v.status)
                  ],
                  [
                    "attempt",
                    false,
                    Spice.intToJson(v.attempt)
                  ]
                ]));
}

function job_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var triggerId = Curry._1(Identifiers.OrderJobTriggerId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "triggerId"), null));
  if (triggerId.TAG === "Ok") {
    var at = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "at"), null));
    if (at.TAG === "Ok") {
      var projectId = Curry._1(Identifiers.ProjectId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "projectId"), null));
      if (projectId.TAG === "Ok") {
        var status = t_decode$20(Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
        if (status.TAG === "Ok") {
          var attempt = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "attempt"), null));
          if (attempt.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      triggerId: triggerId._0,
                      at: at._0,
                      projectId: projectId._0,
                      status: status._0,
                      attempt: attempt._0
                    }
                  };
          }
          var e = attempt._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".attempt" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = status._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".status" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = projectId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".projectId" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = at._0;
    return {
            TAG: "Error",
            _0: {
              path: ".at" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = triggerId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".triggerId" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function response_encode$8(v) {
  return Spice.arrayToJson(job_encode, v);
}

function response_decode$8(v) {
  return Spice.arrayFromJson(job_decode, v);
}

function config$8(params) {
  return {
          kyInstance: kyInstance,
          path: "jobs",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(params_encode$1(params))
          },
          key: [
            "GetJobList",
            JSON.stringify(params_encode$1(params))
          ]
        };
}

var List$1 = {
  argument_encode: argument_encode$1,
  argument_decode: argument_decode$1,
  params_encode: params_encode$1,
  params_decode: params_decode$1,
  response_encode: response_encode$8,
  response_decode: response_decode$8,
  job_encode: job_encode,
  job_decode: job_decode,
  config: config$8
};

function body_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var newOrders = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "newOrders"), null));
  if (newOrders.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              newOrders: newOrders._0
            }
          };
  }
  var e = newOrders._0;
  return {
          TAG: "Error",
          _0: {
            path: ".newOrders" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function body_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "newOrders",
                    false,
                    Spice.boolToJson(v.newOrders)
                  ]]));
}

function argument_encode$2(v) {
  return [
          Curry._1(Identifiers.OrderJobTriggerId.t_encode, v[0]),
          body_encode$4(v[1])
        ];
}

function argument_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.OrderJobTriggerId.t_decode, v0);
  var match$1 = body_decode$4(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$9 = Spice.unitToJson;

var response_decode$9 = Spice.unitFromJson;

function config$9(param) {
  return {
          kyInstance: kyInstance,
          path: "jobs/" + param[0] + "/retry",
          requestOptions: {
            method: "POST",
            json: body_encode$4(param[1])
          }
        };
}

var Retry = {
  argument_encode: argument_encode$2,
  argument_decode: argument_decode$2,
  body_encode: body_encode$4,
  body_decode: body_decode$4,
  response_encode: response_encode$9,
  response_decode: response_decode$9,
  config: config$9
};

var Jobs = {
  JobStatus: JobStatus,
  JobType: JobType,
  List: List$1,
  Retry: Retry
};

function response_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "ref1",
                    false,
                    Curry._1(Identifiers.DeliveryReference.t_encode, v.ref1)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref2)
                  ],
                  [
                    "customerName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.customerName)
                  ],
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function response_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ref1 = Curry._1(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
  if (ref1.TAG === "Ok") {
    var ref2 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
    if (ref2.TAG === "Ok") {
      var customerName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerName"), null));
      if (customerName.TAG === "Ok") {
        var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
        if (orderId.TAG === "Ok") {
          var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
          if (clientId.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      ref1: ref1._0,
                      ref2: ref2._0,
                      customerName: customerName._0,
                      orderId: orderId._0,
                      clientId: clientId._0
                    }
                  };
          }
          var e = clientId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".clientId" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = orderId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".orderId" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = customerName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".customerName" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = ref2._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ref2" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = ref1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ref1" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function config$10(orderId) {
  return {
          kyInstance: kyInstance,
          path: "warehouse/orders/" + orderId + "/basicDetails",
          requestOptions: {
            method: "POST"
          },
          key: [
            "GetAdminOrderBasicDetails",
            orderId
          ]
        };
}

var OrderBasicDetails = {
  response_encode: response_encode$10,
  response_decode: response_decode$10,
  config: config$10
};

function response_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orderIds",
                    false,
                    Spice.arrayToJson(Identifiers.OrderId.t_encode, v.orderIds)
                  ]]));
}

function response_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderIds = Spice.arrayFromJson(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderIds"), null));
  if (orderIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orderIds: orderIds._0
            }
          };
  }
  var e = orderIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$11(param) {
  var warehouseCode = param[2];
  var clientId = param[1];
  var hubId = param[0];
  return {
          kyInstance: kyInstance,
          path: "hubs/" + hubId + "/orders/expected",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify({
                  clientId: Belt_Option.map(clientId, (function (prim) {
                          return prim;
                        })),
                  warehouseCode: Belt_Option.map(warehouseCode, (function (prim) {
                          return prim;
                        }))
                })
          },
          key: [
            "GetHubExpectedOrderList",
            hubId,
            Belt_Option.mapWithDefault(clientId, "empty_clientId", (function (prim) {
                    return prim;
                  })),
            Belt_Option.mapWithDefault(warehouseCode, "empty_warehouseCode", (function (prim) {
                    return prim;
                  }))
          ]
        };
}

var ExpectedOrders = {
  response_encode: response_encode$11,
  response_decode: response_decode$11,
  config: config$11
};

function cityContainer_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ]]));
}

function cityContainer_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
  if (city.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              city: city._0
            }
          };
  }
  var e = city._0;
  return {
          TAG: "Error",
          _0: {
            path: ".city" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode$12(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "cities",
                    false,
                    Spice.arrayToJson(cityContainer_encode, v.cities)
                  ]]));
}

function response_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var cities = Spice.arrayFromJson(cityContainer_decode, Belt_Option.getWithDefault(Js_dict.get(v, "cities"), null));
  if (cities.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              cities: cities._0
            }
          };
  }
  var e = cities._0;
  return {
          TAG: "Error",
          _0: {
            path: ".cities" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$12(param) {
  var country = param[1];
  var startWith = param[0];
  return {
          kyInstance: kyInstance,
          path: "cities/search",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify({
                  startWith: startWith,
                  country: country
                })
          },
          key: [
            "GetCitiesAutocomplete",
            startWith,
            country
          ]
        };
}

var GetCitiesAutocomplete = {
  response_encode: response_encode$12,
  response_decode: response_decode$12,
  cityContainer_encode: cityContainer_encode,
  cityContainer_decode: cityContainer_decode,
  config: config$12
};

function order_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var receivedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "receivedAt"), null));
    if (receivedAt.TAG === "Ok") {
      var name = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
      if (name.TAG === "Ok") {
        var city = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
        if (city.TAG === "Ok") {
          var postalCode = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
          if (postalCode.TAG === "Ok") {
            var country = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
            if (country.TAG === "Ok") {
              var ref1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
              if (ref1.TAG === "Ok") {
                var ref2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
                if (ref2.TAG === "Ok") {
                  var state = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "state"), null));
                  if (state.TAG === "Ok") {
                    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
                    if (clientId.TAG === "Ok") {
                      return {
                              TAG: "Ok",
                              _0: {
                                orderId: orderId._0,
                                receivedAt: receivedAt._0,
                                name: name._0,
                                city: city._0,
                                postalCode: postalCode._0,
                                country: country._0,
                                ref1: ref1._0,
                                ref2: ref2._0,
                                state: state._0,
                                clientId: clientId._0
                              }
                            };
                    }
                    var e = clientId._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".clientId" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = state._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".state" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = ref2._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".ref2" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = ref1._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".ref1" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = country._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".country" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = postalCode._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".postalCode" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = city._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".city" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = name._0;
      return {
              TAG: "Error",
              _0: {
                path: ".name" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = receivedAt._0;
    return {
            TAG: "Error",
            _0: {
              path: ".receivedAt" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function order_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "receivedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.receivedAt)
                  ],
                  [
                    "name",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.name)
                  ],
                  [
                    "city",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.country)
                  ],
                  [
                    "ref1",
                    false,
                    Spice.stringToJson(v.ref1)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.ref2)
                  ],
                  [
                    "state",
                    false,
                    t_encode(v.state)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function response_encode$13(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orders",
                    false,
                    Spice.arrayToJson(order_encode$5, v.orders)
                  ]]));
}

function response_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orders = Spice.arrayFromJson(order_decode$5, Belt_Option.getWithDefault(Js_dict.get(v, "orders"), null));
  if (orders.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orders: orders._0
            }
          };
  }
  var e = orders._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orders" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$13(param) {
  return {
          kyInstance: kyInstance,
          path: "hubs/" + param[0] + "/orders",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify({
                  ref1: param[1]
                })
          },
          mapRawResponse: (function (response) {
              var data = $$Request.decodeRawResponse(response, response_decode$13);
              var tmp;
              if (data.TAG === "Ok") {
                var data$1 = data._0;
                tmp = data$1.orders.length !== 0 ? ({
                      TAG: "Ok",
                      _0: data$1
                    }) : ({
                      TAG: "Error",
                      _0: {
                        TAG: "Custom",
                        _0: "orderNotFound"
                      }
                    });
              } else {
                tmp = data;
              }
              return $$Promise.resolved(tmp);
            })
        };
}

var GetHubOrderByRef1 = {
  response_encode: response_encode$13,
  response_decode: response_decode$13,
  order_encode: order_encode$5,
  order_decode: order_decode$5,
  config: config$13
};

function body_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderIds",
                    false,
                    Spice.arrayToJson(Identifiers.OrderId.t_encode, v.orderIds)
                  ],
                  [
                    "checksum",
                    false,
                    Spice.stringToJson(v.checksum)
                  ]
                ]));
}

function body_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderIds = Spice.arrayFromJson(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderIds"), null));
  if (orderIds.TAG === "Ok") {
    var checksum = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "checksum"), null));
    if (checksum.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                orderIds: orderIds._0,
                checksum: checksum._0
              }
            };
    }
    var e = checksum._0;
    return {
            TAG: "Error",
            _0: {
              path: ".checksum" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = orderIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderIds" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function recipient_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
  if (postalCode.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                postalCode: postalCode._0,
                city: city._0
              }
            };
    }
    var e = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = postalCode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".postalCode" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function recipient_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ]
                ]));
}

function order_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var recipient = recipient_decode(Belt_Option.getWithDefault(Js_dict.get(v, "recipient"), null));
    if (recipient.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                orderId: orderId._0,
                recipient: recipient._0
              }
            };
    }
    var e = recipient._0;
    return {
            TAG: "Error",
            _0: {
              path: ".recipient" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function order_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "recipient",
                    false,
                    recipient_encode(v.recipient)
                  ]
                ]));
}

function response_encode$14(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orders",
                    false,
                    Spice.arrayToJson(order_encode$6, v.orders)
                  ]]));
}

function response_decode$14(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orders = Spice.arrayFromJson(order_decode$6, Belt_Option.getWithDefault(Js_dict.get(v, "orders"), null));
  if (orders.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orders: orders._0
            }
          };
  }
  var e = orders._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orders" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$14(body) {
  return {
          kyInstance: kyInstance,
          path: "orders/recipients",
          requestOptions: {
            method: "POST",
            json: body_encode$5(body)
          },
          key: [
            "GetHubOrdersRecipients",
            JSON.stringify(body_encode$5(body))
          ]
        };
}

var GetHubOrdersRecipients = {
  body_encode: body_encode$5,
  body_decode: body_decode$5,
  response_encode: response_encode$14,
  response_decode: response_decode$14,
  order_encode: order_encode$6,
  order_decode: order_decode$6,
  recipient_encode: recipient_encode,
  recipient_decode: recipient_decode,
  config: config$14
};

function params_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "city",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.postalCode)
                  ]
                ]));
}

function params_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var city = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var postalCode = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  name: name._0,
                  city: city._0,
                  postalCode: postalCode._0
                }
              };
      }
      var e = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var response_encode$15 = response_encode$13;

var response_decode$15 = response_decode$13;

function config$15(param) {
  return {
          kyInstance: kyInstance,
          path: "hubs/" + param[0] + "/orders",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(params_encode$2(param[1]))
          },
          mapRawResponse: (function (response) {
              var data = $$Request.decodeRawResponse(response, response_decode$15);
              var tmp;
              if (data.TAG === "Ok") {
                var data$1 = data._0;
                tmp = data$1.orders.length !== 0 ? ({
                      TAG: "Ok",
                      _0: data$1
                    }) : ({
                      TAG: "Error",
                      _0: {
                        TAG: "Custom",
                        _0: "orderNotFound"
                      }
                    });
              } else {
                tmp = data;
              }
              return $$Promise.resolved(tmp);
            })
        };
}

var GetHubOrderByNameAndCity = {
  params_encode: params_encode$2,
  params_decode: params_decode$2,
  response_encode: response_encode$15,
  response_decode: response_decode$15,
  config: config$15
};

function event_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ],
                  [
                    "date",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.date)
                  ],
                  [
                    "context",
                    false,
                    ColiswebApi__V6_Parcel.ScanContext.t_encode(v.context)
                  ]
                ]));
}

function parcel_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "status",
                    false,
                    ColiswebApi__V6_Parcel.Status.t_encode(v.status)
                  ],
                  [
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ],
                  [
                    "weight",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Weight.WithUnit.t_encode, v.weight)
                  ],
                  [
                    "length",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.length)
                  ],
                  [
                    "width",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.width)
                  ],
                  [
                    "height",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.WithUnit.t_encode, v.height)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "productTypology",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.productTypology)
                  ],
                  [
                    "packageType",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.packageType)
                  ],
                  [
                    "lastEvent",
                    false,
                    Spice.optionToJson(event_encode, v.lastEvent)
                  ],
                  [
                    "directive",
                    false,
                    ColiswebApi__V6_Parcel.Directive.t_encode(v.directive)
                  ]
                ]));
}

function event_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
  if (hubId.TAG === "Ok") {
    var date = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
    if (date.TAG === "Ok") {
      var context = ColiswebApi__V6_Parcel.ScanContext.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "context"), null));
      if (context.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  hubId: hubId._0,
                  date: date._0,
                  context: context._0
                }
              };
      }
      var e = context._0;
      return {
              TAG: "Error",
              _0: {
                path: ".context" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = date._0;
    return {
            TAG: "Error",
            _0: {
              path: ".date" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = hubId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".hubId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function parcel_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var status = ColiswebApi__V6_Parcel.Status.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
  if (status.TAG === "Ok") {
    var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
    if (barcode.TAG === "Ok") {
      var weight = Spice.optionFromJson(Decoders__UnitMeasure.Weight.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "weight"), null));
      if (weight.TAG === "Ok") {
        var length = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "length"), null));
        if (length.TAG === "Ok") {
          var width = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "width"), null));
          if (width.TAG === "Ok") {
            var height = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.WithUnit.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "height"), null));
            if (height.TAG === "Ok") {
              var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
              if (description.TAG === "Ok") {
                var productTypology = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "productTypology"), null));
                if (productTypology.TAG === "Ok") {
                  var packageType = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "packageType"), null));
                  if (packageType.TAG === "Ok") {
                    var lastEvent = Spice.optionFromJson(event_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lastEvent"), null));
                    if (lastEvent.TAG === "Ok") {
                      var directive = ColiswebApi__V6_Parcel.Directive.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "directive"), null));
                      if (directive.TAG === "Ok") {
                        return {
                                TAG: "Ok",
                                _0: {
                                  status: status._0,
                                  barcode: barcode._0,
                                  weight: weight._0,
                                  length: length._0,
                                  width: width._0,
                                  height: height._0,
                                  description: description._0,
                                  productTypology: productTypology._0,
                                  packageType: packageType._0,
                                  lastEvent: lastEvent._0,
                                  directive: directive._0
                                }
                              };
                      }
                      var e = directive._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".directive" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = lastEvent._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".lastEvent" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = packageType._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".packageType" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = productTypology._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".productTypology" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = description._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".description" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = height._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".height" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = width._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".width" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = length._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".length" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = weight._0;
      return {
              TAG: "Error",
              _0: {
                path: ".weight" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = barcode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".barcode" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = status._0;
  return {
          TAG: "Error",
          _0: {
            path: ".status" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function order_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.name)
                  ],
                  [
                    "city",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.country)
                  ],
                  [
                    "receivedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.receivedAt)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.postalCode)
                  ],
                  [
                    "ref1",
                    false,
                    Curry._1(Identifiers.DeliveryReference.t_encode, v.ref1)
                  ],
                  [
                    "state",
                    false,
                    t_encode(v.state)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.ref2)
                  ],
                  [
                    "unitCount",
                    false,
                    Spice.intToJson(v.unitCount)
                  ],
                  [
                    "units",
                    false,
                    Spice.arrayToJson(parcel_encode, v.units)
                  ],
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "expectedHubId",
                    false,
                    encoder(v.expectedHubId)
                  ]
                ]));
}

function order_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var city = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var country = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
      if (country.TAG === "Ok") {
        var receivedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "receivedAt"), null));
        if (receivedAt.TAG === "Ok") {
          var postalCode = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
          if (postalCode.TAG === "Ok") {
            var ref1 = Curry._1(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
            if (ref1.TAG === "Ok") {
              var state = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "state"), null));
              if (state.TAG === "Ok") {
                var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
                if (clientId.TAG === "Ok") {
                  var ref2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
                  if (ref2.TAG === "Ok") {
                    var unitCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "unitCount"), null));
                    if (unitCount.TAG === "Ok") {
                      var units = Spice.arrayFromJson(parcel_decode, Belt_Option.getWithDefault(Js_dict.get(v, "units"), null));
                      if (units.TAG === "Ok") {
                        var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
                        if (orderId.TAG === "Ok") {
                          var expectedHubId = t_decode$7(Belt_Option.getWithDefault(Js_dict.get(v, "expectedHubId"), null));
                          if (expectedHubId.TAG === "Ok") {
                            return {
                                    TAG: "Ok",
                                    _0: {
                                      name: name._0,
                                      city: city._0,
                                      country: country._0,
                                      receivedAt: receivedAt._0,
                                      postalCode: postalCode._0,
                                      ref1: ref1._0,
                                      state: state._0,
                                      clientId: clientId._0,
                                      ref2: ref2._0,
                                      unitCount: unitCount._0,
                                      units: units._0,
                                      orderId: orderId._0,
                                      expectedHubId: expectedHubId._0
                                    }
                                  };
                          }
                          var e = expectedHubId._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".expectedHubId" + e.path,
                                    message: e.message,
                                    value: e.value
                                  }
                                };
                        }
                        var e$1 = orderId._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".orderId" + e$1.path,
                                  message: e$1.message,
                                  value: e$1.value
                                }
                              };
                      }
                      var e$2 = units._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".units" + e$2.path,
                                message: e$2.message,
                                value: e$2.value
                              }
                            };
                    }
                    var e$3 = unitCount._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".unitCount" + e$3.path,
                              message: e$3.message,
                              value: e$3.value
                            }
                          };
                  }
                  var e$4 = ref2._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".ref2" + e$4.path,
                            message: e$4.message,
                            value: e$4.value
                          }
                        };
                }
                var e$5 = clientId._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".clientId" + e$5.path,
                          message: e$5.message,
                          value: e$5.value
                        }
                      };
              }
              var e$6 = state._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".state" + e$6.path,
                        message: e$6.message,
                        value: e$6.value
                      }
                    };
            }
            var e$7 = ref1._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".ref1" + e$7.path,
                      message: e$7.message,
                      value: e$7.value
                    }
                  };
          }
          var e$8 = postalCode._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".postalCode" + e$8.path,
                    message: e$8.message,
                    value: e$8.value
                  }
                };
        }
        var e$9 = receivedAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".receivedAt" + e$9.path,
                  message: e$9.message,
                  value: e$9.value
                }
              };
      }
      var e$10 = country._0;
      return {
              TAG: "Error",
              _0: {
                path: ".country" + e$10.path,
                message: e$10.message,
                value: e$10.value
              }
            };
    }
    var e$11 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$11.path,
              message: e$11.message,
              value: e$11.value
            }
          };
  }
  var e$12 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$12.path,
            message: e$12.message,
            value: e$12.value
          }
        };
}

var response_encode$16 = order_encode$7;

var response_decode$16 = order_decode$7;

function config$16(param) {
  var orderId = param[1];
  var hubId = param[0];
  return {
          kyInstance: kyInstance,
          path: "hubs/" + hubId + "/orders/" + orderId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "HubOrderDetails",
            hubId,
            orderId
          ],
          mapCustomErrors: (function (error) {
              var match = error.response;
              return $$Promise.resolved(match !== undefined && match.status === 404 ? ({
                              TAG: "Custom",
                              _0: "orderNotFound"
                            }) : ({
                              TAG: "Unknown",
                              _0: error
                            }));
            })
        };
}

var HubOrderDetails = {
  response_encode: response_encode$16,
  response_decode: response_decode$16,
  order_encode: order_encode$7,
  order_decode: order_decode$7,
  parcel_encode: parcel_encode,
  parcel_decode: parcel_decode,
  event_encode: event_encode,
  event_decode: event_decode,
  config: config$16
};

function body_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orderIds",
                    false,
                    Spice.arrayToJson(Identifiers.OrderId.t_encode, v.orderIds)
                  ]]));
}

function body_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderIds = Spice.arrayFromJson(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderIds"), null));
  if (orderIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orderIds: orderIds._0
            }
          };
  }
  var e = orderIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function order_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var ref1 = Curry._1(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
    if (ref1.TAG === "Ok") {
      var customerName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerName"), null));
      if (customerName.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  orderId: orderId._0,
                  ref1: ref1._0,
                  customerName: customerName._0
                }
              };
      }
      var e = customerName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".customerName" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = ref1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ref1" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function order_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "ref1",
                    false,
                    Curry._1(Identifiers.DeliveryReference.t_encode, v.ref1)
                  ],
                  [
                    "customerName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.customerName)
                  ]
                ]));
}

function response_encode$17(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orders",
                    false,
                    Spice.arrayToJson(order_encode$8, v.orders)
                  ]]));
}

function response_decode$17(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orders = Spice.arrayFromJson(order_decode$8, Belt_Option.getWithDefault(Js_dict.get(v, "orders"), null));
  if (orders.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orders: orders._0
            }
          };
  }
  var e = orders._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orders" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$17(param) {
  return {
          kyInstance: kyInstance,
          path: "hubs/" + param[0] + "/orders",
          requestOptions: {
            method: "POST",
            json: body_encode$6({
                  orderIds: param[1]
                })
          },
          key: []
        };
}

var HubOrdersBasicDetails = {
  body_encode: body_encode$6,
  body_decode: body_decode$6,
  response_encode: response_encode$17,
  response_decode: response_decode$17,
  order_encode: order_encode$8,
  order_decode: order_decode$8,
  config: config$17
};

function encoder$5(pickupType) {
  var variant = pickupType.NAME;
  if (variant === "store") {
    return {
            type: "store",
            code: pickupType.VAL.code
          };
  }
  if (variant !== "address") {
    return {
            type: "hub",
            code: pickupType.VAL.code
          };
  }
  var match = pickupType.VAL;
  return {
          type: "address",
          address: {
            line1: match.line1,
            postalCode: match.postalCode,
            city: match.city,
            country: match.country
          },
          contact: match.contact
        };
}

function decoder$5(_json) {
  Js_exn.raiseError("PickupType decoder not handled");
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var codec$5 = [
  encoder$5,
  decoder$5
];

var t_encode$22 = encoder$5;

var t_decode$22 = decoder$5;

var PickupType$1 = {
  encoder: encoder$5,
  decoder: decoder$5,
  codec: codec$5,
  t_encode: t_encode$22,
  t_decode: t_decode$22
};

function body_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "slot",
                    false,
                    Toolkit__Decoders.DatetimeTimeSlot.t_encode(v.slot)
                  ],
                  [
                    "storeIdOwner",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeIdOwner)
                  ],
                  [
                    "pickup",
                    false,
                    encoder$5(v.pickup)
                  ],
                  [
                    "barcodes",
                    false,
                    Spice.arrayToJson(Identifiers.Barcode.t_encode, v.barcodes)
                  ]
                ]));
}

function body_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var slot = Toolkit__Decoders.DatetimeTimeSlot.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "slot"), null));
  if (slot.TAG === "Ok") {
    var storeIdOwner = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeIdOwner"), null));
    if (storeIdOwner.TAG === "Ok") {
      var pickup = t_decode$22(Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
      if (pickup.TAG === "Ok") {
        var barcodes = Spice.arrayFromJson(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcodes"), null));
        if (barcodes.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    slot: slot._0,
                    storeIdOwner: storeIdOwner._0,
                    pickup: pickup._0,
                    barcodes: barcodes._0
                  }
                };
        }
        var e = barcodes._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".barcodes" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = pickup._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pickup" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = storeIdOwner._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeIdOwner" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = slot._0;
  return {
          TAG: "Error",
          _0: {
            path: ".slot" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function price_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var value = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "value"), null));
  if (value.TAG === "Ok") {
    var ruleId = Curry._1(Identifiers.ClientRuleId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ruleId"), null));
    if (ruleId.TAG === "Ok") {
      var ruleTitle = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ruleTitle"), null));
      if (ruleTitle.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  value: value._0,
                  ruleId: ruleId._0,
                  ruleTitle: ruleTitle._0
                }
              };
      }
      var e = ruleTitle._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ruleTitle" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = ruleId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".ruleId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = value._0;
  return {
          TAG: "Error",
          _0: {
            path: ".value" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function price_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "value",
                    false,
                    Spice.floatToJson(v.value)
                  ],
                  [
                    "ruleId",
                    false,
                    Curry._1(Identifiers.ClientRuleId.t_encode, v.ruleId)
                  ],
                  [
                    "ruleTitle",
                    false,
                    Spice.stringToJson(v.ruleTitle)
                  ]
                ]));
}

function defaultResponse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "price",
                    false,
                    price_encode(v.price)
                  ]]));
}

function defaultResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var price = price_decode(Belt_Option.getWithDefault(Js_dict.get(v, "price"), null));
  if (price.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              price: price._0
            }
          };
  }
  var e = price._0;
  return {
          TAG: "Error",
          _0: {
            path: ".price" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$18 = price_encode;

var response_decode$18 = price_decode;

function errorBody_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "code",
                    false,
                    Spice.stringToJson(v.code)
                  ]]));
}

function errorBody_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$18(param) {
  return {
          kyInstance: kyInstance,
          path: "warehouse/orders/" + param[0] + "/deliverability",
          requestOptions: {
            method: "POST",
            json: body_encode$7(param[1])
          },
          key: [],
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined && res.status === 404) {
                return $$Promise.map($$Request.decodeResponseError(res, errorBody_decode), (function (decodedError) {
                              if (decodedError.TAG !== "Ok") {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                              switch (decodedError._0.code) {
                                case "GRID_NOT_FOUND" :
                                    return {
                                            TAG: "Custom",
                                            _0: "gridNotFound"
                                          };
                                case "PRICE_NOT_FOUND" :
                                    return {
                                            TAG: "Custom",
                                            _0: "priceNotFound"
                                          };
                                default:
                                  return {
                                          TAG: "Unknown",
                                          _0: error
                                        };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            }),
          mapRawResponse: (function (response) {
              var err = $$Request.decodeRawResponse(response, defaultResponse_decode);
              var tmp;
              tmp = err.TAG === "Ok" ? ({
                    TAG: "Ok",
                    _0: err._0.price
                  }) : err;
              return $$Promise.resolved(tmp);
            })
        };
}

var GetDeliverability = {
  PickupType: PickupType$1,
  body_encode: body_encode$7,
  body_decode: body_decode$7,
  defaultResponse_encode: defaultResponse_encode,
  defaultResponse_decode: defaultResponse_decode,
  price_encode: price_encode,
  price_decode: price_decode,
  response_encode: response_encode$18,
  response_decode: response_decode$18,
  errorBody_encode: errorBody_encode,
  errorBody_decode: errorBody_decode,
  config: config$18
};

function encoder$6(pickupType) {
  if (pickupType.NAME === "manual") {
    return {
            origin: "manual",
            amount: pickupType.VAL
          };
  } else {
    return {
            origin: "auto",
            amount: pickupType.VAL
          };
  }
}

function decoder$6(_json) {
  Js_exn.raiseError("priceOrigin decoder not handled");
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var codec$6 = [
  encoder$6,
  decoder$6
];

var t_encode$23 = encoder$6;

var t_decode$23 = decoder$6;

var PriceOrigin = {
  encoder: encoder$6,
  decoder: decoder$6,
  codec: codec$6,
  t_encode: t_encode$23,
  t_decode: t_decode$23
};

function price_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "price",
                    false,
                    encoder$6(v.price)
                  ],
                  [
                    "allowCustomerSlotUpdate",
                    false,
                    Spice.boolToJson(v.allowCustomerSlotUpdate)
                  ]
                ]));
}

function price_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var price = t_decode$23(Belt_Option.getWithDefault(Js_dict.get(v, "price"), null));
  if (price.TAG === "Ok") {
    var allowCustomerSlotUpdate = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "allowCustomerSlotUpdate"), null));
    if (allowCustomerSlotUpdate.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                price: price._0,
                allowCustomerSlotUpdate: allowCustomerSlotUpdate._0
              }
            };
    }
    var e = allowCustomerSlotUpdate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".allowCustomerSlotUpdate" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = price._0;
  return {
          TAG: "Error",
          _0: {
            path: ".price" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$19(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ],
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.DeliveryUuid.t_encode, v.uuid)
                  ]
                ]));
}

function response_decode$19(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var uuid = Curry._1(Identifiers.DeliveryUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
    if (uuid.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                uuid: uuid._0
              }
            };
    }
    var e = uuid._0;
    return {
            TAG: "Error",
            _0: {
              path: ".uuid" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function config$19(param) {
  var data = Object.assign(body_encode$7(param[1]), price_encode$1(param[2]));
  return {
          kyInstance: kyInstance,
          path: "warehouse/orders/" + param[0] + "/deliveries",
          requestOptions: {
            method: "POST",
            json: data
          },
          key: []
        };
}

var CreateDelivery = {
  PriceOrigin: PriceOrigin,
  price_encode: price_encode$1,
  price_decode: price_decode$1,
  response_encode: response_encode$19,
  response_decode: response_decode$19,
  config: config$19
};

function response_encode$20(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "allErrors",
                    false,
                    Spice.intToJson(v.allErrors)
                  ],
                  [
                    "errorsFromStores",
                    false,
                    Spice.intToJson(v.errorsFromStores)
                  ],
                  [
                    "errorsFromHubs",
                    false,
                    Spice.intToJson(v.errorsFromHubs)
                  ]
                ]));
}

function response_decode$20(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var allErrors = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "allErrors"), null));
  if (allErrors.TAG === "Ok") {
    var errorsFromStores = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "errorsFromStores"), null));
    if (errorsFromStores.TAG === "Ok") {
      var errorsFromHubs = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "errorsFromHubs"), null));
      if (errorsFromHubs.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  allErrors: allErrors._0,
                  errorsFromStores: errorsFromStores._0,
                  errorsFromHubs: errorsFromHubs._0
                }
              };
      }
      var e = errorsFromHubs._0;
      return {
              TAG: "Error",
              _0: {
                path: ".errorsFromHubs" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = errorsFromStores._0;
    return {
            TAG: "Error",
            _0: {
              path: ".errorsFromStores" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = allErrors._0;
  return {
          TAG: "Error",
          _0: {
            path: ".allErrors" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function config$20(param) {
  return {
          kyInstance: kyInstance,
          path: "orders/integrations/errorCounts",
          requestOptions: {
            method: "GET"
          },
          key: ["GetOrdersErrorCounts"]
        };
}

var GetOrdersErrorCounts = {
  response_encode: response_encode$20,
  response_decode: response_decode$20,
  config: config$20
};

var response_encode$21 = Spice.unitToJson;

var response_decode$21 = Spice.unitFromJson;

function config$21(orderId) {
  return {
          kyInstance: kyInstance,
          path: "/orders/" + orderId + "/integrations/force",
          requestOptions: {
            method: "POST"
          }
        };
}

var Force = {
  response_encode: response_encode$21,
  response_decode: response_decode$21,
  config: config$21
};

var response_encode$22 = Spice.unitToJson;

var response_decode$22 = Spice.unitFromJson;

function config$22(orderId) {
  return {
          kyInstance: kyInstance,
          path: "orders/" + orderId + "/integrations/block",
          requestOptions: {
            method: "POST"
          },
          key: []
        };
}

var Block = {
  response_encode: response_encode$22,
  response_decode: response_decode$22,
  config: config$22
};

var Integrations$1 = {
  Force: Force,
  Block: Block
};

export {
  baseUrl ,
  Shared ,
  List ,
  Orders ,
  ListForClients ,
  IntegrationAction ,
  Warehouse ,
  Client ,
  Store ,
  AdminUpdateAddress ,
  Jobs ,
  OrderBasicDetails ,
  ExpectedOrders ,
  GetCitiesAutocomplete ,
  GetHubOrderByRef1 ,
  GetHubOrdersRecipients ,
  GetHubOrderByNameAndCity ,
  HubOrderDetails ,
  HubOrdersBasicDetails ,
  GetDeliverability ,
  CreateDelivery ,
  GetOrdersErrorCounts ,
  Integrations$1 as Integrations,
}
/* kyInstance Not a pure module */
