

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../node_modules/rescript/lib/es6/js_dict.js";
import * as $$Promise from "../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Toolkit__Decoders from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";

function t_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end)
                  ]
                ]));
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end: end_._0
              }
            };
    }
    var e = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function isEqual(a, b) {
  if (a.start.toString() === b.start.toString()) {
    return a.end.toString() === b.end.toString();
  } else {
    return false;
  }
}

var DatetimeTimeSlot = {
  t_encode: t_encode,
  t_decode: t_decode,
  isEqual: isEqual
};

function t_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.end)
                  ]
                ]));
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end: end_._0
              }
            };
    }
    var e = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function isEqual$1(a, b) {
  if (a.start.toString() === b.start.toString()) {
    return a.end.toString() === b.end.toString();
  } else {
    return false;
  }
}

var DateTimeSlot = {
  t_encode: t_encode$1,
  t_decode: t_decode$1,
  isEqual: isEqual$1
};

function t_encode$2(v) {
  switch (v) {
    case "no_package" :
        return "no_package";
    case "too_much_packages" :
        return "too_much_packages";
    case "too_many_packages" :
        return "too_many_packages";
    case "missing_packages" :
        return "missing_packages";
    case "too_heavy" :
        return "too_heavy";
    case "wrong_dimensions" :
        return "wrong_dimensions";
    case "pickup_point_closed" :
        return "pickup_point_closed";
    case "absent_customer" :
        return "absent_customer";
    case "unreachable_customer" :
        return "unreachable_customer";
    case "wrong_address" :
        return "wrong_address";
    case "delivery_rejection" :
        return "delivery_rejection";
    case "wrong_package" :
        return "wrong_package";
    case "package_informations_error" :
        return "package_informations_error";
    case "unavailable_command" :
        return "unavailable_command";
    case "delay" :
        return "delay";
    case "unavailable_recipient" :
        return "unavailable_recipient";
    case "customer_refusal" :
        return "customer_refusal";
    case "other" :
        return "other";
    case "inadapated_vehicle" :
        return "inadapated_vehicle";
    case "handling_not_possible" :
        return "handling_not_possible";
    case "unavailable_order" :
        return "unavailable_order";
    case "waiting" :
        return "waiting";
    case "closed_pickup_point" :
        return "closed_pickup_point";
    case "packaging_missing" :
        return "packaging_missing";
    case "missing_packets" :
        return "missing_packets";
    case "wrong_pickup_address" :
        return "wrong_pickup_address";
    case "insufficient_packaging" :
        return "insufficient_packaging";
    case "non_available_recipient" :
        return "non_available_recipient";
    case "damaged_packages" :
        return "damaged_packages";
    case "non_compliant_packages" :
        return "non_compliant_packages";
    case "other_prestation_asked" :
        return "other_prestation_asked";
    case "delivery_impossible" :
        return "delivery_impossible";
    case "wrong_shipping_address" :
        return "wrong_shipping_address";
    case "recovery_refusal" :
        return "recovery_refusal";
    
  }
}

function t_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("no_package" === v) {
          return {
                  TAG: "Ok",
                  _0: "no_package"
                };
        } else if ("too_much_packages" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_much_packages"
                };
        } else if ("too_many_packages" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_many_packages"
                };
        } else if ("missing_packages" === v) {
          return {
                  TAG: "Ok",
                  _0: "missing_packages"
                };
        } else if ("too_heavy" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy"
                };
        } else if ("wrong_dimensions" === v) {
          return {
                  TAG: "Ok",
                  _0: "wrong_dimensions"
                };
        } else if ("pickup_point_closed" === v) {
          return {
                  TAG: "Ok",
                  _0: "pickup_point_closed"
                };
        } else if ("absent_customer" === v) {
          return {
                  TAG: "Ok",
                  _0: "absent_customer"
                };
        } else if ("unreachable_customer" === v) {
          return {
                  TAG: "Ok",
                  _0: "unreachable_customer"
                };
        } else if ("wrong_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "wrong_address"
                };
        } else if ("delivery_rejection" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_rejection"
                };
        } else if ("wrong_package" === v) {
          return {
                  TAG: "Ok",
                  _0: "wrong_package"
                };
        } else if ("package_informations_error" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_informations_error"
                };
        } else if ("unavailable_command" === v) {
          return {
                  TAG: "Ok",
                  _0: "unavailable_command"
                };
        } else if ("delay" === v) {
          return {
                  TAG: "Ok",
                  _0: "delay"
                };
        } else if ("unavailable_recipient" === v) {
          return {
                  TAG: "Ok",
                  _0: "unavailable_recipient"
                };
        } else if ("customer_refusal" === v) {
          return {
                  TAG: "Ok",
                  _0: "customer_refusal"
                };
        } else if ("other" === v) {
          return {
                  TAG: "Ok",
                  _0: "other"
                };
        } else if ("inadapated_vehicle" === v) {
          return {
                  TAG: "Ok",
                  _0: "inadapated_vehicle"
                };
        } else if ("handling_not_possible" === v) {
          return {
                  TAG: "Ok",
                  _0: "handling_not_possible"
                };
        } else if ("unavailable_order" === v) {
          return {
                  TAG: "Ok",
                  _0: "unavailable_order"
                };
        } else if ("waiting" === v) {
          return {
                  TAG: "Ok",
                  _0: "waiting"
                };
        } else if ("closed_pickup_point" === v) {
          return {
                  TAG: "Ok",
                  _0: "closed_pickup_point"
                };
        } else if ("packaging_missing" === v) {
          return {
                  TAG: "Ok",
                  _0: "packaging_missing"
                };
        } else if ("missing_packets" === v) {
          return {
                  TAG: "Ok",
                  _0: "missing_packets"
                };
        } else if ("wrong_pickup_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "wrong_pickup_address"
                };
        } else if ("insufficient_packaging" === v) {
          return {
                  TAG: "Ok",
                  _0: "insufficient_packaging"
                };
        } else if ("non_available_recipient" === v) {
          return {
                  TAG: "Ok",
                  _0: "non_available_recipient"
                };
        } else if ("damaged_packages" === v) {
          return {
                  TAG: "Ok",
                  _0: "damaged_packages"
                };
        } else if ("non_compliant_packages" === v) {
          return {
                  TAG: "Ok",
                  _0: "non_compliant_packages"
                };
        } else if ("other_prestation_asked" === v) {
          return {
                  TAG: "Ok",
                  _0: "other_prestation_asked"
                };
        } else if ("delivery_impossible" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_impossible"
                };
        } else if ("wrong_shipping_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "wrong_shipping_address"
                };
        } else if ("recovery_refusal" === v) {
          return {
                  TAG: "Ok",
                  _0: "recovery_refusal"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var TransitionProblem = {
  t_encode: t_encode$2,
  t_decode: t_decode$2
};

var DeliveryOption = {};

function t_encode$3(v) {
  if (v === "asc") {
    return "asc";
  } else {
    return "desc";
  }
}

function t_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("asc" === v) {
          return {
                  TAG: "Ok",
                  _0: "asc"
                };
        } else if ("desc" === v) {
          return {
                  TAG: "Ok",
                  _0: "desc"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var SortOrder = {
  t_encode: t_encode$3,
  t_decode: t_decode$3
};

function t_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function t_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Pagination = {
  t_encode: t_encode$4,
  t_decode: t_decode$4
};

function decodeError(decoder, error) {
  var res = error.response;
  if (res !== undefined) {
    return $$Promise.map($$Request.decodeResponseError(res, decoder), (function (res) {
                  if (res.TAG === "Ok") {
                    return {
                            TAG: "Custom",
                            _0: res._0
                          };
                  } else {
                    return {
                            TAG: "Unknown",
                            _0: error
                          };
                  }
                }));
  } else {
    return $$Promise.resolved({
                TAG: "Unknown",
                _0: error
              });
  }
}

function errorPayload_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "code",
                    false,
                    Spice.stringToJson(v.code)
                  ]]));
}

function errorPayload_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function handleError(decoder, error) {
  var res = error.response;
  if (res !== undefined) {
    return $$Promise.map($$Request.decodeResponseError(res, errorPayload_decode), (function (res) {
                  if (res.TAG !== "Ok") {
                    return {
                            TAG: "Unknown",
                            _0: error
                          };
                  }
                  var v = Curry._1(decoder, res._0.code);
                  if (v.TAG === "Ok") {
                    return {
                            TAG: "Custom",
                            _0: v._0
                          };
                  } else {
                    return {
                            TAG: "Unknown",
                            _0: error
                          };
                  }
                }));
  } else {
    return $$Promise.resolved({
                TAG: "Unknown",
                _0: error
              });
  }
}

function v5ErrorPayload_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "type_",
                    false,
                    Spice.stringToJson(v.type)
                  ]]));
}

function v5ErrorPayload_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "type_"), null));
  if (type_.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              type: type_._0
            }
          };
  }
  var e = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: ".type_" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function handleV5Error(decoder, error) {
  var res = error.response;
  if (res !== undefined) {
    return $$Promise.map($$Request.decodeResponseError(res, v5ErrorPayload_decode), (function (res) {
                  if (res.TAG !== "Ok") {
                    return {
                            TAG: "Unknown",
                            _0: error
                          };
                  }
                  var v = Curry._1(decoder, res._0.type);
                  if (v.TAG === "Ok") {
                    return {
                            TAG: "Custom",
                            _0: v._0
                          };
                  } else {
                    return {
                            TAG: "Unknown",
                            _0: error
                          };
                  }
                }));
  } else {
    return $$Promise.resolved({
                TAG: "Unknown",
                _0: error
              });
  }
}

export {
  DatetimeTimeSlot ,
  DateTimeSlot ,
  TransitionProblem ,
  DeliveryOption ,
  SortOrder ,
  Pagination ,
  decodeError ,
  errorPayload_encode ,
  errorPayload_decode ,
  handleError ,
  v5ErrorPayload_encode ,
  v5ErrorPayload_decode ,
  handleV5Error ,
}
/* Promise Not a pure module */
