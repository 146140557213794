

import * as Cx from "../../../../node_modules/rescript-classnames/lib/es6_global/src/Cx.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_exn from "../../../../node_modules/rescript/lib/es6/js_exn.js";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Belt_SortArray from "../../../../node_modules/rescript/lib/es6/belt_SortArray.js";
import * as Toolkit__Utils from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/utils/Toolkit__Utils.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Select from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Select.bs.js";
import * as Toolkit__Ui_Listbox from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Listbox.bs.js";
import * as Toolkit__Ui_Spinner from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Spinner.bs.js";
import * as ColiswebApi__Locales from "../ColiswebApi__Locales.bs.js";
import * as ColiswebApi__V5_Utils from "../v5/ColiswebApi__V5_Utils.bs.js";
import * as ColiswebApi__V6_Order from "../v6/ColiswebApi__V6_Order.bs.js";
import * as Toolkit__Ui_TextInput from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_TextInput.bs.js";
import * as ColiswebApi__V6_Client from "../v6/ColiswebApi__V6_Client.bs.js";
import * as ColiswebApi__V6_Config from "../v6/ColiswebApi__V6_Config.bs.js";
import * as ColiswebApi__V6_Parcel from "../v6/ColiswebApi__V6_Parcel.bs.js";
import * as Toolkit__Ui_MultiSelect from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_MultiSelect.bs.js";
import * as ColiswebApi__V6_Provider from "../v6/ColiswebApi__V6_Provider.bs.js";
import * as Toolkit__Primitives_Array from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";
import * as Toolkit__Ui_ErrorBoundary from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_ErrorBoundary.bs.js";
import * as Toolkit__Primitives_Result from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Result.bs.js";
import * as ColiswebApi__V6_OnlinePayment from "../v6/ColiswebApi__V6_OnlinePayment.bs.js";
import * as Toolkit__Ui_MultiSelectWithValidation from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_MultiSelectWithValidation.bs.js";

var filter = {
  id: "_9a407e28",
  defaultMessage: "Filtrer"
};

var Msg = {
  filter: filter
};

function ApiComponents__TableFilters$TextFilter(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_TextInput.make, {
                    id: props.id,
                    value: Belt_Option.getWithDefault(props.defaultValue, ""),
                    placeholder: intl.formatMessage(filter),
                    onChange: (function ($$event) {
                        var value = $$event.target.value;
                        if (value === "") {
                          return Curry._1(setFilter, undefined);
                        } else {
                          return Curry._1(setFilter, value);
                        }
                      }),
                    allowWhiteSpace: true
                  })
            });
}

var TextFilter = {
  Msg: Msg,
  make: ApiComponents__TableFilters$TextFilter
};

var filterHub = {
  id: "_3a8a7a8a",
  defaultMessage: "Rechercher"
};

var Msg$1 = {
  filterHub: filterHub
};

function ApiComponents__TableFilters$HubListSelect$SelectList(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var $$let = ColiswebApi__V6_Provider.Hubs.GetAllHubsNames;
  var match = $$Request.useFetcher(undefined, {
        response_decode: $$let.response_decode,
        config: $$let.config
      }, {
        withDeleted: props.withDeleted
      });
  var hubs = match[0];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, Belt_SortArray.stableSortBy(hubs, (function (v1, param) {
                                if (Belt_Option.isSome(v1.deletedAt)) {
                                  return 1;
                                } else {
                                  return 0;
                                }
                              })), (function (param) {
                            var name = param.name;
                            return {
                                    itemLabel: Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                name,
                                                Belt_Option.isSome(param.deletedAt) ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                        id: "_4ec3c2e2",
                                                        defaultMessage: " (Supprimé)"
                                                      }) : null
                                              ]
                                            })),
                                    label: name,
                                    value: param.hubId
                                  };
                          })),
                    placeholder: JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_2f765479",
                                defaultMessage: "Choisir"
                              }),
                          className: "font-normal text-gray-400"
                        }),
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    searchPlaceholder: intl.formatMessage(filterHub),
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (selectedHubIds) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, hubs, (function (param) {
                                          var hubId = param.hubId;
                                          var name = param.name;
                                          if (Curry._2(Toolkit__Primitives_Array.$$Array.some, selectedHubIds, (function (selectedHubId) {
                                                    return selectedHubId === hubId;
                                                  }))) {
                                            return {
                                                    itemLabel: Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                              children: [
                                                                name,
                                                                Belt_Option.isSome(param.deletedAt) ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                        id: "_4ec3c2e2",
                                                                        defaultMessage: " (Supprimé)"
                                                                      }) : null
                                                              ]
                                                            })),
                                                    label: name,
                                                    value: hubId
                                                  };
                                          }
                                          
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var SelectList = {
  make: ApiComponents__TableFilters$HubListSelect$SelectList
};

function ApiComponents__TableFilters$HubListSelect(props) {
  var __withDeleted = props.withDeleted;
  var withDeleted = __withDeleted !== undefined ? __withDeleted : true;
  return JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
              fallbackRender: (function (param) {
                  return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                              id: "_e1422919",
                              defaultMessage: "Impossible de charger les hubs."
                            });
                }),
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(ApiComponents__TableFilters$HubListSelect$SelectList, {
                              setFilter: props.setFilter,
                              defaultValue: props.defaultValue,
                              withDeleted: withDeleted
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Toolkit__Ui_Spinner.make, {}))
                  })
            });
}

var HubListSelect = {
  Msg: Msg$1,
  SelectList: SelectList,
  make: ApiComponents__TableFilters$HubListSelect
};

var select = {
  id: "_930bcc71",
  defaultMessage: "Selectionner"
};

var Msg$2 = {
  select: select
};

function ApiComponents__TableFilters$RouteProjectSelect$SelectList$Component(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var $$let = ColiswebApi__V6_Config.RouteProjects.GetAll;
  var match = $$Request.useFetcher(undefined, {
        response_decode: $$let.response_decode,
        config: $$let.config
      }, Caml_option.some(undefined));
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_Select.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, match[0], (function (param) {
                            return {
                                    label: param.name,
                                    value: param.id
                                  };
                          })),
                    onChange: Curry.__1(setFilter),
                    placeholder: intl.formatMessage(select) + "...",
                    defaultValue: props.defaultValue,
                    className: "w-full"
                  }),
              className: "w-full"
            });
}

var Component = {
  make: ApiComponents__TableFilters$RouteProjectSelect$SelectList$Component
};

function ApiComponents__TableFilters$RouteProjectSelect$SelectList(props) {
  return JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
              fallbackRender: (function (param) {
                  return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                              id: "_11adc695",
                              defaultMessage: "Impossible de charger les projets."
                            });
                }),
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(ApiComponents__TableFilters$RouteProjectSelect$SelectList$Component, {
                              setFilter: props.setFilter,
                              defaultValue: props.defaultValue
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Toolkit__Ui_Spinner.make, {}))
                  })
            });
}

var SelectList$1 = {
  Component: Component,
  make: ApiComponents__TableFilters$RouteProjectSelect$SelectList
};

function ApiComponents__TableFilters$RouteProjectSelect$MultiSelectList$Component(props) {
  var setFilter = props.setFilter;
  var $$let = ColiswebApi__V6_Config.RouteProjects.GetAll;
  var match = $$Request.useFetcher(undefined, {
        response_decode: $$let.response_decode,
        config: $$let.config
      }, Caml_option.some(undefined));
  var projects = match[0];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, projects, (function (param) {
                            var name = param.name;
                            return {
                                    itemLabel: Caml_option.some(name),
                                    label: name,
                                    value: param.id
                                  };
                          })),
                    placeholder: JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_2f765479",
                                defaultMessage: "Choisir"
                              }),
                          className: "font-normal text-gray-400"
                        }),
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (selectedProjectsIds) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, projects, (function (param) {
                                          var name = param.name;
                                          var id = param.id;
                                          if (Curry._2(Toolkit__Primitives_Array.$$Array.some, selectedProjectsIds, (function (selectedProjectsId) {
                                                    return selectedProjectsId === id;
                                                  }))) {
                                            return {
                                                    itemLabel: Caml_option.some(name),
                                                    label: name,
                                                    value: id
                                                  };
                                          }
                                          
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var Component$1 = {
  make: ApiComponents__TableFilters$RouteProjectSelect$MultiSelectList$Component
};

function ApiComponents__TableFilters$RouteProjectSelect$MultiSelectList(props) {
  return JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
              fallbackRender: (function (param) {
                  return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                              id: "_11adc695",
                              defaultMessage: "Impossible de charger les projets."
                            });
                }),
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(ApiComponents__TableFilters$RouteProjectSelect$MultiSelectList$Component, {
                              setFilter: props.setFilter,
                              defaultValue: props.defaultValue
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Toolkit__Ui_Spinner.make, {}))
                  })
            });
}

var MultiSelectList = {
  Component: Component$1,
  make: ApiComponents__TableFilters$RouteProjectSelect$MultiSelectList
};

var RouteProjectSelect = {
  Msg: Msg$2,
  SelectList: SelectList$1,
  MultiSelectList: MultiSelectList
};

var filterClient = {
  id: "_3a8a7a8a",
  defaultMessage: "Rechercher"
};

var Msg$3 = {
  filterClient: filterClient
};

function ApiComponents__TableFilters$ClientListSelect$SelectList(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var $$let = ColiswebApi__V6_Client.Clients.GetAllNames;
  var match = $$Request.useFetcher({
        keepPreviousData: true,
        revalidateOnFocus: false,
        revalidateOnMount: true
      }, {
        response_decode: $$let.response_decode,
        config: $$let.config
      }, Caml_option.some(undefined));
  var clients = match[0];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, clients.clients, (function (param) {
                            var name = param.name;
                            return {
                                    itemLabel: Caml_option.some(name),
                                    label: name,
                                    value: param.id
                                  };
                          })),
                    placeholder: JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_2f765479",
                                defaultMessage: "Choisir"
                              }),
                          className: "font-normal text-gray-400"
                        }),
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    searchPlaceholder: intl.formatMessage(filterClient),
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (selectedClientIds) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, clients.clients, (function (param) {
                                          var name = param.name;
                                          var id = param.id;
                                          if (Curry._2(Toolkit__Primitives_Array.$$Array.some, selectedClientIds, (function (selectedClientId) {
                                                    return selectedClientId === id;
                                                  }))) {
                                            return {
                                                    itemLabel: Caml_option.some(name),
                                                    label: name,
                                                    value: id
                                                  };
                                          }
                                          
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var SelectList$2 = {
  make: ApiComponents__TableFilters$ClientListSelect$SelectList
};

function ApiComponents__TableFilters$ClientListSelect(props) {
  return JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
              fallbackRender: (function (param) {
                  return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                              id: "_bf99095e",
                              defaultMessage: "Impossible de charger les clients."
                            });
                }),
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(ApiComponents__TableFilters$ClientListSelect$SelectList, {
                              setFilter: props.setFilter,
                              defaultValue: props.defaultValue
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Toolkit__Ui_Spinner.make, {}))
                  })
            });
}

var ClientListSelect = {
  Msg: Msg$3,
  SelectList: SelectList$2,
  make: ApiComponents__TableFilters$ClientListSelect
};

var filterState = {
  id: "_3a8a7a8a",
  defaultMessage: "Rechercher"
};

var Msg$4 = {
  filterState: filterState
};

function ApiComponents__TableFilters$ParcelState(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var states = [
    "missing",
    "received"
  ];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, states, (function (state) {
                            return {
                                    itemLabel: Caml_option.some(ColiswebApi__Locales.ParcelStatus.humanize(intl, state)),
                                    label: ColiswebApi__Locales.ParcelStatus.humanize(intl, state),
                                    value: ColiswebApi__V6_Parcel.Status.toString(state)
                                  };
                          })),
                    placeholder: JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_72df8435",
                                defaultMessage: "État de l'UM"
                              }),
                          className: "font-normal text-gray-400"
                        }),
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    searchPlaceholder: intl.formatMessage(filterState),
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (selectedStates) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, states, (function (state) {
                                          if (Curry._2(Toolkit__Primitives_Array.$$Array.some, selectedStates, (function (s) {
                                                    return s === ColiswebApi__V6_Parcel.Status.toString(state);
                                                  }))) {
                                            return {
                                                    itemLabel: Caml_option.some(ColiswebApi__Locales.ParcelStatus.humanize(intl, state)),
                                                    label: ColiswebApi__Locales.ParcelStatus.humanize(intl, state),
                                                    value: ColiswebApi__V6_Parcel.Status.toString(state)
                                                  };
                                          }
                                          
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var ParcelState = {
  Msg: Msg$4,
  make: ApiComponents__TableFilters$ParcelState
};

var filterState$1 = {
  id: "_3a8a7a8a",
  defaultMessage: "Rechercher"
};

var Msg$5 = {
  filterState: filterState$1
};

function ApiComponents__TableFilters$OrderState(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var states = [
    "allMissing",
    "complete",
    "incomplete",
    "waitingForUnits"
  ];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, states, (function (state) {
                            return {
                                    itemLabel: Caml_option.some(ColiswebApi__Locales.OrderState.humanize(intl, state)),
                                    label: ColiswebApi__Locales.OrderState.humanize(intl, state),
                                    value: Curry._1(ColiswebApi__V6_Order.Shared.State.toString, state)
                                  };
                          })),
                    placeholder: JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_2f765479",
                                defaultMessage: "Choisir"
                              }),
                          className: "font-normal text-gray-400"
                        }),
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    searchPlaceholder: intl.formatMessage(filterState$1),
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (selectedStates) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, states, (function (state) {
                                          if (Curry._2(Toolkit__Primitives_Array.$$Array.some, selectedStates, (function (s) {
                                                    return s === Curry._1(ColiswebApi__V6_Order.Shared.State.toString, state);
                                                  }))) {
                                            return {
                                                    itemLabel: Caml_option.some(ColiswebApi__Locales.OrderState.humanize(intl, state)),
                                                    label: ColiswebApi__Locales.OrderState.humanize(intl, state),
                                                    value: Curry._1(ColiswebApi__V6_Order.Shared.State.toString, state)
                                                  };
                                          }
                                          
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var OrderState = {
  Msg: Msg$5,
  make: ApiComponents__TableFilters$OrderState
};

var filterState$2 = {
  id: "_3a8a7a8a",
  defaultMessage: "Rechercher"
};

var Msg$6 = {
  filterState: filterState$2
};

function ApiComponents__TableFilters$OrderGlobalState(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var states = [
    "received",
    "planned",
    "canceled"
  ];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, states, (function (state) {
                            return {
                                    itemLabel: Caml_option.some(ColiswebApi__Locales.OrderGlobalState.humanize(intl, state)),
                                    label: ColiswebApi__Locales.OrderGlobalState.humanize(intl, state),
                                    value: Curry._1(ColiswebApi__V6_Order.Shared.GlobalState.toString, state)
                                  };
                          })),
                    placeholder: JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_2f765479",
                                defaultMessage: "Choisir"
                              }),
                          className: "font-normal text-gray-400"
                        }),
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    searchPlaceholder: intl.formatMessage(filterState$2),
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (selectedStates) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, states, (function (state) {
                                          if (Curry._2(Toolkit__Primitives_Array.$$Array.some, selectedStates, (function (s) {
                                                    return s === Curry._1(ColiswebApi__V6_Order.Shared.GlobalState.toString, state);
                                                  }))) {
                                            return {
                                                    itemLabel: Caml_option.some(ColiswebApi__Locales.OrderGlobalState.humanize(intl, state)),
                                                    label: ColiswebApi__Locales.OrderGlobalState.humanize(intl, state),
                                                    value: Curry._1(ColiswebApi__V6_Order.Shared.GlobalState.toString, state)
                                                  };
                                          }
                                          
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var OrderGlobalState = {
  Msg: Msg$6,
  make: ApiComponents__TableFilters$OrderGlobalState
};

var select$1 = {
  id: "_930bcc71",
  defaultMessage: "Selectionner"
};

var Msg$7 = {
  select: select$1
};

function ApiComponents__TableFilters$OrderJobStatus(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var statuses = [
    "created",
    "onGoing",
    "succeeded",
    "failed"
  ];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_Select.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, statuses, (function (variant) {
                            return {
                                    label: ColiswebApi__Locales.JobStatus.humanize(intl, variant),
                                    value: Curry._1(ColiswebApi__V6_Order.Jobs.JobStatus.toString, variant)
                                  };
                          })),
                    onChange: (function (value) {
                        Curry._1(setFilter, Curry._1(ColiswebApi__V6_Order.Jobs.JobStatus.fromString, value));
                      }),
                    placeholder: intl.formatMessage(select$1) + "...",
                    defaultValue: Belt_Option.map(props.defaultValue, (function (v) {
                            return Curry._1(ColiswebApi__V6_Order.Jobs.JobStatus.toString, v);
                          })),
                    className: "w-full"
                  }),
              className: "w-full"
            });
}

var OrderJobStatus = {
  Msg: Msg$7,
  make: ApiComponents__TableFilters$OrderJobStatus
};

var select$2 = {
  id: "_930bcc71",
  defaultMessage: "Selectionner"
};

var Msg$8 = {
  select: select$2
};

function ApiComponents__TableFilters$OrderJobType(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var statuses = [
    "createDeliveries",
    "sendOrderEdi"
  ];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_Select.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, statuses, (function (variant) {
                            return {
                                    label: ColiswebApi__Locales.JobType.humanize(intl, variant),
                                    value: Curry._1(ColiswebApi__V6_Order.Jobs.JobType.toString, variant)
                                  };
                          })),
                    onChange: Curry.__1(setFilter),
                    placeholder: intl.formatMessage(select$2) + "...",
                    defaultValue: props.defaultValue,
                    className: "w-full"
                  }),
              className: "w-full"
            });
}

var OrderJobType = {
  Msg: Msg$8,
  make: ApiComponents__TableFilters$OrderJobType
};

var yes = {
  id: "_24ace383",
  defaultMessage: "Oui"
};

var no = {
  id: "_6679e65c",
  defaultMessage: "Non"
};

var Msg_filterState = {
  id: "_3a8a7a8a",
  defaultMessage: "Rechercher"
};

var Msg$9 = {
  filterState: Msg_filterState,
  yes: yes,
  no: no
};

function ApiComponents__TableFilters$IsTracked(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var states = [
    "true",
    "false"
  ];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, states, (function (state) {
                            return {
                                    itemLabel: Caml_option.some(state === "true" ? intl.formatMessage(yes) : intl.formatMessage(no)),
                                    label: state === "true" ? intl.formatMessage(yes) : intl.formatMessage(no),
                                    value: state
                                  };
                          })),
                    placeholder: JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_2f765479",
                                defaultMessage: "Choisir"
                              }),
                          className: "font-normal text-gray-400"
                        }),
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    allowFilter: false,
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (selectedState) {
                            return [{
                                      itemLabel: Caml_option.some(selectedState ? intl.formatMessage(yes) : intl.formatMessage(no)),
                                      label: selectedState ? intl.formatMessage(yes) : intl.formatMessage(no),
                                      value: selectedState ? "true" : "false"
                                    }];
                          })),
                    onChange: (function (values) {
                        var match = Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                return param.value;
                              }));
                        var len = match.length;
                        var tmp;
                        if (len >= 3) {
                          tmp = Js_exn.raiseError("i fucked up");
                        } else {
                          switch (len) {
                            case 0 :
                                tmp = undefined;
                                break;
                            case 1 :
                                var match$1 = match[0];
                                switch (match$1) {
                                  case "false" :
                                      tmp = false;
                                      break;
                                  case "true" :
                                      tmp = true;
                                      break;
                                  default:
                                    tmp = Js_exn.raiseError("i fucked up");
                                }
                                break;
                            case 2 :
                                var match$2 = match[0];
                                switch (match$2) {
                                  case "false" :
                                      var match$3 = match[1];
                                      tmp = match$3 === "true" ? undefined : Js_exn.raiseError("i fucked up");
                                      break;
                                  case "true" :
                                      var match$4 = match[1];
                                      tmp = match$4 === "false" ? undefined : Js_exn.raiseError("i fucked up");
                                      break;
                                  default:
                                    tmp = Js_exn.raiseError("i fucked up");
                                }
                                break;
                            
                          }
                        }
                        Curry._1(setFilter, tmp);
                      })
                  }),
              className: "w-full"
            });
}

var IsTracked = {
  Msg: Msg$9,
  make: ApiComponents__TableFilters$IsTracked
};

var noFilters = {
  id: "_390c6d0b",
  defaultMessage: "Pas de filtre"
};

var notDamaged = {
  id: "_c266c2a3",
  defaultMessage: "Sans avarie"
};

var damaged = {
  id: "_6a758d03",
  defaultMessage: "Avec avaries"
};

function ApiComponents__TableFilters$OrderDamageStatus(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var options = [
    {
      label: intl.formatMessage(noFilters),
      value: ""
    },
    {
      label: intl.formatMessage(notDamaged),
      value: "notDamaged"
    },
    {
      label: intl.formatMessage(damaged),
      value: "damaged"
    }
  ];
  var v = Belt_Option.flatMap(props.defaultValue, (function (v) {
          return Curry._2(Toolkit__Primitives_Array.$$Array.get, v, 0);
        }));
  var defaultOption = v !== undefined ? Curry._2(Toolkit__Primitives_Array.$$Array.getBy, options, (function (param) {
            return param.value === v;
          })) : Curry._2(Toolkit__Primitives_Array.$$Array.get, options, 0);
  return JsxRuntime.jsx(Toolkit__Ui_Listbox.make, {
              options: options,
              compare: "ValueEquality",
              onChange: (function (param) {
                  var value = param.value;
                  if (value === "") {
                    return Curry._1(setFilter, undefined);
                  } else {
                    return Curry._1(setFilter, [value]);
                  }
                }),
              placeholder: intl.formatMessage(noFilters),
              defaultOption: defaultOption
            });
}

var OrderDamageStatus = {
  noFilters: noFilters,
  notDamaged: notDamaged,
  damaged: damaged,
  make: ApiComponents__TableFilters$OrderDamageStatus
};

var notDamaged$1 = {
  id: "_6b2e9760",
  defaultMessage: "Sans avaries"
};

var damagedButDeliverable = {
  id: "_c762e99e",
  defaultMessage: "Avaries livrables"
};

var undeliverable = {
  id: "_b02edac5",
  defaultMessage: "Avaries non-livrables"
};

function ApiComponents__TableFilters$ParcelDamageStatus(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var options = [
    {
      label: intl.formatMessage(notDamaged$1),
      value: ColiswebApi__V6_Parcel.DamageStatus.toString("notDamaged")
    },
    {
      label: intl.formatMessage(damagedButDeliverable),
      value: ColiswebApi__V6_Parcel.DamageStatus.toString("damagedButDeliverable")
    },
    {
      label: intl.formatMessage(undeliverable),
      value: ColiswebApi__V6_Parcel.DamageStatus.toString("undeliverable")
    }
  ];
  return JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
              options: options,
              placeholder: JsxRuntime.jsx("span", {
                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                          id: "_72df8435",
                          defaultMessage: "État de l'UM"
                        }),
                    className: "font-normal text-gray-400"
                  }),
              buttonClassName: "w-full",
              dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
              defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (selectedStates) {
                      return Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, selectedStates, (function (damageStatus) {
                                    return Curry._2(Toolkit__Primitives_Array.$$Array.getBy, options, (function (s) {
                                                  return s.value === damageStatus;
                                                }));
                                  }));
                    })),
              onChange: (function (values) {
                  Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                              return param.value;
                            })));
                })
            });
}

var ParcelDamageStatus = {
  notDamaged: notDamaged$1,
  damagedButDeliverable: damagedButDeliverable,
  undeliverable: undeliverable,
  make: ApiComponents__TableFilters$ParcelDamageStatus
};

function ApiComponents__TableFilters$StoreStatusProviderSelect(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, props.states, (function (state) {
                            return {
                                    label: Curry._2(ColiswebApi__Locales.V5.Store.StatusProvider.humanize, intl, state),
                                    value: ColiswebApi__V5_Utils.StatusProvider.toString(state)
                                  };
                          })),
                    placeholder: "-",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (states) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.map, states, (function (state) {
                                          return {
                                                  label: Curry._2(ColiswebApi__Locales.V5.Store.StatusProvider.humanize, intl, Curry._1(Toolkit__Primitives_Result.Result.getExn, ColiswebApi__V5_Utils.StatusProvider.t_decode(state))),
                                                  value: state
                                                };
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var StoreStatusProviderSelect = {
  make: ApiComponents__TableFilters$StoreStatusProviderSelect
};

function ApiComponents__TableFilters$DirectivesMultiSelect(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, ColiswebApi__V6_Parcel.Directive.allDirectives, (function (directive) {
                            var tmp;
                            switch (directive) {
                              case "toDeliver" :
                                  tmp = "text-success-600 bg-success-50";
                                  break;
                              case "toThrow" :
                                  tmp = "text-danger-600 bg-danger-50";
                                  break;
                              case "toReturn" :
                              case "toDecide" :
                                  tmp = "text-info-600 bg-info-50";
                                  break;
                              
                            }
                            return {
                                    itemLabel: Caml_option.some(JsxRuntime.jsx("span", {
                                              children: ColiswebApi__V6_Parcel.Directive.humanize(intl, directive),
                                              className: Cx.cx([
                                                    "w-fit px-2 inline rounded",
                                                    tmp
                                                  ])
                                            })),
                                    label: ColiswebApi__V6_Parcel.Directive.humanize(intl, directive),
                                    value: ColiswebApi__V6_Parcel.Directive.toString(directive)
                                  };
                          })),
                    placeholder: "-",
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (directives) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.map, directives, (function (directive) {
                                          return {
                                                  label: ColiswebApi__V6_Parcel.Directive.humanize(intl, Curry._1(Toolkit__Primitives_Result.Result.getExn, ColiswebApi__V6_Parcel.Directive.t_decode(directive))),
                                                  value: directive
                                                };
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var DirectivesMultiSelect = {
  make: ApiComponents__TableFilters$DirectivesMultiSelect
};

function ApiComponents__TableFilters$ResponsibilityMultiSelect(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, ColiswebApi__V6_Parcel.Responsibility.all, (function (responsibility) {
                            return {
                                    itemLabel: Caml_option.some(JsxRuntime.jsx("span", {
                                              children: ColiswebApi__V6_Parcel.Responsibility.humanize(intl, responsibility),
                                              className: Cx.cx(["w-fit px-2 inline rounded"])
                                            })),
                                    label: ColiswebApi__V6_Parcel.Responsibility.humanize(intl, responsibility),
                                    value: ColiswebApi__V6_Parcel.Responsibility.toString(responsibility)
                                  };
                          })),
                    placeholder: "-",
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (responsibilities) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.map, responsibilities, (function (responsibility) {
                                          return {
                                                  label: ColiswebApi__V6_Parcel.Responsibility.humanize(intl, Curry._1(Toolkit__Primitives_Result.Result.getExn, ColiswebApi__V6_Parcel.Responsibility.t_decode(responsibility))),
                                                  value: responsibility
                                                };
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var ResponsibilityMultiSelect = {
  make: ApiComponents__TableFilters$ResponsibilityMultiSelect
};

function ApiComponents__TableFilters$ScanContextMultiSelect(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, Belt_Option.getWithDefault(props.contextOptions, ColiswebApi__V6_Parcel.ScanContext.allScanContexts), (function (scanContext) {
                            return {
                                    label: ColiswebApi__V6_Parcel.ScanContext.humanize(intl, scanContext),
                                    value: ColiswebApi__V6_Parcel.ScanContext.toString(scanContext)
                                  };
                          })),
                    placeholder: "-",
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (scanContexts) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.map, scanContexts, (function (scanContext) {
                                          return {
                                                  label: ColiswebApi__V6_Parcel.ScanContext.humanize(intl, Curry._1(Toolkit__Primitives_Result.Result.getExn, ColiswebApi__V6_Parcel.ScanContext.t_decode(scanContext))),
                                                  value: scanContext
                                                };
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var ScanContextMultiSelect = {
  make: ApiComponents__TableFilters$ScanContextMultiSelect
};

var filterHub$1 = {
  id: "_3a8a7a8a",
  defaultMessage: "Rechercher"
};

var Msg$10 = {
  filterHub: filterHub$1
};

function ApiComponents__TableFilters$WarehouseCodesMultiSelect$SelectList(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var $$let = ColiswebApi__V6_Client.Warehouses.GetAll;
  var match = $$Request.useFetcher(undefined, {
        response_decode: $$let.response_decode,
        config: $$let.config
      }, Caml_option.some(undefined));
  var warehouses = match[0];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, warehouses.warehouses, (function (param) {
                            var name = param.name;
                            return {
                                    itemLabel: Caml_option.some(name),
                                    label: name,
                                    value: param.warehouseCode
                                  };
                          })),
                    placeholder: JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_2f765479",
                                defaultMessage: "Choisir"
                              }),
                          className: "font-normal text-gray-400"
                        }),
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    searchPlaceholder: intl.formatMessage(filterHub$1),
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (selectedWarehousesCodes) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, warehouses.warehouses, (function (param) {
                                          var name = param.name;
                                          var warehouseCode = param.warehouseCode;
                                          if (Curry._2(Toolkit__Primitives_Array.$$Array.some, selectedWarehousesCodes, (function (selectedWarehouseCode) {
                                                    return selectedWarehouseCode === warehouseCode;
                                                  }))) {
                                            return {
                                                    itemLabel: Caml_option.some(name),
                                                    label: name,
                                                    value: warehouseCode
                                                  };
                                          }
                                          
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var SelectList$3 = {
  make: ApiComponents__TableFilters$WarehouseCodesMultiSelect$SelectList
};

function ApiComponents__TableFilters$WarehouseCodesMultiSelect(props) {
  return JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
              fallbackRender: (function (param) {
                  return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                              id: "_bcf3b0ac",
                              defaultMessage: "Impossible de charger les entrepôts."
                            });
                }),
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(ApiComponents__TableFilters$WarehouseCodesMultiSelect$SelectList, {
                              setFilter: props.setFilter,
                              defaultValue: props.defaultValue
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Toolkit__Ui_Spinner.make, {}))
                  })
            });
}

var WarehouseCodesMultiSelect = {
  Msg: Msg$10,
  SelectList: SelectList$3,
  make: ApiComponents__TableFilters$WarehouseCodesMultiSelect
};

var filterHub$2 = {
  id: "_3a8a7a8a",
  defaultMessage: "Rechercher"
};

var Msg$11 = {
  filterHub: filterHub$2
};

function ApiComponents__TableFilters$StoreCodesMultiSelect$SelectList(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  var $$let = ColiswebApi__V6_Client.Stores.GetAllNames;
  var match = $$Request.useFetcher({
        keepPreviousData: true,
        revalidateOnFocus: false,
        revalidateOnMount: true
      }, {
        response_decode: $$let.response_decode,
        config: $$let.config
      }, Caml_option.some(undefined));
  var storesWithStoreCodes = Curry._2(Toolkit__Primitives_Array.$$Array.keep, match[0].stores, (function (param) {
          return Belt_Option.isSome(param.code);
        }));
  var selectedStores = Belt_Option.mapWithDefault(props.defaultValue, [], (function (selectedStoreCodes) {
          return Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, storesWithStoreCodes, (function (param) {
                        var name = param.name;
                        var code = param.code;
                        if (Curry._2(Toolkit__Primitives_Array.$$Array.some, selectedStoreCodes, (function (selectedStoreCode) {
                                  return Belt_Option.mapWithDefault(code, false, (function (storeCode) {
                                                return selectedStoreCode === storeCode;
                                              }));
                                }))) {
                          return {
                                  itemLabel: Caml_option.some(name),
                                  label: name,
                                  value: code
                                };
                        }
                        
                      }));
        }));
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelectWithValidation.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, storesWithStoreCodes, (function (param) {
                            var name = param.name;
                            return {
                                    itemLabel: Caml_option.some(name),
                                    label: name,
                                    value: param.code
                                  };
                          })),
                    placeholder: JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_2f765479",
                                defaultMessage: "Choisir"
                              }),
                          className: "font-normal text-gray-400"
                        }),
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[400px] overflow-auto",
                    searchPlaceholder: intl.formatMessage(filterHub$2),
                    defaultValue: selectedStores,
                    onValidate: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var SelectList$4 = {
  make: ApiComponents__TableFilters$StoreCodesMultiSelect$SelectList
};

function ApiComponents__TableFilters$StoreCodesMultiSelect(props) {
  return JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
              fallbackRender: (function (param) {
                  return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                              id: "_73019638",
                              defaultMessage: "Impossible de charger les magasins."
                            });
                }),
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(ApiComponents__TableFilters$StoreCodesMultiSelect$SelectList, {
                              setFilter: props.setFilter,
                              defaultValue: props.defaultValue
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Toolkit__Ui_Spinner.make, {}))
                  })
            });
}

var StoreCodesMultiSelect = {
  Msg: Msg$11,
  SelectList: SelectList$4,
  make: ApiComponents__TableFilters$StoreCodesMultiSelect
};

function ApiComponents__TableFilters$TransactionStatusMultiSelect(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, ColiswebApi__V6_OnlinePayment.Shared.TransactionStatus.allStatus, (function (transactionStatus) {
                            var $$let = ColiswebApi__V6_OnlinePayment.Shared.TransactionStatus;
                            return {
                                    label: Curry._2(ColiswebApi__V6_OnlinePayment.Shared.TransactionStatus.humanize, intl, transactionStatus),
                                    value: Toolkit__Utils.encodeEnumToString(transactionStatus, {
                                          t_decode: $$let.t_decode,
                                          t_encode: $$let.t_encode
                                        })
                                  };
                          })),
                    placeholder: "-",
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (transactionStatus) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.map, transactionStatus, (function (transactionStatus) {
                                          return {
                                                  label: Curry._2(ColiswebApi__V6_OnlinePayment.Shared.TransactionStatus.humanize, intl, Curry._1(Toolkit__Primitives_Result.Result.getExn, Curry._1(ColiswebApi__V6_OnlinePayment.Shared.TransactionStatus.t_decode, transactionStatus))),
                                                  value: transactionStatus
                                                };
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var TransactionStatusMultiSelect = {
  make: ApiComponents__TableFilters$TransactionStatusMultiSelect
};

var damageLevels = [
  "deliverable",
  "undeliverable"
];

var oui = {
  id: "_24ace383",
  defaultMessage: "Oui"
};

var non = {
  id: "_6679e65c",
  defaultMessage: "Non"
};

function ApiComponents__TableFilters$DamageLevelSelect(props) {
  var setFilter = props.setFilter;
  var intl = ReactIntl.useIntl();
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Toolkit__Ui_MultiSelect.make, {
                    options: Curry._2(Toolkit__Primitives_Array.$$Array.map, damageLevels, (function (damageLevel) {
                            var tmp;
                            tmp = damageLevel === "deliverable" ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                    id: "_24ace383",
                                    defaultMessage: "Oui"
                                  }) : JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                    id: "_6679e65c",
                                    defaultMessage: "Non"
                                  });
                            var tmp$1;
                            tmp$1 = damageLevel === "deliverable" ? intl.formatMessage(oui) : intl.formatMessage(non);
                            return {
                                    itemLabel: Caml_option.some(JsxRuntime.jsx("p", {
                                              children: tmp,
                                              className: Cx.cx([
                                                    "rounded inline px-2",
                                                    damageLevel === "deliverable" ? "text-success-700 bg-success-50 px-1" : "text-warning-600 bg-warning-50 px-1"
                                                  ])
                                            })),
                                    label: tmp$1,
                                    value: ColiswebApi__V6_Parcel.DamageLevel.toString(damageLevel)
                                  };
                          })),
                    placeholder: JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_151a5da7",
                                defaultMessage: "Colis livrable ?"
                              }),
                          className: "font-normal text-gray-400"
                        }),
                    buttonClassName: "w-full",
                    dropdownClassName: "w-full sm:w-60 max-h-[300px] overflow-auto",
                    defaultValue: Belt_Option.mapWithDefault(props.defaultValue, [], (function (selectedStates) {
                            return Curry._2(Toolkit__Primitives_Array.$$Array.keepMap, damageLevels, (function (damageLevel) {
                                          if (!Curry._2(Toolkit__Primitives_Array.$$Array.some, selectedStates, (function (s) {
                                                    return s === ColiswebApi__V6_Parcel.DamageLevel.toString(damageLevel);
                                                  }))) {
                                            return ;
                                          }
                                          var tmp;
                                          tmp = damageLevel === "deliverable" ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_24ace383",
                                                  defaultMessage: "Oui"
                                                }) : JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_6679e65c",
                                                  defaultMessage: "Non"
                                                });
                                          var tmp$1;
                                          tmp$1 = damageLevel === "deliverable" ? intl.formatMessage(oui) : intl.formatMessage(non);
                                          return {
                                                  itemLabel: Caml_option.some(JsxRuntime.jsx("p", {
                                                            children: tmp,
                                                            className: Cx.cx([
                                                                  "rounded inline px-2",
                                                                  damageLevel === "deliverable" ? "text-success-700 bg-success-50 px-1" : "text-warning-600 bg-warning-50 px-1"
                                                                ])
                                                          })),
                                                  label: tmp$1,
                                                  value: ColiswebApi__V6_Parcel.DamageLevel.toString(damageLevel)
                                                };
                                        }));
                          })),
                    onChange: (function (values) {
                        Curry._1(setFilter, Curry._2(Toolkit__Primitives_Array.$$Array.map, values, (function (param) {
                                    return param.value;
                                  })));
                      })
                  }),
              className: "w-full"
            });
}

var DamageLevelSelect = {
  damageLevels: damageLevels,
  oui: oui,
  non: non,
  make: ApiComponents__TableFilters$DamageLevelSelect
};

export {
  TextFilter ,
  HubListSelect ,
  RouteProjectSelect ,
  ClientListSelect ,
  ParcelState ,
  OrderState ,
  OrderGlobalState ,
  OrderJobStatus ,
  OrderJobType ,
  IsTracked ,
  OrderDamageStatus ,
  ParcelDamageStatus ,
  StoreStatusProviderSelect ,
  DirectivesMultiSelect ,
  ResponsibilityMultiSelect ,
  ScanContextMultiSelect ,
  WarehouseCodesMultiSelect ,
  StoreCodesMultiSelect ,
  TransactionStatusMultiSelect ,
  DamageLevelSelect ,
}
/* react Not a pure module */
