

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "../../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Toolkit__Ui_Modal from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Button from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Button.bs.js";
import * as Toolkit__Primitives_Array from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";
import * as Toolkit__FormValidationFunctions from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/form/Toolkit__FormValidationFunctions.bs.js";
import * as AutocompleteAddress__ModalTextInput from "./AutocompleteAddress__ModalTextInput.bs.js";

function AutocompleteAddress__AddressFormModal(props) {
  var defaultValue = props.defaultValue;
  var onSubmit = props.onSubmit;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function (param) {
        return Belt_Option.mapWithDefault(defaultValue, "", (function (param) {
                      return param.line1;
                    }));
      });
  var setLine1 = match[1];
  var line1 = match[0];
  var match$1 = React.useState(function (param) {
        return Belt_Option.mapWithDefault(defaultValue, "", (function (param) {
                      return param.line2;
                    }));
      });
  var setLine2 = match$1[1];
  var line2 = match$1[0];
  var match$2 = React.useState(function (param) {
        return Belt_Option.mapWithDefault(defaultValue, "", (function (param) {
                      return param.postalCode;
                    }));
      });
  var setPostalCode = match$2[1];
  var postalCode = match$2[0];
  var match$3 = React.useState(function (param) {
        return Belt_Option.mapWithDefault(defaultValue, "", (function (param) {
                      return param.city;
                    }));
      });
  var setCity = match$3[1];
  var city = match$3[0];
  var match$4 = React.useState(function (param) {
        return Belt_Option.mapWithDefault(defaultValue, "", (function (param) {
                      return param.country;
                    }));
      });
  var setCountry = match$4[1];
  var country = match$4[0];
  var match$5 = React.useState(function (param) {
        return false;
      });
  var setHasError = match$5[1];
  var hasError = match$5[0];
  var fieldEmpty = React.useCallback((function (value) {
          if (hasError && value === "") {
            return intl.formatMessage(Toolkit__FormValidationFunctions.Msg.stringNonEmpty);
          }
          
        }), [hasError]);
  return JsxRuntime.jsx(Toolkit__Ui_Modal.make, {
              isVisible: props.isVisible,
              title: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                        id: "_50d46c2f",
                        defaultMessage: "Adresse"
                      })),
              body: JsxRuntime.jsx(JsxRuntime.Fragment, {
                    children: Caml_option.some(JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(AutocompleteAddress__ModalTextInput.make, {
                                      label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                            id: "_818a97f1",
                                            defaultMessage: "Ligne 1"
                                          }),
                                      id: "line 1",
                                      name: "line1",
                                      value: line1,
                                      placeholder: "165 avenue de Bretagne",
                                      error: Curry._1(fieldEmpty, line1),
                                      onChange: (function ($$event) {
                                          var value = $$event.target.value;
                                          Curry._1(setLine1, (function (param) {
                                                  return value;
                                                }));
                                        }),
                                      onBlur: (function (param) {
                                          
                                        })
                                    }),
                                props.hideLine2 ? null : JsxRuntime.jsx(AutocompleteAddress__ModalTextInput.make, {
                                        label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                              id: "_c1cbb2b5",
                                              defaultMessage: "Ligne 2"
                                            }),
                                        id: "line 2",
                                        isOptional: true,
                                        name: "line2",
                                        value: line2,
                                        placeholder: "Euratechnologies",
                                        onChange: (function ($$event) {
                                            var value = $$event.target.value;
                                            Curry._1(setLine2, (function (param) {
                                                    return value;
                                                  }));
                                          }),
                                        onBlur: (function (param) {
                                            
                                          })
                                      }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(AutocompleteAddress__ModalTextInput.make, {
                                                    label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                          id: "_db17b356",
                                                          defaultMessage: "Code postal"
                                                        }),
                                                    id: "postalCode",
                                                    name: "postalCode",
                                                    value: postalCode,
                                                    placeholder: "59000",
                                                    error: Curry._1(fieldEmpty, postalCode),
                                                    onChange: (function ($$event) {
                                                        var value = $$event.target.value;
                                                        Curry._1(setPostalCode, (function (param) {
                                                                return value;
                                                              }));
                                                      }),
                                                    onBlur: (function (param) {
                                                        
                                                      })
                                                  }),
                                              className: "w-48"
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(AutocompleteAddress__ModalTextInput.make, {
                                                    label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                          id: "_92e2da12",
                                                          defaultMessage: "Ville"
                                                        }),
                                                    id: "city",
                                                    name: "city",
                                                    value: city,
                                                    placeholder: "Lille",
                                                    error: Curry._1(fieldEmpty, city),
                                                    onChange: (function ($$event) {
                                                        var value = $$event.target.value;
                                                        Curry._1(setCity, (function (param) {
                                                                return value;
                                                              }));
                                                      }),
                                                    onBlur: (function (param) {
                                                        
                                                      })
                                                  }),
                                              className: "w-full"
                                            })
                                      ],
                                      className: "flex gap-3"
                                    }),
                                JsxRuntime.jsx(AutocompleteAddress__ModalTextInput.make, {
                                      label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                            id: "_6c51308c",
                                            defaultMessage: "Pays"
                                          }),
                                      id: "country",
                                      name: "country",
                                      value: country,
                                      placeholder: "France",
                                      error: Curry._1(fieldEmpty, country),
                                      onChange: (function ($$event) {
                                          var value = $$event.target.value;
                                          Curry._1(setCountry, (function (param) {
                                                  return value;
                                                }));
                                        }),
                                      onBlur: (function (param) {
                                          
                                        })
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                            color: "neutral",
                                            onClick: (function (param) {
                                                var requiredFields = [
                                                  line1,
                                                  postalCode,
                                                  city,
                                                  country
                                                ];
                                                var error = Curry._2(Toolkit__Primitives_Array.$$Array.some, requiredFields, (function (f) {
                                                        if (f === "") {
                                                          Curry._1(setHasError, (function (param) {
                                                                  return true;
                                                                }));
                                                          return true;
                                                        }
                                                        Curry._1(setHasError, (function (param) {
                                                                return false;
                                                              }));
                                                        return false;
                                                      }));
                                                if (!error) {
                                                  return Curry._1(onSubmit, {
                                                              line1: line1,
                                                              line2: line2,
                                                              postalCode: postalCode,
                                                              city: city,
                                                              country: country
                                                            });
                                                }
                                                
                                              }),
                                            type_: "button",
                                            children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_257a7b4c",
                                                      defaultMessage: "Suivant"
                                                    }))
                                          }),
                                      className: "flex justify-end mt-4"
                                    })
                              ],
                              className: "flex flex-col gap-3"
                            }))
                  }),
              hide: props.hide,
              size: "sm"
            });
}

var TextInput;

var make = AutocompleteAddress__AddressFormModal;

export {
  TextInput ,
  make ,
}
/* react Not a pure module */
