

import * as JsxRuntime from "react/jsx-runtime";
import * as ApiComponents__VehicleFormatTag from "../../src/components/vehicle-format/ApiComponents__VehicleFormatTag.bs.js";

function Playground_VehicleFormatTag(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ApiComponents__VehicleFormatTag.make, {
                      vehicleFormat: {
                        id: "1",
                        name: "mocked vehicle",
                        width: {
                          NAME: "cm",
                          VAL: 15
                        },
                        height: {
                          NAME: "cm",
                          VAL: 15
                        },
                        length: {
                          NAME: "cm",
                          VAL: 15
                        },
                        volume: {
                          NAME: "m3",
                          VAL: 15
                        }
                      }
                    }),
                JsxRuntime.jsx("p", {
                      children: "Avec popover"
                    }),
                JsxRuntime.jsx(ApiComponents__VehicleFormatTag.make, {
                      vehicleFormat: {
                        id: "1",
                        name: "mocked vehicle",
                        width: {
                          NAME: "cm",
                          VAL: 15
                        },
                        height: {
                          NAME: "cm",
                          VAL: 15
                        },
                        length: {
                          NAME: "cm",
                          VAL: 15
                        },
                        volume: {
                          NAME: "m3",
                          VAL: 15
                        }
                      },
                      showPopover: true
                    })
              ],
              className: "flex flex-col gap-4"
            });
}

var make = Playground_VehicleFormatTag;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
