

import * as Cx from "../../../../node_modules/rescript-classnames/lib/es6_global/src/Cx.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as Toolkit__Hooks from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/hooks/Toolkit__Hooks.bs.js";
import * as Toolkit__Utils from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/utils/Toolkit__Utils.bs.js";
import * as Fa from "react-icons/fa";
import * as Md from "react-icons/md";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Server from "react-dom/server";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as Toolkit__Ui_Modal from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Button from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Button.bs.js";
import * as Toolkit__Primitives from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives.bs.js";
import * as Toolkit__Ui_Tooltip from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Tooltip.bs.js";
import * as ApiComponents__Hooks from "./ApiComponents__Hooks.bs.js";
import * as ColiswebApi__V5_Admin from "../v5/ColiswebApi__V5_Admin.bs.js";
import * as ApiComponents__HereMap from "./ApiComponents__HereMap.bs.js";
import * as Toolkit__Ui_IconButton from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_IconButton.bs.js";
import * as ColiswebApi__V6_Authent from "../v6/ColiswebApi__V6_Authent.bs.js";
import * as ColiswebApi__V6_Provider from "../v6/ColiswebApi__V6_Provider.bs.js";
import * as Toolkit__Primitives_Array from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";
import * as Toolkit__Ui_ErrorBoundary from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_ErrorBoundary.bs.js";

function ApiComponents__RouteTimelineBanner$Timeline$IconDelayed(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("svg", {
              children: JsxRuntime.jsx("path", {
                    d: "M11.2,4.8C10.4,4,9.2,3.5,8,3.5V8l-3.2,3.2c1.8,1.8,4.6,1.8,6.4,0C13,9.4,13,6.6,11.2,4.8C11.2,4.8,11.2,4.8,11.2,4.8z",
                    fill: "currentColor",
                    stroke: "none"
                  }),
              className: className,
              id: "Calque_1",
              height: "100%",
              width: "100%",
              version: "1.1",
              viewBox: "0 0 16 16",
              x: "0px",
              xmlns: "http://www.w3.org/2000/svg",
              y: "0px"
            });
}

function humanize(marker) {
  var match = marker.status;
  switch (match) {
    case "Pending" :
        return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_439149be",
                    defaultMessage: "En attente"
                  });
    case "Current" :
        return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_7202b8ff",
                    defaultMessage: "En cours"
                  });
    case "Successful" :
        return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_8b6014f5",
                    defaultMessage: "Terminée"
                  });
    case "Delayed" :
        return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_830e2a1f",
                    defaultMessage: "En retard"
                  });
    case "Issue" :
        return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_34267258",
                    defaultMessage: "Problème"
                  });
    case "Failed" :
        return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_83b05314",
                    defaultMessage: "Ratée"
                  });
    case "Deleted" :
        return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_a067035e",
                    defaultMessage: "Supprimée"
                  });
    case "Pause" :
        return JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_105b296a",
                    defaultMessage: "Pause"
                  });
    
  }
}

function getIcon(marker) {
  var match = marker.status;
  switch (match) {
    case "Delayed" :
        return JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$Timeline$IconDelayed, {
                    className: "w-5 absolute"
                  });
    case "Issue" :
    case "Failed" :
        return JsxRuntime.jsx(Fa.FaExclamation, {
                    size: 12
                  });
    case "Deleted" :
        return JsxRuntime.jsx(Md.MdClose, {
                    size: 13
                  });
    case "Pause" :
        return JsxRuntime.jsx(Fa.FaPauseCircle, {
                    size: 16
                  });
    default:
      return JsxRuntime.jsx(Fa.FaCheck, {
                  size: 8
                });
  }
}

function getLabelStyle(marker, isMapIconOpt, param) {
  var isMapIcon = isMapIconOpt !== undefined ? isMapIconOpt : false;
  var match = marker.status;
  var match$1 = marker.taskType;
  switch (match) {
    case "Pending" :
        if (isMapIcon) {
          return "border-neutral-700 bg-neutral-400 text-neutral-900";
        } else {
          return "border-gray-200 bg-gray-200 text-gray-500";
        }
    case "Current" :
        return "border-primary-500 bg-primary-500 text-white";
    case "Successful" :
        switch (match$1) {
          case "Pickup" :
              return "border-routeWithdraw bg-routeWithdraw text-white";
          case "Dropoff" :
          case "Break" :
              return "border-success-400 bg-white text-success-400";
          
        }
    case "Delayed" :
        return "border-warning-300 bg-white text-warning-300";
    case "Issue" :
        return "border-warning-500 bg-white text-warning-500";
    case "Failed" :
        return "border-danger-500 bg-white text-danger-500";
    case "Deleted" :
        return "border-neutral-900 bg-white text-neutral-900";
    case "Pause" :
        return "text-neutral-600 bg-neutral-200 whitespace-nowrap text-[11px]";
    
  }
}

function getMarkerStyle(marker, isMapIconOpt, param) {
  var isMapIcon = isMapIconOpt !== undefined ? isMapIconOpt : false;
  var match = marker.status;
  var match$1 = marker.taskType;
  switch (match) {
    case "Pending" :
        if (isMapIcon) {
          return "border-neutral-700 bg-white text-white border-2";
        } else {
          return "border-gray-200 bg-white text-white border-2";
        }
    case "Current" :
        return "border-primary-500 bg-white text-primary-500 border-4";
    case "Successful" :
        switch (match$1) {
          case "Dropoff" :
              return "border-success-400 bg-white text-success-400 border-4";
          case "Pickup" :
          case "Break" :
              return "border-routeWithdraw bg-white text-routeWithdraw border-4";
          
        }
    case "Delayed" :
        return "border-warning-300 bg-white text-warning-300 border-2";
    case "Issue" :
        return "border-warning-300 bg-warning-300 text-white border-2";
    case "Failed" :
        return "border-danger-500 bg-danger-500 text-white border-2";
    case "Deleted" :
        return "border-neutral-900 bg-white text-neutral-900 border-2";
    case "Pause" :
        return "text-neutral-600 bg-neutral-200";
    
  }
}

function getMarkerStyleForPickupTimeline(marker) {
  var match = marker.status;
  switch (match) {
    case "Successful" :
    case "Issue" :
    case "Failed" :
        return "border-routeWithdraw bg-routeWithdraw";
    default:
      return "border-neutral-500 bg-neutral-500";
  }
}

function makeMarkersFromTasks(tasks, pickupCount) {
  var pickupCount$1 = {
    contents: pickupCount
  };
  var dropoffCount = {
    contents: 0
  };
  var currentTask = {
    contents: false
  };
  return Curry._2(Toolkit__Primitives_Array.$$Array.map, tasks, (function (task) {
                var match = task.taskType;
                var status;
                var exit = 0;
                var deletionCause;
                var deliveryState;
                if (typeof match === "object") {
                  var variant = match.NAME;
                  if (variant === "singleDropoff") {
                    var match$1 = match.VAL;
                    deletionCause = match$1.deletionCause;
                    deliveryState = match$1.deliveryState;
                    exit = 1;
                  } else if (variant === "singlePickup") {
                    var singlePickup = match.VAL;
                    if (singlePickup.deliveryState === "package_withdrawn" || singlePickup.deliveryState === "package_withdrawal_pb_ok") {
                      status = "Successful";
                    } else {
                      deletionCause = singlePickup.deletionCause;
                      deliveryState = singlePickup.deliveryState;
                      exit = 1;
                    }
                  } else {
                    var match$2 = match.VAL;
                    var deletionCause$1 = match$2.deletionCause;
                    var isWithdrawalStarted = match$2.successes > 0;
                    var areWithdrawalsFailed = match$2.failures === match$2.deliveriesId.length;
                    if (match$2.deliveryState === "canceled") {
                      if (deletionCause$1 !== undefined) {
                        switch (deletionCause$1) {
                          case "slot_shifted" :
                              status = "Delayed";
                              break;
                          case "removed" :
                              status = "Deleted";
                              break;
                          case "manually_assigned" :
                              status = "Failed";
                              break;
                          
                        }
                      } else {
                        status = "Failed";
                      }
                    } else if (isWithdrawalStarted) {
                      status = "Successful";
                    } else if (areWithdrawalsFailed) {
                      status = "Failed";
                    } else if (currentTask.contents) {
                      status = "Pending";
                    } else {
                      currentTask.contents = true;
                      status = "Current";
                    }
                  }
                } else {
                  status = "Pause";
                }
                if (exit === 1) {
                  var exit$1 = 0;
                  switch (deliveryState) {
                    case "idle" :
                    case "course_accepted" :
                    case "arrival_at_pickup_point" :
                        status = "Pending";
                        break;
                    case "package_withdrawn" :
                    case "package_withdrawal_pb_ok" :
                        exit$1 = 2;
                        break;
                    case "arrival_at_shipping_point" :
                    case "delivered" :
                        status = "Successful";
                        break;
                    case "delivery_pb_ok" :
                    case "package_returned" :
                        status = "Issue";
                        break;
                    default:
                      if (deletionCause !== undefined) {
                        switch (deletionCause) {
                          case "slot_shifted" :
                              status = "Delayed";
                              break;
                          case "removed" :
                              status = "Deleted";
                              break;
                          case "manually_assigned" :
                              status = "Failed";
                              break;
                          
                        }
                      } else {
                        status = "Failed";
                      }
                  }
                  if (exit$1 === 2) {
                    if (currentTask.contents) {
                      status = "Pending";
                    } else {
                      currentTask.contents = true;
                      status = "Current";
                    }
                  }
                  
                }
                var match$3 = task.taskType;
                var match$4 = task.taskType;
                return {
                        status: status,
                        hour: task.estimatedArrivalSlot.start.getHours() + task.estimatedArrivalSlot.start.getMinutes() / 60.0,
                        label: typeof match$3 === "object" ? (
                            match$3.NAME === "singleDropoff" ? (dropoffCount.contents = dropoffCount.contents + 1 | 0, String(dropoffCount.contents)) : (pickupCount$1.contents = pickupCount$1.contents + 1 | 0, "R" + String(pickupCount$1.contents))
                          ) : Belt_Option.mapWithDefault(task.transitionFactors.breakTimeInMinutes, "", (function (v) {
                                  return Toolkit__Utils.durationMinutesToHourMinutesString(v, undefined, undefined);
                                })),
                        taskType: typeof match$4 === "object" ? (
                            match$4.NAME === "singleDropoff" ? "Dropoff" : "Pickup"
                          ) : "Break",
                        task: task
                      };
              }));
}

function ApiComponents__RouteTimelineBanner$Timeline$TimelineRow$TimelineBackground(props) {
  var firstMarkerSize = props.firstMarkerSize;
  var isPickupTimeline = props.isPickupTimeline;
  return JsxRuntime.jsx("div", {
              className: Cx.cx([
                    "w-full absolute rounded",
                    isPickupTimeline ? "bg-neutral-300 h-1" : "bg-gray-200 h-2"
                  ]),
              style: isPickupTimeline ? ({
                    marginLeft: "calc(" + String(firstMarkerSize) + "%)",
                    width: "calc(" + props.latestMarkerSize.toString() + "%  + " + (
                      isPickupTimeline ? "0.25rem" : "0rem"
                    ) + " - " + String(firstMarkerSize) + "%"
                  }) : ({})
            });
}

function ApiComponents__RouteTimelineBanner$Timeline$TimelineRow$TimelineBackgroundProgress(props) {
  var firstMarkerSize = props.firstMarkerSize;
  var isPickupTimeline = props.isPickupTimeline;
  return JsxRuntime.jsx("div", {
              className: Cx.cx([
                    "absolute",
                    isPickupTimeline ? "h-1 bg-routeWithdraw" : "h-2 bg-success-300"
                  ]),
              style: {
                marginLeft: "calc(" + String(firstMarkerSize) + "%)",
                width: "calc(" + props.currentMarkerSize.toString() + "%  + " + (
                  isPickupTimeline ? "0.25rem" : "0rem"
                ) + " - " + String(firstMarkerSize) + "%"
              }
            });
}

function ApiComponents__RouteTimelineBanner$Timeline$TimelineRow$MarkerLabel(props) {
  var marker = props.marker;
  return JsxRuntime.jsx("div", {
              children: marker.label,
              className: Cx.cx([
                    " w-5 h-5 rounded-md border flex items-center justify-center text-xs font-semibold z-10",
                    getLabelStyle(marker, undefined, undefined)
                  ]),
              style: Belt_Option.mapWithDefault(props.markerWidth, {}, (function (width) {
                      return {
                              width: width + "px"
                            };
                    }))
            });
}

function ApiComponents__RouteTimelineBanner$Timeline$TimelineRow$TimelineMarker(props) {
  var dropoffCount = props.dropoffCount;
  var isPickupTimeline = props.isPickupTimeline;
  var marker = props.marker;
  var match = marker.taskType;
  var tmp;
  switch (match) {
    case "Pickup" :
        tmp = isPickupTimeline ? JsxRuntime.jsx(JsxRuntime.Fragment, {
                children: Caml_option.some(JsxRuntime.jsx(Toolkit__Ui_Tooltip.make, {
                          triggerContent: JsxRuntime.jsxs("div", {
                                children: [
                                  marker.label,
                                  JsxRuntime.jsx("span", {
                                        children: dropoffCount,
                                        className: "bg-info-100 text-info-600 px-1 rounded  text-[9px]"
                                      })
                                ],
                                className: "flex flex-col gap-1 mt-4"
                              }),
                          tooltipContent: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_a84a8ddf",
                                defaultMessage: "{count, plural, one {# livraison} other {# livraisons}} à retirer",
                                values: {
                                  count: dropoffCount
                                }
                              }),
                          side: "left",
                          contentClassName: "w-40 text-center mr-2 !bg-routeWithdraw"
                        }))
              }) : getIcon(marker);
        break;
    case "Dropoff" :
        tmp = isPickupTimeline ? null : getIcon(marker);
        break;
    case "Break" :
        tmp = getIcon(marker);
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: Cx.cx([
                    "flex-shrink-0 flex justify-center items-center cursor-pointer",
                    isPickupTimeline && marker.taskType === "Dropoff" ? "w-3 h-3" : "w-5 h-5",
                    isPickupTimeline && marker.taskType === "Pickup" ? "rounded text-white text-xs font-semibold" : "rounded-full",
                    isPickupTimeline ? getMarkerStyleForPickupTimeline(marker) : getMarkerStyle(marker, undefined, undefined)
                  ]),
              style: Belt_Option.mapWithDefault(props.markerWidth, {}, (function (width) {
                      return {
                              width: width + "px"
                            };
                    }))
            });
}

function ApiComponents__RouteTimelineBanner$Timeline$TimelineRow(props) {
  var hourPixelSize = props.hourPixelSize;
  var selectedTaskIndex = props.selectedTaskIndex;
  var onMarkerClick = props.onMarkerClick;
  var __isPickupTimeline = props.isPickupTimeline;
  var __hideLabels = props.hideLabels;
  var markers = props.markers;
  var markersSizes = props.markersSizes;
  var hideLabels = __hideLabels !== undefined ? __hideLabels : false;
  var isPickupTimeline = __isPickupTimeline !== undefined ? __isPickupTimeline : false;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$Timeline$TimelineRow$TimelineBackground, {
                      isPickupTimeline: isPickupTimeline,
                      firstMarkerSize: Curry._2(Toolkit__Primitives_Array.$$Array.get, markersSizes, 0).markerSize,
                      latestMarkerSize: Toolkit__Primitives.$$Array.tailExn(markersSizes).markerSize
                    }),
                Belt_Option.mapWithDefault(props.currentTaskIndex, null, (function (currentTaskIndex) {
                        return JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$Timeline$TimelineRow$TimelineBackgroundProgress, {
                                    isPickupTimeline: isPickupTimeline,
                                    firstMarkerSize: Curry._2(Toolkit__Primitives_Array.$$Array.get, markersSizes, 0).markerSize,
                                    currentMarkerSize: Curry._2(Toolkit__Primitives_Array.$$Array.get, markersSizes, currentTaskIndex).markerSize
                                  });
                      })),
                JsxRuntime.jsx("div", {
                      children: Curry._2(Toolkit__Primitives_Array.$$Array.mapWithIndex, markers, (function (i, marker) {
                              var isMarkerHighlighted = Belt_Option.mapWithDefault(selectedTaskIndex, false, (function (selectedTaskIndex) {
                                      return Curry._2(Toolkit__Primitives_Array.$$Array.get, markersSizes, i).originTaskIndex === selectedTaskIndex;
                                    }));
                              var currentMarkerSize = Curry._2(Toolkit__Primitives_Array.$$Array.get, markersSizes, i).markerSize;
                              var isLongTask = marker.task.transitionFactors.handlingTimeInMinutes > 6;
                              var match = marker.taskType;
                              var match$1 = marker.task.transitionFactors.breakTimeInMinutes;
                              var markerWidth;
                              var exit = 0;
                              switch (match) {
                                case "Pickup" :
                                case "Dropoff" :
                                    exit = 1;
                                    break;
                                case "Break" :
                                    if (match$1 !== undefined) {
                                      markerWidth = (match$1 / 60 * hourPixelSize - 20).toString();
                                    } else {
                                      exit = 1;
                                    }
                                    break;
                                
                              }
                              if (exit === 1) {
                                markerWidth = isLongTask ? (marker.task.transitionFactors.handlingTimeInMinutes / 60 * hourPixelSize).toString() : undefined;
                              }
                              var markerPosition = "calc(" + String(currentMarkerSize) + "% + " + (
                                isPickupTimeline && marker.taskType === "Dropoff" ? "0.25rem" : "0rem"
                              ) + " - " + (
                                isMarkerHighlighted ? "0.75rem" : "0.625rem"
                              ) + ")";
                              return JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsxs("div", {
                                                children: [
                                                  hideLabels ? null : JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$Timeline$TimelineRow$MarkerLabel, {
                                                          marker: marker,
                                                          markerWidth: markerWidth
                                                        }),
                                                  JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$Timeline$TimelineRow$TimelineMarker, {
                                                        marker: marker,
                                                        isPickupTimeline: isPickupTimeline,
                                                        dropoffCount: markers.length - 1 | 0,
                                                        markerWidth: markerWidth
                                                      })
                                                ],
                                                className: Cx.cx([
                                                      "relative flex flex-col gap-1",
                                                      isPickupTimeline ? "" : "w-5 h-[44px]"
                                                    ]),
                                                style: Belt_Option.mapWithDefault(markerWidth, {}, (function (width) {
                                                        return {
                                                                width: width + "px"
                                                              };
                                                      }))
                                              }),
                                          className: Cx.cx([
                                                "flex-shrink-0 absolute cursor-pointer p-1 rounded-lg",
                                                isPickupTimeline ? "" : "transform -translate-y-1/4",
                                                isMarkerHighlighted && !isPickupTimeline ? "bg-primary-600" : ""
                                              ]),
                                          style: {
                                            marginLeft: markerPosition
                                          },
                                          onClick: (function (param) {
                                              Belt_Option.forEach(onMarkerClick, (function (cb) {
                                                      Curry._1(cb, Curry._2(Toolkit__Primitives_Array.$$Array.get, markersSizes, i).originTaskIndex);
                                                    }));
                                            })
                                        }, (
                                          isPickupTimeline ? "sub" : ""
                                        ) + "timeline.pin" + String(i));
                            })),
                      className: Cx.cx([
                            "w-full absolute flex flex-row items-center",
                            isPickupTimeline ? "mt-[2px]" : "mt-1"
                          ])
                    })
              ],
              className: "relative w-full"
            });
}

function ApiComponents__RouteTimelineBanner$Timeline$Hours(props) {
  var startHour = props.startHour;
  var hours = Curry._2(Toolkit__Primitives_Array.$$Array.makeBy, (props.endHour - startHour | 0) + 1 | 0, (function (i) {
          var realStartHour = i + startHour | 0;
          return JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              className: "absolute h-full w-2 dotted-space-vertical  border-neutral-400 ml-2 mt-4"
                            }),
                        (
                          realStartHour <= 9 ? "0" : ""
                        ) + String(realStartHour) + "h"
                      ]
                    }, "hour-" + String(i));
        }));
  return JsxRuntime.jsx("div", {
              children: hours,
              className: "flex flex-row justify-between items-center w-full text-xs uppercase text-neutral-500 font-semibold mb-10"
            });
}

function ApiComponents__RouteTimelineBanner$Timeline$Render(props) {
  var selectedTaskIndex = props.selectedTaskIndex;
  var onMarkerClick = props.onMarkerClick;
  var tasks = props.tasks;
  var match = React.useState(function (param) {
        return false;
      });
  var setDisplayPickupTimelines = match[1];
  var displayPickupTimelines = match[0];
  var markers = makeMarkersFromTasks(tasks, 0);
  var pickupTimelines = Curry._3(Toolkit__Primitives_Array.$$Array.reduce, tasks, [], (function (acc, task) {
          var match = task.taskType;
          if (typeof match !== "object") {
            return acc;
          }
          var variant = match.NAME;
          if (variant === "multiplePickup") {
            var deliveriesId = match.VAL.deliveriesId;
            var dropoffTasks = makeMarkersFromTasks(Curry._2(Toolkit__Primitives_Array.$$Array.concat, [task], Curry._2(Toolkit__Primitives_Array.$$Array.keep, tasks, (function (task) {
                            var match = task.taskType;
                            if (typeof match === "object" && match.NAME === "singleDropoff") {
                              return deliveriesId.includes(match.VAL.deliveryId);
                            } else {
                              return false;
                            }
                          }))), acc.length);
            acc.push(dropoffTasks);
            return acc;
          }
          if (variant !== "singlePickup") {
            return acc;
          }
          var deliveryId = match.VAL.deliveryId;
          var dropoffTasks$1 = makeMarkersFromTasks(Curry._2(Toolkit__Primitives_Array.$$Array.concat, [task], Belt_Option.mapWithDefault(Curry._2(Toolkit__Primitives_Array.$$Array.getBy, tasks, (function (task) {
                              var match = task.taskType;
                              if (typeof match !== "object") {
                                return false;
                              }
                              if (match.NAME !== "singleDropoff") {
                                return false;
                              }
                              var d = match.VAL;
                              if (Belt_Option.isNone(d.deletionCause)) {
                                return d.deliveryId === deliveryId;
                              } else {
                                return false;
                              }
                            })), [], (function (d) {
                          return [d];
                        }))), acc.length);
          acc.push(dropoffTasks$1);
          return acc;
        }));
  var firstMarkerHour = Math.trunc(markers[0].hour);
  var marker = Curry._3(Toolkit__Primitives_Array.$$Array.slice, markers, -1, 1)[0];
  var lastMarkerHour = marker.hour + marker.task.transitionFactors.handlingTimeInMinutes / 60;
  var endHour = Math.trunc(lastMarkerHour + 1);
  var markersSizes = Curry._2(Toolkit__Primitives_Array.$$Array.map, markers, (function (marker) {
          return {
                  originTaskIndex: marker.task.taskOrder,
                  markerSize: (marker.hour - firstMarkerHour) * 100 / (endHour - firstMarkerHour | 0)
                };
        }));
  var hourPixelSize = 1130 / (endHour - firstMarkerHour | 0);
  var taskIndex = Curry._2(Toolkit__Primitives_Array.$$Array.getIndexBy, markers, (function (param) {
          switch (param.status) {
            case "Pending" :
            case "Current" :
                return true;
            default:
              return false;
          }
        }));
  var currentTaskIndex;
  if (taskIndex !== undefined) {
    currentTaskIndex = taskIndex;
  } else {
    switch (props.routeStatus) {
      case "done" :
      case "doneWithError" :
          currentTaskIndex = markers.length - 1 | 0;
          break;
      default:
        currentTaskIndex = taskIndex;
    }
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      pickupTimelines.length > 1 ? JsxRuntime.jsx("button", {
                              children: displayPickupTimelines ? JsxRuntime.jsx(Md.MdKeyboardArrowDown, {
                                      size: 21
                                    }) : JsxRuntime.jsx(Md.MdKeyboardArrowRight, {
                                      size: 21
                                    }),
                              className: Cx.cx([
                                    " text-white rounded-full absolute top-12 mt-[3px] -left-2 hover:bg-primary-700 z-1 hover:z-10",
                                    displayPickupTimelines ? "bg-primary-700" : "bg-neutral-400"
                                  ]),
                              onClick: (function (param) {
                                  Curry._1(setDisplayPickupTimelines, (function (v) {
                                          return !v;
                                        }));
                                })
                            }) : null,
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$Timeline$Hours, {
                                    startHour: firstMarkerHour,
                                    endHour: endHour
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$Timeline$TimelineRow, {
                                            currentTaskIndex: currentTaskIndex,
                                            markersSizes: markersSizes,
                                            markers: markers,
                                            onMarkerClick: onMarkerClick,
                                            selectedTaskIndex: selectedTaskIndex,
                                            hourPixelSize: hourPixelSize
                                          }),
                                      displayPickupTimelines ? Curry._2(Toolkit__Primitives_Array.$$Array.mapWithIndex, pickupTimelines, (function (i, markers) {
                                                var pickupMarkersSizes = Curry._2(Toolkit__Primitives_Array.$$Array.map, markers, (function (param) {
                                                        var task = param.task;
                                                        var taskIndex = Curry._2(Toolkit__Primitives_Array.$$Array.getIndexBy, tasks, (function (param) {
                                                                return param.taskOrder === task.taskOrder;
                                                              }));
                                                        return {
                                                                originTaskIndex: taskIndex,
                                                                markerSize: Curry._2(Toolkit__Primitives_Array.$$Array.get, markersSizes, taskIndex).markerSize
                                                              };
                                                      }));
                                                var currentTaskIndex$1 = Belt_Option.map(currentTaskIndex, (function (currentTaskIndex) {
                                                        return Curry._3(Toolkit__Primitives_Array.$$Array.reduceWithIndex, pickupMarkersSizes, 0, (function (acc, param, i) {
                                                                      if (currentTaskIndex >= param.originTaskIndex) {
                                                                        return i;
                                                                      } else {
                                                                        return acc;
                                                                      }
                                                                    }));
                                                      }));
                                                return JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$Timeline$TimelineRow, {
                                                            currentTaskIndex: currentTaskIndex$1,
                                                            markersSizes: pickupMarkersSizes,
                                                            markers: markers,
                                                            hideLabels: true,
                                                            isPickupTimeline: true,
                                                            onMarkerClick: onMarkerClick,
                                                            selectedTaskIndex: selectedTaskIndex,
                                                            hourPixelSize: hourPixelSize
                                                          }, "pickuppins" + String(i));
                                              })) : null
                                    ],
                                    className: Cx.cx([
                                          "flex flex-col gap-12",
                                          displayPickupTimelines ? "pb-2" : ""
                                        ])
                                  })
                            ],
                            className: "relative w-full ml-5"
                          })
                    ],
                    className: "flex flex-row w-full"
                  }),
              className: "w-full relative"
            });
}

function ApiComponents__RouteTimelineBanner$Timeline(props) {
  var routeDetails = props.routeDetails;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$Timeline$Render, {
                    tasks: routeDetails.performedTasks,
                    onMarkerClick: props.onMarkerClick,
                    selectedTaskIndex: props.selectedTaskIndex,
                    routeStatus: routeDetails.routeSummary.routeStatus
                  }),
              className: "flex flex-col gap-2 w-full px-2 pb-6"
            });
}

function ApiComponents__RouteTimelineBanner$MapModal$CourierDetails(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  var $$let = ColiswebApi__V6_Provider.Providers.Carriers.GetDetail;
  var match = $$Request.useFetcher(undefined, {
        response_decode: $$let.response_decode,
        config: $$let.config
      }, [
        Identifiers.ProviderId.makeFromTransporterId(props.transporterId),
        props.courierId
      ]);
  return JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_a4ca7cfd",
                    defaultMessage: "Livreur {carrierNumber}",
                    values: {
                      carrierNumber: match[0].carrierNumber
                    }
                  }),
              className: className
            });
}

function ApiComponents__RouteTimelineBanner$MapModal$TaskMarkerContent(props) {
  var marker = props.marker;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: marker.label,
                      className: Cx.cx([
                            "text-xs font-semibold font-sans border mb-1 w-5 h-5 rounded-md flex items-center justify-center shadow-md",
                            getLabelStyle(marker, true, undefined)
                          ])
                    }),
                JsxRuntime.jsx("div", {
                      children: getIcon(marker),
                      className: Cx.cx([
                            "w-5 h-5 rounded-full flex items-center justify-center",
                            getMarkerStyle(marker, true, undefined)
                          ])
                    })
              ],
              className: "cursor-pointer"
            });
}

function ApiComponents__RouteTimelineBanner$MapModal$TaskDescriptionCard(props) {
  var task = props.task;
  var intl = ReactIntl.useIntl();
  var currentUser = ApiComponents__Hooks.useUser();
  var match = task.taskType;
  var shippingDeliveryIdrefClipboard = Toolkit__Hooks.useClipboard(intl.formatMessage({
            id: "_24f9644e",
            defaultMessage: "Réf. copiée dans le presse-papiers"
          }), typeof match === "object" && match.NAME === "singleDropoff" ? match.VAL.deliveryId : "");
  var marker = makeMarkersFromTasks([task], 0)[0];
  var headerIcon = getIcon(marker);
  var match$1 = marker.status;
  var headerStyle;
  switch (match$1) {
    case "Pending" :
        headerStyle = "bg-gray-400";
        break;
    case "Current" :
        headerStyle = "bg-primary-600";
        break;
    case "Successful" :
        headerStyle = "bg-success-500";
        break;
    case "Delayed" :
    case "Issue" :
        headerStyle = "bg-warning-500";
        break;
    case "Failed" :
        headerStyle = "bg-danger-700";
        break;
    case "Deleted" :
    case "Pause" :
        headerStyle = "bg-neutral-900";
        break;
    
  }
  var match$2 = marker.status;
  var headerIconStyle;
  switch (match$2) {
    case "Pending" :
        headerIconStyle = "text-white";
        break;
    case "Current" :
        headerIconStyle = "text-primary-600";
        break;
    case "Successful" :
        headerIconStyle = "text-success-500";
        break;
    case "Delayed" :
    case "Issue" :
        headerIconStyle = "text-warning-500";
        break;
    case "Failed" :
        headerIconStyle = "text-danger-700";
        break;
    case "Deleted" :
    case "Pause" :
        headerIconStyle = "";
        break;
    
  }
  var acceptedProposal = Curry._2(Toolkit__Primitives_Array.$$Array.getBy, props.proposals, (function (proposal) {
          return proposal.status === "accepted";
        }));
  var match$3 = task.taskType;
  var isDropoff = typeof match$3 === "object" ? match$3.NAME === "singleDropoff" : false;
  var match$4 = task.taskType;
  var isBreak = typeof match$4 === "object" ? false : true;
  var match$5 = task.taskType;
  var match$6 = task.taskType;
  var tmp;
  if (typeof match$6 === "object") {
    var variant = match$6.NAME;
    if (variant === "singlePickup" || variant === "singleDropoff") {
      var transporterId = currentUser.user.transporterId;
      var baseUrl = transporterId !== undefined ? ColiswebApi__Env.boTransporterUrl + "/" + transporterId : ColiswebApi__Env.legacyUrl + "/admin";
      tmp = JsxRuntime.jsx("a", {
            children: JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                  size: "sm",
                  color: "neutral",
                  variant: "text",
                  className: "border border-info-600 w-full",
                  children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                            id: "_1b93491f",
                            defaultMessage: "Voir les détails"
                          }))
                }),
            href: baseUrl + "/deliveries/" + match$6.VAL.deliveryId,
            target: "_blank"
          });
    } else {
      tmp = null;
    }
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("header", {
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: headerIcon,
                                    className: Cx.cx([
                                          "bg-white rounded-full p-1",
                                          headerIconStyle
                                        ])
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: humanize(marker),
                                    className: "ml-2 uppercase text-white font-display text-sm font-semibold"
                                  })
                            ],
                            className: Cx.cx([
                                  "flex items-center pl-5 py-1 rounded-t-md",
                                  headerStyle
                                ])
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: isDropoff ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                    children: [
                                                      JsxRuntime.jsx("p", {
                                                            children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                  id: "routeDetails.deliveryNumber",
                                                                  defaultMessage: "N° Livraison"
                                                                }),
                                                            className: "text-neutral-500 font-medium text-sm mb-1 uppercase"
                                                          }),
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsxs("p", {
                                                                  children: [
                                                                    shippingDeliveryIdrefClipboard.value,
                                                                    JsxRuntime.jsx(Toolkit__Ui_IconButton.make, {
                                                                          size: "sm",
                                                                          variant: "text",
                                                                          onClick: (function (param) {
                                                                              Curry._1(shippingDeliveryIdrefClipboard.copy, undefined);
                                                                            }),
                                                                          ariaLabel: "copy",
                                                                          icon: JsxRuntime.jsx(Md.MdContentCopy, {})
                                                                        })
                                                                  ],
                                                                  className: Cx.cx(["font-mono flex flex-row justify-between items-center text-sm text-[#0054b3]"])
                                                                }),
                                                            className: "bg-gray-100 pl-2 py-1"
                                                          })
                                                    ]
                                                  }) : null,
                                            className: "mb-3"
                                          }),
                                      isBreak ? null : JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("p", {
                                                      children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                            id: "utils.delivery",
                                                            defaultMessage: "Livraison"
                                                          }),
                                                      className: "uppercase text-sm text-neutral-500"
                                                    }),
                                                JsxRuntime.jsx("p", {
                                                      children: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                            value: task.estimatedArrivalSlot.start,
                                                            weekday: "short",
                                                            month: "long",
                                                            day: "2-digit"
                                                          }),
                                                      className: "text-neutral-800 font-sans"
                                                    })
                                              ],
                                              className: "mb-3"
                                            }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("p", {
                                                    children: isBreak ? JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                            id: "_41d13330",
                                                            defaultMessage: "Créneau"
                                                          }) : JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                            id: "utils.orderedSlot",
                                                            defaultMessage: "Créneau commandé"
                                                          }),
                                                    className: "uppercase text-sm text-neutral-500"
                                                  }),
                                              JsxRuntime.jsxs("p", {
                                                    children: [
                                                      JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                            value: task.plannedSlot.start,
                                                            hour: "2-digit",
                                                            minute: "2-digit"
                                                          }),
                                                      " -",
                                                      JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                            value: task.plannedSlot.end,
                                                            hour: "2-digit",
                                                            minute: "2-digit"
                                                          })
                                                    ],
                                                    className: "text-neutral-800 font-sans"
                                                  })
                                            ],
                                            className: "mb-3"
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx("p", {
                                                            children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                  id: "utils.estimate",
                                                                  defaultMessage: "Estimation"
                                                                }),
                                                            className: "uppercase text-sm text-neutral-500"
                                                          }),
                                                      JsxRuntime.jsx("p", {
                                                            children: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                                  value: task.estimatedArrivalSlot.start,
                                                                  hour: "2-digit",
                                                                  minute: "2-digit"
                                                                }),
                                                            className: "text-neutral-800 font-sans"
                                                          })
                                                    ],
                                                    className: "w-1/2"
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: Belt_Option.mapWithDefault(task.realisedTime, null, (function (dateTime) {
                                                            return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                                        children: [
                                                                          JsxRuntime.jsx("p", {
                                                                                children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                                      id: "utils.completed",
                                                                                      defaultMessage: "Effectuée"
                                                                                    }),
                                                                                className: "uppercase text-sm text-neutral-500"
                                                                              }),
                                                                          JsxRuntime.jsx("p", {
                                                                                children: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                                                      value: dateTime,
                                                                                      hour: "2-digit",
                                                                                      minute: "2-digit"
                                                                                    }),
                                                                                className: "text-neutral-800 font-sans"
                                                                              })
                                                                        ]
                                                                      });
                                                          })),
                                                    className: "w-1/2"
                                                  })
                                            ],
                                            className: "flex flex-row mb-3"
                                          }),
                                      (
                                        typeof match$5 === "object" ? match$5.VAL.taskStatus === "deleted" : false
                                      ) ? JsxRuntime.jsx("span", {
                                              children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_339b7545",
                                                    defaultMessage: "Cette livraison a été retirée de la tournée."
                                                  }),
                                              className: "text-danger-700"
                                            }) : Belt_Option.mapWithDefault(task.address, null, (function (point) {
                                                var line1 = point.address;
                                                var city = point.city;
                                                var postalCode = point.postalCode;
                                                return JsxRuntime.jsxs("div", {
                                                            children: [
                                                              JsxRuntime.jsx("p", {
                                                                    children: point.name,
                                                                    className: "font-semibold text-neutral-700"
                                                                  }),
                                                              JsxRuntime.jsx("p", {
                                                                    children: line1,
                                                                    className: "text-neutral-600"
                                                                  }),
                                                              JsxRuntime.jsx("p", {
                                                                    children: postalCode + (" " + city),
                                                                    className: "text-neutral-600"
                                                                  })
                                                            ]
                                                          });
                                              })),
                                      Belt_Option.mapWithDefault(acceptedProposal, null, (function (proposal) {
                                              return Belt_Option.mapWithDefault(proposal.carrierId, null, (function (courierId) {
                                                            return JsxRuntime.jsxs("div", {
                                                                        children: [
                                                                          JsxRuntime.jsx("p", {
                                                                                children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                                      id: "utils.courier",
                                                                                      defaultMessage: "Livreur"
                                                                                    }),
                                                                                className: "uppercase text-sm text-neutral-500"
                                                                              }),
                                                                          JsxRuntime.jsx("p", {
                                                                                children: JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
                                                                                      fallbackRender: (function (param) {
                                                                                          var error = param.error;
                                                                                          if (typeof error === "object" && error.NAME === "default") {
                                                                                            var match = error.VAL.response;
                                                                                            if (match !== undefined && match.status === 404) {
                                                                                              return JsxRuntime.jsx("p", {
                                                                                                          children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                                                                id: "_c4756b12",
                                                                                                                defaultMessage: "Coursier supprimé."
                                                                                                              }),
                                                                                                          className: "text-danger-500"
                                                                                                        });
                                                                                            }
                                                                                            
                                                                                          }
                                                                                          return JsxRuntime.jsx("p", {
                                                                                                      children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                                                            id: "_c3860a17",
                                                                                                            defaultMessage: "Une erreur est survenue."
                                                                                                          }),
                                                                                                      className: "text-danger-500"
                                                                                                    });
                                                                                        }),
                                                                                      children: JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$MapModal$CourierDetails, {
                                                                                            transporterId: proposal.transporterId,
                                                                                            courierId: courierId
                                                                                          })
                                                                                    }),
                                                                                className: "text-neutral-800 font-sans"
                                                                              })
                                                                        ],
                                                                        className: "mt-3 mb-3"
                                                                      });
                                                          }));
                                            }))
                                    ],
                                    className: "flex flex-col"
                                  }),
                              tmp
                            ],
                            className: "relative flex flex-col justify-between px-5 pb-5 pt-3 leading-tight h-full"
                          })
                    ],
                    className: Cx.cx(["bg-white h-full shadow-sm rounded-md flex flex-col w-[16rem]"])
                  }),
              className: Cx.cx(["absolute p-4 z-50 h-[30rem]"])
            });
}

function ApiComponents__RouteTimelineBanner$MapModal$CarrierLocationBubbleContent(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: props.title,
                      className: "text-xxs text-neutral-600"
                    }),
                JsxRuntime.jsx("p", {
                      children: props.geocodedAt,
                      className: Cx.cx(["text-xs font-semibold font-sans text-neutral-800"])
                    })
              ],
              className: "w-[150px]"
            });
}

function ApiComponents__RouteTimelineBanner$MapModal(props) {
  var routeDetails = props.routeDetails;
  var mapDialog = Toolkit__Hooks.useDisclosure(undefined, undefined);
  var match = $$Request.useFetcher(undefined, {
        response_decode: ColiswebApi__V6_Authent.UpdateSession.response_decode,
        config: ColiswebApi__V6_Authent.UpdateSession.config
      }, Caml_option.some(undefined));
  var session = match[0];
  var performedTasksWithoutBreaks = Curry._2(Toolkit__Primitives_Array.$$Array.keep, routeDetails.performedTasks, (function (param) {
          return param.taskType !== "break";
        }));
  var match$1 = React.useState(function (param) {
        return performedTasksWithoutBreaks[0].taskOrder;
      });
  var setCurrentTaskDetails = match$1[1];
  var currentTaskDetails = match$1[0];
  var origin = Belt_Option.flatMap(Curry._2(Toolkit__Primitives_Array.$$Array.getBy, performedTasksWithoutBreaks, (function (task) {
              return Belt_Option.isSome(task.coordinates);
            })), (function (param) {
          return param.coordinates;
        }));
  var markersInfo = makeMarkersFromTasks(performedTasksWithoutBreaks, 0);
  var intl = ReactIntl.useIntl();
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Toolkit__Ui_IconButton.make, {
                      size: "sm",
                      onClick: (function (param) {
                          Curry._1(mapDialog.show, undefined);
                        }),
                      className: "e2e-mapModalButton ml-6 mr-2 bg-gray-200 mt-10 self-start",
                      icon: JsxRuntime.jsx(Fa.FaMap, {
                            size: 22,
                            className: "text-gray-700"
                          })
                    }),
                JsxRuntime.jsx(Toolkit__Ui_Modal.make, {
                      isVisible: mapDialog.isOpen,
                      title: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_20727aca",
                                defaultMessage: "Plan de tournée"
                              })),
                      body: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$Timeline, {
                                          routeDetails: routeDetails,
                                          onMarkerClick: (function (index) {
                                              Curry._1(setCurrentTaskDetails, (function (param) {
                                                      return index;
                                                    }));
                                            }),
                                          selectedTaskIndex: currentTaskDetails
                                        }),
                                    className: "px-10 mb-4"
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$MapModal$TaskDescriptionCard, {
                                            proposals: routeDetails.proposals,
                                            task: Curry._2(Toolkit__Primitives_Array.$$Array.getBy, routeDetails.performedTasks, (function (t) {
                                                    return t.taskOrder === currentTaskDetails;
                                                  }))
                                          }),
                                      Belt_Option.mapWithDefault(origin, null, (function (origin) {
                                              return JsxRuntime.jsx(ApiComponents__HereMap.make, {
                                                          mapOptions: {
                                                            zoom: 5,
                                                            center: Toolkit__Decoders.Coordinates.toHere(origin),
                                                            padding: {
                                                              top: 100,
                                                              left: 300,
                                                              bottom: 100,
                                                              right: 100
                                                            }
                                                          },
                                                          onLoad: (function (param) {
                                                              var ui = param.ui;
                                                              var mapInstance = param.mapInstance;
                                                              var markers = Curry._2(Toolkit__Primitives_Array.$$Array.map, markersInfo, (function (marker) {
                                                                      var coordinates = marker.task.coordinates;
                                                                      var mapMarker = new H.map.DomMarker({
                                                                            lat: coordinates.latitude,
                                                                            lng: coordinates.longitude
                                                                          }, {
                                                                            icon: Caml_option.some(new H.map.DomIcon(Server.renderToStaticMarkup(JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$MapModal$TaskMarkerContent, {
                                                                                              marker: marker
                                                                                            }))))
                                                                          });
                                                                      mapMarker.addEventListener("tap", (function ($$event) {
                                                                              var target = $$event.target;
                                                                              var position = target.getGeometry();
                                                                              mapInstance.setCenter(position, true);
                                                                              Curry._1(setCurrentTaskDetails, (function (param) {
                                                                                      return marker.task.taskOrder;
                                                                                    }));
                                                                            }));
                                                                      return mapMarker;
                                                                    }));
                                                              var group = new H.map.Group({
                                                                    objects: markers
                                                                  });
                                                              mapInstance.addObject(group);
                                                              mapInstance.getViewModel().setLookAtData({
                                                                    zoom: 10,
                                                                    bounds: group.getBoundingBox()
                                                                  });
                                                              if (session.isAdmin) {
                                                                $$Promise.tapOk(Curry._1(ColiswebApi__V5_Admin.GetRouteLatestLocations.$$Request.exec, routeDetails.routeSummary.routeId), (function (param) {
                                                                        var locationMarkers = Curry._2(Toolkit__Primitives_Array.$$Array.map, param.locations, (function (param) {
                                                                                var marker = new H.map.Marker({
                                                                                      lat: param.latitude,
                                                                                      lng: param.longitude
                                                                                    }, {});
                                                                                marker.setData(Server.renderToStaticMarkup(JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$MapModal$CarrierLocationBubbleContent, {
                                                                                              title: intl.formatMessage({
                                                                                                    id: "_7137a64f",
                                                                                                    defaultMessage: "Position du coursier"
                                                                                                  }),
                                                                                              geocodedAt: intl.formatDate(param.geocodedAt, {
                                                                                                    year: "numeric",
                                                                                                    month: "numeric",
                                                                                                    day: "numeric",
                                                                                                    hour: "numeric",
                                                                                                    minute: "numeric"
                                                                                                  })
                                                                                            })));
                                                                                marker.addEventListener("tap", (function ($$event) {
                                                                                        var target = $$event.target;
                                                                                        var position = target.getGeometry();
                                                                                        var bubble = new H.ui.InfoBubble(target.getGeometry(), {
                                                                                              content: target.getData()
                                                                                            });
                                                                                        ui.addBubble(bubble);
                                                                                        mapInstance.setCenter(position, true);
                                                                                      }));
                                                                                return marker;
                                                                              }));
                                                                        var group = new H.map.Group({
                                                                              objects: locationMarkers
                                                                            });
                                                                        mapInstance.addObject(group);
                                                                      }));
                                                                return ;
                                                              }
                                                              
                                                            }),
                                                          className: "h-full",
                                                          id: "e2e-gmapTranspoRouteDetails"
                                                        });
                                            }))
                                    ],
                                    className: "h-[30rem]"
                                  })
                            ]
                          }),
                      hide: (function (param) {
                          Curry._1(mapDialog.hide, undefined);
                        }),
                      size: {
                        NAME: "custom",
                        VAL: 1200
                      },
                      ariaLabel: "map modal"
                    })
              ]
            });
}

function ApiComponents__RouteTimelineBanner(props) {
  var routeDetails = props.routeDetails;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$Timeline, {
                      routeDetails: routeDetails,
                      onMarkerClick: props.onMarkerClick,
                      selectedTaskIndex: props.selectedTaskIndex
                    }),
                JsxRuntime.jsx(ApiComponents__RouteTimelineBanner$MapModal, {
                      routeDetails: routeDetails
                    })
              ],
              className: Cx.cx(["w-full bg-white flex flex-row px-6 py-2  rounded-b-[20px]"])
            });
}

var make = ApiComponents__RouteTimelineBanner;

export {
  make ,
}
/* react Not a pure module */
