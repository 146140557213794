

import * as ReactIntl from "react-intl";
import * as Fa from "react-icons/fa";
import * as JsxRuntime from "react/jsx-runtime";
import * as ApiComponents__VehicleFormatPopover from "./ApiComponents__VehicleFormatPopover.bs.js";

function ApiComponents__VehicleFormatTag(props) {
  var __showPopover = props.showPopover;
  var vehicleFormat = props.vehicleFormat;
  var showPopover = __showPopover !== undefined ? __showPopover : false;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                            id: "_9816a6e6",
                            defaultMessage: "Véhicule nécessaire"
                          }),
                      className: "uppercase text-neutral-600 font-semibold text-xs"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Fa.FaTruck, {
                                    size: 16
                                  }),
                              className: "p-1 bg-neutral-700 items-center text-white rounded-l"
                            }),
                        JsxRuntime.jsx("p", {
                              children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                    id: "_a1fa2777",
                                    defaultMessage: "Type"
                                  }),
                              className: "text-xs text-neutral-700 h-6 leading-6 border-t border-b border-neutral-400 uppercase px-1 bg-white"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: vehicleFormat.name,
                                      className: "text-neutral-600  text-sm font-semibold  w-full"
                                    }),
                                showPopover ? JsxRuntime.jsx(ApiComponents__VehicleFormatPopover.make, {
                                        vehicleFormat: vehicleFormat
                                      }) : null
                              ],
                              className: "w-full h-6 flex flex-row items-center border rounded-r px-2 border-neutral-400 bg-neutral-100"
                            })
                      ],
                      className: "flex flex-row items-center border border-transparent rounded  pr-1 "
                    })
              ]
            });
}

var make = ApiComponents__VehicleFormatTag;

export {
  make ,
}
/* react-intl Not a pure module */
