

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "../../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ColiswebApi__V5_Client from "../../v5/ColiswebApi__V5_Client.bs.js";
import * as Toolkit__Ui_Autocomplete from "../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Autocomplete.bs.js";
import * as Toolkit__Primitives_Array from "../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";

function ApiComponents__AutocompleteStores(props) {
  var __optionClassName = props.optionClassName;
  var __optionsContainerClassName = props.optionsContainerClassName;
  var __inputClassName = props.inputClassName;
  var __errorSearchClassName = props.errorSearchClassName;
  var __emptySearchClassName = props.emptySearchClassName;
  var __containerClassName = props.containerClassName;
  var defaultValue = props.defaultValue;
  var clientId = props.clientId;
  var onSelect = props.onSelect;
  var containerClassName = __containerClassName !== undefined ? __containerClassName : "";
  var emptySearchClassName = __emptySearchClassName !== undefined ? __emptySearchClassName : "";
  var errorSearchClassName = __errorSearchClassName !== undefined ? __errorSearchClassName : "";
  var inputClassName = __inputClassName !== undefined ? __inputClassName : "";
  var optionsContainerClassName = __optionsContainerClassName !== undefined ? __optionsContainerClassName : "";
  var optionClassName = __optionClassName !== undefined ? __optionClassName : "";
  var match = React.useState(function (param) {
        return defaultValue;
      });
  var setValue = match[1];
  var onSearch = function (search) {
    return $$Promise.mapError($$Promise.mapOk(Curry._1(ColiswebApi__V5_Client.SearchStore.Config.exec, [
                        clientId,
                        search
                      ]), (function (predictions) {
                      return Curry._2(Toolkit__Primitives_Array.$$Array.map, predictions, (function (store) {
                                    return {
                                            label: store.name,
                                            value: store
                                          };
                                  }));
                    })), (function (param) {
                  return "";
                }));
  };
  return JsxRuntime.jsx(Toolkit__Ui_Autocomplete.make, {
              onSelect: (function (suggestion) {
                  Curry._1(setValue, (function (param) {
                          return suggestion;
                        }));
                  Curry._1(onSelect, suggestion);
                }),
              value: match[0],
              onSearch: onSearch,
              emptySearchMessage: Caml_option.some(Belt_Option.getWithDefault(props.emptySearchMessage, JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                            id: "_1f475503",
                            defaultMessage: "Aucun magasin trouvé"
                          }))),
              containerClassName: containerClassName,
              emptySearchClassName: emptySearchClassName,
              errorSearchClassName: errorSearchClassName,
              inputClassName: inputClassName,
              optionsContainerClassName: optionsContainerClassName,
              optionClassName: optionClassName,
              minSearchLength: props.minSearchLength
            });
}

var make = ApiComponents__AutocompleteStores;

export {
  make ,
}
/* react Not a pure module */
