

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Toolkit__Intl from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/intl/Toolkit__Intl.bs.js";
import FrJson from "@root/locale/fr.json";
import * as Toolkit__Primitives_Array from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";

var messages = {
  fr: Curry._1(Toolkit__Primitives_Array.$$Array.concatMany, [FrJson])
};

function onError(msg) {
  console.log(msg);
}

var include = Toolkit__Intl.Make({
      messages: messages,
      defaultLocale: undefined,
      onError: onError
    });

var browserLocale = include.browserLocale;

var locale = include.locale;

var intl = include.intl;

var useIntl = include.useIntl;

var getIntl = include.getIntl;

var getCurrentLocale = include.getCurrentLocale;

var useCurrentLocale = include.useCurrentLocale;

var setCurrentLocale = include.setCurrentLocale;

var Provider = include.Provider;

export {
  browserLocale ,
  locale ,
  intl ,
  useIntl ,
  getIntl ,
  getCurrentLocale ,
  useCurrentLocale ,
  setCurrentLocale ,
  Provider ,
}
/* include Not a pure module */
