

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Primitives_Array from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";
import * as ApiComponents__CommunicationBanner from "../../src/components/ApiComponents__CommunicationBanner.bs.js";

function Playground_CommunicationBanner(props) {
  return JsxRuntime.jsx("div", {
              children: Curry._2(Toolkit__Primitives_Array.$$Array.map, [
                    "transporter",
                    "admin",
                    "client",
                    "store",
                    "hub"
                  ], (function (backOffice) {
                      return JsxRuntime.jsxs("section", {
                                  children: [
                                    JsxRuntime.jsxs("header", {
                                          children: [
                                            JsxRuntime.jsx("h2", {
                                                  children: "BO " + backOffice
                                                }),
                                            JsxRuntime.jsx("a", {
                                                  children: "unleash testing",
                                                  className: "text-info-600 underline",
                                                  href: "https://unleash.testing.colisweb.com/projects/default/features/communication_banner_bo_" + backOffice
                                                })
                                          ],
                                          className: "flex flex-row items-center gap-2"
                                        }),
                                    JsxRuntime.jsx(ApiComponents__CommunicationBanner.make, {
                                          backOffice: backOffice
                                        })
                                  ],
                                  className: "first:border-none border-t border-gray-400 pt-4"
                                }, backOffice);
                    })),
              className: "flex flex-col gap-4"
            });
}

var make = Playground_CommunicationBanner;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
